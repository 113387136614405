<template lang="pug">
  b-container(fluid="true")
    b-container(fluid="true")
      b-container.m-4(fluid="true")
        questionFooter(ref="footer" :questionNumber="questionNumber" :nQuestions="nQuestions" :dataAnswer="answered" @saveData="saveToProfileECS" :ecs_id="ecs_id")
        questionHeader(ref="header" :questionNumber="questionNumber" :nQuestions="nQuestions")
        b-row
          b-col(sm="12")
            h6
              label.col-form-label {{ selectedLabel }}
            b-form-group( label-size="lg")
              b-form-checkbox-group.row( v-if="question.id !== 152" stacked :id="question.id+'_checkbox'"  v-model="answered.answer" :name="question.id+'_checkbox'")
                template(v-for='answer in question.config.answers')
                  b-form-checkbox(@change="changeEvent" :value="answer" ).col-md-3 {{ getAnswers(answer) }}
                    div(v-if="question.id === 188" v-b-tooltip.hover.top="{ customClass: 'dropwdown-tooltip'}" :title="$t('tooltip_188.'+answer.custom_ids)").info i
              b-form-checkbox-group.row( v-if="question.id === 152" stacked :id="question.id+'_checkbox'"  v-model="answered.answer" :name="question.id+'_checkbox'")
                template(v-for='answer in question.config.answers' v-if="type_1_internal_id.indexOf(answer.internal_id) > -1")
                  b-form-checkbox(@change="changeEvent" :value="answer" ).col-md-3 {{ getAnswers(answer) }}
              b-form-checkbox-group.row.type-2-group( v-if="question.id === 152" stacked :id="question.id+'_checkbox'"  v-model="answered.answer" :name="question.id+'_checkbox'")
                template(v-for='answer in question.config.answers' v-if="type_2_internal_id.indexOf(answer.internal_id) > -1")
                  b-form-checkbox(@change="changeEvent" :value="answer" ).col-md-3 {{ getAnswers(answer) }}

              //b-form-checkbox-group(v-if="question.config.customAnswer === 'true'" :id="question.id+'_checkbox_custom'" :name="question.id+'_checkbox_custom'")
              b-form-checkbox(@change="changeEvent" v-if="question.customAnswer === 'true'") v-model="answered.isCustom" :value="true" :unchecked-value="false") {{  $t('ecsForm.skip.other') }}
              b-form-input(@change="changeEvent" v-if="question.customAnswer === 'true'") :disabled="customEnabled" v-model="answered.answer_custom_text" type="text").my-1.form-rounded



</template>

<script>
  import axios from 'axios';
  import utilMixin from "../../mixin/utilsMixin";
  import questionHeader from "../questionHeader";
  import questionFooter from "../questionFooter";

  export default {
    name: "questionMultiECS",
    components: {questionHeader, questionFooter},
    props: ['question','questionNumber','answer','ecs_id', 'exp_id', 'nQuestions'],
    data: function(){
      return{
        selected: "{}",
        selected_custom: "",
        answered: {
          isCustom: false,
          answer: [],
          answer_custom_text: ""
        },
        customAnswer: false,
        customAnswer_text: "",
        skip: {
          skipped: false,
          custom: false,
          answer: ""
        },
        firstAnswered: false,
        firstSkipped: false,
        langId: 1,
        type_1_internal_id : [
          "152_1607082058139",
          "152_1638184618935",
          "152_1638184724621",
          "152_1638184749667",
          "152_1638185715916",
          "152_1651584550748",
          "152_1651584569260",
          "152_1651584584720",
          "152_1651584591627",
          "152_1651584604316",
          "152_1651584623220",
          "152_1651584640996",
          "152_1651584655929",
          "152_1651584666171",
          "152_1651584674087",
          "152_1651584681831",
          "152_1651585232562"
        ],
        type_2_internal_id: ['152_1606230563826',
          "152_1606230649710",
          "152_1606230726561",
          "152_1606230687422",
          "152_1606230611498",
          "152_1651585170940",
          "152_1651585175534",
          "152_1651585108039",
          "152_1651585098513",
          "152_1651585155026",
          "152_1651585065016",
          "152_1651585089330",
          "152_1651585128146",
          "152_1651585144758",
          "152_1651585136766",
          "152_1651585162607",
          "152_1651585045473",
          "152_1651585184410",
          "152_1606231761706",
          "152_1606231789934",
          "152_1606231857409",
          "152_1606231941472",
          "152_1606231899791",
          "152_1606288981588",
          "152_1651585118838",
          "152_1651585053864"
        ]
      }
    },
    mounted: function(){
      console.log('question multiple',this.question);

      if(this.answer){
        if(!this.answer.answer.answer.length)
        {
          let answer = this.answer.answer.answer;
          this.answer.answer.answer = [
            answer
          ];
        }

        this.answered = this.answer.answer;
        //this.answered = [];
        if(this.answer.skip) {
          this.$refs.footer.skip = this.answer.skip;
          this.skip = {
            skipped: this.$refs.footer.skip.skipped,
            custom: this.$refs.footer.customAnswer(),
            answer: this.$refs.footer.customText()
          }
        }
      }
      this.firstAnsweredOrSkipped(this);
    },
    watch:{
      questionNumber: function(newVal,oldVal) {
        if(typeof(this.answer) === 'undefined'){
          console.log('reset answer');
          //this.resetAnswer();
          //this.resetSkip();

        }else{
          this.answered = this.answer.answer;
          if(this.hasBeenSkipped(this.answer)) {
            this.$refs.footer.skip = this.answer.skip;
            this.skip= {
              skipped: this.$refs.footer.skip.skipped,
              custom: this.$refs.footer.customAnswer(),
              answer: this.$refs.footer.customText()
            }
          }else{
            this.resetSkip();
          }
        }
        console.log('watch question number',this.answer);
        if(this.answer && !this.answer.answer.answer.length)
        {
          let answer = this.answer.answer.answer;
          this.answer.answer.answer = [
            answer
          ];

        }
        this.firstAnsweredOrSkipped(this)
      }
    },

    computed: {
      selectedLabel: function () {
        let langOpt = this.getLangOptions;
        if(this.selected.answer) {
          if(this.selected.answer.isCustom){
            return this.stringToLabel(this.question.config['text_lang_' + this.getLang])+': '+"Other, "+this.selected_custom;
          }
          else return this.stringToLabel(this.question.config['text_lang_' + this.getLang])+': '+ this.selected.answer[langOpt];
        }
        else return this.stringToLabel(this.question.config['text_lang_' + this.getLang])+': ';
      },
      customEnabled: function () {
        if(!this.answered.isCustom){
          this.customAnswer_text = "";
          return true;
        }
        else return false;
      },
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      },
      getLangOptions: function () {
        let languageId = this.$store.getters.getLanguage.id;
        let language = "";
        switch (languageId) {
          case 2:
            language = "text_cat";
            break;
          case 3:
            language = "text_fr";
            break;
          case 4:
            language = "text_es";
            break;
          case 5:
            language = "text_de";
            break;
          case 6:
            language = "text_pt";
            break;
          case 7:
            language = "text_sl";
            break;
          default:
            language = "text_"+this.$store.getters.getLanguage.lang;
            break;
        }
        return language;
      }
    },

    mixins: [utilMixin],
    methods:{
      changeEvent: function(){
        this.$refs.footer.onChangeEventHandler();
      },
      saveToProfileECS: function (text) {

        console.log("Save Question: ", this.question.id);
        if (this.$refs.footer.skipQuestion()) {
          this.skip.skipped = this.$refs.footer.skip.skipped;
          this.skip.custom = this.$refs.footer.customAnswer();
          this.skip.answer = this.$refs.footer.customText();
        }

        let _this = this;

        if(!_this.hasBeenAnsweredOrSkipped(_this) && text==="next"){
          //this.$emit('sendResponse', undefined, text);
          this.$emit('sendResponse', undefined, text)
        }else if(!_this.hasBeenAnsweredOrSkipped(_this) && text==="previous"){
          this.$emit('sendResponse', undefined, text);
        } else {
          if(_this.firstAnswered && _this.hasBeenSkipped(_this)){
            _this.resetAnswer();
            _this.save(text)
          }else if(_this.firstSkipped && _this.hasBeenAnswered(_this)){
            _this.resetSkip()
            _this.save(text);
          }else if(_this.hasBeenAnswered(_this) && _this.hasBeenSkipped(_this)){
            //this.$emit('sendResponse', undefined, text);
            this.$emit('sendResponse', undefined, text)
          }else{
            _this.save(text);
          }
        }
      },
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          text = this.question.config['text_lang_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },


      save(text){
        let _this = this;
        this.$emit('sendResponse', {
          id_question: _this.question.id,
          id_category: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip}, text);

        if (this.exp_id) {
          var ecs_exp_id = this.exp_id
        } else {
          var ecs_exp_id = this.ecs_id
        }

        axios.post(this.$parent.url_answer, {
          question_id: _this.question.id,
          ecs_id: ecs_exp_id,
          category_id: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip
        })
          .then(function (response) {
            console.log(response);
            console.log("Done Modify question");

          })
          .catch(function (error) {
            console.log(error);
          });
        if(_this.questionNumber+1 === _this.nQuestions && text == 'next'){
          _this.$refs.footer.showSubmitModal();
        }
      },


      resetAnswer: function () {
        this.answered.isCustom = false;
        this.answered.answer = [];
        this.answered.answer_custom_text = "";
      },
      hasBeenAnswered: function (answer) {

        if(answer) return (!answer.answered.isCustom && answer.answered.answer.length> 0) || (answer.answered.isCustom && answer.answered.answer_custom_text !== "");
        return false;
      },

      hasBeenSkipped: function (answer) {
        if (answer && answer.skip) return answer.skip.skipped || answer.skip.custom;
        return false;
      },

      resetSkip: function () {
        this.$refs.footer.resetModal();
        this.skip = {
          skipped: "",
          custom: false,
          answer: ""
        }
      },

      hasBeenAnsweredOrSkipped: function (answer) {
        return this.hasBeenSkipped(answer) || this.hasBeenAnswered(answer);
      },

      firstAnsweredOrSkipped: function (answer) {
        this.firstAnswered = this.hasBeenAnswered(answer);
        this.firstSkipped = this.hasBeenSkipped(answer);
      },

      getAnswers: function (answer) {
        if(answer[this.getLangOptions])
          return answer[this.getLangOptions];
        else
          return answer['text_1'];
      }
    }
  }
</script>

<style scoped>
  .questions{
    background-color: var(--light-gray-secondary);
  }

  .form-rounded {
    border-radius: 1rem;
  }

  .info {
    display:inline-block;
    padding:0;
    color:var(--green-primary);
    font-size:8px;
    border-radius:50%;
    border:solid 3px var(--green-primary);
    width:20px;
    height:20px;
    margin-left: 5px;
    font-weight:bold;
    text-align:center;
    position:relative;
    cursor:pointer;
    vertical-align:top;
  }

  .multiselect__option--highlight .info
  {
    color:white;
    border:solid 3px white;

  }

  .type-2-group
  {
    border-top: 1px solid gray;
    margin-top:15px;
    padding-top:15px;
  }
</style>

<style>
span span
{
  background:transparent !important;
}

.dropwdown-tooltip .arrow::before {
  border-left-color: #A0CF84 !important;
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
}

.dropdown-tooltip .tooltip-inner,.tooltip-inner{
  background: #A0CF84 !important;
}

</style>