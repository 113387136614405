<template lang="pug">
    b-row.mt-2.mb-2
        b-col(sm="12")
            h6
              //label.col-form-label(v-html="stringToLabel(question['text_' + getLang])")
            b-form-input(@change="changeEvent" :id="'range_'+question.id" v-model="dataResult" type="range" :min="question.minimumvalue" :max="question.maxvalue" :step="question.step")
            div.mt-2 {{ $t('ecsForm.rangeValue') }}: {{ dataResult }}

</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";

  export default {

    name: "questionRadioCol",
    props: ['question', 'answered'],
    mixins: [utilMixin],
    data: function() {
      return{
        dataResult: null,
        langId: 1
      }
    },
    mounted: function(){
    },
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          let text = this.question['text_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      changeEvent: function(){
        this.$emit("changeEvent", this);
      },
      getDataResult: function () {
        if (this.dataResult != null && this.dataResult !== '') {
          return this.dataResult;
        }
        else return null;
      }
    },
    computed:{
      options: function () {
        let ret = [];
        if(!this.question.answers) return ret;
        let lang = this.getLang;
        this.question.answers.forEach(function (answer) {
          if(!answer['text' + lang] || answer['text' + lang] === '')
            lang = '1'
          ret.push({
            text: answer['text_' + lang],
            value: {
              id: answer.internal_id,
              text: answer['text_' + lang],
              id_custom: _this.question.custom_ids,
            }
          });
        });
        return ret;
      },
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      }
    }
  }
</script>

<style scoped>

</style>