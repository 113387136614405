<template lang="pug">
  .bar(style="width: 100%")
    b-row.progress_bar
      b-col.p-1(v-for="(n) in steps-1" v-bind:key="n" :id="getID(n,'bar')" :class="getClass(n)" @click="setStep(n)")
        span
        template(v-if="hasLabels")
          b-row.mt-2
            p.text-center(style="margin-left: -1.5rem" :id="getID(n,'text')" :style="fontWeight(n)") {{ getLabel(n) }}
      b-col.p-1.final(v-bind:key=steps :id="getID(steps,'bar')" :class="getClass(steps)")
        b-row.mt-3
          p.text-center(style="margin-left: -1.5rem" :id="getID(steps,'text')" :style="fontWeight(steps)") {{ getLabel(steps) }}

</template>

<script>

  export default {
    name: "ProgressBar",
    props: {
      max: Number,
      value: Number,
      stepsLabels: {
        type: Array,
        default: [],
        required: false,
      },
      keyPoints: {
        type: Array,
        default: [],
        required: false,
      },
      step: Number,
      progressStep: Number
    },
    data: function () {
      return {
        progressValue: this.value,
        steps: this.max,
        labels: this.stepsLabels
      }
    },
    computed: {
      hasLabels: function () {
        return (this.labels.length !== 0);
      },
    },
    methods: {
      getID: function(index, type) {
        if(type === 'bar'){
          return ("step_" + index);
        }
        else{
          return ("text_" + index);
        }
      },
      getLabel: function(index){
        return (this.labels[index-1]);
      },
      getClass: function (index) {
        var className ="";
        if(this.progressValue+1 >= index){
          className += " active";
          if(this.progressValue+1 > index)
            className += " completed";
        }
        if(this.keyPoints.includes(index)){
          className += " key-point";
        }
        if(this.progressStep == index){
          className += " current"
        }
        return className;
      },
      fontWeight: function (index) {
        var fontWeight = "";
        if(this.progressValue+1 >= index){
          fontWeight = "font-weight: var(--bold-text)";
        }
        return fontWeight;
      },
      setStep: function(step){
        console.log("step: "+step)
        this.$emit("setStep", step)
      }
    }
  }

</script>

<style scoped>
  p{
    color: var(--green-primary);
    font-size: smaller;
  }

  .key-point:before{
    width: 1.5em !important;
    height: 1.5em !important;
    left: -0.75em !important;
    top: -0.35em !important;
  }

  .progress_bar > div.current:before{
    background: var(--dark-red) !important;
    border-color: var(--dark-red) !important;
  }

  .progress_bar > div.current:after{
    background: var(--dark-red) !important;
  }

  .key-point.active:after{
    left: -0.5em;
    width: 1em;
    height: 1em;
    top: -0.1em;
    border-radius: 2em;
  }

  .progress_bar > div span{
    width:auto;
    display:block;
    overflow:hidden;
    top: 0.17em;
    left: 0;
    right: 0;
    margin: auto;
    background: var(--green-secondary);
    height: 0.5em;
    z-index: 1;
  }

  .progress_bar > div.active:before{
    background: #fff;
    border: 0.1em solid var(--gree_primary);
    box-sizing: border-box;
  }

  .progress_bar > div:before{
    content: '';
    display: block;
    position: absolute;
    left: -0.5em;
    width: 1em;
    height: 1em;
    top: -0.07em;
    border-radius: 2em;
    z-index: 10;
    background: #fff;
    border: 0.1em solid var(--green-primary);
    box-sizing: border-box;
  }
  .progress_bar > div:after{
    content: '';
    display: block;
    position: absolute;
    left: -0.25em;
    width: 0.5em;
    height: 0.5em;
    top: 0.18em;
    border-radius: 2em;
    z-index: 10;
    background: #fff;
    box-sizing: border-box;
    -webkit-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -0-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
  .progress_bar > div.active:after{
    background: var(--green-primary);
    cursor: pointer;
  }
  .progress_bar > div span:before{
    content: '';
    width:0px;
    position:absolute;
    display:block;
    top: 0.17em;
    margin: auto;
    background: var(--green-primary);
    border-radius:5px;
    height: .5em;
    z-index: 2;
    -webkit-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -0-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
  .progress_bar > div.active span:before{
    width: 50%;
  }

  .progress_bar > div.active.completed span:before{
    width: 100%;
  }

  .final {
    max-width: fit-content;
  }

</style>
