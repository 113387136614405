<template lang="pug">
  .login
    //Market place
    b-container.p-0(fluid="true").banner
      b-img(src="/images/banner-login.png" fluid alt="Fluid image;")
      div.bannerTitle
        p Market place

    b-container.px-4.pb-4(fluid="true")

      p The EdiCitNet Marketplace is a digital, common platform for entrepreneurs and initiatives creating solutions for urban food systems all across the globe. Our goal is to connect Edible City Solutions (ECS), entrepreneurs, institutions, NGOs and other third parties at the local, regional and global level to enable global knowledge sharing, scalability, replicability, and to create complimentary collaborations with other enterprises worldwide.

      h6 What can the marketplace offer your business?

      ul
        li Collaborative networking: roundtables, working groups, and workshops curated based on your needs
        li Expertise: support from experts to develop your ECS products/services further
        li Promotion: a global platform to highlight your ECS services and products to find your target consumers and partners
        li Free business consultation: a tailor-made professional consulting service based on the maturity, position in value chain, needs, scalability, replicability of your business

      br
      br
      h5(style="text-align:center;")
        a(href="https://www.edicitnet.com/biz/" target="_blank") Join our ECS community and let your idea grow



</template>

<script>

  export default {
    name: 'biz',
    data: function () {
      return {
      }
    },
    components: {
    }
  }
</script>

<style scoped>
  .login{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  .login h5{
    color: var(--green-primary);
    font-weight: var(--bold-text);
  }

  .login-form-1 h5 {
    text-align: center;
    color: black;
  }

  .login-container form {
    padding: 5%;
    background-color: white;
  }

  .home {
    background-color: white;
    border-radius: 0rem;
    padding: 2rem;
    margin: 2rem 2rem 2rem 2rem;
    min-height: var(--height-child-comp);
  }

  a, h6 {
    color: var(--green-primary);
  }

</style>
