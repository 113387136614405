<template lang="pug">
  .about_functionalities_assessment.generalBackground
    b-container.p-4.m-4(fluid="true")
      b-row
        b-col
          p(style="white-space: pre-line") {{ $t('abouts.functionalities_assessment.text') }}
          //b-img(:src="$t('abouts.functionalities_assessment.image')" style="width: 80%")


</template>

<script>
export default {
name: "functionalities_assessment"
}
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  h5 {
    color: var(--green-primary);
  }

</style>
