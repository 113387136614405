<template lang="pug">
  .about_content_solutions.generalBackground
    b-container.p-4.m-4(fluid="true")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.whatisECS icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.whatisECS) {{ $t('abouts.content_solutions.text.subtitle1') }}
          b-collapse.collapsed(id="whatisECS" )
            p(style="white-space: pre-line") {{ $t('abouts.content_solutions.text.text1') }}
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.whatisProfile icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.whatisProfile) {{ $t('abouts.content_solutions.text.subtitle2') }}
          b-collapse.collapsed(id="whatisProfile" )
            p(style="white-space: pre-line") {{ $t('abouts.content_solutions.text.text2') }}
            p.text-center
            b-img(:src="$t('abouts.content_solutions.image')" style="width: 50%; margin-bottom: 20px;")

            h5 {{ $t('abouts.content_solutions.text.subtitle3') }}
            p(style="white-space: pre-line") {{ $t('abouts.content_solutions.text.text3') }}
            h5 {{ $t('abouts.content_solutions.text.subtitle4') }}
            ul
            li {{ $t('abouts.content_solutions.text.list4.line1') }}
            li {{ $t('abouts.content_solutions.text.list4.line2') }}
            li {{ $t('abouts.content_solutions.text.list4.line3') }}
            li {{ $t('abouts.content_solutions.text.list4.line4') }}
            br
            h5 {{ $t('abouts.content_solutions.text.subtitle5') }}
            ul
              li {{ $t('abouts.content_solutions.text.list5.line1') }}
              li {{ $t('abouts.content_solutions.text.list5.line2') }}
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.whatisExperience icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.whatisExperience) {{ $t('abouts.content_solutions.text.subtitle6') }}
          b-collapse.collapsed(id="whatisExperience" )
            p(style="white-space: pre-line") {{ $t('abouts.content_solutions.text.text6') }}
            h5 {{ $t('abouts.content_solutions.text.subtitle7') }}
            p(style="white-space: pre-line") {{ $t('abouts.content_solutions.text.text7') }}
            h5 {{ $t('abouts.content_solutions.text.subtitle8') }}
            ul
              li {{ $t('abouts.content_solutions.text.list8.line1') }}
              li {{ $t('abouts.content_solutions.text.list8.line2') }}
              li {{ $t('abouts.content_solutions.text.list8.line3') }}



</template>

<script>
export default {
name: "toolbox"
}
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

    a, a[type=button] {
      color: var(--green-primary);
      align-self: center;
      font-weight: var(--bold-text);
    }
    a:hover, a:not([href]):hover, a[type=button]:hover {
      color: var(--dark-red);
      text-decoration: none;
    }
    a:active, a[type=button]:active{
      color: var(--dark-red);
    }

    .coll-button{
      color: var(--green-primary);
    }

    .collapsed > a {
      font-weight: var(--text-weight);
    }

    .b-icon.bi.collapsed {
      transform: rotate(180deg);
    }


</style>
