<template lang="pug">
  .edibleMap
    b-jumbotron(header="")
      b-container.map.p-4(fluid="true")
        b-row
          b-col(cols=12)
            div#map
            FilterZone(:custom_types_ecs="custom_types_ecs" :allAnswers="name_answers")
    Counter
    b-row
      b-col(cols="6")
      b-col(cols="6")
        b-row.pb-4
          b-col(cols="9")
            b-row
              b-col.align-self-center
                span.float-right {{$t('buttons.downloadFormat')}}
              b-col
                b-form-select(v-model="downloadFormat" :options="options")
          b-col
            b-button.buttonGreen(pill @click="downloadData" v-if="loading==false" ) {{$t('buttons.downloadData')}}
            img(src="/images/loading.gif" class="loading" v-if="loading==true")

    b-modal(id="downloadModal" :title="$t('edibleList.modal.title')" @ok="handleOk" ref="downloadModal")
      label {{$t('edibleList.modal.name')}}:
      b-form-input(v-model="modal.name" id="input")
      br
      label {{$t('edibleList.modal.number')}}:
      b-form-input(v-model="modal.number")
      br
      label {{$t('edibleList.modal.email')}}:
      b-form-input(v-model="modal.email")
      br
      label {{$t('edibleList.modal.area')}}:
      b-form-select.formulari(v-model="modal.area" :options="modalOptions")
      div(v-if="modal.area=='Other'")
      br
      label {{$t('edibleList.modal.options.specify')}}:
      b-form-input(v-model="modal.otherArea")
      label {{$t('edibleList.modal.area')}}:
      b-form-select.formulari(v-model="modal.area" :options="modalOptions")
      div(v-if="modal.area!='Citizen'")
      br
      label {{$t('edibleList.modal.institution')}}:
      b-form-input(v-model="modal.institution")
      p
      label {{$t('edibleList.modal.purpose')}}:
      b-form-textarea(v-model="modal.purpose" rows="5")
      br
      b-form-checkbox(v-model="modalCheck1") {{$t('edibleList.modal.check1')}}
      b-form-checkbox(v-model="modalCheck2") {{$t('edibleList.modal.check2')}}


  </div>
</template>

<script>
  // @ is an alias to /src
  import MarkerClusterer from "@googlemaps/markerclustererplus";
  import gmapsInit from "../../utils/gmaps.js";
  import FilterZone from "@/components/home/FilterZone";
  import Counter from "@/components/home/Counter";
  import axios from "axios";

  import L from 'leaflet';
  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
  import XLSX from "xlsx";

  export default {
    name: "EdibleMap",
    components: {
      FilterZone,
      Counter,
      LMap,
      LTileLayer,
      LMarker,
    },
    data: function () {
      return {
        modal: {
          name: '',
          email: '',
          number: '',
          area: 'Research',
          otherArea: '',
          institution: '',
          purpose: ''
        },
        modalOptions: [
          {value: 'Research', text: this.$t('edibleList.modal.options.research')},
          {value: 'Public authority', text: this.$t('edibleList.modal.options.public')},
          {value: 'Private company', text: this.$t('edibleList.modal.options.private')},
          {value: 'NGO', text: this.$t('edibleList.modal.options.NGO')},
          {value: 'Citizen', text: this.$t('edibleList.modal.options.citizen')},
          {value: 'Other', text: this.$t('edibleList.modal.options.other')}
        ],
        modalCheck1: 'false',
        modalCheck2: 'false',
        downloadFormat: 'xlsx',
        options: [
          //{value: 'csv', text: 'CSV'},
          {value: 'xlsx',text: this.$t('buttons.excelFile')},
          {value: 'json',text: 'JSON'}
        ],
        error_text:'',
        loading: false,
        map: null,
        allAnswers: [],
        custom_types_ecs: [],
        filteredAnswers: [],
        markers: [],
        filter: null,
        filter_names: null,
        cluster: null
      }
    },
    methods: {
      downloadData(){
        let array_ids = [];
        this.filteredAnswers.forEach(ecs => {
          array_ids.push(ecs.ecs_id)
        })
        this.array_ids = array_ids

        this.$refs['downloadModal'].show()
      },
      handleOk(bvModalEvt) {
        bvModalEvt.preventDefault()

        if (!this.checkFormValidity()) return
        let _this = this;
        axios.post('answers/downloadInfo',{
          info: _this.modal,
          ecs_ids: _this.array_ids
        })
            .then(function (response) {
              console.log(response);
              console.log("Information saved");
            })
            .catch(function (error) {
              console.log(error);
            });

        this.$nextTick(() => {
          this.$bvModal.hide('downloadModal')
        })
        this.modalClosed()

      },
      checkFormValidity(){
        if (
            this.modal.name == '' ||
            this.modal.number == '' ||
            this.modal.email == '' ||
            (this.modal.area == 'Other' && this.modal.otherArea == '') ||
            (this.modal.institution == '' && this.modal.area != 'Citizen') ||
            this.modal.purpose == '' ||
            this.modalCheck1 == false ||
            this.modalCheck2 == false
        ) {
          alert(this.$t('edibleList.modal.informationIncomplete'));
          return false;
        }
        return true;
      },
      async modalClosed(){
        this.loading = true;

        if (!this.$store.getters.getLanguage || this.$store.getters.getLanguage.lang == "en"){
          var lang = "text_1"
        } else  {
          var lang = "text_"+this.$store.getters.getLanguage.lang
        }

        let _this = this;

        await axios.post('answers/getECSParsedAnswers',
            {"ids_ecs": _this.array_ids, "lang": lang}
        )
            .then(function (response){
              console.log(response)
              if (_this.downloadFormat == 'csv')
                _this.downloadCsv(response.data);
              else if (_this.downloadFormat == 'xlsx')
                _this.downloadXlsx(response.data);
              else
                _this.downloadJson(response.data)
            })
            .catch(function (error){
              console.log('Error: '+error)
            })
            .finally(function(){
              _this.loading = false;
            })
      },
      downloadCsv(array){

        var fileTitle = 'ecs_data'

        var headers = '';
        for (let [key,value] of Object.entries(array[0])) {
          if (headers != '') headers += ';'
          headers += '"'+key+'"'
        }
        var csv = headers + '\r\n'

        var array = typeof array != 'object' ? JSON.parse(array) : array;

        for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
            if (line != '') line += ';'
            line += typeof array[i][index] == 'number' ? array[i][index] : '"'+array[i][index]+'"';
          }
          csv += line + '\r\n';
        }

        var exportedFilename = fileTitle + '.csv' || 'export.csv';
        var blob = new Blob([csv], { type: 'text/csv' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      downloadXlsx(data){
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'ecs_data');
        XLSX.writeFile(wb, 'ecs_data.xlsx')
      }, // end downloadXlsx
      downloadJson(data){
        var json = JSON.stringify(data)
        var exportedFilename = 'ecs_data.json';
        var blob = new Blob([json], { type: 'text/json' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      getImage: async function(id){
        let _this = this;
        return new Promise(resolve => {
          axios.post('answers/getECSProfileImage',
              {"id_ecs": id}
          )
              .then(function (response){
                //console.log("image response", response.data, response);
                resolve(response.data);
              })
              .catch(function (error) {
                //console.log("Error getting answers");
                //console.log(error);
                resolve("");
              });
        })
      },
      searchLocation: async function (location, showLocation = true,callback)
      {
        //this.removeCluster()
        if (location.length <= 0)
        {
          this.map.setZoom(5);
          return;
        }
        const google = await gmapsInit();
        const _this = this;
        //
        var request = {
          query: location === "" ? "Girona" : location,
          fields: ['name', 'geometry'],
        };

        if (_this.map == null) {
          _this.map = new google.maps.Map(this.$el.querySelector('#map'), {
            zoom: 5,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          });
        }

        if(showLocation) {
          const service = new google.maps.places.PlacesService(_this.map);

          service.findPlaceFromQuery(request, async function (results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              _this.map.setCenter(results[0].geometry.location);
              _this.map.setZoom(10);
              callback();
            }else
            {
              callback();
            }
          });
        }
      },
      createMarkerCoordinates: async function(place,name, answer) {
        const _this = this;
        const google = await gmapsInit();
        _this.valid = true;
        let icon;
        if(answer.interactive === "\"147.0\""){
          icon ={
            url: "images/marker_ecs_no_interactive.png",
            size: new google.maps.Size(30,40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15)
          };
        }
        else {
          icon = {
            url: "images/marker_ecs_02.png",
            size: new google.maps.Size(30, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15)
          };
        }
        const marker = new google.maps.Marker({
          map: _this.map,
          position: place.location,
          icon: icon

        });

        let imageName = await _this.getImage(answer.ecs_id);
        //console.log("imageName: ",imageName);
        let imageURL = "";
        if(imageName === "" || imageName === undefined) imageURL = 'https://toolbox.edicitnet.com/images/addImage.png';
        else imageURL = 'https://edicitnet.icra.cat/images/'+imageName;

        const contentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h6 id="firstHeading" class="firstHeading">'+name+'</h6>' +
            '<div id="bodyContent">' +
            '<img img data-v-0d1f56ac="" src="'+imageURL+'" onerror="this.onerror=null;this.src=\'https://toolbox.edicitnet.com/images/addImage.png\';" width="50" style=" float: left; margin-right: 5px;">'+
            "<p>"+answer.description+"</p>" +
            "<a href=\"/showEcsProfile/"+answer.ecs_id+"\">Profile</a>"+
            "</div>" +
            "</div>";

        let infowindow = new google.maps.InfoWindow({
          content: contentString
        });

        marker.addListener('click', function() {
          console.log("click on marker coordinate",answer.ecs_id, imageName, contentString)
          infowindow.open(_this.map, marker);
        });

        this.markers.push(marker);
      },
      createMarker: async function (place, name, answer) {
        const _this = this;
        const google = await gmapsInit();
        _this.valid = true;
        const icon ={
          url: "/images/marker_ecs_02.png",
          size: new google.maps.Size(30,40),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(15, 15)
        };

        const marker = new google.maps.Marker({
          map: _this.map,
          position: place.geometry.location,
          icon: icon

        });

        let imageName = await _this.getImage(answer.ecs_id);
        let imageURL = "";
        if(imageName === "" || imageName === undefined) imageURL = 'https://toolbox.edicitnet.com/images/addImage.png';
        else imageURL = 'https://edicitnet.icra.cat/images/'+imageName;

        const contentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h6 id="firstHeading" class="firstHeading">'+name+'</h6>' +
            '<div id="bodyContent">' +
            '<img img data-v-0d1f56ac="" src="'+imageURL+'" onerror="this.onerror=null;this.src=\'https://toolbox.edicitnet.com/images/addImage.png\';" width="50" style=" float: left; margin-right: 5px;">'+
            "<p>"+answer.description+"</p>" +
            "<a href=\"/showEcsProfile/"+answer.ecs_id+"\">Profile</a>"+
            "</div>" +
            "</div>";

        let infowindow = new google.maps.InfoWindow({
          content: contentString
        });

        marker.addListener('click', function() {
          console.log("click on marker",answer.ecs_id, imageName, contentString)
          infowindow.open(_this.map, marker);
        });

        this.markers.push(marker)
        // Add a marker clusterer to manage the markers.
        // console.log("before cluster")
        // this.cluster = new MarkerClusterer(this.map, this.markers, {
        //   imagePath:
        //     "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        // });
      },
      geolocation: async function () {
        if (this.location.length < 0) return;
        const google = await gmapsInit();
        const _this = this;
        const infoWindow = new google.maps.InfoWindow;

        if (_this.map == null) {
          _this.map = new google.maps.Map(this.$el.querySelector('#map'), {
            zoom: 5,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          });
        }
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            this.location = position;
            infoWindow.setPosition(pos);
            infoWindow.setContent('Location found.');
            infoWindow.open(_this.map);
            _this.map.setCenter(pos);
          }, function () {
            handleLocationError(true, infoWindow, _this.map.getCenter());
          });
        } else {
          // Browser doesn't support Geolocation
          var request = {
            query: this.location === "" ? "Girona" : this.location,
            fields: ['name', 'geometry'],
          };
          const service = new google.maps.places.PlacesService(_this.map);

          service.findPlaceFromQuery(request, async function (results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              console.log(results[0].geometry.location);
              _this.map.setCenter({lat: 45, lng: 14});
            }
          });
        }

        /*var request = {
          location: this.location === "" ? "Girona" : this.location,
          radius: '500'
        };
        const service = new google.maps.places.PlacesService(_this.map);

        //_this.map.setCenter(this.location);

        service.nearbySearch(request, async function(results, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            console.log(results[0].geometry.location);
            _this.map.setCenter(location);
          }
        });*/
      },
      setFilterNames: function(filter)
      {
        this.filter_names = filter;
      },
      setFilter: function (filter){
        if(filter.location) {
          var _this = this;
          var callback = function(){
            _this.filter = filter;
            _this.filterData();
          };
          this.searchLocation(filter.location, true,callback);
        }
        else
        {
          this.filter = filter;
          this.filterData();
        }

      },
      filterData: function (){
        let filter = this.filter;
        //console.log(filter);
        /*var bounds = this.map.getBounds();
        console.log(this.map);
        console.log(this.map.getBounds());
        console.log(this.markers[0]);
        console.log(this.markers[0].position.lat());
        console.log(this.markers[0].position.lng());
        console.log(this.markers[0]);*/
        this.filteredAnswers = this.allAnswers.filter(answer =>{
          //console.log(answer);
          if(!answer)
            return false;
          if(!filter.advanced_search)
          {
            if((!filter.showInteractive && !filter.showNonInteractive) || (filter.showInteractive && !filter.showNonInteractive && answer.interactive == "\"147.0\"") || (filter.showNonInteractive && !filter.showInteractive &&  answer.interactive !== "\"147.0\""))
            {
              return false;
            }
            if(filter.name && !answer.ecs_name.toLowerCase().match(filter.name.text.toLowerCase()))
            {
	            return false;
            }

            if(filter.typeOfECS.select.length > 0 && !filter.typeOfECS.select.some(res => {
	            return "\"" + res.text + "\"" === answer.ecs_type;
            })) {
	            return false
			}

            if(filter.typeOfECSCustom.select.length > 0 && !filter.typeOfECSCustom.select.some(res => {
              return (typeof answer.ecs_type_2 !== "undefined" && answer.ecs_type_2 != null && typeof answer.ecs_type_2.answer !== "undefined" && answer.ecs_type_2.answer != null && answer.ecs_type_2.answer.isCustom && answer.ecs_type_2.answer.answer_custom_text == res.value)
            }))
            {
              return false;
            }

          }
          else
          {
            if(filter.typeOfProducts.select.length > 0 && !filter.typeOfProducts.select.some(res => {
              if(res.code === "all"){ return true }
              else {
                let productFind = false;
                //console.log(answer.ecs_name, answer,  answer.ecs_products, res.text)
                if(answer.ecs_products){
                  //
                  answer.ecs_products.forEach(answerProduct => {
                    if(!productFind){
                      if(answerProduct['51_1580214133799']){
                        //console.log(answer.ecs_name,answerProduct, answerProduct["51_1580214133799"], res.text)
                        productFind = answerProduct['51_1580214133799'].some(prod => {
                          return res.list.includes(prod.code2)
                        })
                      }}
                  })
                }
                return productFind;
              }
            })) {return false}

            if(filter.products.select.length > 0 && !filter.products.select.some(res => {
              if(res.code === "all"){ return true }
              else {
                let productFind = false;
                if(answer.ecs_products){
                  answer.ecs_products.forEach(answerProduct => {
                    if(!productFind){
                      if(answerProduct['51_1580214133799']){
                        //console.log(answer.ecs_name,answerProduct, answerProduct["51_1580214133799"], res.text)
                        productFind = answerProduct['51_1580214133799'].some(prod => {
                          return prod.code2 === res.text
                        })
                      }}
                  })
                }
                return productFind;
              }
            })) {return false}

            if(filter.productAvailable.select.length > 0)
            {
              if(!this.findInAnswer(answer.ecs_products,filter.productAvailable.select))
              {
                return false;
              }
            }

            if(filter.typeOfActivities.select.length > 0)
            {
              if(!this.findInAnswer(answer.ecs_activities,filter.typeOfActivities.select))
              {
                return false;
              }
            }

            if(filter.activityAvailable.select.length > 0)
            {
              if(!this.findInAnswer(answer.ecs_activities,filter.activityAvailable.select))
              {
                return false;
              }
            }

            if(filter.initialBudged.min != 0 || filter.initialBudged.max != 10000)
            {
              if(answer.ecs_budget == null || answer.ecs_budget < filter.initialBudged.min || answer.ecs_budget > filter.initialBudged.max)
              {
                return false;
              }
            }

            if(filter.costs.min != 0 || filter.costs.max != 10000)
            {
              if(answer.ecs_costs == null || answer.ecs_costs < filter.costs.min || answer.ecs_costs > filter.costs.max)
              {
                return false;
              }
            }

            if(filter.typeOfAdvertisement.select.length > 0)
            {
              if(!this.findInAnswer(answer.ecs_advertisement,filter.typeOfAdvertisement.select))
              {
                return false;
              }
            }

            if(filter.typeOfFunding.select.length > 0)
            {
              if(!this.findInAnswer(answer.ecs_type_of_funding,filter.typeOfFunding.select))
              {
                return false;
              }
            }

            if(filter.typeOfEdibleStructure.select.length > 0)
            {
              if(!this.findInAnswer(answer.ecs_edible_nature_based_solution,filter.typeOfEdibleStructure.select))
              {
                return false;
              }
            }
            if(filter.urbanChallenges != null && !this.scores.some(res => {
              return res.ecs_id == answer.ecs_id && res[filter.urbanChallenges.code];
            }))
            {
              return false;
            }
            if(filter.sustanability != null && !this.scores.some(res => {
              return res.ecs_id == answer.ecs_id && res[filter.sustanability.code];
            }))
            {
              return false;
            }
          }
          return true;
        });

        if(this.filteredAnswers.length == 0)
        {
          this.error_text = "Results not found";
        }
        else
        {
          this.error_text = "";
        }

        var vm = this;
        this.redrawMarkers();

      },
      findInAnswer: function(answer,code)
      {
        if(!answer)
        {
          return false;
        }
        var is_array = false;
        if(Array.isArray(code))
        {
          is_array = true;
          var codes = [];
          var texts = [];
          Object.keys(code).forEach(key => {
            codes.push(code[key].code);
            texts.push(code[key].text);
          });
        }
        var found = false;
        var i = 0;
        if(!Array.isArray(answer) && typeof answer.answer !== "undefined")
        {
          answer = answer.answer;
        }

        while(!found && i<answer.length)
        {
          if(typeof answer[i].custom_ids !== "undefined")
          {
            if(is_array)
            {
              found = codes.includes(answer[i].custom_ids) || texts.includes(answer[i].custom_ids);
            }
            else
            {
              found = answer[i].custom_ids == code;
            }
          }
          else
          {
            Object.keys(answer[i]).forEach(key => {
              if(!found)
              {
                if(answer[i][key])
                {
                  if(Array.isArray(answer[i][key]))
                  {
                    var j = 0;
                    while(!found && j<answer[i][key].length)
                    {
                      if(is_array)
                      {
                        found = typeof answer[i][key][j].id_custom_answer !== "undefined" && (codes.includes(answer[i][key][j].id_custom_answer) || texts.includes(answer[i][key][j].id_custom_answer));
                      }
                      else
                      {
                        found = typeof answer[i][key][j].id_custom_answer !== "undefined" && answer[i][key][j].id_custom_answer == code;
                      }
                      j++;
                    }
                  }
                  else
                  {
                    if(is_array)
                    {
                      found = typeof answer[i][key].id_custom_answer !== "undefined" && (codes.includes(answer[i][key].id_custom_answer) || texts.includes(answer[i][key].id_custom_answer));
                    }
                    else
                    {
                      found = typeof answer[i][key].id_custom_answer !== "undefined" && answer[i][key].id_custom_answer == code;
                    }
                  }
                }
                //found = true;
              }
            });
          }

          i++;
        }
        if(found)
        {
        }
        return found;
      },
      redrawMarkers: async function (){
        let _this = this;
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null);
        }
        this.markers = []
        //console.log(this.filter);
        //console.log(this.filteredAnswers);
        let _filteredAnswers = [];
        for (const answer of this.filteredAnswers) {
          try {
            //console.log(this.map);
            let draw = true;

            if(this.filter.location != '' && answer.location)
            {
              let location = JSON.parse(answer.location).location;
              let bounds = this.map.getBounds();
              draw = bounds.contains(location);
            }

            if(answer.location && draw) {
              _filteredAnswers.push(answer);
              let locationParsed = JSON.parse(answer.location)
              if (locationParsed.location) {
                await _this.createMarkerCoordinates(locationParsed, answer.ecs_name, answer);
              } else {
                var request = {query: locationParsed.address, fields: ['name', 'geometry']};
                const service = new google.maps.places.PlacesService(_this.map);
                service.findPlaceFromQuery(request, async function (results, status) {
                  if (status === google.maps.places.PlacesServiceStatus.OK) {
                    for (var i = 0; i < results.length; i++) {
                      console.log(results);
                      await _this.createMarker(results[i], answer.ecs_name, answer);
                    }
                  }
                });
              }
            }

          }
          catch (e){
            console.error(answer.ecs_name, answer.location, e)
          }
        }

        this.filteredAnswers = _filteredAnswers;
        this.$root.$emit('endSetFilter',this);

      },
      removeCluster(){
        if (this.cluster)
          this.cluster.clearMarkers()
      }
    },
    computed: {
      name_answers: function()
      {
        let filter = this.filter_names;
        if(filter)
        {
          var answers = this.allAnswers.filter(answer => {
            if (!answer)
            {
              return false;
            }
            if ((!filter.showInteractive && !filter.showNonInteractive) || (filter.showInteractive && !filter.showNonInteractive && answer.interactive == "\"147.0\"") || (filter.showNonInteractive && !filter.showInteractive && answer.interactive !== "\"147.0\"")) {
              return false;
            }
            if (filter.name != "" && !answer.ecs_name.toLowerCase().match(filter.name.text.toLowerCase())) {
              return false;
            }

            if (filter.typeOfECS.select.length > 0 && !filter.typeOfECS.select.some(res => {
              return "\"" + res.text + "\"" === answer.ecs_type;
            })) {
              return false
            }

            if (filter.typeOfECSCustom.select.length > 0 && !filter.typeOfECSCustom.select.some(res => {
              return (typeof answer.ecs_type_2 !== "undefined" && answer.ecs_type_2 != null && typeof answer.ecs_type_2.answer !== "undefined" && answer.ecs_type_2.answer != null && answer.ecs_type_2.answer.isCustom && answer.ecs_type_2.answer.answer_custom_text == res.value)
            })) {
              return false;
            }
            return true;
          });
          return answers;
        }
        else
        {
          return this.allAnswers;
        }

      }
    },
    mounted: async function () {
      let _this = this;

      try {
        //this.geolocation();
        const google = await gmapsInit();
        if (_this.map == null) {
          _this.map = new google.maps.Map(this.$el.querySelector('#map'), {
            zoom: 5,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          });
        }
        //await this.searchLocation("", false);
      } catch (error) {
        console.error(error);
      }

      await axios.post('answers/getAssessment')
        .then(function (response){
          _this.scores = response.data
          //console.log("scores", _this.scores)
        })

      ///////////////////////////////////////////////////////
      await axios.get('answers/getAllECSProfileLocation')
        .then(function (response) {
          if(!response.data){
            //answers.answers = {"questions": {}};
            console.log("No data found");
          } else {
            //console.log("All ECS locations", response.data);
            _this.allAnswers = response.data;
            _this.filteredAnswers = response.data;
            _this.custom_types_ecs = [];
            _this.allAnswers.forEach(async function (answer) {
              try {
                let locationParsed = JSON.parse(answer.location);
                answer.ecs_products = JSON.parse(answer.ecs_products);
                answer.ecs_activities = JSON.parse(answer.ecs_activities);
                answer.ecs_budget = JSON.parse(answer.ecs_budget);
                answer.ecs_costs = JSON.parse(answer.ecs_costs);
                answer.ecs_type_2 = JSON.parse(answer.ecs_type_2);
                answer.ecs_advertisement = JSON.parse(answer.ecs_advertisement);
                answer.ecs_type_of_funding = JSON.parse(answer.ecs_type_of_funding);
                answer.ecs_edible_nature_based_solution = JSON.parse(answer.ecs_edible_nature_based_solution);


                if(answer.ecs_type_2 != null && answer.ecs_type_2.answer.isCustom && !_this.custom_types_ecs.includes(answer.ecs_type_2.answer.answer_custom_text))
                {
                  _this.custom_types_ecs.push({value: answer.ecs_type_2.answer.answer_custom_text});
                }
                if(locationParsed.location){
                  await _this.createMarkerCoordinates(locationParsed, answer.ecs_name, answer);
                }
                else{
                  var request = {
                    query: locationParsed.address,
                    fields: ['name', 'geometry'],
                  };

                  const service = new google.maps.places.PlacesService(_this.map);
                  service.findPlaceFromQuery(request, async function (results, status) {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                      for (var i = 0; i < results.length; i++) {
                        await _this.createMarker(results[i], answer.ecs_name, answer);
                      }
                      //console.log(results[0].geometry.location);
                      //_this.map.setCenter({lat: 45, lng: 14});
                      //_this.map.setCenter(results[0].geometry.location);
                    }
                  });
                }
                _this.map.setCenter({lat: 45, lng: 14});
                _this.map.setZoom(5);
              }
              catch (exeption) {
                //console.log("Problem with: ",answer);
              }
            });
            _this.filteredAnswers = _this.allAnswers
          }
        })
        .catch(function (error) {
          console.log("Error getting answers");
          console.log(error);
        });

        this.custom_types_ecs.sort(function(a,b){
          if ( a.value.toLowerCase().trim()  < b.value.toLowerCase().trim()  ){
            return -1;
          }
          if ( a.value.toLowerCase().trim()  > b.value.toLowerCase().trim()  ){
            return 1;
          }
          return 0;
        });
        let vm = this;
      this.$root.$on('filterResults',($event) => {
        vm.setFilter($event)
      });

      this.$root.$on('filterNames',($event) => {
        vm.setFilterNames($event)
      });
      /*
      this.$root.$on('searchLocation',($event) => {
        vm.searchLocation($event)
      });*/
    },
  }

</script>

<style scoped>


  p.barText {
    color: var(--green-primary);
    font-size: small;
  }

  .jumbotron {
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 0;
  }



  #map {
    float:left;
    height: 71.1vh;
    width: calc(100% - 60px);
  }

  .greenTitles {
    color: var(--green-primary);
    text-align: center;
    font-size: small;
  }

  .checkBox {
    text-align: left;
    color: var(--green-primary);
    font-size: small;
  }

  .form-control {
    border: var(--green-primary) 1px solid;
    border-radius: 25px;
    font-size: small;
  }

  .b-icon.bi.collapsed {
    transform: rotate(180deg);
  }

  .advancedSearchOptions {
    background-color: var(--light-gray-secondary);
    font-size: small;
    margin: 0;
  }

  .optionsBut {
    color: var(--light-gray-primary);
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 2rem;
    align-self: baseline;
    margin: 0.75rem;
    padding: 0 0.1em 0 0.1em;
  }

  .optionsBut:hover {
    color: #6c757d;
    background-color: #ffffff;
    border-color: var(--dark-red);
  }

  .optionsBut > div {
    margin: 0.55rem;
  }

  .optionsBut p {
    padding: 0rem;
    margin: 0rem;
  }

  .custom-control-inline {
    margin-right: 0;
  }



</style>
