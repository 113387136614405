<template lang="pug">
.about_content.generalBackground
  b-alert(v-if="singleECS == 'noScores'"
          show variant= "warning"
          style="text-align:center") {{$t('assessment.indicators.noData') }}<br>{{$t('assessment.indicators.noData2') }}
  b-container(fluid="true" v-if="singleECS.ecs_id")
    div.plot(v-if="chartSust")
      h5 {{$t('assessment.sust_uc_es.sust.title')}}
      Radar.m-3(
        :chartData= "chartSust"
        :options="plotOptions"
        :key="plotKey"
        :height= "150")
    div.plot
      h5 {{$t('assessment.sust_uc_es.uc.title')}}
      Radar.m-3(
        :chartData= "getData('uC')"
        :options="plotOptions"
        :key="plotKey"
        :height= "150")
    div.plot(@click="modalES=true" style="cursor:pointer;")
      h5 {{$t('assessment.sust_uc_es.es.title')}}
      Radar.m-3(
        :chartData= "getData('catES')"
        :options="plotOptions"
        :key="plotKey"
        :height= "150")
      small {{$t('assessment.sust_uc_es.es.moreInfo')}}

    //- modal for Ecosystem services
    b-modal(id="modalES"
      :title="$t('assessment.sust_uc_es.es.title_by_categories')"
      v-model="modalES"
      ok-only
      size="lg")
      b-container(fluid="true")
        b-row
          b-col
            h6 {{$t('assessment.sust_uc_es.es.regulation')}}
            Radar.m-3(:chartData="getData('regES')" :options="plotOptions")
          b-col
            h6 {{$t('assessment.sust_uc_es.es.cultural')}}
            Radar.m-3(:chartData="getData('culES')" :options="plotOptions")
        b-row
          b-col
            h6 {{$t('assessment.sust_uc_es.es.supporting')}}
            Radar.m-3(:chartData="getData('supES')" :options="plotOptions")
          b-col
            h6 {{$t('assessment.sust_uc_es.es.provisioning')}}
            Radar.m-3(:chartData="getData('proES')" :options="plotOptions")

    //- modal for methodology
    b-modal(
      id="methodology"
      :title="$t('assessment.sust_uc_es.methodology')"
      ok-only)
      h6 {{$t('assessment.sust_uc_es.methodText.title1')}}:
      p {{$t('assessment.sust_uc_es.methodText.text1')}}
      h6 {{$t('assessment.sust_uc_es.methodText.title2')}}:
      p {{$t('assessment.sust_uc_es.methodText.text2')}}
        a(href="https://doi.org/10.1016/j.scitotenv.2021.146237" target="_blank")  Castellar et al., 2021)
        | {{$t('assessment.sust_uc_es.methodText.text3')}}

</template>

<script>
  import axios from 'axios';
  import Radar from "./vue-charts/radar.vue";

  export default {
    name: "Sust-UC-ES",
    components: {
      Radar,
    },
    props: {
      id_ecs: Number
    },
    data: function () {
      return {
        singleECS: {},
        plotKey: 0,
        modalES: false,
        plotOptions: {
          legend: false,
          responsive: true,
          maintainAspectRatio:true,
          scale: {
            ticks: {
              display: false,
              min: 0,
              max: 1,
              stepSize: 0.1
            },
            pointLabels: {
              fontSize: 13
            },
            tooltips: {enabled: false}
          },
        },
        labels: {
          uC: {
            UC_climate: [this.$t('assessment.sust_uc_es.uc.climate1'),this.$t('assessment.sust_uc_es.uc.climate2')],
            UC_water: [this.$t('assessment.sust_uc_es.uc.water1'),this.$t('assessment.sust_uc_es.uc.water2')],
            UC_coastal: [this.$t('assessment.sust_uc_es.uc.coastal1'),this.$t('assessment.sust_uc_es.uc.coastal2')],
            UC_green: [this.$t('assessment.sust_uc_es.uc.green1'),this.$t('assessment.sust_uc_es.uc.green2')],
            UC_air: this.$t('assessment.sust_uc_es.uc.airQuality'),
            UC_urban: [this.$t('assessment.sust_uc_es.uc.urban1'),this.$t('assessment.sust_uc_es.uc.urban2')],
            UC_participatory: [this.$t('assessment.sust_uc_es.uc.participatory1'),this.$t('assessment.sust_uc_es.uc.participatory2')],
            UC_social: [this.$t('assessment.sust_uc_es.uc.socialJustice1'),this.$t('assessment.sust_uc_es.uc.socialJustice2')],
            UC_health: [this.$t('assessment.sust_uc_es.uc.publicHealth1'),this.$t('assessment.sust_uc_es.uc.publicHealth2')],
            UC_economic: [this.$t('assessment.sust_uc_es.uc.economic1'),this.$t('assessment.sust_uc_es.uc.economic2')]
          },
          catES: {
            ES_regulation: this.$t('assessment.sust_uc_es.es.regulation'),
            ES_cultural: this.$t('assessment.sust_uc_es.es.cultural'),
            ES_supporting: this.$t('assessment.sust_uc_es.es.supporting'),
            ES_provision: this.$t('assessment.sust_uc_es.es.provisioning')
          },
          regES: {
            climate_regulation: [this.$t('assessment.sust_uc_es.es.climate_regulation1'),this.$t('assessment.sust_uc_es.es.climate_regulation2')],
            water_regulation: [this.$t('assessment.sust_uc_es.es.water_regulation1'),this.$t('assessment.sust_uc_es.es.water_regulation2')],
            water_purification: [this.$t('assessment.sust_uc_es.es.water_purification1'), this.$t('assessment.sust_uc_es.es.water_purification2')],
            air_regulation: [this.$t('assessment.sust_uc_es.es.air_quality1'), this.$t('assessment.sust_uc_es.es.air_quality2')],
            pollination: this.$t('assessment.sust_uc_es.es.pollination'),
            pest_regulation: [this.$t('assessment.sust_uc_es.es.disease1'), this.$t('assessment.sust_uc_es.es.disease2')],
            erosion_control: [this.$t('assessment.sust_uc_es.es.erosion1'), this.$t('assessment.sust_uc_es.es.erosion2')]
          },
          culES: {
            aesthetic: [this.$t('assessment.sust_uc_es.es.aesthetic1'), this.$t('assessment.sust_uc_es.es.aesthetic2')],
            recreational: [this.$t('assessment.sust_uc_es.es.recreation1'), this.$t('assessment.sust_uc_es.es.recreation2')],
            spiritual_values: [this.$t('assessment.sust_uc_es.es.spiritual1'), this.$t('assessment.sust_uc_es.es.spiritual2')],
            social_relations: [this.$t('assessment.sust_uc_es.es.social_relations1'), this.$t('assessment.sust_uc_es.es.social_relations2')]
          },
          supES: {
            habitat: [this.$t('assessment.sust_uc_es.es.habitat1'), this.$t('assessment.sust_uc_es.es.habitat2')],
            soil_formation: [this.$t('assessment.sust_uc_es.es.soil_formation1'), this.$t('assessment.sust_uc_es.es.soil_formation2')],
            nutrient_cycling: this.$t('assessment.sust_uc_es.es.nutrient_cycling'),
            primary_production: [this.$t('assessment.sust_uc_es.es.primary_production1'), this.$t('assessment.sust_uc_es.es.primary_production2')]
          },
          proES: {
            food_fiber: this.$t('assessment.sust_uc_es.es.food'),
            fuel_es: this.$t('assessment.sust_uc_es.es.fuel'),
            biochemicals: [this.$t('assessment.sust_uc_es.es.biochemical1'), this.$t('assessment.sust_uc_es.es.biochemical2')],
            fresh_water: this.$t('assessment.sust_uc_es.es.fresh_water')
          }
        }
      }
    },
    mounted: async function(){
      let _this = this
      let id_ecs = _this.id_ecs
      // get scores
      await axios.post('answers/getECSAssessment', {id_ecs: _this.id_ecs})
      .then(function (response){
        if (response.data.length > 0){
          _this.singleECS = response.data[0]
          console.log("ECS scores", response.data)
        } else {
          _this.singleECS = "noScores"
        }
      })
      .catch(function (error){
        console.log("getAssessment error", error)
      })
    },
    computed: {
      chartSust: function() {
        return this.getSustData();
      },
    },
    methods:{
      getData: function (font) {
        let labels = []
        let scores = [];
        for (const [key, value] of Object.entries(this.singleECS)){
          if (this.labels[font].hasOwnProperty(key)){
            labels.push(this.labels[font][key])
            if (value === null) scores.push(0)
            else scores.push(value)
          }
        }

        var plotData = {
          labels: labels,
          datasets: [{
              label: this.singleECS.name,
              data: scores,
              backgroundColor: 'rgba(93, 143, 73, 0.5)',
              pointRadius: 0}]
        }

        // console.log("plotData", plotData)
        return plotData
      },
      getSustData: function() {
        let sustLabels = [];
        let sustScores = [];
        let sustScoresCompare = [];
        let sustLabelsObj = {
          seeds: this.$t('assessment.sust_uc_es.sust.seeds'),
          weed: this.$t('assessment.sust_uc_es.sust.weed'),
          waste: this.$t('assessment.sust_uc_es.sust.waste'),
          food: this.$t('assessment.sust_uc_es.sust.food'),
          water: this.$t('assessment.sust_uc_es.sust.water'),
          packaging: [this.$t('assessment.sust_uc_es.sust.packaging1'),this.$t('assessment.sust_uc_es.sust.packaging2')],
          energy: this.$t('assessment.sust_uc_es.sust.energy'),
          fuel: this.$t('assessment.sust_uc_es.sust.fuel'),
          fertilizers: this.$t('assessment.sust_uc_es.sust.fertilizers')
        };

        for (const [key, value] of Object.entries(this.singleECS)){
          if (sustLabelsObj.hasOwnProperty(key) && value !== null){
            sustLabels.push(sustLabelsObj[key])
            sustScores.push(this.singleECS[key])
          }
        }

        // if there less than 3 labels, create empty ones
        while (sustScores.length < 3){
          sustLabels.push('')
          sustScores.push(1)
        };

        var sustData = {
          labels: sustLabels,
          datasets: [{
              label: "Label",
              data: sustScores,
              backgroundColor: 'rgba(93, 143, 73, 0.5)',
              pointRadius: 0}]
        }

        return sustData;

      },
    }
  }

</script>

<style scoped>

  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  .rightMenu{
    background-color: var(--light-gray-primary);
    padding: 2em;
  }

  .plot {
    margin: auto;
    padding: 1em;
    text-align: center;
  }

  h4, h4:hover {
    color: var(--green-primary);
    display: inline-block;
  }

  .compare, .compare:hover {
    color: var(--dark-red);
    text-decoration-color: var(--dark-red);
  }

  a:hover {
    text-decoration-color: var(--green-primary);
  }

  h5 {
    text-align: center;
    color: var(--green-primary)
  }

  table.ranking {
    width:100%;
    margin-top:2em;
  }

  td {
    padding: 0.5em;
  }

  .barPlot {
    cursor: pointer;
  }

  .notList {
    list-style-type: none;
    line-height: 50%;
  }

  .notList:hover {
    text-decoration: underline;
  }

  .linkButton {
    padding-left: 0px;
  }


  .buttonIn {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: right;
  }

  .legend{
    background-color: var(--green-primary);
    height: 1em;
    width: 1em;
    display: inline-block;
    margin-right: 0.5em;
  }

  .legendCompare{
    background-color: var(--dark-red);
  }

  .link:hover {
    text-decoration: underline;
  }

</style>
