<template lang="pug">

    b-row
      b-col(sm="12")
        b-row
          b-col(sm="12")
            b-form-group(label="" valid-feedback="Thank you!" :invalid-feedback="invalidFeedback" :state="state")
              b-form-input(id="input-1" v-model="answered" :state="state" @change="changeEvent" trim)

          b-col(sm="12").mt-2
            div.d-inline-flex
              b-button.addButton.btn-primary.btn-sm.btn-block.buttonGreen.mt-0.mr-1(:disabled="!state" v-on:click="saveName") {{$t('buttons.edit')}}
              b-button.addButton.btn-danger.btn-sm.btn-block.mt-0(v-on:click="cancel") {{$t('buttons.cancel')}}





</template>

<script>
import utilMixin from "../../mixin/utilsMixin";
import axios from 'axios';
import questionHeader from "../questionHeader";
import questionFooter from "../questionFooter";

export default {
  name: "questionName",
  components: {questionHeader, questionFooter},
  props: ['question', 'questionNumber', 'answer','ecs_id', 'exp_id', 'nQuestions'],
  mixins: [utilMixin],
  data: function(){
    return{
      answered: "",
      skip: {
        skipped: "",
        custom: false,
        answer: ""
      },
      firstAnswered: false,
      firstSkipped: false,
      oldValue: "",
      langId: 1,
      options: []
    }
  },
  mounted: function(){

    let _this = this;
    axios.get('answers/getAllECSProfileName')
    .then(function (response) {
      console.log("Asked all ECS: ",response.data);
      _this.options = response.data;
    })
    .catch(function (error) {
      console.log("Menu get client error:", error);
      // console.log(error);
    });

    if(this.answer){
      console.log("Loaded Simple question answer");
      this.answered = this.answer.answer;
      this.oldValue = this.answered;
    }
    else{
      console.log("Loaded Simple not found")
    }
    this.firstAnsweredOrSkipped(this);
  },
  watch:{
    questionNumber: function(newVal,oldVal) {
      if(typeof(this.answer) === 'undefined'){
        this.resetAnswer();
        this.resetSkip();
        this.oldValue = "";

      }else{
        this.answered = this.answer.answer;
        this.oldValue = this.answered;

      }
      this.firstAnsweredOrSkipped(this)
    }
  },
  methods:{
    cancel: function()
    {
      this.$parent.editName();
    },
    saveName: function(){
      this.saveToProfileECS();
      this.$parent.editName();
    },
    changeEvent: function(){
    },
    stringToLabel: function (text) {
      if (text) {
        let res = text.replace(/\n/g, '<br>');
        return res;
      }
      else {
        text = this.question.config['text_lang_1']
        let res = text.replace(/\n/g, '<br>');
        return res;
      }
    },
    saveToProfileECS: function (text) {
      this.save(text);
    },

    save(text){
      let _this = this;
      this.$emit('sendResponse', {
        id_question: _this.question.id,
        id_category: _this.question.category_id,
        answer: _this.answered,
        skip: _this.skip}, text);

      if (this.exp_id) {
        var ecs_exp_id = this.exp_id
        console.log("exp_id:",ecs_exp_id)
      } else {
        var ecs_exp_id = this.ecs_id
      }

      let oldQuestionId = _this.question.id;
      let auxAnswer = _this.answered;
      let auxOldValue = _this.oldValue
      axios.post(this.$parent.url_answer, {
        question_id: _this.question.id,
        ecs_id: ecs_exp_id,
        category_id: _this.question.category_id,
        answer: _this.answered,
        skip: _this.skip
      })
      .then(function (response) {
        console.log(response);
        console.log("Done Modify question");
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    hasBeenAnswered: function (answer) {

      if(answer) return answer.answered !== "";
      return false;
    },
    hasBeenSkipped: function (answer) {
      if (answer && answer.skip) return answer.skip.skipped || answer.skip.custom;
      return false;
    },
    resetAnswer: function () {
      this.answered = "";
    },

    resetSkip: function () {
      this.$refs.footer.resetModal();
      this.skip = {
        skipped: "",
        custom: false,
        answer: ""
      }
    },

    hasBeenAnsweredOrSkipped: function (answer) {
      return this.hasBeenSkipped(answer) || this.hasBeenAnswered(answer);
    },

    firstAnsweredOrSkipped: function (answer) {
      this.firstAnswered = this.hasBeenAnswered(answer);
      this.firstSkipped = this.hasBeenSkipped(answer);
    }



  },
  computed: {
    getLang: function () {
      let language = this.$store.getters.getLanguage.id;
      this.langId = language;
      //console.log("Question langID: ", language);
      return language;
    },
    filteredOptions() {
      return this.options.filter(item=>{return item.ecs_name === this.answered && item.ecs_name !== this.oldValue});
    },
    state() {

      if(this.answered.length >= 4){
        return this.filteredOptions.length <= 0
      }
      else
      {
        return false;
      }
    },
    invalidFeedback() {
      if (this.answered.length > 0) {
        if(this.filteredOptions.length > 0)
        {
          return 'This ECS is already registered.';
        }
        return 'Enter at least 4 characters.'
      }
      return ''
    }
  }
}
</script>

<style scoped>
.questions{
  background-color: var(--light-gray-secondary);
}

.error {
  color: var(--dark-red);
}

.buttonGreen
{
  height:100%;
}

.addButton
{
  width:100%;
  height:100%;
  font-size: small;
  font-weight: var(--bold-text);
}

</style>
