<template >
  <div>
    <div class="search-connect-div" @click="showFilters" :class="{'search-collapsed':!hidden}" >
      <div class="search-connect-btn" >
        <svg :class="{'arrow-down':hidden,'arrow-up':!hidden}" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M154.08333,61.275l-11.10833,-11.10833l-56.975,56.975l-56.975,-56.975l-11.10833,11.10833l68.08333,67.725z"></path></g></g></svg>
        <span>Search & Connect</span>
      </div>
    </div>
    <!--<button class="search-connect-button" :class="{'search-collapsed':!hidden}" type="button" @click="showFilters">
      <svg :class="{'arrow-down':hidden,'arrow-up':!hidden}" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M154.08333,61.275l-11.10833,-11.10833l-56.975,56.975l-56.975,-56.975l-11.10833,11.10833l68.08333,67.725z"></path></g></g></svg>

      <span>Search & Connect</span>

    </button>-->
    <div class="filter-zone" :class="{'active':!hidden}">
      <div class="row" :class="{'d-none':hidden}">
        <div class="col-12 search-switch mb-4">
          <div class="custom-control pr-2 quicky-search-custom-control">
            <label for="" class=" text-white">{{ $t('filters.titles.quikySearch') }}</label>
          </div>
          <div class="custom-control custom-switch">
            <input v-model="results.advanced_search" type="checkbox" class="custom-control-input" :id="_uid">
            <label class="custom-control-label text-white capitalize" :for="_uid">{{ $t('filters.titles.advancedSearch') }}</label>
          </div>
        </div>
        <div class="col-12 quiky-search" v-if="!results.advanced_search">
          <div class="row">
            <div class="col-12">
              <h6>{{ $t('edibleMap.advancedSearch.typeOfProfile') }}</h6>
            </div>
            <div class="col-12 typeofecs">
	            <multiselect v-model="results.typeOfECS.select" @input="filterNames"
	                         :multiple="true" track-by="code" label="code"
	                         group-values="types"
	                         :disabled="loading"
	                         group-label="type"
	                         :placeholder="$t('placeholder.typeHere')" :options="typeOfECS.options" :searchable="true" ></multiselect>
            </div>
            <div class="col-12">
              <hr class="border-1">
            </div>
            <div class="col-12 mt-2">
              <h6>{{ $t('filters.titles.others') }}</h6>
            </div>
            <div class="col-12">
              <div class="form-group">
                <multiselect v-model="results.typeOfECSCustom.select" :disabled="loading" @input="filterNames" :multiple="true" track-by="value" label="value" :placeholder="$t('placeholder.typeHere')" :options="custom_types_ecs" :searchable="true" ></multiselect>
              </div>
            </div>
            <div class="col-12 mt-2">
              <h6>{{ $t('edibleMap.advancedSearch.interactivity') }}</h6>
            </div>
            <div class="col-12">
              <div class="row form-checkbox-group">
                <div class="col-12 col-md-6">
                  <div class="form-check" >
                    <input class="form-check-input" type="checkbox"  @change="filterNames" v-model="results.showInteractive" value="interactive" id="interactive">
                    <label class="form-check-label" for="interactive">
                      {{ $t('edibleMap.edibleCitiesSolutions') }}
                    </label>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-check" >
                    <input class="form-check-input" type="checkbox"  @change="filterNames" v-model="results.showNonInteractive" value="non-interactive" id="non-interactive">
                    <label class="form-check-label" for="non-interactive">
                      {{ $t('edibleMap.non-edibleCitiesSolutions') }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <h6>{{ $t('filters.titles.name') }}</h6>
            </div>
            <div class="col-12">
              <div class="form-group">
                <multiselect v-model="results.name"  :multiple="false" track-by="text" label="text" :placeholder="$t('placeholder.typeHere')" :options="ecs_names" :searchable="true" ></multiselect>
              </div>
            </div>
            <div class="col-12 mt-2">
              <h6>{{ $t('filters.titles.location') }}</h6>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input type="text" class="form-control" :disabled="loading" :placeholder="$t('placeholder.writeLocation')" v-model="results.location">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 advanced-search" v-if="results.advanced_search">
          <div class="row">
            <div class="col-12">
              <h6>{{ $t('filters.titles.edibleGoods') }}</h6>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for=""> {{ $t('edibleMap.advancedSearch.typeOfProducts') }}</label>
              <multiselect v-model="results.typeOfProducts.select"  :multiple="true" track-by="text" label="text" :placeholder="$t('filters.selectOne')" :options="typeOfProducts.options" :searchable="true" ></multiselect>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for=""> {{ $t('edibleMap.advancedSearch.products') }}</label>
              <multiselect v-model="results.products.select"  :multiple="true" track-by="text" label="code" :placeholder="$t('filters.selectOne')" :options="productsShown.options" :searchable="true" ></multiselect>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for="">{{ $t('edibleMap.advancedSearch.availability') }}</label>
              <multiselect v-model="results.productAvailable.select" :multiple="true" track-by="code" label="code" :placeholder="$t('filters.selectOne')" :options="productAvailable.options" :searchable="true" :allow-empty="true"></multiselect>
            </div>
            <div class="col-12 mt-2">
              <h6>{{ $t('filters.titles.activitiesServices') }}</h6>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for=""> {{ $t('edibleMap.advancedSearch.activities') }}</label>
              <multiselect v-model="results.typeOfActivities.select"  :multiple="true" track-by="text" label="text" :placeholder="$t('filters.selectOne')" :options="typeOfActivities.options" :searchable="true" :allow-empty="true"></multiselect>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for="">{{ $t('edibleMap.advancedSearch.availability') }}</label>
              <multiselect v-model="results.activityAvailable.select"  :multiple="true" track-by="code" label="code" :placeholder="$t('filters.selectOne')" :options="activityAvailable.options" :searchable="true" :allow-empty="true"></multiselect>
            </div>
            <div class="col-12 mt-2">
              <h6>{{ $t('filters.titles.financial') }}</h6>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for="">{{ $t('edibleMap.advancedSearch.initialBudget') }}</label>
              <div class="input-group">
                <input class="form-control" type="number" v-model="results.initialBudged.min" min="0" placeholder="min.">
                <input class="form-control" type="number" v-model="results.initialBudged.max" :min="results.initialBudged.min" placeholder="max.">
                <div class="input-group-append">
                  <span class="input-group-text">€</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for="">{{$t('ecs_profile.description.running_costs.title') }}</label>
              <div class="input-group">
                <input class="form-control" type="number" v-model="results.costs.min" min="0" placeholder="min.">
                <input class="form-control" type="number" v-model="results.costs.max" :min="results.costs.min" placeholder="max.">
                <div class="input-group-append">
                  <span class="input-group-text">€</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for=""> {{ $t('edibleMap.advancedSearch.advertisement') }}</label>
              <multiselect v-model="results.typeOfAdvertisement.select"  :multiple="true" track-by="text" label="text" :placeholder="$t('filters.selectOne')" :options="typeOfAdvertisement.options" :searchable="true" :allow-empty="true" ></multiselect>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for=""> {{ $t('edibleMap.advancedSearch.funding') }}</label>
              <multiselect v-model="results.typeOfFunding.select"  :multiple="true" track-by="text" label="text" :placeholder="$t('filters.selectOne')" :options="typeOfFunding.options" :searchable="true" :allow-empty="true"></multiselect>
            </div>

            <div class="col-12 mt-2">
              <h6>{{ $t('edibleMap.advancedSearch.edibleNBS') }}</h6>
            </div>
            <div class="col-12  mb-2">
              <multiselect v-model="results.typeOfEdibleStructure.select"  :multiple="true" track-by="text" label="text" :placeholder="$t('filters.selectOne')" :options="typeOfEdibleStructure.options" :searchable="true" :allow-empty="true"></multiselect>
            </div>
            <div class="col-12 mt-2">
              <h6>{{ $t('filters.titles.performance') }}</h6>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for="">{{ $t('assessment.sust_uc_es.uc.title') }}</label>
              <multiselect v-model="results.urbanChallenges"  track-by="text" label="text" :placeholder="$t('filters.selectOne')" :options="urbanChallenges.options" :searchable="false" :allow-empty="true"></multiselect>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <label for="">{{ $t('assessment.sust_uc_es.sust.title') }}</label>
              <multiselect v-model="results.sustanability" track-by="text" label="text" :placeholder="$t('filters.selectOne')" :options="sustanability.options" :searchable="false" :allow-empty="true"></multiselect>
            </div>
            <div class="col-12">
              <h6>{{ $t('filters.titles.location') }}</h6>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input type="text" class="form-control" :disabled="loading" :placeholder="$t('placeholder.writeLocation')" v-model="results.location">
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 text-right mt-4">
          <p class="text-danger">{{error_text}}</p>
          <div class="btn buttonGreen btn-sm btn-danger p-3 mr-2" disabled v-if="loading">
            <div class="spinner-border text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="btn buttonGreen btn-sm btn-danger p-3 mr-2 text-uppercase" @click="clearAll" v-else>
            Clear all
          </div>
          <div class="btn buttonGreen btn-secondary p-3" disabled v-if="loading">
            <div class="spinner-border text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div class="btn buttonGreen btn-secondary p-3 " @click="resultChange" v-else>
            {{ $t('buttons.search') }}
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<style>

.multiselect__single
{
  overflow:hidden;
}
</style>
<style scoped>
.custom-label{
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-height: 60px;
  white-space: nowrap;
}

.spinner-border
{
  width: 1rem;
  height: 1rem;
}
.search-switch
{
  display:inline-flex;
  background-color: var(--green-primary);
  color:white;
  border-radius:10px;
  padding:10px 10px 5px 10px;
}

.custom-control-input:checked ~ .custom-control-label::before{
  background-color: var(--green-primary);
  border-color: white;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before{
  border-color:white;
}


.filter-zone{
  background: var(--green-tertiary);
  height: 100%;
  min-height:71.1vh;
  position: absolute;
  z-index: 1;
  right: 0px;
  -moz-transition: width 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out;
  overflow: scroll;
  padding:0px;
  width:0px;
}

.btn-secondary:hover
{
  background-color: var(--green);
  border: var(--green);
}

.active.filter-zone
{
  max-width: 100%;
  width:470px;
  padding: 20px 30px;
}

.labelStyle{
  color: var(--green-primary);
  font-weight: var(--bold-text); font-size: 14px
}

.arrow-down
{
  float: left;
  margin-left: 10px;
}

.arrow-up
{
  float: left;
  margin-left: 10px;
  transform: rotate(
      180deg
  );
}

.search-connect-div
{
  z-index:2;
  position:absolute;
  right:15px;
  top:0px;
  color:white;
  width:60px;
  height:100%;
  min-height: 71.1vh;
  background-color: var(--green-primary);
  border: 1px solid gray;
  cursor:pointer;
  -moz-transition: margin-right 0.3s ease-in-out;
  -ms-transition: margin-right 0.3s ease-in-out;
  -o-transition: margin-right 0.3s ease-in-out;
  -webkit-transition: margin-right 0.3s ease-in-out;
}

.search-connect-btn
{
  color:white;
  transform-origin: left;
  transform: rotate(
      90deg
  ) translate(0%);
  height: 60px;
  width: 71.1vh;
  text-align: center;
  margin-left: 10px;
}

.search-connect-button
{
  float:right;
  background-color: var(--green-primary);
  color: white;
  border: 1px solid gray;
  display: inline-block;
  transform-origin: top right;
  width: 71.1vh;
  height:60px;
  transform: rotate(
      90deg
  ) translate(0%);
  margin-right:0px;
  -moz-transition: margin-right 0.3s ease-in-out;
  -ms-transition: margin-right 0.3s ease-in-out;
  -o-transition: margin-right 0.3s ease-in-out;
  -webkit-transition: margin-right 0.3s ease-in-out;
}



.edibleList .search-connect-button
{
  transform: rotate(
      90deg
  ) translate(-21.1%);
}

.edibleList .filter-zone
{
  top:0px;
  right:15px;
}

.search-collapsed
{

  margin-right:455px;
}

.edibleList .search-collapsed
{
  margin-right:470px;
}

.quicky-search-custom-control
{
  margin-left:50px;
}
@media (max-width: 600px) {
  .search-collapsed, .edibleList .search-collapsed{
    margin-right:100%;
  }
}
</style>

<script>

import Multiselect from 'vue-multiselect';
import axios from "axios";

export default {
  name: "FilterZone",
  components:{
    Multiselect
  },
  props: ['allAnswers','error_text','custom_types_ecs','list_filter'],
  methods:{
    clearAll(){

      //$(this.$el).find('.filter-zone .typeofecs input:checkbox:checked').removeAttr('checked');
      console.log(this.results.typeOfECS.select);
      var advanced_search = this.results.advanced_search;
      this.results = {
        name: "",
        advanced_search: advanced_search,
        showInteractive: true,
        showNonInteractive: true,
        selectedInterests: ["interactive","non-interactive"],
        location: "",
        keywords: "",
        sustanability: null,
        urbanChallenges: null,
        typeOfProfile: {select: { code: 'all', text: 'All' }},
        typeOfECS: {select: []},
        typeOfECSCustom: {select:[]},
        typeOfProducts: {select: []},
        products: {select: []},
        productAvailable: {select: []},
        typeOfActivities: {select: []},
        activityAvailable: {select: []},
        initialBudged: {min: 0, max: 10000},
        costs: {min: 0, max: 10000},
        typeOfAdvertisement: {select: []},
        typeOfFunding: {select: []},
        typeOfEdibleStructure: {select: []},
        typeOfNonEdibleStructure: {select: { code: 'all', text: 'All' }},
        type_1: [],
        type_2: []
      };
      this.resultChange();
    },
    searchLocation(){
    },
    resultChange(){
      this.loading = true;
      this.$root.$emit("filterResults",this.results);
    },
    filterNames()
    {
      this.$root.$emit("filterNames",this.results);
    },
    showFilters()
    {
      this.hidden = !this.hidden;
    },
    fillOptions()
    {
      var _this = this;
      axios.post('questions/query/getquestions')
          .then(function (response) {
            if(!response.data){
            } else {
              var index = response.data.findIndex(element => { return element.id == 152; });
              var typeecs = JSON.parse(response.data[index].config);
              let type_1_internal_id = [
                "152_1607082058139",
                "152_1638184618935",
                "152_1638184724621",
                "152_1638184749667",
                "152_1638185715916",
                "152_1651584550748",
                "152_1651584569260",
                "152_1651584584720",
                "152_1651584591627",
                "152_1651584604316",
                "152_1651584623220",
                "152_1651584640996",
                "152_1651584655929",
                "152_1651584666171",
                "152_1651584674087",
                "152_1651584681831",
                "152_1651585232562"
              ];
              let type_2_internal_id = ['152_1606230563826',
                "152_1606230649710",
                "152_1606230726561",
                "152_1606230687422",
                "152_1606230611498",
                "152_1651585170940",
                "152_1651585175534",
                "152_1651585108039",
                "152_1651585098513",
                "152_1651585155026",
                "152_1651585065016",
                "152_1651585089330",
                "152_1651585128146",
                "152_1651585144758",
                "152_1651585136766",
                "152_1651585162607",
                "152_1651585045473",
                "152_1651585184410",
                "152_1606231761706",
                "152_1606231789934",
                "152_1606231857409",
                "152_1606231941472",
                "152_1606231899791",
                "152_1606288981588",
                "152_1651585118838",
                "152_1651585053864"
              ];

              _this.typeOfECS.options = {} ;
              typeecs.answers.forEach(element => {
                try {
                  var object = {};
                  object.text = element.custom_ids;

                  if(typeof(element['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                  {
                    object.code = element['text_'+_this.$store.getters.getLanguage.lang];
                  }
                  else
                  {
                    object.code = element.text_1;
                  }

                  let e = {};
                  e.text = element.custom_ids;
                  e.code = object.code;

                  if(type_1_internal_id.find(e => e === element.internal_id))
                  {
                    _this.type_1.push(e);
                  }
                  else if(type_2_internal_id.find(e => e === element.internal_id))
                  {
                    _this.type_2.push(e);
                  }
                }
                catch(error)
                {
                  console.log(error);
                }

              });
			  _this.typeOfECS.options = [
	            {
					type: _this.$t('filters.titles.urbanFoodInitiatives'),
					types: _this.type_1
	            },
				{
				  type: _this.$t('filters.titles.urbanFoodSolutions'),
				  types: _this.type_2
				}
			  ];
              var index = response.data.findIndex(element => { return element.id == 51; });
              var question = JSON.parse(response.data[index].config);
              _this.products.options = [];
              var position = null;
              var availability = null;
              question.columns.forEach((col,pos) => {
                if(col.custom_ids === "products") {
                  position = pos;
                }
                if(col.custom_ids === "availability") {
                  availability = pos;
                }

              });

              _this.activityAvailable.options = [];
              _this.productAvailable.options = [];

              question.columns[availability].answers.forEach(element => {
                try {
                  var object = {};
                  object.text = element.custom_ids;
                  if(typeof(element['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                  {
                    object.code = element['text_'+_this.$store.getters.getLanguage.lang];
                  }
                  else
                  {
                    object.code = element.text_1;
                  }
                  _this.activityAvailable.options.push(object);
                  _this.productAvailable.options.push(object);
                }
                catch(error)
                {
                  console.log(error);
                }
              });

              question.columns[position].answers.forEach(element => {
                try {
                  var object = {};
                  object.text = element[2];
                  switch(_this.$store.getters.getLanguage.lang)
                  {
                    case 'cat':
                      object.code = element[3];
                      break;
                    case 'fr':
                      object.code = element[4];
                      break;
                    case 'es':
                      object.code = element[5];
                      break;
                    case 'de':
                      object.code = element[6];
                      break;
                    case 'pr':
                      object.code = element[7];
                      break;
                    case 'sl':
                      object.code = element[8];
                      break;
                    case 'rm':
                      //TEMP
                      object.code = element[0];
                      break;
                    case 'en':
                    default:
                      object.code = element[0];
                      break;
                  }
                  _this.products.options.push(object);
                }
                catch(error)
                {
                  console.log(error);
                }
              });
              _this.productsShown = Object.assign(_this.productsShown, _this.products);

              var index = response.data.findIndex(element => { return element.id == 52; });
              var question = JSON.parse(response.data[index].config);
              _this.typeOfActivities.options = [];
              //console.log(question);

              position = null;
              question.columns.forEach((col,pos) => {
                if(col.custom_ids.includes("activity") || col.custom_ids.includes("activities")) {
                  position = pos;
                }
              });

              question.columns[position].answers.forEach(element => {
                try {
                  var object = {};
                  object.code = element.custom_ids;
                  if(typeof(element['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                  {
                    object.text = element['text_'+_this.$store.getters.getLanguage.lang];
                  }
                  else
                  {
                    object.text = element.text_1;
                  }
                  _this.typeOfActivities.options.push(object);
                }
                catch(error)
                {
                  console.log(error);
                }
              });
              var index = response.data.findIndex(element => { return element.id == 69; });
              var question = JSON.parse(response.data[index].config);
              _this.typeOfAdvertisement.options = [];

              question.answers.forEach(element => {
                try {
                  var object = {};
                  object.code = element.custom_ids;
                  if(typeof(element['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                  {
                    object.text = element['text_'+_this.$store.getters.getLanguage.lang];
                  }
                  else
                  {
                    object.text = element.text_1;
                  }
                  _this.typeOfAdvertisement.options.push(object);
                }
                catch(error)
                {
                  console.log(error);
                }

              });
              //console.log(question);
              var index = response.data.findIndex(element => { return element.id == 66; });
              var question = JSON.parse(response.data[index].config);
              _this.typeOfFunding.options = [];
              question.columns[0].answers.forEach(element => {
                try {
                  var object = {};
                  object.code = element.custom_ids;
                  if(typeof(element['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                  {
                    object.text = element['text_'+_this.$store.getters.getLanguage.lang];
                  }
                  else
                  {
                    object.text = element.text_1;
                  }
                  _this.typeOfFunding.options.push(object);
                }
                catch(error)
                {
                  console.log(error);
                }

              });

              var index = response.data.findIndex(element => { return element.id == 75; });
              var question = JSON.parse(response.data[index].config);
              _this.typeOfEdibleStructure.options = [];
              var position = null;

              question.columns.forEach((col,pos) => {
                if(col.custom_ids === "ediblestructure") {
                  position = pos
                }
              })
              question.columns[position].answers.forEach(element => {
                try {
                  var object = {};
                  object.code = element.custom_ids;
                  if(typeof(element['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                  {
                    object.text = element['text_'+_this.$store.getters.getLanguage.lang];
                  }
                  else
                  {
                    object.text = element.text_1;
                  }
                  _this.typeOfEdibleStructure.options.push(object);
                }
                catch(error)
                {
                  console.log(error);
                }
              });
            }
          })
          .catch(function (error) {
            console.log("Error getting answers");
            console.log(error);
          });
    }
  },
  watch: {
    '$store.getters.getLanguage': function() {
      //Object.assign(this.$data, this.$options.data.apply(this));
      console.log('change language');
      this.fillOptions();
    },
    'allAnswers': function(val) {
      var _this = this;
      this.ecs_names = [];
      val.forEach(element => {
        var object = {};
        if (element.ecs_name != "")
        {
          object.text = element.ecs_name;
          object.code = element.ecs_name;
          _this.ecs_names.push(object);
        }
        _this.ecs_names.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));
      });
    },
    'results.typeOfProducts.select': function(val)
    {
      this.productsShown.options = [];
      var _this = this;
      this.results.products.select = [];
      if(val.length > 0)
      {
        this.results.typeOfProducts.select.forEach(typeOfProduct => {
          typeOfProduct.list.forEach(codeProduct => {

            var product = _this.products.options.find(product => {
              return codeProduct == product.text;
            });
            if(product)
            {
              this.productsShown.options.push(product);
            }
          });
        })
      }
      else
      {
        this.productsShown = Object.assign(this.productsShown, this.products);
      }
    }
  },
  mounted() {
    this.fillOptions();
    var _this = this;
    this.$root.$on('filterResults',($event) => {
      console.log($event);
      _this.results = $event;
      _this.loading = true;
    });
    this.$root.$on('endSetFilter',($event) => {
      _this.loading = false;
    });

    //his.productsShown = Object.assign(this.productsShown, this.products);
  } ,
  computed:{
    urbanChallenges: function(){
      return {select: null,
          options: [
        {code: "avg_uc", text: this.$t('assessment.sust_uc_es.average')+' '+this.$t('assessment.sust_uc_es.uc.title')},
        {code: "UC_climate", text: this.$t('assessment.sust_uc_es.uc.climate1')+' '+this.$t('assessment.sust_uc_es.uc.climate2')},
        {code: "UC_water", text: this.$t('assessment.sust_uc_es.uc.water1')+' '+this.$t('assessment.sust_uc_es.uc.water2')},
        {code: "UC_coastal", text: this.$t('assessment.sust_uc_es.uc.coastal1')+' '+this.$t('assessment.sust_uc_es.uc.coastal2')},
        {code: "UC_green", text: this.$t('assessment.sust_uc_es.uc.green1')+' '+this.$t('assessment.sust_uc_es.uc.green2')},
        {code: "UC_air", text: this.$t('assessment.sust_uc_es.uc.airQuality')},
        {code: "UC_urban", text: this.$t('assessment.sust_uc_es.uc.urban1')+' '+this.$t('assessment.sust_uc_es.uc.urban2')},
        {code: "UC_participatory", text: this.$t('assessment.sust_uc_es.uc.participatory1')+' '+this.$t('assessment.sust_uc_es.uc.participatory2')},
        {code: "UC_social", text: this.$t('assessment.sust_uc_es.uc.socialJustice1')+' '+this.$t('assessment.sust_uc_es.uc.socialJustice2')},
        {code: "UC_health", text: this.$t('assessment.sust_uc_es.uc.publicHealth1')+' '+this.$t('assessment.sust_uc_es.uc.publicHealth2')},
        {code: "UC_economic", text: this.$t('assessment.sust_uc_es.uc.economic1')+' '+this.$t('assessment.sust_uc_es.uc.economic2')}
      ]};
    },
    sustanability: function(){
      return {
        select: null,
            options: [
          {code: "avg_sust", text: this.$t('assessment.sust_uc_es.average')+' '+this.$t('assessment.sust_uc_es.sust.title')},
          {code: "seeds", text: this.$t('assessment.sust_uc_es.sust.seeds')},
          {code: "weed", text: this.$t('assessment.sust_uc_es.sust.weed')},
          {code: "waste", text: this.$t('assessment.sust_uc_es.sust.waste')},
          {code: "food", text: this.$t('assessment.sust_uc_es.sust.food')},
          {code: "water", text: this.$t('assessment.sust_uc_es.sust.water')},
          {code: "packaging", text: this.$t('assessment.sust_uc_es.sust.packaging1')+' '+this.$t('assessment.sust_uc_es.sust.packaging2')},
          {code: "energy", text: this.$t('assessment.sust_uc_es.sust.energy')},
          {code: "fuel", text: this.$t('assessment.sust_uc_es.sust.fuel')},
          {code: "fertilizers", text: this.$t('assessment.sust_uc_es.sust.fertilizers')}
        ]
      }

    },
    typeOfProducts: function()
    {
      return {
        select: null,
        options: [
          { code: 'raw-plant', text: this.$t('ecs_profile.iconsTitles.rawPlant'), list: [
              "01.11.11",
              "01.11.12",
              "01.11.20",
              "01.11.31",
              "01.11.32",
              "01.11.33",
              "01.11.41",
              "01.11.42",
              "01.11.43",
              "01.11.49",
              "01.11.50",
              "01.11.61",
              "01.11.62",
              "01.11.69",
              "01.11.71",
              "01.11.72",
              "01.11.73",
              "01.11.74",
              "01.11.75",
              "01.11.76",
              "01.11.77",
              "01.11.79",
              "01.11.81",
              "01.11.82",
              "01.11.91",
              "01.11.92",
              "01.11.93",
              "01.11.94",
              "01.11.95",
              "01.11.96",
              "01.11.99",
              "01.12.10",
              "01.13.11",
              "01.13.12",
              "01.13.13",
              "01.13.14",
              "01.13.15",
              "01.13.16",
              "01.13.17",
              "01.13.19",
              "01.13.21",
              "01.13.29",
              "01.13.31",
              "01.13.32",
              "01.13.33",
              "01.13.34",
              "01.13.39",
              "01.13.41",
              "01.13.42",
              "01.13.43",
              "01.13.44",
              "01.13.49",
              "01.13.51",
              "01.13.52",
              "01.13.53",
              "01.13.54",
              "01.13.59",
              "01.13.60",
              "01.13.71",
              "01.13.72",
              "01.13.80",
              "01.13.90",
              "01.14.10",
              "01.19.39",
              "01.21.11",
              "01.21.12",
              "01.22.11",
              "01.22.12",
              "01.22.13",
              "01.22.14",
              "01.22.19",
              "01.23.11",
              "01.23.12",
              "01.23.13",
              "01.23.14",
              "01.23.19",
              "01.24.10",
              "01.24.21",
              "01.24.22",
              "01.24.23",
              "01.24.24",
              "01.24.25",
              "01.24.26",
              "01.24.27",
              "01.24.28",
              "01.24.29",
              "01.25.11",
              "01.25.12",
              "01.25.13",
              "01.25.19",
              "01.25.20",
              "01.25.31",
              "01.25.32",
              "01.25.33",
              "01.25.34",
              "01.25.35",
              "01.25.39",
              "01.25.90",
              "01.26.11",
              "01.26.12",
              "01.26.20",
              "01.26.90",
              "01.27.11",
              "01.27.12",
              "01.27.13",
              "01.27.14",
              "01.28.11",
              "01.28.12",
              "01.28.13",
              "01.28.14",
              "01.28.15",
              "01.28.16",
              "01.28.17",
              "01.28.18",
              "01.28.19",
              "01.28.20",
              "02.30.40",
              "03.00.64",
              "02.30.40",
              "03.00.63",
              "03.00.64",
              "01.99.01",
              "01.99.02",
              "01.99.03",
              "01.99.04",
              "01.99.05",
              "01.99.06",
              "01.99.07",
            ] },
          { code: 'raw-animal', text: this.$t('ecs_profile.iconsTitles.rawAnimal'), list: [
              "01.41.20",
              "01.45.21",
              "01.45.22",
              "01.47.21",
              "01.47.22",
              "01.47.23",
              "01.47.24",
              "01.49.21",
              "01.49.22",
              "01.49.23",
              "01.49.24",
              "03.00.23",
              "03.00.24",
              "03.00.32",
              "03.00.43",
              "03.00.44",
              "03.00.45",
              "03.00.21",
              "03.00.22",
              "03.00.23",
              "03.00.24",

            ] },
          { code: 'manufactured', text: this.$t('assessment.indicators.manufactured'), list: [
              "10.11.11",
              "10.11.12",
              "10.11.13",
              "10.11.14",
              "10.11.15",
              "10.11.20",
              "10.11.31",
              "10.11.32",
              "10.11.33",
              "10.11.34",
              "10.11.35",
              "10.11.39",
              "10.11.50",
              "10.12.10",
              "10.12.20",
              "10.12.30",
              "10.12.40",
              "10.13.11",
              "10.13.12",
              "10.13.13",
              "10.13.14",
              "10.13.15",
              "10.20.11",
              "10.20.12",
              "10.20.13",
              "10.20.14",
              "10.20.15",
              "10.20.16",
              "10.20.21",
              "10.20.22",
              "10.20.23",
              "10.20.24",
              "10.20.25",
              "10.20.26",
              "10.20.31",
              "10.20.32",
              "10.20.33",
              "10.20.34",
              "10.31.11",
              "10.31.12",
              "10.31.13",
              "10.31.14",
              "10.32.11",
              "10.32.12",
              "10.32.13",
              "10.32.14",
              "10.32.15",
              "10.32.16",
              "10.32.17",
              "10.32.19",
              "10.39.11",
              "10.39.12",
              "10.39.13",
              "10.39.14",
              "10.39.15",
              "10.39.16",
              "10.39.17",
              "10.39.18",
              "10.39.21",
              "10.39.22",
              "10.39.23",
              "10.39.24",
              "10.39.25",
              "10.39.29",
              "10.41.11",
              "10.41.12",
              "10.41.19",
              "10.41.21",
              "10.41.22",
              "10.41.23",
              "10.41.24",
              "10.41.25",
              "10.41.29",
              "10.41.41",
              "10.41.42",
              "10.41.51",
              "10.41.52",
              "10.41.53",
              "10.41.54",
              "10.41.55",
              "10.41.56",
              "10.41.57",
              "10.41.58",
              "10.41.59",
              "10.41.60",
              "10.41.71",
              "10.42.10",
              "10.51.11",
              "10.51.12",
              "10.51.21",
              "10.51.22",
              "10.51.30",
              "10.51.40",
              "10.51.51",
              "10.51.52",
              "10.51.53",
              "10.51.54",
              "10.51.55",
              "10.51.56",
              "10.52.10",
              "10.61.11",
              "10.61.12",
              "10.61.21",
              "10.61.22",
              "10.61.23",
              "10.61.24",
              "10.61.31",
              "10.61.32",
              "10.61.33",
              "10.62.11",
              "10.62.12",
              "10.62.13",
              "10.62.14",
              "10.71.11",
              "10.71.12",
              "10.72.11",
              "10.72.12",
              "10.72.19",
              "10.73.11",
              "10.73.12",
              "10.81.11",
              "10.81.12",
              "10.81.13",
              "10.81.14",
              "10.82.11",
              "10.82.12",
              "10.82.13",
              "10.82.14",
              "10.82.21",
              "10.82.22",
              "10.82.23",
              "10.82.24",
              "10.83.11",
              "10.83.12",
              "10.83.13",
              "10.83.14",
              "10.83.15",
              "10.84.11",
              "10.84.12",
              "10.84.21",
              "10.84.22",
              "10.84.23",
              "10.85.11",
              "10.85.12",
              "10.85.13",
              "10.85.14",
              "10.85.19",
              "10.86.10",
              "10.89.11",
              "10.89.12",
              "10.89.13",
              "10.89.14",
              "10.89.15",
              "10.89.16",
              "10.89.17",
              "10.89.19",
              "11.01.10",
              "11.02.11",
              "11.02.12",
              "11.02.20",
              "11.03.10",
              "11.04.10",
              "11.05.10",
              "11.06.10",
              "11.07.11",
              "11.07.19",
            ] },
          { code: 'non-edible', text: this.$t('assessment.indicators.noEdible'), list: [
              "100.01",
              "100.02",
              "100.03",
              "100.04",
              "100.05",
            ] },
        ]
      };
    }
  },

  data() {
    return {
      type_1: [],
      type_2: [],
      loading: false,
      hidden: true,
      ecs_names: [],
      productsShown : {},
      results:{
        name: "",
        advanced_search: false,
        showInteractive: true,
        showNonInteractive: true,
        selectedInterests: ["interactive","non-interactive"],
        location: "",
        keywords: "",
        sustanability: null,
        urbanChallenges: null,
        typeOfProfile: {select: { code: 'all', text: 'All' }},
        typeOfECS: {select: []},
        typeOfECSCustom: {select: []},
        typeOfProducts: {select: []},
        products: {select: []},
        productAvailable: {select: []},
        typeOfActivities: {select: []},
        activityAvailable: {select: []},
        initialBudged: {min: 0, max: 10000},
        costs: {min: 0, max: 10000},
        typeOfAdvertisement: {select: []},
        typeOfFunding: {select: []},
        typeOfEdibleStructure: {select: []},
        typeOfNonEdibleStructure: {select: { code: 'all', text: 'All' }},
      },
      typeOfProfile: {
        select: { code: 'all', text: 'All' },
        options: [
          { code: 'all', text: 'All' },
          { code: '147.1', text: 'Interactive' },
          { code: '147.0', text: 'No interactive' },
        ]
      },
      typeOfECS:{
        select: null,
        options: [
          { code: 'all', text: 'All'},
          { code: 'Large urban park', text: '1.1.1'},
          { code: 'Forest garden', text: '1.1.2'},
          { code: 'School garden', text: '1.1.3'},
          { code: 'Pocket garden/park', text: '1.1.4'},
          { code: 'Private gardens', text: '1.1.5'},
          { code: 'Urban forest', text: '1.1.6'},
          { code: 'Urban orchards', text: '1.1.7'},
          { code: 'Community garden', text: '1.1.8'},
          { code: 'Heritage garden', text: '1.1.9'},
          { code: 'Green Corridors', text: '1.1.10'},
          { code: 'Street trees', text: '1.1.11'},
          { code: 'Cooperative', text: '1.0.1'},
          { code: 'Local market/store', text: '1.0.2'},
          { code: 'Local wholesale ', text: '1.0.3'},
          { code: 'Local distribution company', text: '1.0.4'},
          { code: 'Local food rescue enterprise', text: '1.0.5'},
          { code: 'Community kitchen ', text: '1.0.6'}
        ]
      },
      products: {
        options: [
          { code: 'Durum wheat', text: '01.11.11' },
          { code: 'Wheat, except durum wheat', text: '01.11.12' },
          { code: 'Maize, except sweet corn', text: '01.11.20' },
          { code: 'Barley', text: '01.11.31' },
          { code: 'Rye', text: '01.11.32' },
          { code: 'Oats', text: '01.11.33' },
          { code: 'Sorghum, except fodder sorghum', text: '01.11.41' },
          { code: 'Millet', text: '01.11.42' },
          { code: 'Triticale', text: '01.11.43' },
          { code: 'Other cereals', text: '01.11.49' },
          { code: 'Cereals straw and husks (e.g. unprepared straw, whether or not chopped, ground, pressed or in the form of pellets), except cleaned, bleached or dyed straw', text: '01.11.50' },
          { code: 'Beans, green', text: '01.11.61' },
          { code: 'Peas, green (e.g. green peas and forage peas)', text: '01.11.62' },
          { code: 'Other green leguminous vegetables, except soya beans', text: '01.11.69' },
          { code: 'Beans, dry', text: '01.11.71' },
          { code: 'Broad beans, dry', text: '01.11.72' },
          { code: 'Chick peas, dry', text: '01.11.73' },
          { code: 'Lentils, dry', text: '01.11.74' },
          { code: 'Peas, dry', text: '01.11.75' },
          { code: 'Cow peas', text: '01.11.76' },
          { code: 'Pigeon peas, dry', text: '01.11.77' },
          { code: 'Pulses (dried leguminous vegetables), except soya beans', text: '01.11.79' },
          { code: 'Soya beans, except roasted soya beans used as coffee substitutes', text: '01.11.81' },
          { code: 'Groundnuts, in shell', text: '01.11.82' },
          { code: 'Lin seed', text: '01.11.91' },
          { code: 'Mustard seed', text: '01.11.92' },
          { code: 'Rape or colza seed', text: '01.11.93' },
          { code: 'Sesame seed', text: '01.11.94' },
          { code: 'Sunflower seed', text: '01.11.95' },
          { code: 'Castor oil seeds', text: '01.11.96' },
          { code: 'Other oil seeds n.e.c.', text: '01.11.99' },
          { code: 'Rice, not husked', text: '01.12.10' },
          { code: 'Asparagus', text: '01.13.11' },
          { code: 'Cabbages', text: '01.13.12' },
          { code: 'Cauliflowers and broccoli', text: '01.13.13' },
          { code: 'Lettuce', text: '01.13.14' },
          { code: 'Chicory, except chicory roots', text: '01.13.15' },
          { code: 'Spinach', text: '01.13.16' },
          { code: 'Artichokes', text: '01.13.17' },
          { code: 'Other leafy or stem vegetables (e.g. parsley)', text: '01.13.19' },
          { code: 'Watermelons', text: '01.13.21' },
          { code: 'Other melons', text: '01.13.29' },
          { code: 'Chillies and peppers, green (only capsicum), except fruit-bearing vegetables of the genus Pimenta', text: '01.13.31' },
          { code: 'Cucumbers and gherkins', text: '01.13.32' },
          { code: 'Eggplants (aubergines)', text: '01.13.33' },
          { code: 'Tomatoes', text: '01.13.34' },
          { code: 'Other fruit-bearing vegetables (e.g. courgettes- sweet corn- vegetables of the genus Pimenta- pumpkins, squash and other vegetables, fresh ears of sweetcorn for ornamental purposes), except dried ears of sweetcorn bleached, dyed or otherwise worked for ornamental purposes', text: '01.13.39' },
          { code: 'Carrots and turnips', text: '01.13.41' },
          { code: 'Garlic', text: '01.13.42' },
          { code: 'Onions (e.g. onions and shallots)', text: '01.13.43' },
          { code: 'Leeks and other alliaceous vegetables (e.g. Japanese bunching onion or Welsh onion and chives)', text: '01.13.44' },
          { code: 'Other root, bulb or tuberous vegetables (e.g. salad beetroot- radishes - horse-radish- celeriac rooted celery or German celery- parsnip, similar edible roots), except elery, other than rooted celery', text: '01.13.49' },
          { code: 'Potatoes', text: '01.13.51' },
          { code: 'Sweet potatoes', text: '01.13.52' },
          { code: 'Cassava', text: '01.13.53' },
          { code: 'Taro', text: '01.13.54' },
          { code: 'Other edible roots and tubers with high starch or inulin content, except taro', text: '01.13.59' },
          { code: 'Vegetable seeds, except beet seeds', text: '01.13.60' },
          { code: 'Sugar beet', text: '01.13.71' },
          { code: 'Sugar beet seeds', text: '01.13.72' },
          { code: 'Mushrooms and truffles, except forest mushrooms and wild truffles', text: '01.13.80' },
          { code: 'Vegetables, fresh, (e.g. fennel, when used as a vegetable,- celeriac other than "rooted celery"- other fresh vegetables, n.e.cThis subcategory also includes:- fresh edible lucerne, soya bean and sunflower sprouts- bean sprouts and shoots), except eleriac "rooted celery" and fennel, when used as a spice', text: '01.13.90' },
          { code: 'Sugar cane', text: '01.14.10' },
          { code: 'Raw vegetable materials (e.g. sweet sorghum, - pollen (pollen meal), small pellets containing pollen gathered by bees formed by nectar, honey and bee saliva) except chicory roots, pollen obtained by a mechanical process classified as part of the plant from which it is derived, dried pollen extract obtained by the solvent extraction', text: '01.19.39' },
          { code: 'Table grapes', text: '01.21.11' },
          { code: 'Other grapes, fresh', text: '01.21.12' },
          { code: 'Avocados', text: '01.22.11' },
          { code: 'Bananas, plantains and similar', text: '01.22.12' },
          { code: 'Dates', text: '01.22.13' },
          { code: 'Figs, except cactus pear', text: '01.22.14' },
          { code: 'Other tropical and subtropical fruits', text: '01.22.19' },
          { code: 'Pomelo and grapefruits', text: '01.23.11' },
          { code: 'Lemons and limes', text: '01.23.12' },
          { code: 'Oranges', text: '01.23.13' },
          { code: 'Tangerines, mandarins, clementines', text: '01.23.14' },
          { code: 'Other citrus fruits', text: '01.23.19' },
          { code: 'Apples', text: '01.24.10' },
          { code: 'Pears', text: '01.24.21' },
          { code: 'Quinces', text: '01.24.22' },
          { code: 'Apricots', text: '01.24.23' },
          { code: 'Cherries', text: '01.24.24' },
          { code: 'Peaches', text: '01.24.25' },
          { code: 'Nectarines', text: '01.24.26' },
          { code: 'Plums', text: '01.24.27' },
          { code: 'Sloes', text: '01.24.28' },
          { code: 'Other pome fruits and stone fruits n.e.c.', text: '01.24.29' },
          { code: 'Kiwi fruit', text: '01.25.11' },
          { code: 'Raspberries', text: '01.25.12' },
          { code: 'Strawberries', text: '01.25.13' },
          { code: 'Other berries, the fruits of the genus vaccinium (e.g. cranberry, blueberry, lingonberry, bilberry, huckleberry- currant and gooseberries)', text: '01.25.19' },
          { code: 'Fruit seeds (e.g. seeds of ornamental trees or trees intended to beautify parks, gardens and streets) except locust bean seed undecorticated, neither crushed nor ground', text: '01.25.20' },
          { code: 'Almonds', text: '01.25.31' },
          { code: 'Chestnuts', text: '01.25.32' },
          { code: 'Hazelnuts', text: '01.25.33' },
          { code: 'Pistachios', text: '01.25.34' },
          { code: 'Walnuts', text: '01.25.35' },
          { code: 'Other nuts (excluding wild edible nuts, groundnuts and coconuts)', text: '01.25.39' },
          { code: 'Other tree and bush fruits (e.g. ersimmons, cactus pears, pomegranate)', text: '01.25.90' },
          { code: 'Table olives', text: '01.26.11' },
          { code: 'Olives for production of olive oil', text: '01.26.12' },
          { code: 'Coconuts', text: '01.26.20' },
          { code: 'Other oleaginous fruits', text: '01.26.90' },
          { code: 'Coffee beans, not roasted', text: '01.27.11' },
          { code: 'Tea leaves', text: '01.27.12' },
          { code: 'Maté leaves', text: '01.27.13' },
          { code: 'Cocoa beans', text: '01.27.14' },
          { code: 'Pepper (piper spp.), raw', text: '01.28.11' },
          { code: 'Chillies and peppers, dry (capsicum spp.), except unprocessed spices of the genus Pimenta', text: '01.28.12' },
          { code: 'Nutmeg, mace and cardamoms, raw', text: '01.28.13' },
          { code: 'Anise, badian, coriander, cumin, caraway, fennel and juniper berries, raw', text: '01.28.14' },
          { code: 'Cinnamon (canella), raw', text: '01.28.15' },
          { code: 'Cloves (whole stems), raw', text: '01.28.16' },
          { code: 'Ginger, dry, raw', text: '01.28.17' },
          { code: 'Vanilla, raw', text: '01.28.18' },
          { code: 'Other spices, not processed (e.g. chicory roots- unprocessed spices of the genus Pimenta) except roasted chicory and other roasted coffee substitutes', text: '01.28.19' },
          { code: 'Hop cones', text: '01.28.20' },
          { code: 'Wild growing edible products', text: '02.30.40' },
          { code: 'Seaweeds and other algae for human consumption, farmed', text: '03.00.64' },
          { code: 'Seaweeds and other algae not for human consumption, farmed', text: '03.00.66' },
          { code: 'Wild growing edible products', text: '02.30.40' },
          { code: 'Raw milk from dairy cattle (e.g- raw buffalo milk)', text: '01.41.20' },
          { code: 'Raw milk from sheep', text: '01.45.21' },
          { code: 'Raw milk from goats', text: '01.45.22' },
          { code: 'Hen eggs in shell, fresh', text: '01.47.21' },
          { code: 'Eggs from other poultry, in shell, fresh', text: '01.47.22' },
          { code: 'Hen eggs for hatching', text: '01.47.23' },
          { code: 'Eggs from other poultry, for hatching', text: '01.47.24' },
          { code: 'Natural honey', text: '01.49.21' },
          { code: 'Raw milk, except raw milk of bovine and buffalo animals', text: '01.49.22' },
          { code: 'Snails, fresh, chilled, frozen, dried, salted or in brine, except sea snails', text: '01.49.23' },
          { code: 'Edible products of farm animal origin n.e.c.', text: '01.49.24' },
          { code: 'Fish, live, fresh or chilled, for human consumption, marine, farmed', text: '03.00.23' },
          { code: 'Fish, live, fresh or chilled, for human consumption, freshwater, farmed', text: '03.00.24' },
          { code: 'Crustaceans, not frozen, farmed', text: '03.00.32' },
          { code: 'Oysters, live, fresh or chilled, farmed', text: '03.00.43' },
          { code: 'Other molluscs, live, fresh or chilled, farmed', text: '03.00.44' },
          { code: 'Other aquatic invertebrates, live, fresh or chilled, farmed', text: '03.00.45' },
          { code: 'Fish, live, fresh or chilled, for human consumption, marine, not farmed', text: '03.00.21' },
          { code: 'Fish, live, fresh or chilled, for human consumption, freshwater, not farmed', text: '03.00.22' },
          { code: 'Fish, live, fresh or chilled, for human consumption, marine, farmed', text: '03.00.23' },
          { code: 'Fish, live, fresh or chilled, for human consumption, freshwater, farmed', text: '03.00.24' },
          { code: 'Seaweeds and other algae for human consumption, wild', text: '03.00.63' },
          { code: 'Seaweeds and other algae for human consumption, farmed', text: '03.00.64' },
          { code: 'Meat of bovine animals, fresh or chilled', text: '10.11.11' },
          { code: 'Meat of swine, fresh or chilled', text: '10.11.12' },
          { code: 'Meat of sheep, fresh or chilled', text: '10.11.13' },
          { code: 'Meat of goats, fresh or chilled', text: '10.11.14' },
          { code: 'Meat of horses and other equines, fresh or chilled', text: '10.11.15' },
          { code: 'Edible offal of bovine animals, swine, sheep, goats, horses and other equines, fresh or chilled', text: '10.11.20' },
          { code: 'Meat of bovine animals, frozen', text: '10.11.31' },
          { code: 'Meat of swine, frozen', text: '10.11.32' },
          { code: 'Meat of sheep, frozen', text: '10.11.33' },
          { code: 'Meat of goats, frozen', text: '10.11.34' },
          { code: 'Meat of horses and other equines, frozen', text: '10.11.35' },
          { code: 'Other meat and edible offal, fresh, chilled or frozen', text: '10.11.39' },
          { code: 'Fats of bovine animals, sheep, goats or pigs', text: '10.11.50' },
          { code: 'Meat of poultry, fresh or chilled', text: '10.12.10' },
          { code: 'Meat of poultry, frozen', text: '10.12.20' },
          { code: 'Fats of poultry', text: '10.12.30' },
          { code: 'Edible offal of poultry', text: '10.12.40' },
          { code: 'Swine meat, cuts, salted, dried or smoked (bacon and ham)', text: '10.13.11' },
          { code: 'Bovine meat, salted, dried or smoked', text: '10.13.12' },
          { code: 'Other meat and edible meat offal, salted, in brine, dried or smoked (excluding swine and bovine meat); edible flours and meals of meat or meat offal', text: '10.13.13' },
          { code: 'Sausages and similar products of meat, offal or blood', text: '10.13.14' },
          { code: 'Other prepared and preserved meat, meat offal or blood,', text: '10.13.15' },
          { code: 'Fish fillets and other fish meat (whether or not minced), fresh or chilled', text: '10.20.11' },
          { code: 'Fish livers and roes, fresh or chilled', text: '10.20.12' },
          { code: 'Fish, frozen', text: '10.20.13' },
          { code: 'Fish fillets, frozen', text: '10.20.14' },
          { code: 'Fish meat (whether or not minced), frozen', text: '10.20.15' },
          { code: 'Fish livers and roes, frozen', text: '10.20.16' },
          { code: 'Fish fillets, dried, salted or in brine, but not smoked', text: '10.20.21' },
          { code: 'Fish livers, roes, fins, heads, tails, maws and other edible offal dried, smoked, salted or in brine; flours, meals and pellets of fish, fit for human consumption', text: '10.20.22' },
          { code: 'Fish, dried, whether or not salted, or in brine', text: '10.20.23' },
          { code: 'Fish, including fillets, smoked', text: '10.20.24' },
          { code: 'Fish, otherwise prepared or preserved, except prepared fish dishes', text: '10.20.25' },
          { code: 'Caviar and caviar substitutes', text: '10.20.26' },
          { code: 'Crustaceans frozen, dried, salted or in brine', text: '10.20.31' },
          { code: 'Molluscs, frozen, dried, salted or in brine', text: '10.20.32' },
          { code: 'Other aquatic invertebrates and seaweed, frozen, dried, salted or in brine', text: '10.20.33' },
          { code: 'Crustaceans, molluscs and other aquatic invertebrates and seaweed, otherwise prepared or preserved', text: '10.20.34' },
          { code: 'Potatoes, frozen', text: '10.31.11' },
          { code: 'Dried potatoes whether or not cut or sliced but not further prepared', text: '10.31.12' },
          { code: 'Dried potatoes in the form of flour, meal, flakes, granulates and pellets', text: '10.31.13' },
          { code: 'Potatoes prepared or preserved', text: '10.31.14' },
          { code: 'Tomato juice', text: '10.32.11' },
          { code: 'Orange juice', text: '10.32.12' },
          { code: 'Grapefruit juice', text: '10.32.13' },
          { code: 'Pineapple juice', text: '10.32.14' },
          { code: 'Grape juice', text: '10.32.15' },
          { code: 'Apple juice', text: '10.32.16' },
          { code: 'Mixtures of fruit and vegetable juices', text: '10.32.17' },
          { code: 'Other fruit and vegetable juices', text: '10.32.19' },
          { code: 'Vegetables, frozen', text: '10.39.11' },
          { code: 'Vegetables provisionally preserved', text: '10.39.12' },
          { code: 'Dried vegetables', text: '10.39.13' },
          { code: 'Cut and packaged vegetables and fruits', text: '10.39.14' },
          { code: 'Beans, preserved otherwise than by vinegar or acetic acid, except prepared vegetable dishes', text: '10.39.15' },
          { code: 'Peas, preserved otherwise than by vinegar or acetic acid, except prepared vegetable dishes', text: '10.39.16' },
          { code: 'Other vegetables (except potatoes), preserved otherwise than by vinegar or acetic acid, except prepared vegetable dishes', text: '10.39.17' },
          { code: 'Vegetables (except potatoes), fruit, nuts and other edible parts of plants, prepared or preserved by vinegar or acetic acid', text: '10.39.18' },
          { code: 'Fruit and nuts, uncooked or cooked, frozen', text: '10.39.21' },
          { code: 'Jams, fruit jellies and fruit or nut puree and pastes', text: '10.39.22' },
          { code: 'Nuts, groundnuts, roasted, salted or otherwise prepared', text: '10.39.23' },
          { code: 'Fruit and nuts, provisionally preserved, not for immediate consumption', text: '10.39.24' },
          { code: 'Groundnuts and nuts, shelled', text: '10.39.25' },
          { code: 'Other prepared, dried or preserved fruits and nuts', text: '10.39.29' },
          { code: 'Lard stearin, lard oil, oleostearin, oleo-oil and tallow oil, not emulsified or mixed or otherwise prepared', text: '10.41.11' },
          { code: 'Fats and oils and their fractions, of fish and marine mammals', text: '10.41.12' },
          { code: 'Other animal fats and oils and their fractions, whether or not refined, but not chemically modified', text: '10.41.19' },
          { code: 'Groundnut oil, crude', text: '10.41.21' },
          { code: 'Olive oil, crude', text: '10.41.22' },
          { code: 'Sunflower-seed oil, crude', text: '10.41.23' },
          { code: 'Rape, colza and mustard oil, crude', text: '10.41.24' },
          { code: 'Palm oil, crude', text: '10.41.25' },
          { code: 'Other vegetable oils, crude', text: '10.41.29' },
          { code: 'Oil-cake and other solid residues, of vegetable fats or oils', text: '10.41.41' },
          { code: 'Flours and meals of oil seeds or oleaginous fruits, except those of mustard', text: '10.41.42' },
          { code: 'Soya-bean oil and its fractions, refined but not chemically modified', text: '10.41.51' },
          { code: 'Groundnut oil and its fractions, refined but not chemically modified', text: '10.41.52' },
          { code: 'Olive oil and its fractions, refined but not chemically modified', text: '10.41.53' },
          { code: 'Sunflower-seed oil and its fractions, refined but not chemically modified', text: '10.41.54' },
          { code: 'Cotton-seed oil and its fractions, refined but not chemically modified', text: '10.41.55' },
          { code: 'Rape, colza and mustard oil and their fractions, refined but not chemically modified', text: '10.41.56' },
          { code: 'Palm oil and its fractions, refined but not chemically modified', text: '10.41.57' },
          { code: 'Coconut oil and its fractions, refined but not chemically modified', text: '10.41.58' },
          { code: 'Other oils and their fractions, refined but not chemically modified; fixed vegetable fats and other vegetable oils (except maize oil) and their fractions n.e.c. refined but not chemically modified', text: '10.41.59' },
          { code: 'Animal or vegetable fats and oils and their fractions, hydrogenated, esterified, but not further prepared', text: '10.41.60' },
          { code: 'Vegetable waxes (excluding triglycerides)', text: '10.41.71' },
          { code: 'Margarine and similar edible fats', text: '10.42.10' },
          { code: 'Processed liquid milk', text: '10.51.11' },
          { code: 'Milk and cream of > 6% fat, not concentrated or sweetened', text: '10.51.12' },
          { code: 'Skimmed milk and cream powder', text: '10.51.21' },
          { code: 'Whole milk and cream powder', text: '10.51.22' },
          { code: 'Butter and dairy spreads', text: '10.51.30' },
          { code: 'Cheese and curd', text: '10.51.40' },
          { code: 'Milk and cream, concentrated or containing added sugar or other sweetening matter, other than in solid forms', text: '10.51.51' },
          { code: 'Yoghurt and other fermented or acidified milk or cream', text: '10.51.52' },
          { code: 'Casein', text: '10.51.53' },
          { code: 'Lactose and lactose syrup', text: '10.51.54' },
          { code: 'Whey', text: '10.51.55' },
          { code: 'Dairy products n.e.c.', text: '10.51.56' },
          { code: 'Ice cream and other edible ice', text: '10.52.10' },
          { code: 'Husked rice', text: '10.61.11' },
          { code: 'Rice, semi- or wholly milled or broken', text: '10.61.12' },
          { code: 'Wheat or maslin flour', text: '10.61.21' },
          { code: 'Other cereal flour', text: '10.61.22' },
          { code: 'Vegetable flour and meal', text: '10.61.23' },
          { code: 'Mixes for preparation of bakers\' wares', text: '10.61.24' },
          { code: 'Groats and meal of wheat', text: '10.61.31' },
          { code: 'Cereal groats, meal and pellets n.e.c.', text: '10.61.32' },
          { code: 'Breakfast cereals and other cereal grain products', text: '10.61.33' },
          { code: 'Starches; inulin; wheat gluten; dextrins and other modified starches', text: '10.62.11' },
          { code: 'Tapioca and substitutes prepared from starch in flakes, grains and the like', text: '10.62.12' },
          { code: 'Glucose and glucose syrup; fructose and fructose syrup; invert sugar; sugars and sugar syrups n.e.c.', text: '10.62.13' },
          { code: 'Maize oil', text: '10.62.14' },
          { code: 'Fresh bread', text: '10.71.11' },
          { code: 'Fresh pastry goods and cakes', text: '10.71.12' },
          { code: 'Crispbread, rusks, toasted bread and similar toasted products', text: '10.72.11' },
          { code: 'Gingerbread and the like; sweet biscuits; waffles and wafers', text: '10.72.12' },
          { code: 'Other dry or preserved bakers\' wares', text: '10.72.19' },
          { code: 'Macaroni, noodles and similar farinaceous products', text: '10.73.11' },
          { code: 'Couscous', text: '10.73.12' },
          { code: 'Raw cane or beet sugar, in solid form', text: '10.81.11' },
          { code: 'Refined cane or beet sugar and chemically pure sucrose, in solid form, not containing added flavouring or colouring matter', text: '10.81.12' },
          { code: 'Refined cane or beet sugar, containing added flavouring or colouring matter; maple sugar and maple syrup', text: '10.81.13' },
          { code: 'Molasses', text: '10.81.14' },
          { code: 'Cocoa paste, whether or not defatted', text: '10.82.11' },
          { code: 'Cocoa butter, fat and oil', text: '10.82.12' },
          { code: 'Cocoa powder, not containing added sugar or other sweetening matter', text: '10.82.13' },
          { code: 'Cocoa powder, containing added sugar or other sweetening matter', text: '10.82.14' },
          { code: 'Chocolate and food preparations containing cocoa (except sweetened cocoa powder), in bulk forms', text: '10.82.21' },
          { code: 'Chocolate and food preparations containing cocoa (except sweetened cocoa powder), other than in bulk forms', text: '10.82.22' },
          { code: 'Sugar confectionery (including white chocolate), not containing cocoa', text: '10.82.23' },
          { code: 'Fruit, nuts, fruit-peel and other parts of plants, preserved by sugar', text: '10.82.24' },
          { code: 'Coffee, decaffeinated or roasted', text: '10.83.11' },
          { code: 'Coffee substitutes; extracts, essences and concentrates of coffee or coffee substitutes; coffee husks and skins', text: '10.83.12' },
          { code: 'Green tea (not fermented), black tea (fermented) and partly fermented tea, in immediate packings of a content ≤ 3 kg', text: '10.83.13' },
          { code: 'Extracts, essences, concentrates and preparations of tea or maté', text: '10.83.14' },
          { code: 'Herb infusions', text: '10.83.15' },
          { code: 'Vinegar and substitutes for vinegar obtained from acetic acid', text: '10.84.11' },
          { code: 'Sauces; mixed condiments and mixed seasonings; mustard flour and meal and prepared mustard', text: '10.84.12' },
          { code: 'Pepper (piper spp.), processed', text: '10.84.21' },
          { code: 'Chillies and peppers, dry (capsicum spp.), processed', text: '10.84.22' },
          { code: 'Cinnamon (canella), processed; other processed spices', text: '10.84.23' },
          { code: 'Prepared meals and dishes based on meat, meat offal or blood', text: '10.85.11' },
          { code: 'Prepared meals and dishes based on fish, crustaceans and molluscs', text: '10.85.12' },
          { code: 'Prepared meals and dishes based on vegetables', text: '10.85.13' },
          { code: 'Prepared meals and dishes based on pasta', text: '10.85.14' },
          { code: 'Other prepared dishes and meals', text: '10.85.19' },
          { code: 'Homogenised food preparations and dietetic food', text: '10.86.10' },
          { code: 'Soups and broths and preparations thereof', text: '10.89.11' },
          { code: 'Eggs, not in shell, and eggs yolks fresh or preserved; eggs in shells preserved or cooked; egg albumin', text: '10.89.12' },
          { code: 'Yeasts (active or inactive); other single-cell micro-organisms, dead; prepared baking powders', text: '10.89.13' },
          { code: 'Extracts and juices of meat, fish and aquatic invertebrates', text: '10.89.14' },
          { code: 'Vegetable saps and extracts; pectic substances; mucilages and thickeners', text: '10.89.15' },
          { code: 'Prepared perishable food products such as sandwiches and fresh pizza', text: '10.89.16' },
          { code: 'Food supplements for human consumption', text: '10.89.17' },
          { code: 'Miscellaneous food products n.e.c.', text: '10.89.19' },
          { code: 'Distilled alcoholic beverages', text: '11.01.10' },
          { code: 'Sparkling wine of fresh grapes', text: '11.02.11' },
          { code: 'Wine of fresh grapes, except sparkling wine; grape must', text: '11.02.12' },
          { code: 'Wine lees; argol', text: '11.02.20' },
          { code: 'Other fermented beverages (e.g., cider, perry, mead); mixed beverages containing alcohol', text: '11.03.10' },
          { code: 'Vermouth and other flavoured wine of fresh grapes', text: '11.04.10' },
          { code: 'Beer, except dregs from brewing', text: '11.05.10' },
          { code: 'Malt', text: '11.06.10' },
          { code: 'Mineral waters and aerated waters, not sweetened nor flavoured', text: '11.07.11' },
          { code: 'Other non alcoholic beverages', text: '11.07.19' },
          { code: 'Basil', text: '01.99.01' },
          { code: 'Oregano', text: '01.99.02' },
          { code: 'Lavender ', text: '01.99.03' },
          { code: 'Rosemary', text: '01.99.04' },
          { code: 'Sage', text: '01.99.05' },
          { code: 'Chives ', text: '01.99.06' },
          { code: 'Other herbs (i.e chamomile, melisa, marygold, thyme)', text: '01.99.07' },
          { code: 'Aromatic products (i.e. soap)', text: '100.01' },
          { code: 'Organic fertilizers', text: '100.02' },
          { code: 'Medicinal products (i.e essential oil)', text: '100.03' },
          { code: 'Fabrics (i.e textil products)', text: '100.04' },
          { code: 'Other non-edible products', text: '100.05' },
        ]
      },
      productAvailable: {
        select: null,
        options: [
          { code: 'January', text: '26.1' },
          { code: 'February', text: '26.2' },
          { code: 'March', text: '26.3' },
          { code: 'April', text: '26.4' },
          { code: 'May', text: '26.5' },
          { code: 'June', text: '26.6' },
          { code: 'July', text: '26.7' },
          { code: 'August', text: '26.8' },
          { code: 'September', text: '26.9' },
          { code: 'October', text: '26.10' },
          { code: 'November', text: '26.11' },
          { code: 'December', text: '26.12' },
        ]
      },
      typeOfActivities: {
        select: null,
        options: [
          { code: '5.1.1', text: 'Workshops ' },
          { code: '5.1.2', text: 'Trainings & seminars ' },
          { code: '5.2', text: 'Therapeutic/Recreational/Physical activities ' },
          { code: '5.3', text: 'Research activities ' },
          { code: '5.4', text: 'Markets/festivals' },
          { code: '5.5', text: 'Volunteering programs ' },
          { code: '5.6', text: 'Open discussions on specific topics  ' },
          { code: '5.7', text: 'Recovery and reintegration program for vulnerable groups' },
          { code: '5.8.1', text: 'Event cattering services' },
          { code: '5.8.2', text: 'Canteen services' },
          { code: '5.9', text: 'Harvesting or planting events' },
          { code: '5.10', text: 'Manufacturing of edible goods' },
        ]
      },
      activityAvailable: {
        select: null,
        options: [
          { code: 'January', text: '26.1' },
          { code: 'February', text: '26.2' },
          { code: 'March', text: '26.3' },
          { code: 'April', text: '26.4' },
          { code: 'May', text: '26.5' },
          { code: 'June', text: '26.6' },
          { code: 'July', text: '26.7' },
          { code: 'August', text: '26.8' },
          { code: 'September', text: '26.9' },
          { code: 'October', text: '26.10' },
          { code: 'November', text: '26.11' },
          { code: 'December', text: '26.12' },
        ]
      },
      initialBudged:{
        minim: "0",
        maximum: "1000000"
      },
      typeOfAdvertisement: {
        select: null,
        options: [
          {code: '22.1', text: 'Word of mouth '},
          {code: '22.2', text: 'Internet /social media '},
          {code: '22.3', text: 'Television and radio'},
          {code: '22.4', text: 'Flyers, brochures '},
          {code: '22.5', text: 'Public events '},
        ]
      },
      typeOfFunding: {
        select: null,
        options: [
          { code: '23.1.1', text: 'European funds' },
          { code: '23.1.2', text: 'Public national/regional budget ' },
          { code: '23.1.3', text: 'Public local authority\'s budget ' },
          { code: '23.2', text: 'Self-funded  ' },
          { code: '23.3', text: 'Membership' },
          { code: '23.4.1', text: 'Commercialisation of edible products' },
          { code: '23.4.2', text: 'Workshops, events, tourism' },
          { code: '23.5.1', text: 'Corporate investment' },
          { code: '23.5.2', text: 'Funds provided by non-governmental organization ' },
          { code: '23.5.3', text: 'Funds provided by the private foundation' },
          { code: '23.6', text: 'Crowd-sourcing' },
        ]
      },
      typeOfEdibleStructure: {
        select: null,
        options: [
          { code: '1.2.1', text: 'Intensivegreenroof' },
          { code: '1.2.2', text: 'Extensivegreenroof' },
          { code: '1.2.3', text: 'Semi-intensivegreenroof' },
          { code: '1.2.4', text: 'Greenwallsystem' },
          { code: '1.2.5', text: 'Verticalmobilegarden' },
          { code: '1.2.6', text: 'GreenFaçade' },
          { code: '1.2.7', text: 'Plantergreenwall' },
          { code: '1.2.8', text: 'Vegetatedpergola' },
          { code: '1.2.13', text: 'Raingardens' }
        ]
      },
      typeOfNonEdibleStructure: {
        select: { code: 'all', text: 'All' },
        options: [
          { code: 'all', text: 'All' },
          { code: '1.2.1', text: 'Intensivegreenroof' },
          { code: '1.2.2', text: 'Extensivegreenroof' },
          { code: '1.2.3', text: 'Semi-intensivegreenroof' },
          { code: '1.2.4', text: 'Greenwallsystem' },
          { code: '1.2.5', text: 'Verticalmobilegarden' },
          { code: '1.2.6', text: 'GreenFaçade' },
          { code: '1.2.7', text: 'Plantergreenwall' },
          { code: '1.2.8', text: 'Vegetatedpergola' },
          { code: '1.2.9', text: 'Vegetatedgridpave' },
          { code: '1.2.10', text: 'Infiltrationbasin' },
          { code: '1.2.11', text: '(Wet)RetentionPond' },
          { code: '1.2.12', text: 'Swales' },
          { code: '1.2.13', text: 'Raingardens' },
          { code: '1.2.14', text: 'Constructedwetlands' },
          { code: '1.3', text: 'Sheltersforbiodiversity' }
        ]
      },
    }
  }
}
</script>


