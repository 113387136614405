<template lang="pug">
  .home
    //WelcomeProfile
    b-container(fluid="true")
      b-tabs.homeTab(content-class="mt-3" fill active-nav-item-class="activeTabTitle" nav-class="tabTitle" )
        b-tab.tabText(:title="$t('homeTab.edibleMap')" active style="border-color: var(--green-primary) var(--green-primary) #fff;" id="tab1" )
          EdibleMap
        b-tab.tabText(:title="$t('homeTab.edibleList')" id="tab2")
          EdibleList
        b-tab.tabText(id="tab3")
          template(#title)
            span {{$t('homeTab.edibleEvents')}}
              b-icon-lock.ml-2(v-b-tooltip.hover.right="$t('underConstruction.tooltip')")
          div(style="height:700px;")
            b-alert(show variant="warning" style="text-align:center;") {{$t('underConstruction.edibleFeed')}}
    //b-container.homeBar.p-2(style="margin: 1rem 5rem 0rem 5rem;" fluid="true")
      b-row.d-flex.p-0(style="margin: 0rem 0rem -1rem -1rem;")
        b-col.text-center
          p.barText.text-center {{ $t('progressBar.login') }}
        b-col
          p.barText.text-center
        b-col
          p.barText.text-center {{ $t('progressBar.completeProfile') }} / {{ $t('progressBar.createExperience') }}
        b-col(style="display: inline-block; max-width: fit-content;")
      b-row.d-flex.p-0
        b-col.offset(sm="12")
          ProgressBar(:max=3 v-bind:value="getProgressValue" :keyPoints="[]"
            :stepsLabels="[$t('progressBar.publicTools'),$t('progressBar.basicTools'),$t('progressBar.premiumTools')]"
            v-bind:key="getProgressValue")

</template>

<script>
  import WelcomeProfile from "@/components/home/Welcome-Profile.vue";
  import EdibleMap from "@/components/home/EdibleMap.vue";
  import EdibleList from "@/components/home/EdibleList";
  import ProgressBar from "@/components/ProgressBar";
  import axios from 'axios';

  export default {
    name: 'home',
    data: function () {
      return {
        client: {},
        categories: [],
        answers: []
      }
    },
    components: {
      WelcomeProfile,
      EdibleMap,
      ProgressBar,
      EdibleList
    },
    computed: {
      ECSCreated: function () {
        const parentECS = (JSON.parse(JSON.stringify(this.$parent.$data))).listECS;
        if(!this.$store.getters.isLoggedIn)
          return false;
        else
          return (parentECS.length !== 0);
      },
      ECSExpCreated: function () {
        const parentEXP = (JSON.parse(JSON.stringify(this.$parent.$data))).listExpirience;
        if(!this.$store.getters.isLoggedIn)
          return false;
        else
          return (parentEXP.length !== 0);
      },
      userLogged: function () {
        return (this.$store.getters.isLoggedIn.length !== 0);
      },
      getProgressValue: function (){
        let value = 0;
        if (this.userLogged) {
          value += 1;
        }
        if(this.ECSCreated){
          value +=1;
        }
        if(this.ECSExpCreated){
          value +=1;
        }
        return value;
      }
    }
  }
</script>

<style>
  .activeTabTitle {
    font-weight: var(--bold-text) !important;
    color: var(--dark-red) !important;
    font-size: 18px;
  }

  .tabTitle{
    color: var(--green-primary) !important;
    font-size: 18px;
  }

  a.nav-link {
    color: var(--green-primary) ;
    font-size: 18px;
  }

  a.nav-link:hover {
    color: var(--dark-red) ;
    font-size: 18px;
  }

  .nav-tabs .nav-link{
    background-color: white !important;
    border: 10px solid var(--light-gray-primary) !important;
    border-radius: 0 !important;
  }

  .nav-tabs .nav-link.active{
    background-color: white !important;
    border: 10px solid #fff !important;
    border-radius: 0 !important;
    color: var(--green-primary) !important;
  }

</style>

<style scoped>

  .home {
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 2rem 2rem;
    min-height: var(--height-child-comp);
  }

  p.barText{
    color: var(--green-primary);
    font-size: small;
  }

</style>
