<script>
import { Radar, mixins } from 'vue-chartjs';
Chart.defaults.global.defaultFontFamily = "Fira Sans";
// //Chart.defaults.global.defaultFontSize = "14";
Chart.defaults.global.tooltipenabled = false;


export default {
  extends: Radar,
  // mixins: [mixins.reactiveData, mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null,
    }
  },
  mounted () {
    console.log("Paint radar chart",this.chartData, this.options)
    this.renderChart(this.chartData, this.options)
  },
  methods:{
    repaint(){
      this.renderChart(this.chartData, this.options)
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  watch:{
    chartData(){
      console.log("showing the chart data:",this.chartData)
    }
  }
}
</script>

<style>
</style>