<template lang="pug">
  b-container(fluid).comments.p-3.mb-4
    //h4 Comments
    b-row
      b-col

        .add-comment(v-if="$store.getters.getUser.id")
          h4 {{$t('comments.addComment')}}
          .username(v-if="!user.username")
            label {{$t('comments.setUsername')}}
            b-input(name="username" v-model="newUsername")
          .textInput
            label(v-if="username") {{$t('comments.hi')}} {{ username }}, {{$t('comments.writeComment')}}:
            label(v-else) {{$t('comments.writeComment')}}:
            b-textarea(v-model="comment_text")
          b-button.float-right.mt-3(@click="addComment()") {{$t('comments.sendComment')}}
    b-row
      b-col
        comment(v-for="com in commentsList" :key="com.id" :commentContent="com" v-on:deleteComment="deleteComment($event)" :isAdmin="isAdmin")
    b-row(v-if="!$store.getters.getUser.id")
      b-col(style="height:700px;")
        b-alert(show variant="warning" style="text-align:center;") You must be registered to comment :)
</template>

<script>
  import comment from "@/components/showProfile/comments/comment"
  import axios from "axios";

  export default {
    name: "commentsTab",
    components: {comment},
    props: ["isAdmin","id_ecs"],
    data(){
      return {
        user: {},
        username: "",
        newUsername: "",
        commentsList: [],
        comment_text: ""
      }
    },
    mounted() {
      let _this = this
      axios.post("comments/getComments",{id_ecs: this.id_ecs })
      .then(response => {
        _this.commentsList = response.data
        if(_this.commentsList) {
          _this.commentsList.forEach(comment => {
            comment.creationDate = new Date(comment.creationDate);
          })
          _this.commentsList.sort((a, b) => {
            return a.creationDate - b.creationDate;
          })
        }
        else {
          _this.commentsList = []
        }
      })
      .catch(err => {
        console.log(err)
      })
      this.user = {...this.$store.getters.getUser}
      console.log("User", this.user)
      this.username = this.user.username
      console.log("Comments show actual user: ", this.$store.getters.getUser.id)
    },
    methods:{
      deleteComment(comment){
        this.commentsList = this.commentsList.filter(com => {
          return com.id !== comment
        })
      },
      async addComment() {
        let usernameDefined = false
        let userResp = {}
        //console.log(this.username, this.newUsername)
        if(this.username === "" || this.username === null){
          //console.log("Username is empty, setting new username", this.newUsername)
          if(this.newUsername !== "") {
            //console.log("Set username ", this.newUsername)
            userResp = await this.setUsername();
          }
        }
        else usernameDefined = true;
        if(usernameDefined || userResp.data){
          console.log("Username set, now the comment", this.id_ecs, this.comment_text)
          try {
            const resp = await axios.post("comments/addComment",{id_ecs: this.id_ecs, comment_text: this.comment_text, username: this.username})
            console.log(resp)
            this.commentsList.unshift({
              client_name: this.username,
              client_uid: this.$store.getters.getUser.id,
              comment_answer:null,
              comment_text: this.comment_text,
              creationDate: new Date(),
              ecs_id:this.id_ecs,
              id:resp.data.insertId,
            })
            this.comment_text = ""
          } catch (err){
            console.log(err)
            return err;
          }
        }
        else console.log("Username is empty or update failed")
      },
      async setUsername(){
        let _this = this;
        try {
          const resp = await axios.post("clients/updateUsername",{username: _this.newUsername})
          console.log(resp)
          if(resp.data) {
            this.username = _this.newUsername;
            this.user.username = _this.newUsername;
            this.$store.dispatch('updateUser', {user: _this.user});
          }
          return resp;
        } catch (err){
          return err;
        }
      }

    }
  }
</script>

<style scoped>

</style>
