<template lang="pug">
  .perceptions
    h3 {{$t('perceptions.titleMotivations')}}
      b-icon-question-circle.h5.pl-2(v-b-tooltip.hover.right="$t('perceptions.tooltip')" )
    chartBar(style="height: 350px" :chartData="this.engagementChart.dataChart" :options="this.engagementChart.options")
    h3 {{$t('perceptions.titleSDGs')}}
      b-icon-question-circle.h5.pl-2(v-b-tooltip.hover.right="$t('perceptions.tooltip')" )
    chartBar(style="height: 350px" :chartData="this.engagementChart2.dataChart" :options="this.engagementChart2.options")
</template>

<script>

  import chartBar from './perceptionBars'
  import axios from "axios";

  export default{
  name: "perceptionsTab",
  props: {
    id_ecs: Number,
  },
  components: {
    chartBar
  },
  computed:{
    engagementChart() {
      return {
        dataChart: {
          labels: [
            "Source of income",
            "Access to local food",
            "Access to healthier food ",
            "Access to affordable food",
            "Food sovereignty ",
            "Relaxation & leisure",
            "Physical Health / Physical exercise",
            "Mental health",
            "Care for the environment / environmental values",
            "Learning new skills",
            "Socialising",
            "Change the world"
          ],
          datasets: [
            {
              showLine: false,
              label: 'Data One',
              backgroundColor: '#A2CE89',
              data: this.perceptions.engage
            }
          ]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              gridLines: {
                display: false
              },
              ticks: {
                display: false,
                beginAtZero: true,
                max: 7
              }
            }],
            yAxes: [{
              categoryPercentage: 1.0,
              barPercentage: 0.7,
              //maxBarThickness: 10,
              gridLines: {
                display: false
              },
              afterFit: function (scaleInstance) {
                scaleInstance.width = 280; // sets the width to 100px
              }
            }]
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    engagementChart2(){
      return {
      dataChart: {
        labels: [
          'No poverty',
          'Zero hunger',
          'Good Health and well-being',
          'Quality education',
          'Gender equality',
          'Decent work and economic growth',
          'Reduced inequalities',
          'Sustainable cities and communities',
          'Responsible consumption and production',
          'Climate action'
        ],
        datasets: [
          {
            showLine: false,
            label: 'Data One',
            backgroundColor: '#A2CE89',
            data: this.perceptions.sdg
          }
        ]
      },
      options: {
        legend:{
          display: false
        },
        scales: {
          xAxes: [{

            gridLines: {
              display:false
            },
            ticks:{
              display:false,
              beginAtZero: true,
              max: 7
            }
          }],
          yAxes: [{
            categoryPercentage: 1.0,
            barPercentage: 0.7,
            //maxBarThickness: 10,
            gridLines: {
              display:false
            },
            afterFit: function(scaleInstance) {
              scaleInstance.width = 280; // sets the width to 100px
              scaleInstance.height = 100;
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }}
  } ,
  data() {
    return{
      perceptions: {
        engage: [0,0,0,0,0,0,0,0,0,0],
        sdg: [0,0,0,0,0,0,0,0,0,0]
      }
    }
  },
  mounted() {

    axios.post('answers/getECSProfilePerceptions',
        {
          "id_ecs": this.$route.params.id,
          "id_question": 107,
        })
        .then( resp => {
          console.log("Perceptions: 107",resp)
          if(resp.data){
            let income = 0;
            let localfood = 0;
            let healthyFood = 0;
            let afforableFood = 0;
            let foodSovereignty = 0;
            let relaxation = 0;
            let physicalHealthy = 0;
            let mentalHealthy = 0;
            let environmental = 0;
            let newSkills = 0;
            let socialization = 0;
            let changeWorld = 0;

            let counter = 0;
            resp.data.forEach(ans => {
              if(ans.answer){
                let realAnswer = JSON.parse(ans.answer)
                if(realAnswer.answer){
                  let ra = realAnswer.answer;
                  income += ra["107_1583160311393"]
                  localfood += ra["107_1583160493686"]
                  healthyFood += ra["107_1583161880263"]
                  afforableFood += ra["107_1583161881359"]
                  foodSovereignty += ra["107_1583161882171"]
                  relaxation += ra["107_1583161917826"]
                  physicalHealthy += ra["107_1583161918678"]
                  mentalHealthy += ra["107_1583161919701"]
                  environmental += ra["107_1583161920412"]
                  newSkills += ra["107_1583161955422"]
                  socialization += ra["107_1583161987856"]
                  changeWorld += ra["107_1587023134207"]
                  counter++;
                }
              }
            })
            if(counter > 0){
              this.perceptions.engage = [
                income/counter,
                localfood/counter,
                healthyFood/counter,
                afforableFood/counter,
                foodSovereignty/counter,
                relaxation/counter,
                physicalHealthy/counter,
                mentalHealthy/counter,
                environmental/counter,
                newSkills/counter,
                socialization/counter,
                changeWorld/counter
                ]
              console.log(this.perceptions)
            }
          }
        })
        .catch(err => {
          console.log(err)
        });

    axios.post('answers/getECSProfilePerceptions',
        {
          "id_ecs": this.$route.params.id,
          "id_question": 108,
        })
        .then( resp => {
          console.log("Perceptions: 108",resp)
          if(resp.data){
            let no_poverty = 0;
            let no_hunger = 0;
            let well_being = 0;
            let education = 0;
            let equality = 0;
            let economic = 0;
            let inequalities = 0;
            let sustainable = 0;
            let consumption = 0;
            let climate = 0;
            let counter = 0;
            resp.data.forEach(ans => {
              if(ans.answer){
                let realAnswer = JSON.parse(ans.answer)
                if(realAnswer.answer){
                  let ra = realAnswer.answer;
                  if(ra["108_1583162245235"]) no_poverty    += ra["108_1583162245235"]
                  if(ra["108_1583162252851"]) no_hunger     += ra["108_1583162252851"]
                  if(ra["108_1583162260189"]) well_being    += ra["108_1583162260189"]
                  if(ra["108_1583162268496"]) education     += ra["108_1583162268496"]
                  if(ra["108_1583162286378"]) equality      += ra["108_1583162286378"]
                  if(ra["108_1583162352031"]) economic      += ra["108_1583162352031"]
                  if(ra["108_1583162361673"]) inequalities  += ra["108_1583162361673"]
                  if(ra["108_1583162368442"]) sustainable   += ra["108_1583162368442"]
                  if(ra["108_1583162376365"]) consumption   += ra["108_1583162376365"]
                  if(ra["108_1583162389347"]) climate       += ra["108_1583162389347"]
                  counter++;
                }
              }
            })
            if(counter > 0){
              this.perceptions.sdg = [
                no_poverty/counter,
                no_hunger/counter,
                well_being/counter,
                education/counter,
                equality/counter,
                economic/counter,
                inequalities/counter,
                sustainable/counter,
                consumption/counter,
                climate/counter,
              ]
              console.log(this.perceptions)
            }
          }
        })
        .catch(err => {
          console.log(err)
        });
    }
  }
</script>

<style scoped>

</style>