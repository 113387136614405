<template lang="pug">
  b-container
    b-row
      b-col
        b-row.bg-success.p-2
          b-col.align-self-center
            h5.mb-0 {{ questionNumber+1 }}. Question:
          b-col(align-self="end")
            .text-right
              b-button(variant = "primary" @click="saveToProfile").mr-1 Guardar
              b-button(v-b-modal.modal_not_answer variant = "secondary") Not answer
      b-col(sm="12")
        label.col-form-label() {{ stringToLabel(question.config['text_lang_1']) }}
        b-table(:items="answered" :fields="fields")
          template(v-slot:cell(remove_button)="data")
            b-button(variant="danger" @click="removeRow(data.item)") Remove
          template(v-slot:cell()="data")
            div {{ getData(data.value) }}
      b-col(sm="12")
          b-button(v-if="!editing" @click="editing = true") Add
          template(v-if="editing" v-for="question in question.config.columns")
              questionOpenCol(v-if="question.type === 'open_answer'" :ref="question.internal_id" :question="question" :sendResult="auxData")
              questionRadioCol(v-if="question.type === 'single_answer'" :ref="question.internal_id" :question="question" :sendResult="auxData")
              questionCheckCol(v-if="question.type === 'multi_answer'" :ref="question.internal_id" :question="question" :sendResult="auxData")
              questionRangeCol(v-if="question.type === 'range_answer'" :ref="question.internal_id" :question="question" :sendResult="auxData")
              questionDropdownCol(v-if="question.type === 'drowdown_answer'" :ref="question.internal_id" :question="question" :sendResult="auxData")
          b-row(v-if="editing")
              b-col(sm="1")
                  b-button(@click="cancelLine") Cancel
              b-col(sm="1")
                  b-button(@click="addLine") Add
              b-col(sm="10" v-if="errorFound")
                  h3 {{ this.errorFound.text }}
</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import questionOpenCol from '../tableCombinationQuestions/questionOpenCol';
  import questionRadioCol from '../tableCombinationQuestions/questionRadioCol';
  import questionRangeCol from '../tableCombinationQuestions/questionRangeCol';
  import questionDropdownCol from '../tableCombinationQuestions/questionDropdownCol';
  import questionCheckCol from '../tableCombinationQuestions/questionCheckCol';

  import axios from 'axios';

  export default {

    components: {
      questionOpenCol,
      questionRadioCol,
      questionRangeCol,
      questionDropdownCol,
      questionCheckCol
    },
    name: "questionTableCombination",
    props: ['question', 'questionNumber','answer'],
    mixins: [utilMixin],
    mounted: function (){
      console.log("Loading Range question");
      if(this.answer !== undefined){
        this.answered = this.answer.answer;
      }
    },
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else return "Not defined";
      },
      cancelLine: function () {
        this.editing = false;
      },
      removeRow: function (data) {
        console.log(data);
        this.answered = this.answered.filter(function (item) {
          console.log(item);
          return item !== data;
        });
      },
      getData: function (data){
        let ret = "";

        console.log("Data content: ", data);
        if(Array.isArray(data)){
          console.log("Is Array");
          data.forEach(function (item) {
            console.log(item);
            ret += item.text+", ";
          });
          ret = ret.substring(0,ret.length-3);
          return ret;
        }
        else {
          return data.text;
        }
      },
      addLine: function () {
        let _this = this;
        let _Vue = this.Vue;
        let error = false;
        let object = {};

        console.log(_this.$refs);
        this.question.config.columns.forEach(function (col) {

          console.log(_this.$refs[col.internal_id][0]);
          console.log(_this.$refs[col.internal_id][0].getDataResult());
          if(_this.$refs[col.internal_id][0].question.type === 'multi_answer'){
            if(!_this.$refs[col.internal_id][0].getDataResult() || _this.$refs[col.internal_id][0].getDataResult().text === []){
              //_Vue.set(_this.error,'text','Please answer all questions');
              _this.errorFound.text = 'Please answer all questions';
              error = true;
            }
          }
          else{
            if(!_this.$refs[col.internal_id][0].getDataResult() || _this.$refs[col.internal_id][0].getDataResult().text === null){
              //_Vue.set(_this.error,'text','Please answer all questions');
              _this.errorFound.text = 'Please answer all questions';
              error = true;
            }
          }

          object[col.internal_id] = _this.$refs[col.internal_id][0].getDataResult();
          if(_this.$refs[col.internal_id][0].question.type === 'multi_answer') _this.$refs[col.internal_id][0].dataResult = [];
          else _this.$refs[col.internal_id][0].dataResult = null;
          //answers.push(object);
        });

        //Save data
        if(error) console.log(this.errorFound);
        else {
          this.errorFound = {};
          this.answered.push(object);
        }
        this.auxData = {};
        //this.editing = false;
      },
      saveToProfile: function () {
        console.log("Save Question: ", this.question.id);
        let _this = this;
        if(this.answered === [] || this.answered.length === 0){
          console.log("Please respond something");
        }
        else {
          this.$emit('sendResponse', {
            id_question: _this.question.id,
            id_category: _this.question.category_id,
            answer: this.answered});
          axios.post('answers/setClientProfileAnswer', {
            question_id: _this.question.id,
            category_id: _this.question.category_id,
            answer: _this.answered,
          })
            .then(function (response) {
              console.log(response);
              console.log("Done Modify question");
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },
    data: function () {
      return{
        answered: [],
        editing: false,
        auxData: {},
        errorFound: {}
      }
    },
    computed: {
      fields: function () {
        let ret = [];
        // {
        //   key: 'age',
        //   label: 'Person age'
        // }
        this.question.config.columns.forEach( function (col) {
          ret.push({
            key: col.internal_id,
            label: col.title_1
          })
        });
        ret.push({key: 'remove_button', label: ''});
        return ret;
      }
    }

  }
</script>

<style scoped>

</style>