<template >
  <svg :width="width"
       :height="height"
       viewBox="0 0 50 50"
       :aria-labelledby="iconName"
       role="img">
    <title :id="iconName" lang="en">{{iconName}} icon</title>
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconBase",
    props: {
      iconName: {
        type: String,
        default: 'box'
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }
  }
</script>

<style scoped>
  svg {
    display: inline-block;
    vertical-align: baseline;
    margin-left: -4px;
    stroke: black;
    stroke-width: 0.3;
  }
</style>