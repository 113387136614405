<template>
  <path vector-effect="non-scaling-stroke"
        d="M 26.70,42.80
           C 26.70,42.80 23.50,42.80 23.50,42.80
             22.40,42.80 21.60,42.00 21.50,41.00
             21.50,41.00 21.00,38.50 21.00,38.50
             20.00,38.20 19.20,37.90 18.40,37.40
             18.40,37.40 16.20,38.90 16.20,38.90
             15.40,39.50 14.20,39.40 13.50,38.60
             13.50,38.60 11.40,36.50 11.40,36.50
             10.60,35.80 10.40,34.70 11.10,33.80
             11.10,33.80 12.60,31.70 12.60,31.70
             12.20,30.90 11.80,30.10 11.50,29.10
             11.50,29.10 8.80,28.60 8.80,28.60
             7.80,28.40 7.10,27.60 7.10,26.60
             7.10,26.60 7.10,23.40 7.10,23.40
             7.10,22.30 7.90,21.50 8.90,21.40
             8.90,21.40 11.50,21.00 11.50,21.00
             11.80,20.00 12.10,19.20 12.60,18.40
             12.60,18.40 11.00,16.20 11.00,16.20
             10.40,15.40 10.50,14.20 11.30,13.50
             11.30,13.50 13.50,11.30 13.50,11.30
             14.30,10.50 15.50,10.50 16.20,11.10
             16.20,11.10 18.30,12.60 18.30,12.60
             19.20,12.10 20.10,11.70 21.00,11.50
             21.00,11.50 21.50,8.90 21.50,8.90
             21.70,7.90 22.50,7.20 23.50,7.20
             23.50,7.20 26.70,7.20 26.70,7.20
             27.80,7.20 28.60,8.00 28.70,9.00
             28.70,9.00 29.20,11.60 29.20,11.60
             30.10,11.90 30.90,12.20 31.80,12.70
             31.80,12.70 33.90,11.20 33.90,11.20
             34.80,10.60 35.90,10.70 36.60,11.40
             36.60,11.40 38.80,13.60 38.80,13.60
             39.60,14.40 39.60,15.60 39.00,16.30
             39.00,16.30 37.50,18.40 37.50,18.40
             38.00,19.30 38.30,20.10 38.60,21.00
             38.60,21.00 41.20,21.50 41.20,21.50
             42.20,21.70 42.90,22.50 42.90,23.50
             42.90,23.50 42.90,26.70 42.90,26.70
             42.90,27.80 42.10,28.60 41.10,28.70
             41.10,28.70 38.50,29.20 38.50,29.20
             38.20,30.10 37.90,30.90 37.40,31.80
             37.40,31.80 38.90,33.90 38.90,33.90
             39.50,34.70 39.40,35.90 38.60,36.60
             38.60,36.60 36.40,38.80 36.40,38.80
             35.60,39.60 34.40,39.60 33.70,39.00
             33.70,39.00 31.60,37.50 31.60,37.50
             30.80,38.00 29.90,38.30 29.10,38.60
             29.10,38.60 28.60,41.30 28.60,41.30
             28.60,42.10 27.70,42.80 26.70,42.80 Z
           M 18.40,35.30
           C 18.40,35.30 18.90,35.60 18.90,35.60
             19.70,36.10 20.80,36.60 22.00,36.90
             22.00,36.90 22.50,37.00 22.50,37.00
             22.50,37.00 23.20,40.70 23.20,40.70
             23.20,40.90 23.30,41.00 23.50,41.00
             23.50,41.00 26.70,41.00 26.70,41.00
             26.80,41.00 27.00,41.00 27.00,40.70
             27.00,40.70 27.60,37.00 27.60,37.00
             27.60,37.00 28.10,36.90 28.10,36.90
             29.10,36.60 30.20,36.20 31.20,35.60
             31.20,35.60 31.70,35.30 31.70,35.30
             31.70,35.30 34.80,37.50 34.80,37.50
             34.90,37.60 35.10,37.60 35.20,37.50
             35.20,37.50 37.40,35.30 37.40,35.30
             37.50,35.20 37.50,35.00 37.40,34.90
             37.40,34.90 35.20,31.80 35.20,31.80
             35.20,31.80 35.50,31.30 35.50,31.30
             36.10,30.30 36.50,29.20 36.80,28.10
             36.80,28.10 36.90,27.60 36.90,27.60
             36.90,27.60 40.60,26.90 40.60,26.90
             40.80,26.90 40.90,26.80 40.90,26.60
             40.90,26.60 40.90,23.40 40.90,23.40
             40.90,23.30 40.90,23.10 40.60,23.10
             40.60,23.10 36.90,22.50 36.90,22.50
             36.90,22.50 37.00,21.90 37.00,21.90
             36.70,20.90 36.30,19.90 35.70,18.70
             35.70,18.70 35.40,18.20 35.40,18.20
             35.40,18.20 37.60,15.20 37.60,15.20
             37.70,15.10 37.70,14.90 37.60,14.80
             37.60,14.80 35.40,12.60 35.40,12.60
             35.20,12.40 35.00,12.50 34.90,12.60
             34.90,12.60 31.90,14.70 31.90,14.70
             31.90,14.70 31.40,14.40 31.40,14.40
             30.40,13.80 29.30,13.40 28.20,13.10
             28.20,13.10 27.70,13.00 27.70,13.00
             27.70,13.00 27.00,9.20 27.00,9.20
             27.00,9.00 26.90,8.90 26.70,8.90
             26.70,8.90 23.50,8.90 23.50,8.90
             23.40,8.90 23.20,8.90 23.20,9.20
             23.20,9.20 22.60,12.90 22.60,12.90
             22.60,12.90 22.00,13.00 22.00,13.00
             20.90,13.30 19.80,13.80 18.70,14.40
             18.70,14.40 18.20,14.70 18.20,14.70
             18.20,14.70 15.10,12.50 15.10,12.50
             15.00,12.40 14.80,12.40 14.70,12.50
             14.70,12.50 12.50,14.70 12.50,14.70
             12.40,14.80 12.40,15.00 12.50,15.10
             12.50,15.10 14.70,18.30 14.70,18.30
             14.70,18.30 14.40,18.80 14.40,18.80
             13.90,19.60 13.40,20.70 13.10,21.90
             13.10,21.90 13.00,22.50 13.00,22.50
             13.00,22.50 9.30,23.10 9.30,23.10
             9.20,23.10 9.10,23.20 9.10,23.40
             9.10,23.40 9.10,26.60 9.10,26.60
             9.10,26.70 9.10,26.90 9.40,26.90
             9.40,26.90 13.10,27.50 13.10,27.50
             13.10,27.50 13.20,28.00 13.20,28.00
             13.50,29.30 14.00,30.30 14.50,31.10
             14.50,31.10 14.80,31.60 14.80,31.60
             14.80,31.60 12.60,34.70 12.60,34.70
             12.50,34.80 12.50,35.00 12.60,35.00
             12.60,35.00 12.70,35.10 12.70,35.10
             12.70,35.10 14.90,37.30 14.90,37.30
             15.00,37.40 15.20,37.40 15.30,37.30
             15.30,37.30 18.40,35.30 18.40,35.30 Z
           M 25.10,33.20
           C 23.00,33.20 20.90,32.40 19.20,30.80
             17.60,29.20 16.80,27.20 16.80,24.90
             16.80,22.70 17.70,20.60 19.20,19.00
             20.80,17.40 22.80,16.60 25.10,16.60
             27.30,16.60 29.40,17.50 31.00,19.00
             32.60,20.60 33.40,22.60 33.40,24.90
             33.40,27.10 32.50,29.20 31.00,30.80
             29.30,32.40 27.20,33.20 25.10,33.20 Z
           M 25.10,18.40
           C 23.40,18.40 21.70,19.10 20.50,20.30
             19.30,21.50 18.60,23.20 18.60,24.90
             18.60,26.60 19.30,28.30 20.50,29.50
             23.00,32.10 27.20,32.10 29.70,29.50
             30.90,28.30 31.60,26.60 31.60,24.90
             31.60,23.20 30.90,21.50 29.70,20.30
             28.40,19.00 26.80,18.40 25.10,18.40 Z" />
</template>

<script>
  export default {
    name: "ToolsViewsIcon"
  }
</script>

<style scoped>

</style>