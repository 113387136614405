import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { languages } from './index'
import { defaultLocale } from './index'
const messages = Object.assign(languages)

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages
})




