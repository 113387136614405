<template lang="pug">
  b-container
    b-row
      b-col
        b-row.bg-success.p-2
          b-col.align-self-center
            h5.mb-0 {{ questionNumber+1 }}. Question:
          b-col(align-self="end")
            .text-right
              b-button(variant = "primary" @click="saveToProfile").mr-1 Guardar
              b-button(v-b-modal.modal_not_answer variant = "secondary") Not answer
    b-row.p-1
      b-col(sm="12").mb-1
        b-form-group( label-size="lg" :label="selectedLabel")
        template(v-for='answer in question.config.answers')
          b-form-radio(v-model="answered" :name="'radio_form_'+question.id" :id='answer.internal_id' :value="{isCustom: false, answer: answer, answer_custom_text: ''}") {{ answer.text_1 }}
        b-form-radio(v-if="question.config.customAnswer === 'true'" v-model="answered.isCustom" :name="'radio_form_'+question.id" :id="'radio_form_'+question.id" :value="true") {{  $t('ecsForm.skip.other') }}
        b-form-input(v-if="question.config.customAnswer === 'true'" :disabled="customEnabled" v-model="answered.answer_custom_text" type="text").my-1
      .col-sm-12.mb-1(v-if="stringToLabel(question.config.text_example_lang_1) !== ''")
        p
          button.btn.btn-primary(type='button' data-toggle='collapse' data-target='#collapseExample' aria-expanded='false' aria-controls='collapseExample')
            | Example
        #collapseExample.collapse
          .card.card-body(v-html="stringToLabel(question.config['text_example_lang_1'])")


</template>

<script>
  import axios from 'axios';
  import utilMixin from "../../mixin/utilsMixin";

  export default {
    name: "questionSingle",
    data: function(){
      return{
        selected: "{}",
        selected_custom: "",
        answered: {
          isCustom: false,
          answer: "",
          answer_custom_text: ""
        }
      }
    },
    mounted: function(){
      if(this.answer !== undefined){
        this.answered = this.answer.answer;
      }
    },
    computed: {
      selectedLabel: function () {
        if(this.selected.answer) {
          if(this.selected.answer.isCustom){
            return this.stringToLabel(this.question.config['text_lang_1'])+': '+"Other, "+this.selected_custom;
          }
          else return this.stringToLabel(this.question.config['text_lang_1'])+': '+ this.selected.answer.text_1;
        }
        else return this.stringToLabel(this.question.config['text_lang_1'])+': ';
      },
      customEnabled: function () {
        return !this.answered.isCustom;
      }
    },
    props: ['question', 'questionNumber','answer'],
    mixins: [utilMixin],
    methods:{
      changeAnswer: function (question) {
        if(this.answered.answer !== `${question.id}_custom`){
          // this.answered.answer_custom_text = "";
          this.$set(this.answered, 'answer_custom_text', "")
        }
      },
      saveToProfile: function () {
        console.log("Save Question: ", this.question.id);
        let _this = this;
        if(this.answered.answer === "" && this.answered.answer_custom_text === ""){
          console.log("Please respond something");
        }
        else {

          this.$emit('sendResponse', {
            id_question: _this.question.id,
            id_category: _this.question.category_id,
            answer: this.answered});
          axios.post('answers/setClientProfileAnswer', {
            question_id: _this.question.id,
            category_id: _this.question.category_id,
            answer: _this.answered,
          })
            .then(function (response) {
              console.log(response);
              console.log("Done Modify question");
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    }
  }
</script>

<style scoped>

</style>