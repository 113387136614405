<template lang="pug">
  .about_content.generalBackground
    b-container(fluid="true")
      b-tabs.homeTab(content-class="mt-3" fill active-nav-item-class="activeTabTitle" nav-class="tabTitle" )
        b-tab.tabText(:title="$t('abouts.content_solutions.title')" active style="border-color: var(--green-primary) var(--green-primary) #fff;" id="tab1" )
          AboutContentSolutions
        b-tab.tabText(:title="$t('abouts.content_plan.title')" id="tab2")
          AboutContentPlan
        b-tab.tabText(:title="$t('abouts.content_projects.title')" id="tab3")
          AboutContentProjects

</template>

<script>
import AboutContentSolutions from "@/components/about/content_solutions.vue";
import AboutContentPlan from "@/components/about/content_plan.vue";
import AboutContentProjects from "@/components/about/content_projects.vue";

export default {
name: "toolbox",
components: {
  AboutContentSolutions,
  AboutContentPlan,
  AboutContentProjects
},

}
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }
</style>
