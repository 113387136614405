<template lang="pug">
  b-container(fluid="true")
    b-container(fluid="true")
      b-container.m-4(fluid="true")
        questionFooter(ref="footer" :questionNumber="questionNumber" :nQuestions="nQuestions" :dataAnswer="answered" @saveData="saveToProfileECS" :ecs_id="ecs_id")
        questionHeader(ref="header" :questionNumber="questionNumber" :nQuestions="nQuestions")
        b-row
          b-col(sm="12")
              h6
                label.col-form-label(v-html="stringToLabel(question.config['text_lang_' + getLang])")

          b-input-group
            b-form-input.mb-1.form-rounded(@change="changeEvent" :class="'form-control'" v-model="answered.address" v-on:keyup.enter="searchLocation()")
            b-input-group-append
              b-button(@click="searchLocation").form-rounded.mb-1 Search
          div#map.mb-4


            //script(src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD6YD1VaM16bbuXFXOu2xd2Ik_SiBRTGDs&libraries=places&callback=initMap" async defer)


</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import gmapsInit from "../../../utils/gmaps.js"
  import axios from 'axios';
  import questionHeader from "../questionHeader";
  import questionFooter from "../questionFooter";

  export default {
    name: "questionLocation",
    components: {questionHeader, questionFooter},
    props: ['question', 'questionNumber','answer','ecs_id', 'exp_id', 'nQuestions'],
    mixins: [utilMixin],
    data: function(){
      return{
         address: "",
         map: null,
         infowindow: null,
         valid: false,
         marker: null,
         answered: {
           location: "",
           address: "",
           components: ""
         },
         skip: {
          skipped: false,
          custom: false,
          answer: ""
        },
        firstAnswered: false,
        firstSkipped: false,
        langId: 1
      }
    },
    methods: {
      changeEvent: function () {
        this.$refs.footer.onChangeEventHandler();
      },
      // doubleClick: function() {
      //   google.maps.event.addListener(map, 'dblclick', function(e) {
      //     var positionDoubleclick = e.latLng;
      //     _this.createMarker(positionDoubleclick);
      //     // if you don't do this, the map will zoom in
      //     e.stopPropagation();
      //   })
      // },
      searchLocation: async function (from) {
        if (this.answered.address < 0) return;

        const google = await gmapsInit();
        let _this = this;

        if (this.map == null) {
          this.map = new google.maps.Map(this.$el.querySelector('#map'), {
            center: {lat: 45, lng: 20},
            zoom: 4,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: false,
            fullscreenControl: false
          });
        }

        if (from == "mounted" && this.answered.location){
          this.map.setCenter(this.answered.location);
          this.map.setZoom(15)
          this.createMarker(this.answered.location, "coord")
        } else if (this.answered.address) {
          var request = {
            query: this.answered.address,
            fields: ['name', 'geometry'],
          };

          const service = new google.maps.places.PlacesService(_this.map);

          service.findPlaceFromQuery(request, async function (results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              // console.log("test: ", results)
              // for (var i = 0; i < results.length; i++) {
              //   await _this.createMarker(results[i]);
              //   _this.answered.location = results[i].geometry.location;
              //   console.log(results[i]);
              // }
              console.log("Results: "+results[0].geometry.location);
              _this.answered.location = results[0].geometry.location
              _this.map.setCenter(results[0].geometry.location);
              _this.map.setZoom(15)
              _this.createMarker(results[0].geometry.location, "coord")
            _this.saveToProfileECS();
            }
          });
        }

        google.maps.event.addListener(_this.map, 'dblclick', function(e) {
          var positionDoubleclick = e.latLng;
          e.stop();
          event.cancelBubble = true;
          _this.createMarker(positionDoubleclick, "coord");
          // if you don't do this, the map will zoom in

        })
      },
      createMarker: async function (place, coord) {

        const _this = this;
        const google = await gmapsInit();

        if (_this.marker)
          _this.marker.setMap(null)

        _this.valid = true;
        if (coord){
          var position = place
        } else {
          var position = place.geometry.location
        }

        _this.marker = new google.maps.Marker({
          map: _this.map,
          position: position,
          draggable: true,
          title: "Move me to the exact location"
        });

        google.maps.event.addListener(_this.marker, 'dragend', function (evt) {
          console.log('New coords', evt.latLng.lat(), evt.latLng.lng())
          _this.answered.location.lat = evt.latLng.lat();
          _this.answered.location.lng = evt.latLng.lng();
          _this.saveToProfileECS();
        });
      },
      saveToProfileECS: async function (text) {
        // save marker location and address components

        if(this.marker)
        {
          this.answered.location = this.marker.position;
          const geocoder = new google.maps.Geocoder();
          await geocoder.geocode({
            location: this.marker.position,
            language: 'English'
          }, (results, status) => {
            if (status === "OK") {
              console.log("Address components", results[0].address_components)
              this.answered.components = results[0].address_components
              // results[0].address_components.forEach(function(comp){
              //   if (comp.types[0] == 'locality' || comp.types[0] == 'country')
              //     console.log(comp.short_name)
              // })
            } else {
              console.log("Geocoder failed due to: " + status);
            }
          });
        }



        console.log("Save Question: ", this.question.id);
        if (this.$refs.footer.skipQuestion()) {
          this.skip.skipped = this.$refs.footer.skip.skipped;
          this.skip.custom = this.$refs.footer.customAnswer();
          this.skip.answer = this.$refs.footer.customText();
        }

        let _this = this;

        if(!_this.hasBeenAnsweredOrSkipped(_this) && text==="next"){
          this.$emit('sendResponse', undefined, text);
        }else if(!_this.hasBeenAnsweredOrSkipped(_this) && text==="previous"){
          this.$emit('sendResponse', undefined, text);
        } else {
          if(_this.firstAnswered && _this.hasBeenSkipped(_this)){
            _this.resetAnswer();
            _this.save(text)
          }else if(_this.firstSkipped && _this.hasBeenAnswered(_this)){
            _this.resetSkip()
            _this.save(text);
          }else if(_this.hasBeenAnswered(_this) && _this.hasBeenSkipped(_this)){
            this.$emit('sendResponse', undefined, text);
          }else{
            _this.save(text);
          }
        }
      },
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          text = this.question.config['text_lang_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },

      save(text){
        let _this = this;
        this.$emit('sendResponse', {
          id_question: _this.question.id,
          id_category: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip}, text);

        if (this.exp_id) {
          var ecs_exp_id = this.exp_id
        } else {
          var ecs_exp_id = this.ecs_id
        }

        axios.post(this.$parent.url_answer, {
          question_id: _this.question.id,
          ecs_id: ecs_exp_id,
          category_id: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip
        })
          .then(function (response) {
            console.log(response);
            console.log("Done Modify question");

          })
          .catch(function (error) {
            console.log(error);
          });
        if(_this.questionNumber+1 === _this.nQuestions && text == 'next'){
          _this.$refs.footer.showSubmitModal();
        }
      },
      hasBeenAnswered: function (answer) {

        if(answer) return answer.answered.location !== "" || answer.answered.address !== "";
        return false;
      },
      hasBeenSkipped: function (answer) {
        if (answer && answer.skip) return answer.skip.skipped || answer.skip.custom;
        return false;
      },

      resetAnswer: function () {
        this.answered.location = "";
        this.answered.address = "";
      },

      resetSkip: function () {
        this.$refs.footer.resetModal();
        this.skip = {
          skipped: "",
          custom: false,
          answer: ""
        }
      },

      hasBeenAnsweredOrSkipped: function (answer) {
        return this.hasBeenSkipped(answer) || this.hasBeenAnswered(answer);
      },

      firstAnsweredOrSkipped: function (answer) {
        this.firstAnswered = this.hasBeenAnswered(answer);
        this.firstSkipped = this.hasBeenSkipped(answer);
      }
    },
    mounted: async function () {

      if(this.answer){
        this.answered = this.answer.answer;
        if(this.answer.skip) {
          this.$refs.footer.skip = this.answer.skip;
          this.skip = {
            skipped: this.$refs.footer.skip.skipped,
            custom: this.$refs.footer.customAnswer(),
            answer: this.$refs.footer.customText()
          }
        }
        this.searchLocation("mounted");
      }
      else{
        try {
          /*console.log("Loading location question.")
          if(this.answer !== undefined){
            this.answered = this.answer.answer;
          }
          console.log("Ref Object",this.$el.querySelector('#map'));
          const _this = this;
          const google = await gmapsInit();
          const sydney = new google.maps.LatLng(-33.867, 151.195);
          const geocoder = new google.maps.Geocoder();

          _this.map = new google.maps.Map(this.$el.querySelector('#map'), {
            center: sydney,
            zoom: 15,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          });

          _this.map.setCenter(sydney);*/
          this.searchLocation();
          //_this.map = new google.maps.Map(this.$el.querySelector('#map'));
        } catch (error) {
          console.error(error);
        }
      }
      this.firstAnsweredOrSkipped(this);

    },
    watch:{
      questionNumber: function(newVal,oldVal) {

        if(typeof(this.answer) === 'undefined'){
          this.resetAnswer();
          this.resetSkip();
        }else{
          this.answered = this.answer.answer;
          if(this.hasBeenSkipped(this.answer)) {
            this.$refs.footer.skip = this.answer.skip;
            this.skip= {
              skipped: this.$refs.footer.skip.skipped,
              custom: this.$refs.footer.customAnswer(),
              answer: this.$refs.footer.customText()
            }
          }else{
            this.resetSkip();
          }
          if(typeof this.answered === "string"){
            this.address = this.answered;
            this.answered = {
              location: "",
              address: this.address
            };
          }
        }
        this.firstAnsweredOrSkipped(this)
      }
    },
    computed: {
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      }
    }
  }
</script>

<style scoped>
    #map{
        height: 40rem;
        width: 100%;
        margin-top: 10px;
    }
    .questions{
      background-color: var(--light-gray-secondary);
    }
    .form-rounded {
      border-radius: 1rem;
    }
</style>
