<template lang="pug">
    b-container.category-tree
        b-row
            b-col(cols="12")
                b-alert(hide dismissible fade variant="danger")#loginAlert Your session expired. Please login again.
        b-row
            b-col(cols="6")
                .input-group.mb-1
                    input#search.form-control(type='text', placeholder='Search node ..', v-model="searchCategoryText", @change="searchInTree")
                #jstree_div
                button.btn.btn-primary(style='margin-top: 5px;' @click="postCallUpdateCategories") Save tree
            b-col(cols="6")
                b-row
                    b-col(cols="12").align-middle
                        h5.float-left Category titles
                        p.float-right.text-sm-right id: {{ categoria_actual.id }}
                form( v-if = "categoria_actual.title_default !== 'No category selected' && categoria_actual.title_default !== 'Root'")
                    .row(v-for="lang in langs")
                        label.col-sm-2.col-form-label {{ lang.lang_text }}
                        .col-sm-10
                            input.inputLanguage(
                            :id="'lang_id_'+lang.id"
                            type='text'
                            :class="lang.id === 1 ? 'form-control-plaintext' : 'form-control'"
                            :readonly='lang.id === 1'
                            :value='getTextCategory(categoria_actual.id,lang.id)')
                    button.btn.btn-primary(style='margin-top: 15px;' type="button" @click="postSetCategorieName(categoria_actual.id)") Save category names
</template>

<script>
  import axios from 'axios';

  export default {
    name: "category-tree",
    data: function() {
      return {
        searchCategoryText: '',
        langs: {},
        categoria_actual: '',
        categories: [],
        changes: [],
      }
    },
    mounted: function(){
      let placesScript = document.createElement('script');
      placesScript.setAttribute('src', '/javascripts/jstree/jstree.min.js');
      document.head.appendChild(placesScript);

      console.log(this.$store.state.token);

      let dataTree = [];
      axios.post('/query/getcategories')
        .then(function (response) {
          console.log(response);
          console.log("Done");
          $('#element').toast('show');
          //TODO:
          // Eliminar de la llista tots els canvis correctament gestionats.
          // Mostrar error per els que no s'han realitzat.
          this.categories.forEach(function (category) {
            dataTree.push({ "id" : category.id, "parent" : category.parent_category, "text" : category.category_text, "position": category.position, "state":{"opened":true}});
          })

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    methods: {
      searchInTree: function () {
        console.log("Search");
        $('#jstree_div').jstree('search', this.searchCategoryText);
      },
      postCallUpdateCategories: function () {
        console.log("Call initialized");
        let sendChanges = this.changes;
        console.log(sendChanges);
        axios.post('applyChangesCategories', {
          sendChanges
        })
          .then(function (response) {
            console.log(response);
            console.log("Done");
            $('#element').toast('show')
            //TODO:
            // Eliminar de la llista tots els canvis correctament gestionats.
            // Mostrar error per els que no s'han realitzat.
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      initJSTree: function () {


        $(function () {
          $('#jstree_div').bind('loaded.jstree', function (e, data) {
            // invoked after jstree has loaded
            $('#jstree_div').on("select_node.jstree", function (e, data) {
              console.log(data);
              app.$data.categoria_actual = {
                title_default: data.node.text,
                id: data.node.id
              }
              app.getQuestions(data.node.id);

            });
            $('#jstree_div').on("move_node.jstree", function (e, data) {
              console.log(data)
              console.log("Node moved:");
              let dataSend = {
                operation: "move",
                node_id: data.node.id,
                node_parent_id_old: data.old_parent,
                node_parent_id_new: data.parent,
                position_old: data.old_position,
                position_new: data.position
              };
              app.$data.changes.push(dataSend);
            });
            $('#jstree_div').on("rename_node.jstree", function (e, data) {
              console.log("Tree change (rename):");
              let dataSend = {
                operation: "rename",
                node_id: data.node.id,
                node_name: data.text
              };

              if(app.$data.categoria_actual.id === data.node.id){
                app.$data.categoria_actual.title_default = data.text;
              }

              let ele = app.$data.categories_texts.find(function (c) {
                return c.id_category === data.node.id && c.id_lang === 1;
              });
              if(ele)ele.category_text = data.text;

              app.$data.changes.push(dataSend);
            });
            $('#jstree_div').on("create_node.jstree", function (e, data) {
              console.log("Tree change  (create):");
              let dataSend = {
                operation: "create",
                node_id: data.node.id,
                node_parent_id: data.parent,
                position: data.position
              };
              app.$data.changes.push(dataSend);
            });
            $('#jstree_div').on("delete_node.jstree", function (e, data) {
              console.log("Tree change  (delete):");
              let dataSend = {
                operation: "delete",
                node_id: data.node.id,
                node_parent_id: data.node.parent,
                position: data.node.original.position
              };
              console.log("Data send: ", dataSend);
              app.$data.changes.push(dataSend);
            });
          }).jstree({
            "core": {
              "animation": 0,
              "check_callback": function (operation, node) {
                //console.log(operation,node);
                if(node.id === 'root'){
                  if(operation === 'delete_node' ||
                    operation === 'rename_node' ||
                    operation === 'move_node' ||
                    operation === 'dnd_move' ||
                    operation === 'changed'
                  ) return false;
                }
                else {
                  if (operation === 'delete_node') {
                    let result = confirm('Are you sure you want to delete this node?');
                    console.log('Deletion confirmed?', result);
                    return result;
                  }
                }
                return true;
              },
              "themes": [{"stripes": true}, {"dir": "/javascripts/jstree/themes/"}],
              //"themes":{name: "proton", responsive: true},
              'data': dataTree
            },
            "types": {
              "#": {
                "max_children": 1,
                "max_depth": 4,
                "valid_children": ["root"]
              },
              "root": {
                "icon": "/static/3.3.8/assets/images/tree_icon.png",
                "valid_children": ["default"]
              },
              "default": {
                "valid_children": ["default", "file"]
              },
              "file": {
                "icon": "glyphicon glyphicon-file",
                "valid_children": []
              }
            },
            'contextmenu': {
              'items': function (node) {
                var items = $.jstree.defaults.contextmenu.items();
                items.ccp = false;

                return items;
              }
            },
            "search": {
              "case_insensitive": true,
              "show_only_matches": true
            },
            "plugins": [
              "contextmenu", "dnd", "search", "types", "wholerow", "changed"
            ]
          });
        });

      }
    }
  }
</script>

<style scoped>

</style>