<template>
  <path vector-effect="non-scaling-stroke"
        d="M 37.80,19.90
           C 40.70,19.90 43.10,17.10 43.10,13.60
             43.10,10.30 40.90,8.00 37.80,8.00
             34.70,8.00 32.50,10.30 32.50,13.60
             32.60,17.00 34.90,19.90 37.80,19.90 Z
           M 37.80,9.10
           C 40.20,9.10 41.80,10.90 41.80,13.50
             41.80,16.30 40.00,18.60 37.80,18.60
             35.60,18.60 33.80,16.30 33.80,13.50
             33.80,10.90 35.40,9.10 37.80,9.10 Z
           M 38.40,21.80
           C 38.40,21.80 37.30,21.80 37.30,21.80
             32.10,21.80 28.60,24.80 28.60,29.30
             28.60,29.60 28.90,29.90 29.20,29.90
             29.50,29.90 29.80,29.60 29.80,29.30
             29.80,24.70 33.80,23.10 37.30,23.10
             37.30,23.10 38.40,23.10 38.40,23.10
             41.90,23.10 45.90,24.70 45.90,29.30
             45.90,29.60 46.20,29.90 46.50,29.90
             46.80,29.90 47.10,29.60 47.10,29.30
             47.10,24.80 43.60,21.80 38.40,21.80 Z
           M 17.00,27.90
           C 21.40,27.90 25.00,23.60 25.00,18.20
             25.00,13.20 21.70,9.60 17.00,9.60
             12.30,9.60 9.00,13.10 9.00,18.20
             9.00,23.60 12.60,27.90 17.00,27.90 Z
           M 17.00,11.60
           C 20.70,11.60 23.10,14.30 23.10,18.30
             23.10,22.60 20.30,26.10 17.00,26.10
             13.70,26.10 10.90,22.60 10.90,18.30
             10.90,14.30 13.40,11.60 17.00,11.60 Z
           M 17.90,29.80
           C 17.90,29.80 16.20,29.80 16.20,29.80
             8.20,29.80 2.90,34.40 2.90,41.20
             2.90,41.70 3.30,42.10 3.80,42.10
             4.30,42.10 4.70,41.70 4.70,41.20
             4.70,34.20 10.80,31.70 16.10,31.70
             16.10,31.70 17.80,31.70 17.80,31.70
             23.10,31.70 29.20,34.20 29.20,41.20
             29.20,41.70 29.60,42.10 30.10,42.10
             30.60,42.10 31.00,41.70 31.00,41.20
             31.20,34.40 25.90,29.80 17.90,29.80 Z" />
</template>

<script>
  export default {
    name: "PartOfUsIcon"
  }
</script>

<style scoped>

</style>