<template lang="pug">
  .profileNew
    b-modal.modal-footer-sm(id="info_modal" title="Info"  ref="info_modal")
      p Welcome to the Edible Cities Network.
      p You are about to create a public PROFILE in the most comprehensive platform for urban food initiatives/organizations. The public PROFILE showcases diverse information about urban food initiatives/organizations and contains interactive features to promote an inclusive knowledge sharing and networking. The users of the digital platform can interact with your initiative/organization by:
      p Leaving comments and questions on your PROFILE.
      p Following your PROFILE to get updates on events and news.
      p Sharing their experiences with your initiative/organization.
      p User can learn and get inspired by your initiative/organization.
      p User can connect with your organization/initiative to learn from your expertise.
      p Remember, you can share as much information as you want in your PROFILE. There is no required information, but we recommend you to keep adding/updating your profile information to make the most out of the platform.
      p Enjoy your experience.
      p EdiCitNet Team
    b-container.p-0(fluid="true").banner
      b-row
        b-col(sm="3" md="2"  xl="1")
          b-row
            b-col(cols="12" v-for="(cluster,key) in clusters" :key="key").pr-0
              div.p-1
                div.pb-0.progress-bar.progress-bar-striped.text-white.bg-success( v-if="percentages[cluster.slug] > 0" :class="{'progress-bar-animated':percentages[cluster.slug] != 100}" :style="'width:'+percentages[cluster.slug]+'%'" :aria-valuenow="percentages[cluster.slug]" aria-valuemin="0" aria-valuemax="100") {{percentages[cluster.slug]}}%

                div.progress-bar.progress-bar-striped.progress-bar-animated.text-dark.bg-white( v-else :style="'width:100%'" :aria-valuenow="0" aria-valuemin="0" aria-valuemax="100") {{percentages[cluster.slug]}}%
              div.p-1
                div.cluster.text-center(:class="{'green-back-cluster':active_cluster != cluster,'red-back-cluster':active_cluster == cluster}" v-on:click="setActive(cluster)")
                  icon-base(icon-name="login" iconColor="white")
                    login-icon
                  p.text-white {{  cluster.name }}
                div(v-if="active_cluster == cluster && toggle_cluster" )
                  ul.questions-list.pt-2(v-if="active_cluster_questions.length > 0" :class="{'mb-1':active_cluster.slug == 'monitoring'}")
                    li(v-for="quest in active_cluster_questions" v-on:click="clickQuestion(quest.id)" :class="{'answered':questionAsnwered(quest.id),'active':quest.config.text_navbar_lang_1 == question.config.text_navbar_lang_1}") {{quest.config['text_navbar_lang_'+$store.getters.getLanguage.id] ? quest.config['text_navbar_lang_'+$store.getters.getLanguage.id] : quest.config['text_navbar_lang_1']}}
                  ul.monitered-list.mb-1(v-if="active_cluster.slug == 'monitoring'")
                    li(v-for="monitored in monitoring_questions" v-on:click="clickQuestion(monitored.id)" :class="{'active':monitored.id == question.id}")  {{monitored.config['text_navbar_lang_'+$store.getters.getLanguage.id] !== '' ? monitored.config['text_navbar_lang_'+$store.getters.getLanguage.id] : monitored.config['text_navbar_lang_1']}}
                  ul.questions-list(v-if="active_cluster.slug == 'monitoring'")
                    li(v-on:click="clickQuestion(182)" :class="{'answered':questionAsnwered(182),'active':questions.find(e=>e.id == 182).config.text_navbar_lang_1 == question.config.text_navbar_lang_1}") {{questions.find(e=> e.id == 182).config['text_navbar_lang_'+$store.getters.getLanguage.id] !== '' ? questions.find(e=> e.id == 182).config['text_navbar_lang_'+$store.getters.getLanguage.id] : questions.find(e=> e.id == 182).config['text_navbar_lang_1']}}
                    li(:class="{'active':show_report}" v-on:click="showReport")  {{$t('profile.showReport')}}


        b-col(sm="9" md="10"  xl="11")
          b-row
            b-col(cols="12").text-center.profile-banner
              b-button.go-profile.btn.buttonGreen.btn-secondary.btn-block(:href="'/showEcsProfile/'+ecs_id" ) {{ $t('buttons.checkprofile') }}
              b-button.go-popup.buttonGreen.btn-secondary.btn-block(v-on:click="showInfo" ) INFO
              div.greenBack.pt-4
                b-img(v-show="imageShown" v-if="imageURL && profile_img_question" :src="imageURL"  v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="profile_img_question.config['text_lang_'+$store.getters.getLanguage.id]" fluid v-on:click="changeProfileImage" :class="{'loading-img':loading_img}").profile-img.rounded-circle
                questionFileUpload(v-if="profile_img_question" ref="profile_component" :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+profile_img_question.id]" :questionNumber="step" :question="profile_img_question", :nQuestions="questions.length" ).mb-4.d-none


            b-col(cols="4").text-center.offset-4
              p.pl-3.pr-3.text-right.mt-5
                h2.m-4.ecs-name(style="" v-if="!editting_name" v-on:click="editName") {{ ecs_name }}
                questionName(v-else :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+profile_name_question.id]" :questionNumber="step" :question="profile_name_question", :nQuestions="questions.length").mb-4

            b-col(cols="12").mt-5
              b-container.m-4(fluid="true")
                div(v-if="show_report")
                  b-row
                    b-col(cols="12")
                      p This section is under cosntruction. However, bellow you can see some examples of how the monitoring data will be showcased

                    b-col(cols="6")
                      b-img(src="/images/reports/economics.png").img-fluid
                    b-col(cols="6")
                      b-img(src="/images/reports/landuse.png").img-fluid
                    b-col(cols="6")
                      b-img(src="/images/reports/members.png").img-fluid
                    b-col(cols="6")
                      b-img(src="/images/reports/ownership.png").img-fluid
                    b-col(cols="6").mt-5.offset-6
                      b-row.pb-4
                        b-col
                          DownloadData(:monitoring_id="ecs_id", :ecs_name="ecs_name")
                    //b-col(cols=12)
                    //  h5.boldTitle {{ $t('ecs_profile.description.title') }}
                    //b-col(cols="12")
                    //  p(v-html="description")
                div(v-else-if="question")
                  questionOpen(v-if="question.config.type === 'open_answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question", :nQuestions="questions.length").mb-4
                  questionFileUpload(v-else-if="question.config.type === 'file_upload'" :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question", :nQuestions="questions.length" ).mb-4
                  questionSingle(v-else-if="question.config.type === 'single_answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"      :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
                  questionMulti(v-else-if="question.config.type === 'multi_answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"      :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
                  //questionDropdown(v-else-if="question.config.type === 'dropdown-answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]").mb-4
                  questionLocation(v-else-if="question.config.type === 'location_answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
                  questionRange(v-else-if="question.config.type === 'range-answer'" :ecs_id="ecs_id"   @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
                  questionTableCombination(v-else-if="question.config.type === 'table-combination-answer'"  :ecs_id="ecs_id"   @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
                  questionScore(v-else-if="question.config.type === 'score_answer'"  :ecs_id="ecs_id"   @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
                  div(v-else) Question format error
</template>

<script>
import Multiselect from 'vue-multiselect';
import axios from 'axios';

import questionOpen from './questionTypes/profileECS/questionOpenProfileECS';
import questionName from './questionTypes/profileECS/questionName';
import questionRange from './questionTypes/profileECS/questionRangeProfileECS';
import questionLocation from './questionTypes/profileECS/questionLocationProfileECS';
import questionFileUpload from './questionTypes/profileECS/questionProfileImage';
import questionSingle from './questionTypes/profileECS/questionSingleProfileECS';
import questionScore from './questionTypes/profileECS/questionScoreProfileECS';
import questionMulti from './questionTypes/profileECS/questionMultiProfileECS';
import questionDropdown from './questionTypes/profileECS/questionDropdownProfileECS';
import questionTableCombination from './questionTypes/profileECS/questionTableCombinationProfileECS';
import IconBase from '@/components/IconBase.vue';
import LoginIcon from '@/components/icons/LoginIcon.vue';
import DownloadData from "@/components/downloadData";
export default {
  name: "ProfileEcs",
  components: {
    questionName,
    Multiselect,
    questionOpen,
    questionRange,
    questionLocation,
    questionMulti,
    questionDropdown,
    questionSingle,
    questionScore,
    questionFileUpload,
    questionTableCombination,
    IconBase,
    LoginIcon,
    DownloadData
  },
  data: function () {
    return {
      downloadFormat: 'xlsx',
      options: [
        //{value: 'csv', text: 'CSV'},
        {value: 'xlsx',text: this.$t('buttons.excelFile')},
        {value: 'json',text: 'JSON'}
      ],
      active_cluster: null,
      editting_name: false,
      show_report: false,
      questions: [],
      answers: {"answers": {
          "questions": {}
        }
      },
      progressValue: 0,
      progressMaxValue: 0,
      ecs_id: "",
      ecs_name: "",
      url_answer: '/answers/setECSProfileAnswer',
      question: null,
      step: 0,
      imageShown: true,
      imageURL: "",
      // ids of monitoring questions
      keyPoints: [62,75,182,51,146,52,78,86],
      percentages: [],
      profile_name_question: null,
      profile_img_question: null,
      loading_img: false,
      loading: false,
      toggle_cluster: true,
      monitoring_questions: []
    }
  },
  computed: {
	  clusters: function()
	  {
	    return [
			  {
				  name: this.$t('profile.basicInformation'),
				  slug: 'basic_information',
				  related: [152,147, 156, 54, 118, 124, 145, 62,65, 187],
			  },
			  {
				  name: this.$t('profile.contact'),
				  slug: 'contact_and_location',
				  related: [29, 30]
			  }
			  ,
			  {
				  name: this.$t('profile.products'),
				  slug: 'products_and_activities',
				  related: [51,75,134,189,52]
			  }
			  ,
			  {
				  name: this.$t('profile.network'),
				  slug: 'network_and_financial',
				  related: [137,69,95,66,68,67]
			  }
			  ,
			  {
				  name: this.$t('profile.sustainability'),
				  slug: 'sustainability',
				  related: [88,85,84,86,138,81,82,70,78,79]
			  }
			  ,
			  {
				  name: this.$t('profile.monitoring'),
				  slug: 'monitoring',
				  related: [188],
				  monitoring: []
			  }
		  ]
	},
    description: function(){

      if(this.answers.answers.questions['id_124'])
      {
        return this.answers.answers.questions['id_124'].answer;
      }
      return "";
    },
    createLabels: function(){
      let labels = [];
      for (let i=0;i<=38;i++){
        if (i == this.keyPoints[0]) labels.push(this.$t('progressBar.basicProfile'));
        else if (i == this.keyPoints[1]) labels.push(this.$t('progressBar.productsActivities'));
        else if (i == this.keyPoints[2]) labels.push(this.$t('progressBar.participationNetwork'));
        else if (i == this.keyPoints[3]) labels.push(this.$t('progressBar.financialInformation'));
        else if (i == this.keyPoints[4]) labels.push(this.$t('progressBar.sustainabilityAssessment'));
        else labels.push('')
      }

      return labels
    },
    active_cluster_questions: function()
    {
      if(this.active_cluster)
      {
        let _this = this;
        let related = [];

        this.active_cluster.related.forEach(function(e){
          let found = _this.questions.find(element => element.id == e);
          if(found)
          {
            related.push(found);
          }
          else
          {
            console.log('not found',e);
          }
        });

        return related;
      }
      return [];
    }

  },
  mounted: async function () {
    this.ecs_id = this.$route.query.id;
    let _this = this;


    let questions = this.questions;
    this.$root.$on('updateProfileImg', (data) => {
      _this.getNewProfileImg();
    });
    //console.log("Start petition");
    await axios.post('answers/getECSProfileImage', {"id_ecs": _this.ecs_id})
        .then(function (response) {
          if(response.data !== "") _this.imageURL = 'https://edicitnet.icra.cat/images/'+response.data+'?'+ new Date().getTime();
          else _this.imageURL = 'https://toolbox.edicitnet.com/images/addImage.png';
        })
        .catch(function (error) {
          console.log("Error getting answers");
          console.log(error);
        });

    let returnIsValid = await axios.post('questions/getECSProfileQuestions')
        .then(function (response) {
          if(response.status === 401){
            console.log("Unauthorized");
            alert("Unauthorized please re-login");
            return false;
          }
          else {
            //console.log("Done getting questions.");
            response.data.forEach(function (question) {
              //console.log("showQuestion")
              //console.log(question, question.config)
              question.config = JSON.parse(question.config);
              _this.progressMaxValue++;
              questions.push(question);


            });
            //Name 28
            //Profile img 94
            _this.profile_name_question = questions.find(element => element.id == 28);
            _this.profile_img_question = questions.find(element => element.id == 94);

            return true;
          }
        })
        .catch(function (error) {
          console.log("Error getting questions: ", error);
          return false;
        });


    let answers = this.answers;
    //console.log("Loading Answers Client");
    await axios.post('answers/getECSProfileAnswers',
        {"id_ecs": _this.ecs_id, "client_id": _this.$store.getters.getUser.id}
    )
        .then(function (response) {
          if(!response.data){
            _this.step = 0;

          } else {
            response.data.forEach(function (answer) {
              _this.ecs_name = answer.ecs_name;

              _this.answers.answers = JSON.parse(answer.answers);
              console.log(_this.answers);
              _this.step = Object.keys(_this.answers.answers.questions).length;
            });
            _this.checkMonitoringQuestions();
          }




          _this.setPercentages();

          if(!Object.keys(_this.percentages).find(key => _this.percentages[key] > 0))
          {
            //SHOW POPUP
            _this.showInfo();
          }

          //First question
          _this.checkAutocompleteQuestions();

          let id_initial = 152

          if (_this.$route.query.step && questions.find(e=> e.id === Number(_this.$route.query.step))){
            id_initial = Number(_this.$route.query.step)
          }


          _this.question=questions.find(element => element.id === id_initial);
          _this.step = Object.keys(_this.answers.answers.questions).indexOf(element => element.id == 152);
          let question_id = _this.question.id;
          let find_active_cluster = _this.clusters.find(element => element.related.indexOf(question_id) >=0);

          if(find_active_cluster)
          {
            _this.active_cluster = find_active_cluster;
          }

        })
        .catch(function (error) {
          console.log("Error getting answers");
          console.log(error);
        });

  },
  methods: {
    checkMonitoringQuestions: function()
    {

      let monitoring_question = this.questions.find(element => element.id == 188);
      let answered_monitoring = this.answers.answers.questions['id_188'];
      let monitoring = [];
      //console.log('monitoring_questions',answered_monitoring);
      let answers  = [];
      if(answered_monitoring)
      {
        //console.log('answered_monitoring',answered_monitoring.answer.answer);
        answers = answered_monitoring.answer.answer;
      }
      //console.log('monitoring_question',monitoring_question);
      //console.log('monitoring_question',this.answers.answers);

      answers.forEach(function(e){
        //console.log('e monitoring ',e);
        switch(e.custom_ids)
        {
          case "landuse":
            monitoring = monitoring.concat([62,75,134]);
            break;
          case "ownership":
            monitoring = monitoring.concat([62]);
            break;
          case "networking":
            monitoring = monitoring.concat([137]);
            break;
          case "members":
            monitoring = monitoring.concat([18,65,52]);
            break;
          case "products":
            monitoring = monitoring.concat([51,75]);
            break;
          case "economics":
            monitoring = monitoring.concat([65,66,67,62,68,22]);
            break;
          case "sustainability":
            monitoring = monitoring.concat([88,85,84,86,138,81,82,70,78,79,62,75,134]);
            break;
        }
      });
      monitoring = monitoring.filter(function(item, pos) {
        return monitoring.indexOf(item) == pos;
      })

      let related = [];
      let _this = this;
      monitoring.forEach(function(e){
        //console.log('monitoring e',e);
        let found = _this.questions.find(element => element.id == e);
        if(found)
        {
          related.push(found);
        }
        else
        {
          console.log('not found',e);
        }
      });

      //console.log('monitored questions related',related);
      related.sort(function(a, b){
        let index_a = _this.clusters.findIndex(element => element.related.indexOf(a.id) >= 0);
        let index_b = _this.clusters.findIndex(element => element.related.indexOf(b.id) >= 0);
        if(index_a === index_b)
        {
          index_a = _this.clusters[index_a].related.indexOf(a.id);
          index_b = _this.clusters[index_b].related.indexOf(b.id);
        }
        return index_a-index_b;
      });

      this.monitoring_questions = related;
    },
    checkAutocompleteQuestions: function()
    {
      let _this = this;
      try {
        if(typeof _this.answers.answers.questions['id_189'] !== 'undefined' && _this.answers.answers.questions['id_189'] )
        {
          let answer_189 = _this.answers.answers.questions['id_189'].answer.forEach(function(e){
            let code = e['189_1652422532173'].code;
            //Now check if the answer is in 52
            let find_answer = false;

            if(typeof _this.answers.answers.questions['id_52'] !== 'undefined' && _this.answers.answers.questions['id_52'])
            {
	            find_answer = _this.answers.answers.questions['id_52'].answer.find(elem =>
		            elem['189_1652422479138'] && elem['189_1652422479138'].id_custom_answer === code
	            )
            }
            if(!find_answer)
            {
              //Add first column answer to 52
              let answer_52 = {};
              let question = _this.questions.find(element => element.id === 52);
              let question_52 = _this.questions.find(element => element.id === 52).config.columns[0].answers.find(
                  elem => elem.custom_ids === code
              );
              if(question_52)
              {
                answer_52['189_1652422479138'] = {
                  id: question_52.internal_id,
                  text: question_52.text_1,
                  id_custom: "activity",
                  id_custom_answer: code
                };
                answer_52['52_1653918097568'] = null;
                answer_52['52_1653918165792'] = [];
                answer_52['52_1653918480917'] = [];
                answer_52['52_1653918575536'] = null;
                answer_52['189_1652422532173'] = [];
                if(!_this.answers.answers.questions['id_52'])
                {
                  _this.answers.answers.questions['id_52'] = {};
                  _this.answers.answers.questions['id_52'].answer = [];
                  _this.answers.answers.questions['id_52'].id_question = 52;
                  _this.answers.answers.questions['id_52'].idquestion_category = "j1_2";
                  _this.answers.answers.questions['id_52'].id_client = _this.answers.answers.questions['id_189'].id_client;
                  _this.answers.answers.questions['id_52'].skip = {
                    "skipped": "",
                    "custom": false,
                    "answer": ""
                  };
                }
                _this.answers.answers.questions['id_52'].answer.push(answer_52);
              }

            }
          });
        }
      }
      catch(error)
      {
        console.log('error',error);
      }
    },
    downloadData: function()
    {
      console.log('download data');
    },
    showReport: function()
    {
      this.show_report = true;
    },
    showInfo: function()
    {
      this.$refs.info_modal.show()
    },
    editName: function()
    {
      this.editting_name = !this.editting_name;
    },
    changeProfileImage: function()
    {
      this.$refs.profile_component.$refs.profile_img.$refs.input.click();
    },
    questionAsnwered: function(o)
    {
      let answered = typeof this.answers.answers.questions['id_'+o] !== "undefined"
          && typeof this.answers.answers.questions['id_'+o].answer !== "undefined"
          && this.answers.answers.questions['id_'+o].answer !== null
          && (typeof this.answers.answers.questions['id_'+o].answer !== "object"
              || typeof this.answers.answers.questions['id_'+o].answer.length === "undefined"
              || this.answers.answers.questions['id_'+o].answer.length > 0);

      return answered;
    },
    setPercentages:function()
    {
      let percentages = {};
      let _this = this;
      //console.log('answers',this.answers);
      this.clusters.forEach(function(e){
        let total = e.related.length;
        let answered = 0;
        //console.log('cluster',e);
        e.related.forEach(function(o){
          if(_this.questionAsnwered(o))
          {
            answered++;
          }
          else
          {
            //console.log('not answered',o);
          }
        });
        let percentage = 0;
        if(total > 0)
        {
          percentage = answered * 100 / total;
        }
        percentage = Math.round(percentage);
        percentages[e.slug] = percentage;
      });
      this.percentages = percentages;

      /*console.log(monitoring_question);
      console.log('answered_monitoring',answered_monitoring);
      console.log('this.answers.answers',this.answers.answers);*/
    },
    setActive:function(cluster)
    {
      if(this.active_cluster !== cluster)
      {
        this.active_cluster = cluster;
        this.toggle_cluster = true;
        this.clickQuestion(cluster.related[0])
      }
      else
      {
        this.toggle_cluster = !this.toggle_cluster;
      }
    },
    recieveResponse: function (answer, text) {
      let id = 0;
      if(answer !== undefined){
        this.answers.answers.questions["id_"+answer.id_question] = answer;
        this.checkAutocompleteQuestions();
        if(answer.id_question === 188)
        {
          this.checkMonitoringQuestions();
        }
      }
      this.setPercentages();

      console.log('recieveResponse',answer,text);
      let find = this.active_cluster.related.indexOf(this.question.id);
      if(typeof answer !== 'undefined' && typeof answer.id_question !== 'undefined' && answer.id_question === 28)
      {
        this.profile_name_question = this.questions.find(element => element.id == 28);
        this.ecs_name = answer.answer;
      }
      else
      {
        if(text === "next" && this.step<this.questions.length)
        {
          if(find < (this.active_cluster.related.length-1))
          {
            id = this.active_cluster.related[find+1];
          }
          else
          {
            let current_index = this.clusters.indexOf(this.active_cluster);
            if(current_index  < (this.clusters.length-1))
            {
              this.active_cluster = this.clusters[current_index+1];
              if(this.active_cluster.related.length > 0)
              {
                id = this.active_cluster.related[0];
              }
            }
            else
            {
              console.log('finish?');
            }
          }
        }else if(text === "previous"){
          if(find > 0)
          {
            //console.log('next id',id);
            id = this.active_cluster.related[find-1];
          }
          else
          {
            let current_index = this.clusters.indexOf(this.active_cluster);
            if(current_index  > 0)
            {
              this.active_cluster = this.clusters[current_index-1];
              if(this.active_cluster.related.length > 0)
              {
                id = this.active_cluster.related[this.active_cluster.related.length-1];
              }
            }
            else
            {
              //console.log('first?');
            }
          }
        }

        if(text)
        {
          let found = this.questions.find(element => element.id == id);
          console.log('found',found);
          if(found)
          {
            this.question=found;
            if(id == this.clusters[0].related[0])
            {
              this.step = 0;
            }
            else
            {
              this.step = this.questions.indexOf(found);
            }
          }
          else
          {
            console.log('something went wrong, id not found',id);
          }
        }
      }



    },
    refreshProgressBar: function () {
      let numberAnsweredQuestions = Object.keys(this.answers.answers.questions).length;
      this.progressValue = numberAnsweredQuestions;
    },
    clickAnswer (text) {
      //if (Number(text)-1 <= this.progressValue){
      this.step = Number(text)-1
      this.recieveResponse();
      //}
    },
    getNewProfileImg()
    {
      let _this = this;
      this.loading_img = true;
      axios.post('answers/getECSProfileImage', {"id_ecs": _this.ecs_id})
          .then(function (response) {
            _this.loading_img = false;
            if(response.data !== "") _this.imageURL = 'https://edicitnet.icra.cat/images/'+response.data+'?'+ new Date().getTime();
            else _this.imageURL = 'https://toolbox.edicitnet.com/images/addImage.png';
          })
          .catch(function (error) {
            this.loading_img = false;
            console.log("Error getting profile image");
            console.log(error);
          });
    },
    clickQuestion(id)
    {
      this.show_report = false;
      let found = this.questions.find(element => element.id == id);
      if(found)
      {
        this.question=found;
        if(id == this.clusters[0].related[0])
        {
          this.step = 0;
        }
        else
        {
          this.step = this.questions.indexOf(found);
        }
      }
      else
      {
        console.log('something went wrong, id not found',id);
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.profileNew{
  background-color: white;
  border-radius: 0rem;
  padding: 0rem;
  margin: 2rem 2rem 0;
  min-height: var(--height-child-comp);
}

.ECSdescription{
  color: var(--green-primary);
  font-weight: bold;
}



</style>

<style scoped>
.greenBack{
  background: #A0CF84;
  height: 115px;
  display: block;
}

.cluster
{
  height: 105px;
  display: block;
  padding: 10px 5px 5px 5px;
  font-size:12px;
  font-weight:bold;
  cursor:pointer;
}
.green-back-cluster
{
  background: #A0CF84;
}

.green-back-cluster:hover
{
  background:var(--green-primary);
}

.red-back-cluster
{
  background: #c00000;
}
.cluster svg
{
  stroke: white;
}
.profile-img
{
  object-fit: cover;
  width: 10.6vw;
  height: 10.6vw;
  cursor:pointer;
}

.profile-img:hover
{
  border:2px solid var(--green-primary);
}

.profile-banner
{
  height:220px;
}

.questions-list
{
  padding-inline-start: 18px;
  font-size:12px;
}

.questions-list li:hover
{
  cursor:pointer;
  color: #c00000;
}

.questions-list li.active
{
  font-weight:bold;
}

.questions-list li.answered
{
  color:var(--green-primary);
}

.monitered-list
{
  font-size:11px;
  padding-inline-start: 28px;
  color: var(--gray-font-color);
  font-weight:normal;
}

.monitered-list li:hover {
  cursor: pointer;
  color: #c00000;
}


.monitered-list li.active
{
  font-weight:bold;
}

.go-profile
{
  position: absolute;
  right: 50px;
  top: 70px;
  width: auto;
}

.go-popup
{
  position: absolute;
  left: 50px;
  top: 70px;
  width: auto;
  margin-top: 0px !important;
}

.loading-img
{
  opacity:0.4;
}

.ecs-name
{
  color: var(--green-primary);
  cursor:pointer;
}

.ecs-name:hover
{
  color: #A0CF84;
}
</style>