export default {
  methods: {
    stringToLabel: function (text) {
      if (text) {
        let res = text.replace(/\n/g, '<br>');
        return res;
      }
      else return "";
    }
  }
}