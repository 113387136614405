<template>
  <path vector-effect="non-scaling-stroke"
        d="M 25.00,27.00
           C 29.40,27.00 33.00,22.70 33.00,17.30
             33.00,12.30 29.70,8.70 25.00,8.70
             20.30,8.70 17.00,12.20 17.00,17.30
             17.00,22.70 20.60,27.00 25.00,27.00 Z
           M 25.00,10.70
           C 28.70,10.70 31.10,13.40 31.10,17.40
             31.10,21.70 28.30,25.20 25.00,25.20
             21.70,25.20 18.90,21.70 18.90,17.40
             18.90,13.40 21.30,10.70 25.00,10.70 Z
           M 25.90,28.90
           C 25.90,28.90 24.20,28.90 24.20,28.90
             16.20,28.90 10.90,33.50 10.90,40.30
             10.90,40.80 11.30,41.20 11.80,41.20
             12.30,41.20 12.70,40.80 12.70,40.30
             12.70,33.30 18.80,30.80 24.10,30.80
             24.10,30.80 25.80,30.80 25.80,30.80
             31.10,30.80 37.20,33.30 37.20,40.30
             37.20,40.80 37.60,41.20 38.10,41.20
             38.60,41.20 39.00,40.80 39.00,40.30
             39.20,33.50 33.80,28.90 25.90,28.90 Z" />
</template>

<script>
  export default {
    name: "LoginIcon"
  }
</script>

<style scoped>

</style>