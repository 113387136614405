<template lang="pug">
    b-row
        b-form-file(
          ref="profile_img"
          v-model="file")


</template>

<script>
import utilMixin from "../../mixin/utilsMixin";
import axios from "axios";
import questionHeader from "../questionHeader";
import questionFooter from "../questionFooter";

export default {
  name: "questionFileUpload",
  components: {questionHeader, questionFooter},
  props: ['question', 'questionNumber', 'answer','ecs_id', 'exp_id', 'nQuestions'],
  mixins: [utilMixin],
  data: function (){
    return{
      file: null,
      uploading: false,
      answered: false,
      shown: false,
      skip: {
        skipped: "",
        custom: false,
        answer: ""
      },
      firstAnswered: false,
      firstSkipped: false,
      langId: 1
    }
  },
  mounted: function(){
  },
  watch:{
    file: function(val)
    {
      this.file = val;
      console.log(this.file);
      this.saveToProfileECS();
    }
  },
  methods:{
    uploadFile: function (text) {
      console.log('upload file',text);
      if(this.file !== null){
        let data = new FormData();
        // console.log(this.file);
        data.append('file', this.file, this.file.fileName);
        data.append('idEcs', this.ecs_id);
        axios.post("questions/query/setImageECS", data, {
          headers: {
            'accept': 'application/json',
            'Accept-Language': 'es-ES,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
          }
        })
            .then((response) => {
              //handle success
              this.uploading = false;
              this.answered = true;
              console.log("setImageECS",response);
              this.save(text);
            }).catch((error) => {
          this.uploading = false;
          console.log(error);
          console.log(error.response);
        });
      }else{
        this.save(text);
      }
    },

    saveToProfileECS: function (text) {

      if(this.file !== null){
        this.uploading = true;
      }
      let _this = this;
      _this.uploadFile(text);

    },
    resetAnswer: function () {
      this.answered = false;
      this.uploading = false;
      this.file = null;
    },
    stringToLabel: function (text) {
      if (text) {
        let res = text.replace(/\n/g, '<br>');
        return res;
      }
      else {
        text = this.question.config['text_lang_1']
        let res = text.replace(/\n/g, '<br>');
        return res;
      }
    },
    save: function (text) {
      console.log('emit updateProfileImg');
      this.$root.$emit('updateProfileImg');
      /*
      this.$emit('sendResponse', {
        id_question: this.question.id,
        id_category: this.question.category_id,
        answer: this.answered,
        skip: this.skip}, text);


      axios.post(this.$parent.url_answer, {
        question_id: this.question.id,
        ecs_id: ecs_exp_id,
        category_id: this.question.category_id,
        answer: this.answered,
        skip: this.skip
      })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });*/
    },
  },
  computed: {
    getLang: function () {
      let language = this.$store.getters.getLanguage.id;
      this.langId = language;
      //console.log("Question langID: ", language);
      return language;
    }
  }
}
</script>

<style scoped>
.questions{
  background-color: var(--light-gray-secondary);
}
.form-rounded {
  border-radius: 1rem;
}
</style>
