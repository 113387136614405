<template>
  <path vector-effect="non-scaling-stroke"
        d="M 42.10,21.50
           C 41.00,20.40 38.80,20.30 37.60,21.50
             37.60,21.50 30.90,28.30 30.90,28.30
             28.80,25.50 25.20,23.90 20.70,23.90
             20.70,23.90 19.10,23.90 19.10,23.90
             11.90,23.80 7.00,28.00 7.00,34.20
             7.00,34.70 7.40,35.00 7.80,35.00
             8.20,35.00 8.70,34.60 8.70,34.10
             8.70,27.70 14.30,25.40 19.10,25.40
             19.10,25.40 20.70,25.40 20.70,25.40
             24.00,25.40 27.80,26.50 29.70,29.40
             29.70,29.40 20.80,38.40 20.80,38.40
             20.40,38.80 20.00,39.40 19.90,40.10
             19.90,40.10 19.30,43.30 19.30,43.30
             19.30,43.60 19.30,44.00 19.60,44.20
             19.80,44.40 20.00,44.50 20.30,44.50
             20.30,44.50 23.60,43.80 23.60,43.80
             24.20,43.70 24.70,43.40 25.20,43.00
             25.20,43.00 42.20,26.00 42.20,26.00
             42.80,25.40 43.10,24.60 43.10,23.80
             43.00,23.00 42.70,22.10 42.10,21.50 Z
           M 40.70,24.60
           C 40.70,24.60 23.70,41.60 23.70,41.60
             23.60,41.70 23.30,41.90 23.10,41.90
             23.10,41.90 21.50,42.30 21.50,42.30
             21.50,42.30 21.80,40.60 21.80,40.60
             21.80,40.40 21.90,40.20 22.10,40.00
             22.10,40.00 39.00,23.10 39.00,23.10
             39.30,22.80 39.60,22.70 39.80,22.70
             40.00,22.70 40.40,22.80 40.60,23.10
             41.20,23.50 41.20,24.20 40.70,24.60 Z
           M 19.90,22.10
           C 23.90,22.10 27.20,18.10 27.20,13.30
             27.20,8.70 24.20,5.50 19.90,5.50
             15.60,5.50 12.60,8.70 12.60,13.30
             12.60,18.10 15.90,22.10 19.90,22.10 Z
           M 19.90,7.20
           C 23.20,7.20 25.50,9.60 25.50,13.30
             25.50,17.20 23.00,20.40 19.90,20.40
             16.80,20.40 14.30,17.20 14.30,13.30
             14.30,9.60 16.60,7.20 19.90,7.20 Z" />
</template>

<script>
  export default {
    name: "EcsPerfIcon"
  }
</script>

<style scoped>

</style>