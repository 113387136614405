<script>
import { Bar } from 'vue-chartjs';
// Chart.defaults.global.defaultFontFamily = "Fira Sans";
// Chart.defaults.global.defaultFontSize = "14";


export default {
  name: "bar",
  extends: Bar,
  props: ['chartData','options'],
  methods: {
    update() {
      this.$data._chart.update()
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
