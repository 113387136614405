<template lang="pug">
  b-container(fluid="true")
    b-container(fluid="true")
      b-container.m-4(fluid="true")
        questionFooter(ref="footer" :questionNumber="questionNumber" :nQuestions="nQuestions" :dataAnswer="answered" @saveData="saveToProfileECS" :ecs_id="ecs_id")
        questionHeader(ref="header" :questionNumber="questionNumber" :nQuestions="nQuestions")
        b-row
          b-col(sm="12")
            h6
              label.col-form-label(v-html="stringToLabel(question.config['text_lang_' + getLang])")
            b-col(sm="9").mb-4
              b-form-file(
                  ref="profile_img"
                  v-model="file"
                  :placeholder="$t('placeholder.chooseFile')"
                  :drop-placeholder="$t('placeholder.dropFile')")
            //b-img(v-show="shown" :src="`http://edicityapi.h2793818.stratoserver.net/images/${ecs_id}.png `" @error="imageLoadError")


</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import axios from "axios";
  import questionHeader from "../questionHeader";
  import questionFooter from "../questionFooter";

  export default {
    name: "questionFileUpload",
    components: {questionHeader, questionFooter},
    props: ['question', 'questionNumber', 'answer','ecs_id', 'exp_id', 'nQuestions'],
    mixins: [utilMixin],
    data: function (){
      return{
        file: null,
        uploading: false,
        answered: false,
        shown: false,
        skip: {
          skipped: "",
          custom: false,
          answer: ""
        },
        firstAnswered: false,
        firstSkipped: false,
        langId: 1
      }
    },
    mounted: function(){
      if(this.answer){
        this.answered = this.answer.answer;
        if(this.answer.skip) {
          this.$refs.footer.skip = this.answer.skip;
          this.skip = {
            skipped: this.$refs.footer.skip.skipped,
            custom: this.$refs.footer.customAnswer(),
            answer: this.$refs.footer.customText()
          }
        }

      }
      else{
        console.log("Loaded Simple not found")
      }
      this.firstAnsweredOrSkipped(this);

    },
    watch:{
      questionNumber: function(newVal,oldVal) {
        if(typeof(this.answer) === 'undefined'){
          this.resetAnswer();
          this.$refs.footer.resetModal();
        }else{
          this.answered = this.answer.answer;
          if(this.hasBeenSkipped(this.answer)) {
            this.$refs.footer.skip = this.answer.skip;
            this.skip= {
              skipped: this.$refs.footer.skip.skipped,
              custom: this.$refs.footer.customAnswer(),
              answer: this.$refs.footer.customText()
            }
          }else{
            this.$refs.footer.resetModal();
          }
        }
        this.firstAnsweredOrSkipped(this)

      },
      file: function(val)
      {
        console.log('profile_update');
      }
    },
    methods:{
      uploadFile: function (text) {
        if(this.file !== null){
          let data = new FormData();
          // console.log(this.file);
          data.append('file', this.file, this.file.fileName);
          data.append('idEcs', this.ecs_id);
          axios.post("questions/query/setImageECS", data, {
            headers: {
              'accept': 'application/json',
              'Accept-Language': 'es-ES,en;q=0.8',
              'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
          }
        })
            .then((response) => {
              //handle success
              this.uploading = false;
              this.answered = true;
              console.log("setImageECS",response);
              this.save(text);
            }).catch((error) => {
            this.uploading = false;
            console.log(error);
            //handle error
          });
        }else{
          this.save(text);
        }
      },

      saveToProfileECS: function (text) {

        if(this.file !== null){
          this.uploading = true;
        }

        console.log("Save Question: ", this.question.id);
        if (this.$refs.footer.skipQuestion()) {
          this.skip.skipped = this.$refs.footer.skip.skipped;
          this.skip.custom = this.$refs.footer.customAnswer();
          this.skip.answer = this.$refs.footer.customText();
        }

        let _this = this;

        if(!_this.hasBeenAnsweredOrSkipped(this) && text==="next"){
          this.$emit('sendResponse', undefined, text);
        }else if(!_this.hasBeenAnsweredOrSkipped(this) && text==="previous"){
          this.$emit('sendResponse', undefined, text);
        } else {
          if(_this.firstAnswered && _this.hasBeenSkipped(_this)){
            _this.resetAnswer();
            _this.save(text)
          }else if(_this.firstSkipped && _this.hasBeenAnswered(_this)){
            _this.resetSkip()
            _this.uploadFile(text);
          }else if(_this.hasBeenAnswered(_this) && _this.hasBeenSkipped(_this)){
            this.$emit('sendResponse', undefined, text);
          }else{
            if(_this.hasBeenAnswered(_this)){
              _this.uploadFile(text);
            }else{
              _this.save(text);
            }
          }
        }
      },

      hasBeenAnswered: function (answer) {
        if(answer) return answer.answered || answer.uploading;
        return false;
      },
      hasBeenSkipped: function (answer) {
        if (answer && answer.skip) return answer.skip.skipped || answer.skip.custom;
        return false;
      },
      resetAnswer: function () {
        this.answered = false;
        this.uploading = false;
        this.file = null;
      },
      resetSkip: function () {
        this.$refs.footer.resetModal();
        this.skip = {
          skipped: "",
          custom: false,
          answer: ""
        }
      },
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          text = this.question.config['text_lang_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      save: function (text) {

        this.$emit('sendResponse', {
          id_question: this.question.id,
          id_category: this.question.category_id,
          answer: this.answered,
          skip: this.skip}, text);

        let _this = this;
        let oldQuestionId = this.question.id;
        let auxFile = (this.file !== null);

        if (this.exp_id) {
          var ecs_exp_id = this.exp_id
        } else {
          var ecs_exp_id = this.ecs_id
        }

        axios.post(this.$parent.url_answer, {
          question_id: this.question.id,
          ecs_id: ecs_exp_id,
          category_id: this.question.category_id,
          answer: this.answered,
          skip: this.skip
        })
          .then(function (response) {
            console.log(response);
            if (oldQuestionId === 94 && auxFile){
              _this.$router.go(0);
            }

          })
          .catch(function (error) {
            console.log(error);
          });
      },
      hasBeenAnsweredOrSkipped: function (answer) {
        return this.hasBeenSkipped(answer) || this.hasBeenAnswered(answer);
      },

      firstAnsweredOrSkipped: function (answer) {
        this.firstAnswered = this.hasBeenAnswered(answer);
        this.firstSkipped = this.hasBeenSkipped(answer);
      }
    },
    computed: {
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      }
    }
  }
</script>

<style scoped>
  .questions{
    background-color: var(--light-gray-secondary);
  }
  .form-rounded {
    border-radius: 1rem;
  }
</style>
