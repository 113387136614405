<template lang="pug">
  .about_create_profile.generalBackground
    b-container.p-4.m-4(fluid="true")
      b-row
        b-col
          h2 {{ $t('menu.info.share_knowledge') }}
          p(style="white-space: pre-line") {{ $t('abouts.share_knowledge_create_profile.text') }}
          ol
            li {{ $t('abouts.share_knowledge_create_profile.list.line1') }}<br>

            li {{ $t('abouts.share_knowledge_create_profile.list.line2') }}<br>
              //b-img(:src="$t('abouts.share_knowledge_create_profile.image1')" style="width: 20%")
            li {{ $t('abouts.share_knowledge_create_profile.list.line3') }}<br>
              //b-img(:src="$t('abouts.share_knowledge_create_profile.image2')" style="width: 50%")
            li {{ $t('abouts.share_knowledge_create_profile.list.line4') }}<br>
              //b-img(:src="$t('abouts.share_knowledge_create_profile.image3')" style="width: 50%")

</template>

<script>
export default {
name: "share_knowledge_create_profile"
}
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }
</style>
