<template lang="pug">
  div
    //.background
    //.triangle
    .login
      b-container.p-0(fluid="true").banner
        b-img(src="/images/banner-login.png" fluid alt="Fluid image;")
        div.bannerTitle
          p Log in
      b-container.px-4.pb-4(fluid="true")
        b-row
          b-col
            b-container(fluid="true").mx-4.mb-4
              h5.display-5 {{ $t('login.title') }}
              //p.pt-3 You are only 2 STEPS away towards being part of the FoodLinkSpot. Log in!
        b-row
          b-col
            b-container.signIn.m-4(fluid="true")
              b-row(align-h="center")
                b-col.login-form-1(sm="12" md="8" xl="4" )
                  form.p-4
                    h5.my-3 {{ $t('login.signIn') }}
                    //b-form-group
                    b-form-input.input(type='text' :placeholder="$t('placeholder.email')" v-model='username' style="border-color: var(--light-gray-secondary); border-radius: 25px; font-size: small;")
                    //b-form-group
                    b-form-input.input(type='password' :placeholder="$t('placeholder.password')" v-model='password' style="border-color: var(--light-gray-secondary); border-radius: 25px; font-size: small;" v-on:keyup.enter="login")
                    b-form-group.text-center.m-0
                      input.btnSubmit(type='button' :value="$t('buttons.login')" @click='login')
                    template(v-if="msg")
                      b-form-group.text-center
                        span.errorLogin.mx-2 {{ msg }}
                    b-form-group.text-center
                      a.ForgetPwd(href='sign-up'  value='Login') {{ $t('login.noAccount') }}
                      br
                      a.ForgetPwd(href='recover-password') {{ $t('login.recoverPassword') }}
</template>

<style>
  .login h5{
    color: var(--green-primary);
    font-weight: var(--bold-text);
  }

  .login-form-1 h5 {
    text-align: center;
    color: black;
  }

  .login-container form {
    padding: 5%;
    background-color: white;
  }

  .btnSubmit {
    width: 50%;
    min-width: fit-content;
    border-radius: 1rem;
    padding: 1.5%;
    border: none;
    cursor: pointer;
    background-color: var(--green-primary);
    font-weight: bold;
    font-size: small;
    overflow: hidden
  }

  .login-form-1 .btnSubmit:hover {
    background-color: var(--light-gray-primary);
  }

  .login-form-1 .btnSubmit {
    font-weight: var(--bold-text);
    color: #fff;
    background-color: var(--green-primary);
  }

  .login-form-1 .ForgetPwd {
    color: var(--green-primary);
    font-weight: var(--bold-text);
    text-decoration: none;
    text-align: center;
    font-size: small;
  }

  .login-form-1 .ForgetPwd:hover {
    color: var(--dark-red);
  }

  .login{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  .signIn {
    background-color: var(--light-gray-secondary);
  }

  .login-form-1 h5 {
    text-align: center;
    font-weight: bolder;
  }

  .errorLogin {
    color: var(--dark-red);
    font-size: small;
  }

  .input {
    margin-bottom: 1rem;
  }

</style>

<script>
  import AuthService from '@/services/AuthService.js';

  export default {
    name: "login",
    data() {
      return {
        username: '',
        password: '',
        msg: '',
      };
    },
    methods: {
      async login() {
        try {
          const credentials = {
            username: this.username,
            password: this.password,
            lang: this.getLang
          };
          const response = await AuthService.login(credentials);
          this.msg = response.msg;

          const token = response.token;
          const user = response.user;
          const language = (JSON.parse(response.user['json_data']))['lang'];

          this.$store.dispatch('login', {token, user});
          this.$store.dispatch('changeLanguage', {language});

          this.$router.push('/');
          this.$router.go(0);
        } catch (error) {
          this.msg = error.response.data.msg;
        }
      }
    },
    computed: {
      getLang: function () {
        let lang = this.$store.getters.getLanguage;
        return lang;
      }
    }
  };
</script>
