<template lang="pug">
    b-row.mt-2.mb-2
        b-col(sm="12")
          h6
            //label.col-form-label(v-html="stringToLabel(question['text_' + getLang])")
          b-input-group
            b-form-input(@change="changeEvent" v-model="dataResult" :class="'form-control'").form-rounded
            b-input-group-append
              b-button(@click="searchLocation" ).form-rounded {{ $t('buttons.search') }}
          div(:id="'map_'+question['internal_id']").map


</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import gmapsInit from "../../../utils/gmaps.js"

  export default {

    name: "questionOpenCol",
    props: ['question', 'sendResult'],
    mixins: [utilMixin],
    mounted(){
      this.searchLocation();
    },
    computed: {
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      }
    },
    methods:{
      searchLocation: async function () {
        if(this.dataResult.length < 0) return;
        const google = await gmapsInit();
        const _this = this;

        var sydney = new google.maps.LatLng(41.981, 2.823);

        var request = {
          query: this.dataResult,
          fields: ['name', 'geometry'],
        };

        if(_this.map == null){
          _this.map = new google.maps.Map(this.$el.querySelector('#map_'+this.question['internal_id']), {
            center: sydney,
            zoom: 15,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          });
        }
        const service = new google.maps.places.PlacesService(_this.map);

        service.findPlaceFromQuery(request, async function(results, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            for (var i = 0; i < results.length; i++) {
              await _this.createMarker(results[i]);
              console.log(results[i]);
            }
            console.log(results[0].geometry.location);
            _this.map.setCenter(results[0].geometry.location);
          }
        });
      },
      createMarker: async function (place) {

        const _this = this;
        const google = await gmapsInit();
        _this.valid = true;
        var marker = new google.maps.Marker({
          map: _this.map,
          position: place.geometry.location
        });
      },
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          let text = this.question['text_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      changeEvent: function(){
        this.$emit("changeEvent", this);
      },
      getDataResult: function () {
        if (this.dataResult != null && this.dataResult !== '') {
            return {
              id: this.question.internal_id,
              text: this.dataResult
            }
        }
        else return null;
      }
    },
    data: function () {
      return {
        dataResult: "",
        map: null,
        valid: false,
        langId: 1
      }
    }
  }
</script>

<style scoped>
  .map{
    height: 10rem;
    width: 100%;
    margin-top: 10px;
  }
  .form-rounded {
    border-radius: 1rem;
  }

  .form-rounded-rigth {
    border-right-radius: 1rem;
  }
</style>