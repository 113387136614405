<template>
  <path vector-effect="non-scaling-stroke"
        d="M 16.90,9.84
           C 14.60,9.84 12.40,10.74 10.70,12.54
             7.30,16.14 7.30,21.94 10.70,25.54
             10.70,25.54 24.40,39.94 24.40,39.94
             24.70,40.34 25.40,40.34 25.70,39.94
             30.30,35.14 34.90,30.34 39.40,25.54
             42.80,21.94 42.80,16.14 39.40,12.54
             36.00,8.94 30.30,8.94 26.90,12.54
             26.90,12.54 25.00,14.54 25.00,14.54
             25.00,14.54 23.10,12.54 23.10,12.54
             21.30,10.54 19.00,9.84 16.90,9.84
             16.90,9.84 16.90,9.84 16.90,9.84 Z
           M 16.90,11.64
           C 18.70,11.64 20.50,12.34 21.90,13.84
             21.90,13.84 24.40,16.54 24.40,16.54
             24.70,16.94 25.40,16.94 25.70,16.54
             25.70,16.54 28.20,13.84 28.20,13.84
             31.00,10.94 35.30,10.94 38.10,13.84
             40.90,16.74 40.90,21.44 38.10,24.34
             33.70,28.94 29.40,33.44 25.00,38.04
             25.00,38.04 11.90,24.34 11.90,24.34
             9.10,21.44 9.10,16.74 11.90,13.84
             13.30,12.34 15.10,11.64 16.90,11.64 Z" />
</template>

<script>
  export default {
    name: "ItemsIcon"
  }
</script>

<style scoped>

</style>