<template>
  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScLEX7N3Ew3nwebBRj_6rxvi04ZhCW-q9asMYLAeUuQRaWs0w/viewform?embedded=true"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0" marginwidth="0">S&#39;està carregant…
  </iframe>
</template>

<script>
export default {
  name: "reportBugs"
}
</script>

<style scoped>

</style>