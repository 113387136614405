import { render, staticRenderFns } from "./EcsPerfIcon.vue?vue&type=template&id=011d3bc7&scoped=true&"
import script from "./EcsPerfIcon.vue?vue&type=script&lang=js&"
export * from "./EcsPerfIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "011d3bc7",
  null
  
)

export default component.exports