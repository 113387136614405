<template>
  <path vector-effect="non-scaling-stroke"
        d="M 38.10,13.30
           C 36.90,11.90 35.30,11.00 33.60,10.90
             33.60,10.90 35.00,8.30 35.00,8.30
             35.60,7.20 35.20,5.80 34.10,5.20
             33.00,4.80 31.70,5.10 31.10,6.00
             30.70,4.90 29.40,4.40 28.30,4.70
             27.20,5.10 26.60,6.10 26.80,7.30
             25.80,6.90 24.60,7.30 24.10,8.30
             23.80,8.80 23.80,9.40 24.00,10.00
             24.10,10.60 24.50,11.00 25.00,11.30
             25.00,11.30 25.00,11.30 25.10,11.30
             25.10,11.30 27.50,12.60 27.50,12.60
             26.30,13.60 25.50,14.90 25.10,16.70
             25.00,17.40 24.90,18.10 25.00,18.90
             25.20,19.00 25.40,19.20 25.50,19.40
             24.50,18.70 23.50,18.10 22.50,17.80
             20.70,17.20 19.00,17.20 17.40,18.00
             17.40,18.00 17.30,15.20 17.30,15.20
             17.30,13.90 16.20,13.00 15.00,13.00
             13.80,13.10 12.90,14.00 12.80,15.10
             11.90,14.40 10.50,14.50 9.80,15.40
             9.00,16.30 9.00,17.50 9.80,18.40
             8.70,18.60 7.90,19.50 8.00,20.70
             8.00,21.30 8.30,21.80 8.70,22.30
             9.10,22.70 9.60,22.90 10.20,22.90
             10.20,22.90 10.20,22.90 10.30,22.90
             10.30,22.90 13.00,22.80 13.00,22.80
             12.40,24.30 12.40,25.80 13.00,27.50
             14.60,32.30 20.90,36.80 25.80,39.80
             31.20,43.00 36.70,45.30 37.80,45.50
             38.00,45.50 38.10,45.50 38.30,45.50
             38.50,45.50 38.70,45.50 38.80,45.50
             39.10,45.60 39.40,45.60 39.70,45.50
             40.40,45.30 40.90,44.80 41.30,43.90
             41.70,42.90 42.50,37.00 42.50,30.70
             42.20,24.80 41.50,17.10 38.10,13.30 Z
           M 32.10,7.50
           C 32.30,7.10 32.80,6.90 33.30,7.10
             33.70,7.30 33.90,7.90 33.70,8.30
             33.70,8.30 32.40,10.80 32.40,10.80
             32.40,10.80 31.70,8.50 31.70,8.50
             31.70,8.50 32.10,7.50 32.10,7.50 Z
           M 28.70,6.70
           C 29.10,6.50 29.60,6.80 29.70,7.30
             29.70,7.30 31.00,11.60 31.00,11.60
             30.50,11.70 29.90,11.90 29.30,12.10
             29.30,12.10 28.00,7.80 28.00,7.80
             28.00,7.30 28.20,6.80 28.70,6.70 Z
           M 25.50,10.80
           C 25.30,10.70 25.10,10.50 25.10,10.30
             25.00,10.10 25.10,9.90 25.20,9.60
             25.40,9.20 25.90,9.00 26.40,9.20
             26.40,9.20 27.30,9.70 27.30,9.70
             27.30,9.70 28.00,12.00 28.00,12.00
             28.00,12.00 25.50,10.80 25.50,10.80 Z
           M 14.80,14.30
           C 15.30,14.30 15.70,14.70 15.70,15.10
             15.70,15.10 15.80,17.90 15.80,17.90
             15.80,17.90 14.00,16.30 14.00,16.30
             14.00,16.30 14.00,15.20 14.00,15.20
             14.00,14.70 14.40,14.30 14.80,14.30 Z
           M 10.70,16.30
           C 11.00,15.90 11.60,15.90 11.90,16.30
             11.90,16.30 15.20,19.30 15.20,19.30
             14.80,19.70 14.40,20.10 14.00,20.60
             14.00,20.60 10.70,17.60 10.70,17.60
             10.40,17.20 10.30,16.60 10.70,16.30 Z
           M 10.00,21.40
           C 9.80,21.40 9.50,21.40 9.40,21.20
             9.20,21.00 9.20,20.80 9.20,20.60
             9.20,20.10 9.50,19.70 10.00,19.70
             10.00,19.70 11.10,19.70 11.10,19.70
             11.10,19.70 12.90,21.30 12.90,21.30
             12.90,21.30 10.00,21.40 10.00,21.40 Z
           M 25.70,38.20
           C 25.70,38.20 26.70,37.20 26.70,37.20
             26.70,37.20 25.70,36.20 25.70,36.20
             25.70,36.20 24.50,37.40 24.50,37.40
             23.30,36.60 22.10,35.80 20.90,34.90
             20.90,34.90 24.10,31.70 24.10,31.70
             24.10,31.70 23.10,30.70 23.10,30.70
             23.10,30.70 19.80,34.00 19.80,34.00
             17.20,31.80 14.90,29.40 14.10,26.90
             13.30,24.60 13.90,22.60 15.80,20.70
             17.20,19.30 18.50,18.70 20.10,18.70
             20.70,18.70 21.40,18.80 22.10,19.00
             23.50,19.50 25.00,20.50 26.40,21.70
             26.40,21.70 22.30,25.80 22.30,25.80
             22.30,25.80 23.30,26.80 23.30,26.80
             23.30,26.80 27.40,22.70 27.40,22.70
             28.90,24.20 30.20,25.90 31.50,27.70
             31.50,27.70 29.50,29.70 29.50,29.70
             29.50,29.70 30.50,30.70 30.50,30.70
             30.50,30.70 32.30,28.90 32.30,28.90
             32.80,29.70 33.20,30.50 33.70,31.20
             34.60,32.80 35.50,34.30 36.20,35.70
             36.20,35.70 35.40,36.50 35.40,36.50
             35.40,36.50 36.40,37.50 36.40,37.50
             36.40,37.50 36.90,37.00 36.90,37.00
             38.30,39.90 39.20,42.10 39.20,42.60
             39.30,43.20 39.10,43.50 39.00,43.60
             38.90,43.70 38.60,43.80 37.90,43.80
             37.20,43.70 32.00,41.70 26.40,38.40
             26.10,38.40 25.90,38.30 25.70,38.20 Z
           M 40.70,35.80
           C 40.70,35.80 39.60,36.10 39.60,36.10
             39.60,36.10 40.00,37.40 40.00,37.40
             40.00,37.40 40.70,37.20 40.70,37.20
             40.60,39.20 40.40,40.70 40.20,41.80
             39.60,39.90 37.50,35.20 34.80,30.50
             32.70,26.90 29.70,22.60 26.30,19.90
             26.30,19.00 26.30,17.50 26.40,16.90
             26.90,14.50 28.40,13.00 31.00,12.40
             32.80,11.90 34.40,12.10 35.70,12.80
             36.20,13.10 36.80,13.50 37.20,14.10
             38.20,15.20 38.90,16.80 39.50,18.60
             39.50,18.60 34.00,20.10 34.00,20.10
             34.00,20.10 34.40,21.40 34.40,21.40
             34.40,21.40 39.90,19.90 39.90,19.90
             40.40,21.90 40.70,24.10 40.90,26.30
             40.90,26.30 38.00,27.20 38.00,27.20
             38.00,27.20 38.40,28.50 38.40,28.50
             38.40,28.50 40.90,27.80 40.90,27.80
             40.90,28.70 40.90,29.60 40.90,30.50
             40.90,32.40 40.80,34.20 40.70,35.80 Z" />
</template>

<script>
  export default {
    name: "WhatEcsIcons"
  }
</script>

<style scoped>

</style>