<template lang="pug">
  b-row.mt-2.mb-2
    b-col(sm="12")
      h6
        //label.col-form-label(v-html="stringToLabel(question['text_' + getLang])")
      b-form-group
        b-form-checkbox-group(stacked @change="changeEvent" :id="question.internal_id" v-model="dataResult" :options="options" :name="question.internal_id")
        b-form-checkbox(@change="changeEvent" v-model="isCustom" :value="true" :unchecked-value="false"  v-if="question.customAnswer === 'true'") {{  $t('ecsForm.skip.other') }}
        b-form-input(@change="changeEvent" :disabled="customEnabled" v-model="customDataResult" type="text"  v-if="question.customAnswer === 'true'").form-rounded.my-1

</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";

  export default {

    name: "questionRadioCol",
    props: ['question', 'answered', 'sendResult'],
    mixins: [utilMixin],
    data: function() {
      return{
        dataResult: this.sendResult,
        isCustom: false,
        customDataResult: "",
        langId: 1
      }
    },
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          let text = this.question['text_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      changeEvent: function(){
        this.$emit("changeEvent", this);
      },
      getDataResult: function () {
        this.dataResult = this.dataResult.filter(element => element.id != 'other');
        let retVal = [];
        if (this.isCustom && this.customDataResult != null) {
          //return this.customDataResult;
          retVal.push({id: "other", text: this.customDataResult })
        }
        if (this.dataResult != null && this.dataResult !== []) {
          //return this.dataResult;
          retVal = retVal.concat(this.dataResult);
        }
        if(retVal === []) return null;
        else return retVal;
      }
    },
    mounted: function()
    {
      console.log('mounted',this.dataResult);
      let _this = this;
      if(!this.dataResult)
      {
        this.dataResult = [];
      }
      this.dataResult.forEach(function(e){
        if(e.id_custom == "52_months")
        {
          let options = [
            { values: ['January', 'Gener'], code: '52.1.1' },
            { values: ['February','Febrer',], code: '52.1.2' },
            { values: ['March','Març'], code: '52.1.3' },
            { values: ['April','Abril'], code: '52.1.4' },
            { values: ['May','Maig'], code: '52.1.5' },
            { values: ['June','Juny'], code: '52.1.6' },
            { values: ['July','Juliol'], code: '52.1.7' },
            { values: ['August','Agost'], code: '52.1.8' },
            { values: ['September','Setembre'], code: '52.1.9' },
            { values: ['October','Octubre'], code: '52.1.10' },
            { values: ['November','Novembre'], code: '52.1.11' },
            { values: ['December','Desembre'], code: '52.1.12' },
          ];
          let find = options.find(element => element.values.indexOf(e.text) >= 0);
          if(find)
          {
            e.id_custom_answer = find.code;
            e.id_custom = "months";
            e.text = find.values[0];
            find = _this.options.find(element => element.value.id_custom_answer == e.id_custom_answer);
            if(find)
            {
              e.id = find.value.id;
            }
          }
        }
        let find = _this.options.find(element => element.value.id === e.id);
        if(find)
        {
          e.text = find.text;
        }
      });
	    if(this.sendResult && this.sendResult.length > 0)
        {
        let other = this.sendResult.filter(element => element.id == "other");
        if(other.length > 0)
        {
          other = other.map(element => element.text).join(', ');
          this.customDataResult = other;
          this.isCustom = true;
        }


      }
    },
    computed:{
      options: function () {
        let ret = [];
        let _this = this;
        if(!this.question.answers) return ret;
        let lang = this.getLangOptions;
        this.question.answers.forEach(function (answer) {
          if(answer[lang] === '' || !answer[lang])
            lang = 'text_1';
		  let option = {
	        id: answer.internal_id,
	        text: answer[lang],
	        id_custom_answer: answer.custom_ids
		  };
		  if( _this.question.custom_ids)
	        {
				option['id_custom'] = _this.question.custom_ids;
	        }
          ret.push({
            text: answer[lang],
            value: option
          });
        });
        return ret;
      },
      customEnabled: function () {
        return !this.isCustom;
      },
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      },
      getLangOptions: function () {
        let languageId = this.$store.getters.getLanguage.id;
        let language = "";
        switch (languageId) {
          case 2:
            language = "text_cat";
            break;
          case 3:
            language = "text_fr";
            break;
          case 4:
            language = "text_es";
            break;
          case 5:
            language = "text_de";
            break;
          case 6:
            language = "text_pt";
            break;
          case 7:
            language = "text_sl";
            break;
          default:
            language = "text_"+this.$store.getters.getLanguage.lang;
            break;
        }
        return language;
      }
    }
  }
</script>

<style scoped>
  .form-rounded {
    border-radius: 1rem;
  }
</style>