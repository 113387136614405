<template lang="pug">
  .about_toolbox.generalBackground
    b-container.p-4.m-4(fluid="true")
      b-row
        b-col
          h2 {{ $t('menu.info.about_toolbox') }}
          p(style="white-space: pre-line") {{ $t('abouts.toolbox.text') }}
          ul
            li
              b {{ $t('abouts.toolbox.list.line1') }}
            li
              b {{ $t('abouts.toolbox.list.line2') }}
            li
              b {{ $t('abouts.toolbox.list.line3') }}
          p.text-center
            b-img(:src="$t('abouts.toolbox.image')" style="width: 50%")


</template>

<script>
export default {
name: "toolbox"
}
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }
</style>
