<template lang="pug">
  div
    //.container.login-container
      .row
        .col-md-6.login-form-1
    .login
      b-container.p-0(fluid="true").banner
        b-img(src="/images/banner-login.png" fluid alt="Fluid image;")
        div.bannerTitle
          p Sign up
      b-container.px-4.pb-4(fluid="true")
        b-row
          b-col
            b-container(fluid="true").mx-4.mb-4
              h5.display-5 {{ $t('signUp.title') }}
              //p.pt-3 {{ $t('signUp.subtitle') }}
        b-row
          b-col
            b-container.signIn.m-4(fluid="true")
              b-row(align-h="center")
                b-col.login-form-1(sm="12" md="8" xl="4" )
                  form.p-4
                    h5.my-3 {{ $t('signUp.signUp') }}
                    //b-form-group#fieldset-horizontal(label-cols-sm='4' label-cols-lg='3' label='Email' label-for='input-horizontal')
                    b-form-input.input(type='text' :placeholder="$t('placeholder.email')" v-model='username' style="border-color: var(--light-gray-secondary); border-radius: 25px; font-size: small;")
                    //b-form-group#fieldset-horizontal(label-cols-sm='4' label-cols-lg='3' label='Password' label-for='input-horizontal')
                    b-form-input.input(type='password' :placeholder="$t('placeholder.password')" v-model='password' style="border-color: var(--light-gray-secondary); border-radius: 25px; font-size: small;")
                    //b-form-group#fieldset-horizontal(label-cols-sm='4' label-cols-lg='3' label='Password (repeat)' label-for='input-horizontal')
                    b-form-input.input(type='password' :placeholder="$t('placeholder.repeatPassword')" v-model='password_repeat' style="border-color: var(--light-gray-secondary); #f8f9fa; border-radius: 25px; font-size: small;")
                    //b-modal(size="xl" role="dialog" :title="$t('terms.title')" id="modal_conditions")
                      include terms.html
                    //input.ForgetPwd(type='button' :value="$t('signUp.readTerms')" v-b-modal.modal_conditions="" style="border: none; background-color: inherit")
                    b-form-checkbox.terms( name="accept-conditions" v-model="checkedCondition1" required) {{ $t('signUp.readAndUnderstood') }} {{ " " }}
                      a(href="https://www.edicitnet.com/consent-information-sheet/" target="_blank") {{ $t('signUp.readAndUnderstoodLink') }}
                    b-form-checkbox.terms( name="accept-conditions2" v-model="checkedCondition2" required) {{ $t('signUp.giveConsent') }} {{ " " }}
                      a(href="https://www.edicitnet.com/edicitnet-informed-consent/" target="_blank") {{ $t('signUp.giveConsentLink') }}
                    br
                    .form-group.text-center.m-0
                      input.btnSubmit(type='button' :value="$t('buttons.createAccount')" @click='signUp')
                    template(v-if="msg")
                      b-form-group.text-center
                        span.errorLogin.mx-2 {{ msg }}
                    .form-group.text-center
                      a.ForgetPwd(href='login'  value='Login') {{ $t('signUp.login') }}
                      br
                      a.ForgetPwd(href='recover-password') {{ $t('login.recoverPassword') }}
          //.col-md-6
            b-img(src="/images/logo.png" fluid-grow alt="Responsive image")

</template>

<style>
  .terms {
    font-size: small;
    font-weight: 600;
  }
</style>

<script>
  import AuthService from '@/services/AuthService.js';

  export default {
    data() {
      return {
        username: '',
        password: '',
        password_repeat: '',
        checkedCondition1: false,
        checkedCondition2: false,
        msg: ''
      };
    },
    methods: {
      async signUp() {
        try {
          const credentials = {
            username: this.username,
            password: this.password,
            password_repeat: this.password_repeat,
            lang: this.getLang
          };
          console.log("langIDSignUP: ", credentials.lang);
          if (this.checkedCondition1 && this.checkedCondition2) {
            const response = await AuthService.signUp(credentials);
            this.msg = response.msg;
            if (response.code === 200) {
              console.log(this.msg);
              this.$router.push('/login');
            }
          } else {
            this.msg = "Please check conditions";
          }
        } catch (error) {
          this.msg = error.response.data.msg;
        }
      }
    },
    computed: {
      getLang: function () {
        let lang = this.$store.getters.getLanguage;
        return lang;
      }
    }
  };
</script>
