import { render, staticRenderFns } from "./questionRangeProfile.vue?vue&type=template&id=f8b421c2&scoped=true&lang=pug&"
import script from "./questionRangeProfile.vue?vue&type=script&lang=js&"
export * from "./questionRangeProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8b421c2",
  null
  
)

export default component.exports