<template lang="pug">
  .listExperience(v-if="question")
    b-container.p-0(fluid="true").banner
      b-img(src="/images/banner-myECSprofile.png" fluid alt="Fluid image;")
      div.bannerTitle
        //p {{ $t('menu.experiences.addNewExperience')}}
    b-container.pl-4.pr-4.profile(fluid="true")
      b-row.align-self-center.position-sticky.sticky-top.bg-white
        b-col(sm="1").align-self-center
          b-img(v-show="imageShown" :src="`${imageURL}`" fluid)
          //b-img(v-show="imageShown" :src="'https://edicitnet.icra.cat/images/'+ecs_id+'.png'" @error="imageLoadError" fluid)
          //b-img(v-show="!imageShown" src="/images/addImage.png" fluid)
        b-col(sm="3").align-self-center
          h2.m-4(style="color: var(--questions-font-color);") {{ ecs_name }}
      //- b-row
      //-   b-col
      //-     b-container.m-3
      //-       h5.ECSdescription {{ $t('ecsForm.title') }}
      //-       p.pt-3 {{ $t('ecsForm.subtitle') }}
      b-row
        b-col(style="margin-top: 230px")
          b-container.ml-5.mr-5.mt-2(fluid="true")
            ProgressBarNavigator(:questions="questions" :answers="answers.answers.questions" :steps-labels="createLabels" :max="progressMaxValue" :value="progressMaxValue"
              v-bind:key="progressValue" :keyPoints="keyPoints", :progressStep="step+1" v-on:setStep="clickAnswer")
            //- ProgressBar(:steps-labels="createLabels" :max="progressMaxValue" :value="progressValue"
              v-bind:key="progressValue" :keyPoints="keyPoints" :progressStep="step+1" v-on:setStep="clickAnswer")
      b-row
        b-col
          b-container.questions.m-4(fluid="true")
            questionOpen(v-if="question.config.type === 'open_answer'" :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question", :nQuestions="questions.length").mb-4
            questionFileUpload(v-else-if="question.config.type === 'file_upload'" :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question", :nQuestions="questions.length" ).mb-4
            questionSingle(v-else-if="question.config.type === 'single_answer'" :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"      :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
            questionMulti(v-else-if="question.config.type === 'multi_answer'" :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"      :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
            //questionDropdown(v-else-if="question.config.type === 'dropdown-answer'" :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]").mb-4
            questionLocation(v-else-if="question.config.type === 'location_answer'" :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
            questionRange(v-else-if="question.config.type === 'range-answer'" :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
            questionTableCombination(v-else-if="question.config.type === 'table-combination-answer'" :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
            questionScore(v-else-if="question.config.type === 'score_answer'"  :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
            questionCalendar(v-else-if="question.config.type === 'calendar_answer'"  :ecs_id="ecs_id" :exp_id="exp_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4

            div(v-else) ToDo: {{ question.config.type }}
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import axios from 'axios';

  import questionOpen from './questionTypes/profileECS/questionOpenProfileECS';
  import questionRange from './questionTypes/profileECS/questionRangeProfileECS';
  import questionLocation from './questionTypes/profileECS/questionLocationProfileECS';
  import questionFileUpload from './questionTypes/profileECS/questionFileUploadProfileECS';
  import questionSingle from './questionTypes/profileECS/questionSingleProfileECS';
  import questionScore from './questionTypes/profileECS/questionScoreProfileECS';
  import questionCalendar from './questionTypes/profileECS/questionCalendar';
  import questionMulti from './questionTypes/profileECS/questionMultiProfileECS';
  import questionTableCombination from './questionTypes/profileECS/questionTableCombinationProfileECS';
  import ProgressBar from "./ProgressBar";
  import ProgressBarNavigator from "@/components/ProgressBarNavigator";

  export default {
    name: "EcsProfile",
    components: {
      Multiselect,
      ProgressBar,
      ProgressBarNavigator,
      questionOpen,
      questionRange,
      questionLocation,
      questionMulti,
      questionSingle,
      questionScore,
      questionCalendar,
      questionFileUpload,
      questionTableCombination
    },
    data: function () {
      return {

        questions: [],
        answers: {"answers": {
          "questions": {}
          }
        },
        progressValue: 0,
        progressMaxValue: 0,
        exp_id: "",
        ecs_id: "",
        ecs_name: "",
        url_answer: 'https://edicitnet.icra.cat/api/answers/setECSProfileAnswerExperience',
        question: null,
        step: 0,
        imageShown: true,
        keyPoints: [1,8,11,14,16],
        imageURL: "",
      }
    },
    computed: {
      createLabels() {
        let labels = [];
        for (let i=1;i<=this.progressMaxValue;i++){
          if (i==this.keyPoints[0]) labels.push(this.$t('progressBar.personalData'));
          else if (i==this.keyPoints[1]) labels.push(this.$t('progressBar.enablersBarriers'));
          else if (i==this.keyPoints[2]) labels.push(this.$t('progressBar.engagement'));
          else if (i==this.keyPoints[3]) labels.push(this.$t('progressBar.impact'));
          else labels.push('')
        }
        return labels
      }
    },
    mounted: async function () {
      console.log(this.$route.query.id);
      this.exp_id = this.$route.query.id;
      this.ecs_id = this.$route.query.id_ecs;

      this.ecs_name = this.$route.query.name_ecs;
      let _this = this;

      await axios.post('answers/getECSProfileImage', {"id_ecs": _this.ecs_id})
          .then(function (response) {
            if(response.data !== "") _this.imageURL = 'https://edicitnet.icra.cat/images/'+response.data;
            else _this.imageURL = 'https://toolbox.edicitnet.com/images/addImage.png';
          })
          .catch(function (error) {
            console.log("Error getting answers");
            console.log(error);
          });

      let questions = this.questions;
      console.log("Start petition");
      console.log('questions',questions);
      let returnIsValid = await axios.post('questions/getECSExperienceQuestions')
        .then(function (response) {
          console.log(response);
          if(response.status === 401){
            console.log("Unauthorized");
            alert("Unauthorized please re-login");
            return false;
          }
          else {
            console.log("Done");
            response.data.forEach(function (question) {
              question.config = JSON.parse(question.config);
              _this.progressMaxValue++;
              questions.push(question);

            });
            return true
          }
        })
        .catch(function (error) {
          return false;
          console.log("Error getting questions: ", error);
        });

      console.log("Valid Call Qurestions:", returnIsValid);

      let answers = this.answers;
      console.log("Loading Answers Client");
      await axios.post('answers/getECSExpirienceAnswers',
          {"id_ecs": _this.exp_id}
        )
        .then(function (response) {
          console.log(response);
          console.log("Done Answers");
          if(!response.data){
            //answers.answers = {"questions": {}};
            console.log("No data found");
            _this.step = 0;

          }
          else {
            response.data.forEach(function (answer) {
              console.log(answer);
              let answerParsed = JSON.parse(answer.answer);
              console.log(answerParsed);
              _this.answers.answers.questions['id_'+answerParsed.id_question] = answerParsed;
            });


            _this.step = Object.keys(_this.answers.answers.questions).length;
            if(_this.step >= _this.questions.length){
              _this.step = _this.questions.length -1;
            }

          }
          _this.refreshProgressBar();
          _this.question=questions[_this.step];
        })
        .catch(function (error) {
          console.log("Error getting answers");
          console.log(error);
        });

    },
    methods: {
      recieveResponse: function (answer, text) {
        if(answer !== undefined){
          this.answers.answers.questions["id_"+answer.id_question] = answer;
        }

        if(text === "next" && this.step<this.questions.length){
          this.step++;
        }else if(text === "previous"){
          this.step--;
        }
        this.refreshProgressBar();
        this.question=this.questions[this.step];
      },


      refreshProgressBar: function () {
        let numberAnsweredQuestions = Object.keys(this.answers.answers.questions).length;
        this.progressValue = numberAnsweredQuestions;
      },
      imageLoadError () {
        this.imageShown = false;
      },
      clickAnswer (text) {
        this.step = Number(text)-1
        this.recieveResponse();
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
  .listExperience{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }
  .ECSdescription{
    color: var(--green-primary);
    font-weight: var(--bold-text);
  }
</style>
