import axios from 'axios';

//const url = 'http://localhost:3030/api/';
//const url = 'http://edicityapi.h2793818.stratoserver.net/api/';

export default {
  login(credentials) {
    return axios
      .post('login-front/', credentials)
      .then(response => response.data);
  },
  signUp(credentials) {
    console.log("register", credentials)
    return axios
      .post('sign-up-front/', credentials)
      .then(response => response.data);
  },
  recoverPass(credentials) {
    return axios
      .post('recover-pass/', credentials)
      .then(response => response.data);
  },
  getSecretContent() {
    return axios.get('secret-route/').then(response => response.data);
  }
};