<template lang="pug">
  .listECS
    b-container.p-0(fluid="true").banner
      b-img(src="/images/banner-myECSprofile.png" fluid alt="Fluid image;")
      div.bannerTitle
        p {{ $t('menu.profiles.myEcsProfiles') }}
    b-container.pl-4.pr-4.pb-4.profile(fluid="true")
      b-row(align-v="end")
        b-col
        b-col(cols="2")
          b-button.buttonGreen(pill @click="$router.push('/new-ecs2')" ) {{ $t('menu.profiles.createANewEcs')}}
        b-col(cols="4" v-if="ids_ecs.length > 0")
          DownloadData(:ecs_ids="ids_ecs")
        //- b-col(cols="2")
          span {{$t('buttons.downloadFormat')}}
          b-form-select(v-model="downloadFormat" :options="options")
        //- b-col(cols="2")
          b-button.buttonGreen(pill @click="downloadData(ids_ecs)" v-if="!loading") {{$t('buttons.downloadData')}}
          img(src="images/loading.gif" style="height:30px" v-if="loading")
      br
      b-table(:items="items" :fields="fields" hover)
        template(#head(name)="data")
          span {{$t('list.name')}}
        template(v-slot:cell(buttons)="data")
          //- b-dropdown(id="dropdown-1" size="lg"  variant="btn-outline-secondary" toggle-class="text-decoration-none" text="···")
          //-   b-dropdown-item(:href="'/showEcsProfile/'+data.item.ecs_id") {{ $t('buttons.goProfile') }}
          //-   b-dropdown-item(:href="'/ecs-profile?id='+data.item.ecs_id") {{ $t('buttons.editProfile') }}
          //-   b-dropdown-item(:href="'/assessment/'+data.item.ecs_id") {{ $t('buttons.monitor') }}
          //-   b-dropdown-item-button(@click="removeRow(data.item)" class="remove")
          //-     span(style="color:var(--dark-red)") {{ $t('buttons.remove') }}
          b-button.greenLink.mr-3(variant="link" :href="'/showEcsProfile/'+data.item.ecs_id") {{ $t('buttons.goProfile') }}
          b-button.greenLink.mr-3(variant="link" :href="'/ecs-profile?id='+data.item.ecs_id") {{ $t('buttons.editProfile') }}
          b-button.greenLink.mr-3(variant="link" :href="'/assessment/'+data.item.ecs_id") {{ $t('buttons.monitor') }}
          b-button.greenLink.mr-3(variant="link" :href="'/design-planing/'+data.item.ecs_id") {{ $t('buttons.design') }}
          b-button.greenLink.mr-3(variant="link" @click="downloadExperiences(data.item)") {{ $t('buttons.downloadExperiences') }}
          b-button.redLink.mr-3(variant="link" @click="removeRow(data.item)") {{ $t('buttons.remove') }}
          //- template(v-slot:cell(edit)="data")
          //- template(v-slot:cell(assessment)="data")
          //- template(v-slot:cell(remove_button)="data")
          //- template(v-slot:cell()="data") */
          //-   div {{ data.value }}
</template>

<script>
  import axios from 'axios';
  import XLSX from 'xlsx';
  import DownloadData from './downloadData.vue'

  export default {
    name: "EcsList",
    components: {
      DownloadData
    },
    mounted() {
      let _this = this;
      axios.get('clients/ecs')
        .then(function (response) {
          console.log("Asked response: ",response.data);
          response.data.forEach( item => {
            _this.items.push({
              "ecs_id" : item.id,
              "name": item.name
             })
            _this.ids_ecs.push(item.id)
          });
        })
        .catch(function (error) {
          console.log("Menu get client error:", error);
          // console.log(error);
        });
    },
    data(){

      return{
        fields:[
          { key: 'name', label: this.$t('list.name'), sortable: true },
          { key: 'buttons', label: '', tdClass:'buttonCell'}
        ],
        items:[],
        ids_ecs: [],
        downloadFormat: 'xlsx',
        options: [
          //{value: 'csv', text: 'CSV'},
          {value: 'xlsx',text: this.$t('buttons.excelFile')},
          {value: 'json',text: 'JSON'}
        ],
        loading: false
      }
    },
    methods:{
      async removeRow(item){

        console.log(item);
        let _this = this;
        if (!confirm("Are you sure to delete "+item.name+"?")) return
        await axios.post('clients/removeEcs',
          {"id_ecs": item.ecs_id}
        )
          .then(function (response) {
            console.log(response);
            console.log("Done Delete");
            _this.$router.go(0);
          })
          .catch(function (error) {
            console.log("Error getting answers");
            console.log(error);
          });
      },
      downloadExperiences(ecs){
        console.log("ecs", ecs)
        let _this = this;
        axios.post('answers/getExperiencesOfECS', {"id_ecs": ecs.ecs_id})
            .then(function (response) {
              console.log("Experiences: ",response.data);
              if (response.data.warning)
                alert(response.data.warning)
              else {
                _this.downloadExcel(response.data, ecs.name)
              }

            })
            .catch(function (error) {
              console.log("Download Experiences:", error);
              // console.log(error);
            });
      },
      downloadExcel(data, ecsName){
        const wb = XLSX.WorkBook = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, ws, ecsName);
        XLSX.writeFile(wb, ecsName+'.xlsx')
      }
    }
  }
</script>

<style scoped>
  .listECS{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  h5{
    color: var(--green-primary)
  }

  .buttonCell{
    text-align: right !important;
  }

  .greenLink, .greenLink:hover{
    color: var(--green-primary)
  }

  .redLink, .redLink:hover{
    color: var(--dark-red)
  }

</style>
