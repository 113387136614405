<template>
  <path vector-effect="non-scaling-stroke"
        d="M 8.70,16.40
           C 8.70,17.00 9.00,17.60 9.40,18.00
             9.80,18.40 10.30,18.60 10.90,18.60
             10.90,18.60 10.90,18.60 11.00,18.60
             11.00,18.60 13.70,18.50 13.70,18.50
             13.10,20.00 13.10,21.50 13.70,23.20
             15.30,28.00 21.60,32.50 26.50,35.50
             31.90,38.70 37.40,41.00 38.50,41.20
             38.70,41.20 38.80,41.20 39.00,41.20
             39.70,41.20 40.30,41.00 40.70,40.60
             41.20,40.10 41.40,39.30 41.30,38.40
             41.10,37.30 38.90,31.80 35.60,26.40
             32.70,21.50 28.10,15.20 23.30,13.60
             21.50,13.00 19.80,13.00 18.20,13.80
             18.20,13.80 18.10,11.00 18.10,11.00
             18.10,9.70 17.00,8.80 15.80,8.80
             14.60,8.90 13.70,9.80 13.60,10.90
             12.70,10.20 11.30,10.30 10.60,11.20
             10.60,11.20 10.60,11.20 10.60,11.20
             9.80,12.10 9.80,13.30 10.60,14.20
             9.40,14.30 8.60,15.30 8.70,16.40 Z
           M 12.80,12.10
           C 12.80,12.10 16.10,15.10 16.10,15.10
             15.70,15.50 15.30,15.90 14.90,16.40
             14.90,16.40 11.60,13.40 11.60,13.40
             11.30,13.10 11.20,12.50 11.60,12.20
             11.90,11.70 12.50,11.70 12.80,12.10 Z
           M 16.70,11.00
           C 16.70,11.00 16.80,13.80 16.80,13.80
             16.80,13.80 15.00,12.20 15.00,12.20
             15.00,12.20 15.00,11.10 15.00,11.10
             15.00,10.60 15.30,10.20 15.80,10.20
             16.30,10.20 16.70,10.60 16.70,11.00 Z
           M 16.80,16.60
           C 18.20,15.20 19.50,14.60 21.10,14.60
             21.70,14.60 22.40,14.70 23.10,14.90
             24.50,15.40 26.00,16.40 27.40,17.60
             27.40,17.60 23.30,21.70 23.30,21.70
             23.30,21.70 24.30,22.70 24.30,22.70
             24.30,22.70 28.40,18.60 28.40,18.60
             29.90,20.10 31.20,21.80 32.50,23.60
             32.50,23.60 30.50,25.60 30.50,25.60
             30.50,25.60 31.50,26.60 31.50,26.60
             31.50,26.60 33.30,24.80 33.30,24.80
             33.80,25.60 34.20,26.40 34.70,27.10
             35.60,28.70 36.50,30.20 37.20,31.60
             37.20,31.60 36.40,32.40 36.40,32.40
             36.40,32.40 37.40,33.40 37.40,33.40
             37.40,33.40 37.90,32.90 37.90,32.90
             39.30,35.80 40.20,38.00 40.20,38.50
             40.30,39.10 40.10,39.40 40.00,39.50
             39.90,39.60 39.60,39.70 38.90,39.70
             38.20,39.60 33.00,37.60 27.40,34.30
             27.20,34.20 27.10,34.10 26.90,34.00
             26.90,34.00 27.90,33.00 27.90,33.00
             27.90,33.00 26.90,32.00 26.90,32.00
             26.90,32.00 25.70,33.20 25.70,33.20
             24.50,32.40 23.30,31.60 22.10,30.70
             22.10,30.70 25.30,27.50 25.30,27.50
             25.30,27.50 24.30,26.50 24.30,26.50
             24.30,26.50 21.00,29.80 21.00,29.80
             18.40,27.60 16.10,25.20 15.30,22.70
             14.30,20.50 14.80,18.50 16.80,16.60 Z
           M 10.10,16.40
           C 10.10,15.90 10.40,15.50 10.90,15.50
             10.90,15.50 12.00,15.50 12.00,15.50
             12.00,15.50 13.80,17.10 13.80,17.10
             13.80,17.10 11.00,17.20 11.00,17.20
             10.80,17.20 10.50,17.20 10.40,17.00
             10.20,16.90 10.10,16.70 10.10,16.40 Z" />
</template>

<script>
  export default {
    name: "MyEcsIcon"
  }
</script>

<style scoped>

</style>