import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

import AuthService from '@/services/AuthService.js';

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    token: '',
    user: {},
    title: 'Home',
    language: null
  };
};

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    },
    getTitle: state => {
      return state.title;
    },
    getLanguage: state => {
      return state.language;
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    },
    SET_TITLE: (state, title) => {
      state.title = title;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
    }
  },
  actions: {
    login: ({ commit, dispatch }, { token, user }) => {
      commit('SET_TOKEN', token);
      commit('SET_USER', user);
      // set auth header
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit('RESET', '');
    },
    changeLanguage: ({ commit, dispatch}, { language }) => {
      commit('SET_LANGUAGE', language);
    },
    updateUser: ({ commit, dispatch}, { user }) => {
      commit('SET_USER', user);
    },
  }
})
