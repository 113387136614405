<template lang="pug">
  .about_content.generalBackground
    b-container(fluid="true" v-if="dataInd")
      b-alert(v-model="noData" variant="warning" style="text-align:center;") {{ $t('assessment.indicators.noData')}}
      b-row(v-if="dataInd.landUse && dataInd.landUse.totalArea")
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.landUse icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.landUse) {{ $t('assessment.indicators.landUse') }}
          b-collapse.collapsed(id="landUse" visible)
            b-row
              b-col
                table
                  thead
                    th
                    th {{ $t('assessment.indicators.surface' )}}
                    th {{ $t('assessment.indicators.percentage' )}}
                  tr(v-if="dataInd.landUse.totalArea")
                    td.bold {{ $t('assessment.indicators.totalArea' )}}
                    td.result {{dataInd.landUse.totalArea.result}}
                    td.result 100
                  tr(v-if="dataInd.landUse.edibleArea")
                    td.bold {{$t('assessment.indicators.edibleArea')}}
                    td.result {{dataInd.landUse.edibleArea.result}}
                    td.result(v-if="dataInd.landUse.percEdibleArea") {{dataInd.landUse.percEdibleArea.result}}
                  tr(v-if="dataInd.landUse.greenArea")
                    td.bold {{$t('assessment.indicators.greenArea')}}
                    td.result {{dataInd.landUse.greenArea.result}}
                    td.result(v-if="dataInd.landUse.percGreenArea") {{dataInd.landUse.percGreenArea.result}}
              b-col(v-if="getAreaData")
                Donut.m-3(:chartData="getAreaData" :options="donutOptions")
      //b-row(v-if="dataInd.participation && dataInd.participation.total>0")
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.participation icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.participation) {{ $t('assessment.indicators.participation') }}
          b-collapse.collapsed(id="participation" visible)
            b-row
              b-col
                table
                  thead
                    th
                    th {{ $t('assessment.indicators.total' )}}
                    th {{ $t('assessment.indicators.women' )}}
                    th {{ $t('assessment.indicators.men' )}}
                    th {{ $t('assessment.indicators.femaleratio' )}}
                  tr
                    td.bold {{ $t('assessment.indicators.totalparticipants' )}}
                    td.result {{dataInd.participation.total}}
                    td.result {{dataInd.participation.female}}
                    td.result {{dataInd.participation.male}}
                    td.result {{dataInd.participation.femaleRatio}}
                  tr
                    td.bold {{$t('assessment.indicators.employees')}}
                    td.result {{dataInd.participation.femaleEmployees + dataInd.participation.maleEmployees}}
                    td.result {{dataInd.participation.femaleEmployees}}
                    td.result {{dataInd.participation.maleEmployees}}
                    td.result(v-if="dataInd.participation.maleEmployees>0") {{ Math.round(dataInd.participation.femaleEmployees / dataInd.participation.maleEmployees *100) / 100 }}
                  tr
                    td.bold {{$t('assessment.indicators.volunteers')}}
                    td.result {{dataInd.participation.femaleVolunteers + dataInd.participation.maleVolunteers}}
                    td.result {{dataInd.participation.femaleVolunteers}}
                    td.result {{dataInd.participation.maleVolunteers}}
                    td.result(v-if="dataInd.participation.maleVolunteers>0") {{ Math.round(dataInd.participation.femaleVolunteers / dataInd.participation.maleVolunteers *100) / 100 }}
                  tr
                    td.bold {{$t('assessment.indicators.visitors')}}
                    td.result {{dataInd.participation.femaleVisitors + dataInd.participation.maleVisitors}}
                    td.result {{dataInd.participation.femaleVisitors}}
                    td.result {{dataInd.participation.maleVisitors}}
                    td.result(v-if="dataInd.participation.maleVolunteers>0") {{ Math.round(dataInd.participation.femaleVolunteers / dataInd.participation.maleVolunteers *100) / 100 }}
              b-col(v-if="getParticipationData")
                Bar.m-3(:chartData="getParticipationData" :options="barOptions")
      b-row(v-if="dataInd.products && dataInd.products.nEdibleProducts > 0")
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.products icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.products) {{ $t('assessment.indicators.productsActivities') }}
          b-collapse.collapsed(id="products" visible)
            b-row
              b-col
                table
                  tr
                    td.bold(style="color:var(--green-primary)") {{ $t('assessment.indicators.nEdibleProducts') }}
                    td.result {{dataInd.products.nEdibleProducts}}
                br
                table(v-if="dataInd.products.type")
                  thead
                    th {{ $t('assessment.indicators.productsType' )}}
                    th {{ $t('assessment.indicators.percentage' )}}
                  tr
                    td {{ $t('assessment.indicators.plantRaw' )}}
                    td.result {{ dataInd.products.type.plantRaw}}
                  tr
                    td {{ $t('assessment.indicators.animalRaw') }}
                    td.result {{ dataInd.products.type.animalRaw }}
                  tr
                    td {{ $t('assessment.indicators.manufactured' )}}
                    td.result {{ dataInd.products.type.manufactured}}
                  tr
                    td {{ $t('assessment.indicators.noEdible' )}}
                    td.result {{ dataInd.products.type.noEdible}}
                br
                table
                  thead
                    th {{ $t('assessment.indicators.productUses' )}}
                    th {{ $t('assessment.indicators.percentage' )}}
                  tr(v-for="use in dataInd.products.uses" v-if="use.n > 0")
                    td {{ use.text}}
                    td.result {{ use.n }}
                br
                table
                  thead
                    th {{ $t('assessment.indicators.products' )}}
                    th {{ $t('assessment.indicators.production' )}}
                  tr(v-for="product in dataInd.products.productionByProduct")
                    td {{product.product}}
                    td {{ product.yield }} {{ product.unit }}
                br
                table
                  thead
                    th {{ $t('assessment.indicators.totalProduction' )}}
                    th
                  tr(v-for="unit in dataInd.products.productionByUnit" v-if="unit.value>0")
                    td {{ unit.text }}
                    td {{ unit.value}}
                br
                table
                  thead
                    th {{ $t('assessment.indicators.yieldArea' )}}
                  tr(v-for="area in dataInd.products.yieldArea" v-if="area.value > 0")
                    td {{ area.text}}
                    td {{ area.value }}
                  tr(v-for="area in dataInd.products.yieldArea" v-if="!area.value > 0")
                    td {{ area.text}}
                    td {{ $t('assessment.indicators.noData' )}}
              b-col
                Donut.m-3(:chartData="getProductsData" :options="donutOptions" v-if="getProductsData")
                br
                Donut.m-3(:chartData="getUsesData" :options="donutOptions" v-if="getUsesData")


</template>

<script>
  import axios from 'axios';
  import Donut from "./vue-charts/donut.vue";
  import Bar from "./vue-charts/bar.vue";

  export default {
    name: "indicators",
    components: {
      Donut,
      Bar
    },
    props: {
      id_ecs: Number,
      public: Boolean
    },
    data: function () {
      return {
        dataInd: {init: 'empty'},
        donutOptions: {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            animateRotate: false
          },
          legend: {
            fullWidth: false,
            labels: {fontSize: 14}
          }

        },
        barOptions: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            fullWidth: false,
            labels: {fontSize: 14}
          }
        },
        test: true
      }
    },
    mounted: function(){
      if (!this.$store.getters.getLanguage || this.$store.getters.getLanguage.lang == "en"){
        var lang = "text_1"
      } else  {
        var lang = "text_"+this.$store.getters.getLanguage.lang
      }
      let _this = this;
      // axios.post('answers/getECSIndicators',
      //     {"id_ecs": _this.id_ecs, "lang": lang}
      //     )
      //     .then(function (response){
      //       console.log("dataInd: ", response);
      //       _this.dataInd = response.data;
      //     })
      //     .catch(function (error) {
      //       console.log("Error getting data");
      //       console.log(error);
      //     })
      //     .finally(function (){
      //       console.log("dataInd loaded")
      //     });
    },
    computed: {
      getAreaData: function (){
        if (this.dataInd.landUse && this.dataInd.landUse.percGreenArea && this.dataInd.landUse.percEdibleArea){
          return {
            datasets: [
              {
                data: [
                  this.dataInd.landUse.percGreenArea.result,
                  0,
                  100 - this.dataInd.landUse.percGreenArea.result
                ], //Edible area = 0
                backgroundColor: ['#5d8f49','#c51e23', '#706f6f']
              },
              {
                data: [
                  0,
                  this.dataInd.landUse.percEdibleArea.result,
                  100 - this.dataInd.landUse.percEdibleArea.result
                ], // Green area = 0
                backgroundColor: ['#5d8f49','#c51e23', '#706f6f']
              }
            ],
            labels: [this.$t('assessment.indicators.greenArea' ), this.$t('assessment.indicators.edibleArea' ), this.$t('assessment.indicators.other' )],
          }
        } else { return false}
      },
      getParticipationData: function (){
        if (this.dataInd.participation){
          return {
            datasets: [
              {
                label: this.$t('assessment.indicators.women'),
                data: [
                  this.dataInd.participation.female,
                  this.dataInd.participation.femaleEmployees,
                  this.dataInd.participation.femaleVolunteers,
                  this.dataInd.participation.femaleVisitors
                  ],
                backgroundColor: '#5d8f49'
              },
              {
                label: this.$t('assessment.indicators.men'),
                data: [
                  this.dataInd.participation.male,
                  this.dataInd.participation.maleEmployees,
                  this.dataInd.participation.maleVolunteers,
                  this.dataInd.participation.maleVisitors
                ], // Green area = 0
                backgroundColor: '#c51e23'
              }
            ],
            labels: [
              this.$t('assessment.indicators.total' ),
              this.$t('assessment.indicators.employees' ),
              this.$t('assessment.indicators.volunteers' ),
              this.$t('assessment.indicators.visitors' ),
            ],
          }
        } else { return false}
      },
      getProductsData: function (){
        if (this.dataInd.products && this.dataInd.products.type){
          return {
            datasets: [
              {
                data: [
                  this.dataInd.products.type.plantRaw,
                  this.dataInd.products.type.animalRaw,
                  this.dataInd.products.type.manufactured,
                  this.dataInd.products.type.noEdible,
                ], //Edible area = 0
                backgroundColor: ['#5d8f49','#c51e23', '#fff129', '#1700e6']
              }
            ],
            labels: [
              this.$t('assessment.indicators.plantRaw'),
              this.$t('assessment.indicators.animalRaw'),
              this.$t('assessment.indicators.manufactured'),
              this.$t('assessment.indicators.noEdible'),
            ],
          }
        } else {return false}
      },
      getUsesData: function (){
        if (this.dataInd.products && this.dataInd.products.uses){
          return {
            datasets: [
              {
                data: [
                  this.dataInd.products.uses['51_1596192510976_1603208237848'].n,
                  this.dataInd.products.uses['51_1596192510976_1596192669768'].n,
                  this.dataInd.products.uses['51_1596192510976_1596192702637'].n,
                  this.dataInd.products.uses['51_1596192510976_1596192723982'].n,
                  this.dataInd.products.uses['51_1596192510976_1606233068378'].n,
                  this.dataInd.products.uses['51_1596192510976_1606233324674'].n
                ], //Edible area = 0
                backgroundColor: ['#5d8f49','#c51e23', '#fff129', '#1700e6', '#e66f00', '#a702c4' ]
              }
            ],
            labels: [
              this.$t('assessment.indicators.self-consumption'),
              this.$t('assessment.indicators.directCommercialization'),
              this.$t('assessment.indicators.exchanging'),
              this.$t('assessment.indicators.donation'),
              this.$t('assessment.indicators.localCommercialization'),
              this.$t('assessment.indicators.distribution')
            ],
          }
        } else {return false}
      },
      noData: function(){
        if ( this.dataInd && this.dataInd.participation
          && !this.dataInd.landUse.totalArea
          && !this.dataInd.participation.total
          && !this.dataInd.products.nEdibleProducts > 0
        ){
            return true
          } else if (this.dataInd.init){
            return true
          }
          else if (this.dataInd && this.dataInd.landUse){
            return false
          }
      }
    },
    methods:{

    }
  }

</script>

<style scoped>

.generalBackground{
  background-color: white;
  border-radius: 0rem;
  padding: 0rem;
  margin: 2rem 2rem 0;
  min-height: var(--height-child-comp);
}

.coll-button{
  margin-top: 1.5em;
}

table{
  max-width: 90%;
}

table td {
    word-wrap: break-word;         /* All browsers since IE 5.5+ */
    overflow-wrap: break-word;     /* Renamed property in CSS3 draft spec */
}

td, th{
  padding-right: 1em;
}

th{
  color: var(--green-primary);
  padding-bottom: 1em;
}

a, a[type=button] {
  color: var(--green-primary);
  align-self: center;
  font-weight: var(--bold-text);
}
a:hover, a:not([href]):hover, a[type=button]:hover {
  color: var(--dark-red);
  text-decoration: none;
}
a:active, a[type=button]:active{
  color: var(--dark-red);
}

.coll-button{
  color: var(--green-primary);
}

.collapsed > a {
  font-weight: var(--text-weight);
}

.b-icon.bi.collapsed {
  transform: rotate(180deg);
}

.bold{
  font-weight: bold;
}

.result{
  text-align: right;
}

</style>
