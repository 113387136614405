<template lang="pug">
  b-row.text-center.p-4
    b-col
      h4
        span.number {{counter.total}}
        span.word {{ $t('abouts.content_solutions.title') }}
          b-icon(icon="info-circle-fill" v-b-tooltip.hover.bottom="$t('abouts.content_solutions.text.text1')").icon
    b-col
      h4
        span
          img(src="/images/marker_ecs_02.png" height="25px").mr-2
        span.number {{Number(counter.total) - Number(counter['non-interactive'])}}
        span.word {{ $t('edibleMap.edibleCitiesSolutions') }}
          b-icon(icon="info-circle-fill" v-b-tooltip.hover.bottom="$t('edibleMap.advancedSearch.tooltips.interactive')").icon
    b-col
      h4
        span
          img(src="/images/marker_ecs_no_interactive.png" height="25px").mr-2
        span.number {{counter['non-interactive']}}
        span.word {{ $t('edibleMap.non-edibleCitiesSolutions') }}
          b-icon(icon="info-circle-fill" v-b-tooltip.hover.bottom="$t('edibleMap.advancedSearch.tooltips.noInteractive')").icon
</template>

<script>

  import axios from "axios";

  export default {
    name: 'Counter',
    data: function(){
      return {
        counter: {}
      }
    },
    mounted: function() {
      let _this = this;
      axios.post('answers/getCounter')
      .then(function(response){
        console.log("Counter", response.data)
        _this.counter = response.data
      })
      .catch(function(error){
        console.log("getCounter error", error)
      })
    }
  }

</script>

<style scoped>
  .word {
    color: var(--green-primary) !important;
    padding-left: 0.3em;
  }

  .number {
    color: var(--dark-red) !important;
  }

  .icon {
    color: var(--gray-font-color);
    margin-left: 0.4em;
    font-size: 0.8em;
  }
</style>
