<template lang="pug">
    b-row.mt-2.mb-4
        b-col
            b-row(align-v="center")
                b-col
                    h5.mb-0 {{ questionNumber }}. Question:
                b-col(align-self="end")
                    .text-right
                        b-button(variant = "primary" @click="saveToEcs").mr-1 Guardar
                        b-button(v-b-modal.modal_not_answer variant = "secondary") Not answer
            b-row
                b-col(sm="12")
                    label.col-form-label(v-html="stringToLabel(question.config['text_lang_1'])")
                    input.mb-1(v-if="question.config.lines === 'oneline'" :maxlength='question.config.maxchars' :type='question.config.text_type' v-model="answer" :class="'form-control'")
                    textarea.mb-1(v-else-if="question.config.lines === 'multiline'" :maxlength='question.config.maxchars' :type='question.config.text_type'  v-model="answer" :class="'form-control'" )
                    p Hola
</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import axios from 'axios';

  export default {
    name: "questionOpen",
    props: ['question','ecs','questionNumber'],
    mixins: [utilMixin],
    data: function(){
      return{
        answer: ""
      }
    },
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else return "Not defined";
      },
      saveToEcs: function () {
        if(this.ecs) {

          const _this = this;
          console.log(this.ecs);

          // let client_id = req.userData.userId;
          // let question_id = 'id_'+req.body.question_id;
          // let ecs_id = req.body.ecs_id;
          // let category_id = req.body.category_id;
          // let answer = req.body.answer;

          let question_id = this.question.id;
          let category_id = this.question.category_id;
          let answer = this.answer;
          let selected_custom = this.selected_custom;

          axios.post('answers/setAnswerECS',{
            question_id: question_id,
            category_id: category_id,
            ecs_id: _this.ecs,
            answer: answer,
            selected_custom: selected_custom
          })
            .then(function (response) {
              console.log(response);
              console.log("Done Modify question");
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        else{
          console.log("Not allowed to save");
        }
      }
    }
  }
</script>

<style scoped>

</style>