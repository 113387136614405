<template lang="pug">
  b-container
    b-row
      b-col
        b-row.bg-success.p-2
          b-col.align-self-center
            h5.mb-0 {{ questionNumber+1 }}. Question:
          b-col(align-self="end")
            .text-right
              b-button(variant = "primary" @click="saveToProfile").mr-1 Guardar
              b-button(v-b-modal.modal_not_answer variant = "secondary") Not answer
      b-col(sm="12")
        label.col-form-label() {{ stringToLabel(question.config['text_lang_1']) }} {{ answered }}
        b-form-input(:id="'range_'+question.id" v-model="answered" type="range" :min="question.config.minimumvalue" :max="question.config.maxvalue" :step="question.config.step")

</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import axios from 'axios';

  export default {
    name: "questionRange",
    props: ['question', 'questionNumber','answer'],
    data: function () {
      return {
        answered: 2000,
      }
    },
    mixins: [utilMixin],
    mounted: function (){
      console.log("Loading Range question");
      if(this.answer !== undefined){
        this.answered = this.answer.answer;
      }
    },
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else return "Not defined";
      },
      saveToProfile: function () {
        console.log("Save Question: ", this.question.id);
        let _this = this;
        if(this.answered === []){
          console.log("Please respond something");
        }
        else {
          this.$emit('sendResponse', {
            id_question: _this.question.id,
            id_category: _this.question.category_id,
            answer: this.answered});
          axios.post('answers/setClientProfileAnswer', {
            question_id: _this.question.id,
            category_id: _this.question.category_id,
            answer: _this.answered,
          })
            .then(function (response) {
              console.log(response);
              console.log("Done Modify question");
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    }
  }
</script>

<style scoped>

</style>