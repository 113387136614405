<template lang="pug">
  b-container(fluid="true")
    b-container(fluid="true")
      b-container.m-4(fluid="true")
        questionFooter(ref="footer" :questionNumber="questionNumber" :nQuestions="nQuestions" :dataAnswer="answered" @saveData="saveToProfileECS" :ecs_id="ecs_id")
        questionHeader(ref="header" :questionNumber="questionNumber" :nQuestions="nQuestions")
        b-row.p-1
          b-col(sm="12").mb-4
            h6
              label.col-form-label {{selectedLabel}}
            b-form-radio( v-for='answer in question.config.answers' @change="changeEvent" v-model="answered" :name="'radio_form_'+question.id"  :key="answer.internal_id" :id='answer.internal_id' :value="{isCustom: false, answer: answer, answer_custom_text: ''}") {{ getAnswers(answer) }}
            b-form-radio(@change="changeEvent" v-if="question.config.customAnswer === 'true'" v-model="answered.isCustom" :name="'radio_form_'+question.id" :id="'radio_form_'+question.id" :value="true") {{  $t('ecsForm.skip.other') }}
            b-form-input(@change="changeEvent" v-if="question.config.customAnswer === 'true'" :disabled="customEnabled" v-model="answered.answer_custom_text" type="text").form-rounded.my-1
          .col-sm-12.mb-1(v-if="stringToLabel(question.config['text_example_lang_' + getLang]) !== ''")
            p
              button.btn.btn-primary(type='button' data-toggle='collapse' data-target='#collapseExample' aria-expanded='false' aria-controls='collapseExample')
                | Example
            #collapseExample.collapse
              .card.card-body(v-html="stringToLabel(question.config['text_example_lang_' + getLang])")



</template>

<script>
  import axios from 'axios';
  import utilMixin from "../../mixin/utilsMixin";
  import questionHeader from "../questionHeader";
  import questionFooter from "../questionFooter";

  export default {
    name: "questionSingle",
    components: {questionHeader, questionFooter},
    data: function(){
      return{
        selected: "{}",
        selected_custom: "",
        answered: {
          isCustom: false,
          answer: "",
          answer_custom_text: ""
        },
        skip: {
          skipped: false,
          custom: false,
          answer: ""
        },
        firstAnswered: false,
        firstSkipped: false,
        langId: 1
      }
    },
    mounted: function(){
      //console.log('questionSingleProfileECS',this.answer);
      if(this.answer){
        this.answered = this.answer.answer;
        //console.log('skipped',this.answer.skip)
        if(this.answer.skip) {
          this.$refs.footer.skip = this.answer.skip;
          this.skip = {
            skipped: this.$refs.footer.skip.skipped,
            custom: this.$refs.footer.customAnswer(),
            answer: this.$refs.footer.customText()
          }
        }

        let find =  this.question.config.answers.find(element => element === this.answered.answer);

        if(!find && typeof this.answered.answer !== 'undefined')
        {
          find = this.question.config.answers.find(element => element.internal_id === this.answered.answer.internal_id);
          this.answered.answer = find;
        }

      }
      this.firstAnsweredOrSkipped(this);
    },

    watch:{
      answer: function (newVal, oldVal){
        if(newVal){
          this.answered = newVal.answer;

          if(newVal.skip) {
            this.$refs.footer.skip = newVal.skip;
            this.skip = {
              skipped: this.$refs.footer.skip.skipped,
              custom: this.$refs.footer.customAnswer(),
              answer: this.$refs.footer.customText()
            }
          }

          let find =  this.question.config.answers.find(element => element === this.answered.answer);

          if(!find && typeof this.answered.answer !== 'undefined')
          {
            find = this.question.config.answers.find(element => element.internal_id === this.answered.answer.internal_id);
            this.answered.answer = find;
          }
        }
        this.firstAnsweredOrSkipped(this);
      },
      questionNumber: function(newVal,oldVal) {
        if(typeof(this.answer) === 'undefined'){
          this.resetAnswer();
          this.resetSkip();

        }else{
          this.answered = this.answer.answer;
          if(this.hasBeenSkipped(this.answer)) {
            this.$refs.footer.skip = this.answer.skip;
            this.skip= {
              skipped: this.$refs.footer.skip.skipped,
              custom: this.$refs.footer.customAnswer(),
              answer: this.$refs.footer.customText()
            }
          }else{
            this.resetSkip();
          }
        }
        this.firstAnsweredOrSkipped(this)
      }
    },


    computed: {
      selectedLabel: function () {
        let langOpt = this.getLangOptions;
        if(this.selected.answer) {
          if(this.selected.answer.isCustom){
            if(this.question.config['text_lang_' + this.getLang])
              return this.stringToLabel(this.question.config['text_lang_' + this.getLang])+': '+"Other, "+this.selected_custom;
            else
              return this.stringToLabel(this.question.config['text_lang_1'])+': '+"Other, "+this.selected_custom;
          }
          else
            if(this.question.config['text_lang_' + this.getLang])
              return this.stringToLabel(this.question.config['text_lang_' + this.getLang])+': '+ this.selected.answer[langOpt];
            else
              return this.stringToLabel(this.question.config['text_lang_1'])+': '+ this.selected.answer[langOpt];
        }
        else
          if(this.question.config['text_lang_' + this.getLang])
            return this.stringToLabel(this.question.config['text_lang_' + this.getLang])+': ';
          else
            return this.stringToLabel(this.question.config['text_lang_1'])+': ';
      },
      customEnabled: function () {
        return !this.answered.isCustom;
      },
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      },
      getLangOptions: function () {
        let languageId = this.$store.getters.getLanguage.id;
        let language = "";
        switch (languageId) {
          case 2:
            language = "text_cat";
            break;
          case 3:
            language = "text_fr";
            break;
          case 4:
            language = "text_es";
            break;
          case 5:
            language = "text_de";
            break;
          case 6:
            language = "text_pt";
            break;
          default:
            language = "text_"+this.$store.getters.getLanguage.lang;
            break;
        }

        return language;
      }
    },
    props: ['question', 'questionNumber','answer','ecs_id', 'exp_id', 'nQuestions'],
    mixins: [utilMixin],
    methods:{
      changeEvent: function(){
        this.$refs.footer.onChangeEventHandler();
      },
      changeAnswer: function (question) {
        if(this.answered.answer !== `${question.id}_custom`){
          // this.answered.answer_custom_text = "";
          this.$set(this.answered, 'answer_custom_text', "")
        }
      },
      saveToProfileECS: function (text) {

        console.log("Save Question single: ", this.question.id);
        if (this.$refs.footer.skipQuestion()) {
          this.skip.skipped = this.$refs.footer.skip.skipped;
          this.skip.custom = this.$refs.footer.customAnswer();
          this.skip.answer = this.$refs.footer.customText();
        }

        let _this = this;

        if(!_this.hasBeenAnsweredOrSkipped(this) && text==="next"){
          this.$emit('sendResponse', undefined, text);
        }else if(!_this.hasBeenAnsweredOrSkipped(this) && text==="previous"){
          this.$emit('sendResponse', undefined, text);
        } else {
          if(_this.firstAnswered && _this.hasBeenSkipped(_this)){
            _this.resetAnswer();
            _this.save(text)
          }else if(_this.firstSkipped && _this.hasBeenAnswered(_this)){
            _this.resetSkip()
            _this.save(text);
          }else if(_this.hasBeenAnswered(_this) && _this.hasBeenSkipped(_this)){
            this.$emit('sendResponse', undefined, text);
          }else{
            _this.save(text);
          }
        }
      },

      save(text){
        let _this = this;
        this.$emit('sendResponse', {
          id_question: _this.question.id,
          id_category: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip}, text);

        if (this.exp_id) {
          var ecs_exp_id = this.exp_id
          console.log("exp_id:",ecs_exp_id)
        } else {
          var ecs_exp_id = this.ecs_id
        }

        axios.post(this.$parent.url_answer, {
          question_id: _this.question.id,
          ecs_id: ecs_exp_id,
          category_id: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip
        })
          .then(function (response) {
            console.log(response);
            console.log("Done Modify question");

          })
          .catch(function (error) {
            console.log(error);
          });
        if(_this.questionNumber+1 === _this.nQuestions && text == 'next'){
          _this.$refs.footer.showSubmitModal();
        }
      },

      hasBeenAnswered: function (answer) {

        if(answer) return (answer.answered.answer !== "" && !answer.answered.isCustom) || (answer.answered.answer_custom_text !== "" && answer.answered.isCustom);
        return false;
      },

      hasBeenSkipped: function (answer) {
        if (answer && answer.skip) return answer.skip.skipped || answer.skip.custom;
        return false;
      },

      resetSkip: function () {
        this.$refs.footer.resetModal();
        this.skip = {
          skipped: "",
          custom: false,
          answer: ""
        }
      },

      hasBeenAnsweredOrSkipped: function (answer) {
        return this.hasBeenSkipped(answer) || this.hasBeenAnswered(answer);
      },

      firstAnsweredOrSkipped: function (answer) {
        this.firstAnswered = this.hasBeenAnswered(answer);
        this.firstSkipped = this.hasBeenSkipped(answer);
      },
      resetAnswer: function () {
        this.answered.isCustom = false;
        this.answered.answer = "";
        this.answered.answer_custom_text = "";
      },
      getAnswers: function (answer) {
        if(answer[this.getLangOptions])
          return answer[this.getLangOptions];
        else
          return answer['text_1'];
      }
    }
  }
</script>

<style scoped>
  .questions{
    background-color: var(--light-gray-secondary);
  }
  .form-rounded {
    border-radius: 1rem;
  }
</style>
