import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home.vue'
import Menus from '../views/Menus.vue'
import SignUp from "../views/SignUp.vue";
import SendRecoverPasswordEmail from "../views/SendRecoverPasswordEmail.vue";
import Login from "../views/Login.vue";
import Categories from "../views/admin/Categories"
import Category from "../components/Category"
import Profile from "../components/Profile"
import EcsProfile from "../components/EcsProfileNew"
import ProfileEcs from "../components/EcsProfile"
import EcsExpirience from "../components/EcsExpirience"
import AddEcsExpirience from "../components/AddNewExpirience"
import CreateEcsProfile from "../components/CreateNewECS"
import CreateEcsProfile2 from "../components/CreateNewECS2"
import EcsList from "../components/EcsList"
import EcsExperienceList from "../components/EcsExperienceList";
import DesignPlaning from "@/components/DesignPlaning";
import MarketPlace from "@/components/MarketPlace";
import Assessment from "@/components/assessment/Assessment";
import AboutToolbox from "@/components/about/toolbox";

import ShowECSProfile from "@/components/showProfile/showECSProfile";

import AboutContent from "@/components/about/content";
import AboutContentSolutions from "@/components/about/content_solutions";
import AboutContentPlan from "@/components/about/content_plan";
import AboutContentProjects from "@/components/about/content_projects";

import AboutShareKnowledge from "@/components/about/share_knowledge"
import AboutShareKnowledgeCreateProfile from "@/components/about/share_knowledge_create_profile"
import AboutShareKnowledgeCreateExpirience from "@/components/about/share_knowledge_create_expirience"

import AboutFunctionalities from "@/components/about/functionalities";
import AboutFunctionalitiesSearch from "@/components/about/functionalities_search";
import AboutFunctionalitiesAssessment from "@/components/about/functionalities_assessment";
import AboutFunctionalitiesPlan from "@/components/about/functionalities_plan";
import AboutClassification from "@/components/about/classification";

import AboutUnlock from "@/components/about/unlock";

import NotFound from "../views/not_found_404.vue";
import WhatEcsAre from "../views/what_ecs_are";
import EdibleMap from "@/components/home/EdibleMap.vue";
import ReportBugs from "@/components/reportBugs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Menus,
    children: [
      {
        path: '',
        component: Home
      },
      {
        path: 'profile',
        component: Profile
      },
      {
        path: 'ecs-lists',
        component: EcsList
      },
      {
        path: 'ecs-profile',
        component: ProfileEcs
      },
      {
        path: 'old-ecs-profile',
        component: EcsProfile
      },
      {
        path: 'ecs-experience',
        component: EcsExpirience
      },
      {
        path: 'add-ecs-experience',
        component: AddEcsExpirience
      },
      {
        path: 'new-ecs/:id',
        component: CreateEcsProfile
      },
      {
        path: 'new-ecs',
        component: CreateEcsProfile
      },
      {
        path: 'new-ecs2',
        component: CreateEcsProfile2
      },
      {
        path: 'biz',
        component: MarketPlace
      },
      {
        path: 'design-planing',
        component: DesignPlaning
      },
      {
        path: 'design-planing/:id',
        component: DesignPlaning
      },
      {
        path: "/assessment",
        name: 'assessment',
        component: Assessment
      },
      {
        path: "/assessment/:id",
        name: 'assessment',
        component: Assessment
      },
      {
        path: '/category/:id',
        component: Category
      },
      {
        path: "/login",
        name: "login",
        component: Login
      },
      {
        path: "/what-ecs-are",
        name: "what-ecs-is",
        component: WhatEcsAre
      },
      { path: "/about_toolbox", name: "about-toolbox", component: AboutToolbox},
      { path: "/about_content", name: "about-content", component: AboutContent},
      { path: "/about_content_ecs", name: "about_content_ecs", component: AboutContentSolutions},
      { path: "/about_content_master_plans", name: "about_content_master_plans", component: AboutContentPlan},
      { path: "/about_content_food_projects", name: "about_content_food_projects", component: AboutContentProjects},
      { path: "/about_content_best_practices", name: "about_content_best_practices", component: null},
      { path: "/share_knowledge", name: "share_knowledge", component: AboutShareKnowledge},
      { path: "/share_knowledge_create_profile", name: "share_knowledge_create_profile", component: AboutShareKnowledgeCreateProfile},
      { path: "/share_knowledge_create_expirence", name: "share_knowledge_create_expirence", component: AboutShareKnowledgeCreateExpirience},
      { path: "/share_knowledge_add_plan", name: "share_knowledge_add_plan", component: null},
      { path: "/share_knowledge_add_project", name: "share_knowledge_add_project", component: null},
      { path: "/share_knowledge_add_practice", name: "share_knowledge_add_practice", component: null},
      { path: "/about_functionalities", name: "about_functionalities", component: AboutFunctionalities},
      { path: "/about_functionalities_search", name: "about_functionalities_search", component: AboutFunctionalitiesSearch},
      { path: "/about_functionalities_assessment", name: "about_functionalities_assessment", component: AboutFunctionalitiesAssessment},
      { path: "/about_functionalities_design", name: "about_functionalities_design", component: AboutFunctionalitiesPlan},
      { path: "/unlock_tools", name: "unlock_tools", component: AboutUnlock},
      { path: "/about_classification", name: "about_classification", component: AboutClassification},
      { path: "/report_bugs", name: "report_bugs", component: ReportBugs},

      { path: "/showEcsProfile/:id", name: "showEcsProfile", component: ShowECSProfile},
      {
        path: 'ecs-experience-list',
        component: EcsExperienceList
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: SignUp
      },
      {
        path: "/recover-password/:token?",
        name: "recover-password",
        component: SendRecoverPasswordEmail
      }
    ]
  },
  {
    path: '/map',
    component: EdibleMap,
    children: []
  },
  {
    path: '/showEcsProfile-alone/:id',
    component: ShowECSProfile,
    children: []
  },
  {
    path: '/admin',
    component: Menus,
    children: [{
      path: '',
      component: Home
    }]
  },
  {
    path: '/admin',
    component: Menus,
    children: [{
      path: 'categories',
      component: Categories
    }]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },

  { path: "*",
    name: "404",
    component: Menus,
    children: [{
      path: "*",
      component: NotFound
    }]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
export default router
