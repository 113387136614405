<template lang="pug">
  .showProfile
    b-container(fluid).pl-5.pr-5.pt-4
      //b-row
        //b-col(cols="12").p-0
          //b-container(fluid).whiteBack
        //b-col(cols="12").p-0
          //b-container(fluid).greenBack
        //b-col(cols="12").p-0
          //b-container(fluid).whiteBack
      b-row

      b-row.bg-white
        b-col(cols="12")
          b-row
            b-col(cols="12").pt-4.greenBack
              p.pl-3.pr-3.text-right.m-0
                //b-button(pill variant="outline-light" size="sm") FOLLOW
                //b-button.ml-4(:href="'/new-ecs/'+$route.params.id" pill variant="light" size="sm" class="outlineButton")  {{$t('ecs_profile.shareExperience')}}
          b-row
            b-col.pl-5.pr-5
              b-row.pt-4
                b-col(cols="2")
                  b-img(fluid center style="object-fit: cover; width: 10.6vw; height: 10.6vw;" :src="'https://edicitnet.icra.cat/images/'+ecs_data.logo" onerror="this.onerror=null;this.src='https://toolbox.edicitnet.com/images/addImage.png';" rounded="circle")
                b-col(cols="10")
                  b-row
                    b-col(cols="9")
                      h2.pl-3.pt-4.text-white
                        b {{ ecs_data.name }}
                        b-button.ml-4(v-if="isOwner" class="outlineButton" :href="'/ecs-profile?id='+$route.params.id" pill variant="light" size="sm") {{$t('buttons.editProfile')}}
                        b-button.ml-4(v-if="isOwner" class="outlineButton" :href="'/design-planing/'+$route.params.id" pill variant="light" size="sm") {{$t('buttons.design')}}
                        b-button.ml-4(:href="'/new-ecs/'+$route.params.id" pill variant="light" size="sm" class="outlineButton")  {{$t('ecs_profile.shareExperience')}}

                  b-row.pt-4
                    b-col
                      span.pl-3.pt-4.textBlack <b>0</b> {{$t('ecs_profile.followers')}}   |   <b>{{ ecs_data.numberOfExperience }}</b> {{$t('ecs_profile.experiences')}}   |   <b>{{ ecs_data.participants }}</b> {{$t('ecs_profile.participants')}}
                  b-row.pt-4
                    b-col.pl-3
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.nonEdible')" v-if="ecs_data.icons.nonEdible" :src="iconNonEdible" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.rawPlant')"  v-if="ecs_data.icons.rawPlant" :src="iconRawPlant" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.rawAnimal')" v-if="ecs_data.icons.rawAnimal" :src="iconRawAnimal" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.processed')" v-if="ecs_data.icons.processed" :src="iconProcessedEdibleGoods" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.selfConsumption')" v-if="ecs_data.icons.self_consumption" :src="iconSelfConsumption" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.commercialization')" v-if="ecs_data.icons.commercialitzation" :src="iconCommercialitzation" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.donation')" v-if="ecs_data.icons.donation" :src="iconDonation" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.exchange')" v-if="ecs_data.icons.exchange" :src="iconExchange" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.workshops')" v-if="ecs_data.icons.educational" :src="iconWorkshop" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.cattering')" v-if="ecs_data.icons.cooking" :src="iconCanteen" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.therapeutic')" v-if="ecs_data.icons.therapeutic" :src="iconTherapeutic" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.research')" v-if="ecs_data.icons.research" :src="iconResearch" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.markets')" v-if="ecs_data.icons.markets_festivals" :src="iconMarkets" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.voluntering')" v-if="ecs_data.icons.volunteer" :src="iconVoluntering" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.discussions')" v-if="ecs_data.icons.discussions" :src="iconOpenDiscussions" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.recovery')" v-if="ecs_data.icons.recovery" :src="iconRecoveryProgram" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.harvesting')" v-if="ecs_data.icons.planting" :src="iconHarvesting" width="40")
                      b-img.ml-2(v-b-tooltip.hover.bottom="{ customClass: 'myTooltipClass'}" :title="$t('ecs_profile.iconsTitles.manufacturing')" v-if="ecs_data.icons.trading" :src="iconManEdibleGoods" width="40")

              b-row(style="min-height: 24px")
                b-col(cols="2").text-center
                  span.littleText.textBlack.boldText {{ ecs_data.typeOfECS }}
              b-row(style="min-height: 24px" )
                b-col(cols="2").text-center
                  span.littleText.textBlack.boldText(v-if="ecs_data.year !== '' && ecs_data.year !== 0") {{ ecs_data.year }}
        b-col.pl-5.pr-5
          b-row
            b-col(cols="9").pl-1.greyBackground
              b-container.p-4
                b-row
                  b-col
                    h5.boldTitle {{ $t('ecs_profile.description.title') }}
                b-row
                  b-col(cols="7")
                    div(v-if="ecs_data.creatorId === '9b233a5f-0750-489d-bf9f-79b24614b901'")
                        p <b>Source: </b>
                          a(style="color: grey" target="_blank" :href="ecs_data.moreInfo") Naturvation project (2017) Urban Nature Atlas: A Database of Nature-Based Solutions Across 100 European Cities
                    p(v-html="ecs_data.description")
                    a.mt-2.text-dark(v-b-toggle="'reference'" v-if="ecs_data.moreInfo !== ''" size="sm") See references
                    b-collapse(id="reference")
                      p(v-if="ecs_data.reference !== ''") {{ ecs_data.reference }}
                      //a(v-if="ecs_data.moreInfo !== ''" target="_blank" :href="ecs_data.moreInfo" ) Read more

                  b-col(cols="5").littleText

                    b-row.pt-1(v-if="ecs_data.iniciators.length > 0")
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{ $t('profile.iniciators') }}
                      b-col(cols=6).expand

                        b-row(v-for="iniciator in ecs_data.iniciators" :key="iniciator")
                          b-col(cols="12").text-truncate
                            span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="iniciator==$t('profile.noData')" :title="iniciator") {{ iniciator }}
                    b-row.pt-1(v-if="ecs_data.landlordOwner.length > 0")
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{$t('ecs_profile.description.landlordOwner.title') }}
                      b-col(cols=6).expand

                        b-row(v-for="owner in ecs_data.landlordOwner" :key="owner")
                          b-col(cols="12").text-truncate
                            span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="owner==$t('profile.noData')" :title="owner") {{ owner }}

                    b-row.pt-1(v-if="ecs_data.ecsOwner.length > 0")
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{$t('ecs_profile.description.ecsOwner.title') }}
                      b-col(cols=6)

                        b-row(v-for="owner in ecs_data.ecsOwner" :key="owner")
                          b-col(cols="12").text-truncate
                            span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="owner==$t('profile.noData')" :title="owner") {{ owner }}
                    b-row.pt-1(v-if="ecs_data.decisionMaking.length > 0")
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{$t('ecs_profile.description.decisionMaking.title') }}
                      b-col(cols=6)

                        b-row(v-for="decision in ecs_data.decisionMaking" :key="decision")
                          b-col(cols="12").text-truncate
                            span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="decision==$t('profile.noData')" :title="decision") {{ decision }}
                    //b-row.pt-1
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{$t('ecs_profile.description.since.title') }}
                      b-col(cols=6)

                        span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :title="ecs_data.year") {{ ecs_data.year }}
                    b-row.pt-1(v-if="ecs_data.type_founding.length > 0")
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{$t('ecs_profile.description.type_funding.title') }}
                      b-col(cols=6)

                        b-row(v-for="founding in ecs_data.type_founding" :key="founding").textBlack
                          b-col(cols="12").text-truncate
                            span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :title="founding") {{ founding }}
                    b-row.pt-1(v-if="ecs_data.type_marqueting.length > 0")
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{$t('ecs_profile.description.type_marketing.title') }}
                      b-col(cols=6)

                        b-row(v-for="marqueting in ecs_data.type_marqueting" :key="marqueting").marqueting
                          b-col(cols="12").text-truncate
                            span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="marqueting==$t('profile.noData')" :title="marqueting") {{ marqueting }}

                    b-row.pt-1
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{$t('ecs_profile.description.initial_budget.title') }}
                      b-col(cols=6)
                        span.textBlack {{ Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(ecs_data.initial_budged) }}
                    b-row
                      b-col(cols=6).textBlack.text-right
                        span.font-weight-bold {{$t('ecs_profile.description.running_costs.title') }}
                      b-col(cols=6)
                        span.textBlack(v-if="ecs_data.running_costs != 0") {{ Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(ecs_data.runing_costs) }}/{{$t('profile.year')}}
                    b-row(v-if="ecs_data.access")
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{$t('ecs_profile.description.access.title') }}
                      b-col(v-if="ecs_data.access" cols=6)
                        span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="ecs_data.access==$t('profile.noData')" :title="ecs_data.access")  {{ecs_data.access}} 123
                    //b-row.pt-1
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{ $t('profile.surface') }}
                      b-col(cols=6).expand
                        b-row
                          b-col(cols="12").text-truncate
                            span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" v-if="ecs_data.surface!=0" :title="ecs_data.surface") {{ ecs_data.surface }}
                    b-row.pt-1(v-if="ecs_data.stage")
                      b-col(cols=6).text-right
                        span.font-weight-bold.textBlack {{ $t('profile.stage') }}
                      b-col(cols=6).expand
                        b-row
                          b-col(cols="12").text-truncate
                            span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="ecs_data.stage==$t('profile.noData')" :title="ecs_data.stage") {{ ecs_data.stage }}

                .contentInteractive(v-if="ecs_data.creatorId !== '9b233a5f-0750-489d-bf9f-79b24614b901'")
                  b-row.pt-3
                    b-col(cols="6" v-if="ecs_data.products.length > 0")
                      b-row
                        b-col
                          h5.boldTitle {{ $t('ecs_profile.products.title') }}

                      b-row(v-for="product in ecs_data.products" :key="product.product")
                        b-col(cols="12").text-truncate
                          span.textBlack.pr-2(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}"  :title="product.months_string") {{ product.product }}

                    b-col(cols="6"  v-if="ecs_data.productionMethods.length > 0").pt-3
                      b-row
                        b-col
                          h5.boldTitle {{ $t('ecs_profile.productionMethods.title') }}

                      b-row(v-for="ens in ecs_data.productionMethods" :key="ens")
                        b-col(cols="12").text-truncate
                          span.textBlack.pr-2( v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="ens==$t('profile.noData')" :title="ens") {{ ens }}
                  b-row.pt-3
                    b-col(cols="6" v-if="ecs_data.activities.length > 0")
                      b-row
                        b-col
                          h5.boldTitle {{ $t('ecs_profile.activities.title') }}

                      b-row(v-for="activity in ecs_data.activities" :key="activity.activity")
                        b-col(cols="12").text-truncate
                          span.textBlack.pr-2(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="activity.activity==$t('profile.noData')" :title="activity.months_string") {{ activity.activity }}

                    b-col(cols="6"  v-if="ecs_data.network.length > 0").pt-3
                      b-row
                        b-col
                          h5.boldTitle(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :title="ecs_data.contribution_tooltip") {{ $t('ecs_profile.network.title') }}

                      b-row(v-for="(partner,key) in ecs_data.network" :key="key")
                        b-col(cols="12").text-truncate
                          span.textBlack(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" v-if="typeof partner !== 'object'" :disabled="partner==$t('profile.noData')" :title="ecs_data.network_tooltip[key]") {{ partner }}
                          a.textBlack( :href="'/showEcsProfile/'+partner.code"  v-else="typeof partner === 'object'") {{ partner.name }}
                            b-img(v-if="partner.interactive" src="/images/marker_ecs_02.png").network-icon
                            b-img(v-else src="/images/marker_ecs_no_interactive.png").network-icon
                  b-row.pt-3
                    b-col(cols="6"  v-if="ecs_data.edibleNatureSolutions.length > 0")
                      b-row
                        b-col
                          h5.boldTitle {{ $t('ecs_profile.edibleNatureSolutions.title') }}

                      b-row(v-for="ens in ecs_data.edibleNatureSolutions" :key="ens")
                        b-col(cols="12").text-truncate
                          span.textBlack.pr-2(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="ens==$t('profile.noData')" :title="ens") {{ ens }}

                    b-col(cols="6"  v-if="ecs_data.nonEdibleSolutions.length > 0").pt-3
                      b-row
                        b-col
                          h5.boldTitle {{ $t('ecs_profile.nonEdibleSolutions.title') }}

                      b-row(v-for="(ens,key) in ecs_data.nonEdibleSolutions" :key="key")
                        b-col(cols="12").text-truncate
                          span.textBlack.pr-2(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}"  :disabled="ens==$t('profile.noData')" :title="ens") {{ ens }}
                  b-row.pt-3
                    b-col(cols="12" v-if="ecs_data.benefits_text")
                      b-row
                        b-col
                          h5.boldTitle {{ $t('profile.benefits') }}

                      b-row
                        b-col(cols="12")
                          span.textBlack.pr-2(v-b-tooltip.hover="{ customClass: 'myTooltipClass'}" :disabled="ecs_data.benefits_text==$t('profile.noData')" :title="ecs_data.benefits_text") {{ecs_data.benefits_text}}


            b-col(cols="3")
              b-container.pt-4.pl-3.pr-3.pb-0
                b-row
                  h5.boldTitle {{ $t('ecs_profile.location.title') }}
                b-row(v-if="ecs_data.location.address")
                  span
                    b-icon-geo-alt-fill.mr-2
                    | {{ ecs_data.location.address }}
                b-row(v-if="ecs_data.location.email")
                  span
                    b-icon-envelope-fill.mr-2
                    | {{ ecs_data.location.email }}

                b-row(v-if="ecs_data.location.coordinates.lat").pt-1.mt-2.mb-2
                  b-img(fluid :src="ecs_data.location.mapURL")
                b-row(v-for="social in ecs_data.social" :key="social.url+social.type").text-center
                  span(v-if="social.type === 'facebook'").mt-2
                    a.mr-2(:href="social.url").textColorGrey
                      img(:src="socialFacebook").socialIcons
                      | facebook
                  span(v-else-if="social.type === 'twitter'").mt-2
                    a.mr-2(:href="social.url").textColorGrey
                      img(:src="socialTwitter").socialIcons
                      | twitter
                  span(v-else-if="social.type === 'instagram'").mt-2
                    a.mr-2(:href="social.url").textColorGrey
                      img(:src="socialInstagram").socialIcons
                      | instagram
                  span(v-else-if="social.type === 'pinterest'").mt-2
                    a.mr-2(:href="social.url").textColorGrey
                      img(:src="socialPinteres").socialIcons
                      | pinterest
                  span(v-else-if="social.type === 'web'").mt-2.align-content-center
                    a.mr-2(:href="social.url").textColorGrey
                      b-icon-globe.socialIcons
                      span.m-1 site


      .contentInteractive
        b-row.pt-3.bg-white(v-if="ecs_data.images.length > 0 || isOwner")
          b-col(style="padding-left: 2rem !important; min-height: 200px").pl-5.pr-5
            b-row.mr-0.ml-0
              b-col(cols="12").pr-0.pl-0
                b-container(fluid)
                  b-row
                    b-col(cols="12" style="white-space: nowrap; overflow-x:auto")
                      b-row.flex-nowrap
                        div(style="position: relative" v-for="image in ecs_data.images" :key="image.id").pl-0.pr-2
                          //b-img(src="https://image.freepik.com/free-photo/female-hand-holding-tomato-organic-farm_1150-6775.jpg" height="200")
                          b-img(:src="'http://edicitnet.icra.cat/imagesProfiles/'+image.filename" height="200")
                          b-icon(v-if="isOwner" style="position: absolute; top:10px; left: 10px" variant="danger" icon="trash-fill" @click="deleteImage(image)")
                        div.pl-1.text-center(v-if="ecs_data.images.length < 5")
                          input(type="file" accept="image/*" hidden ref="fileinput" @change="addImage($event)")
                          b-button(v-if="isOwner" @click="openFileSelect") {{$t('ecs_profile.addImage')}}
                  //div
                    b-img(src="https://via.placeholder.com/150")
                  //VueSlickCarousel( v-bind="slickSettings" )
                    div.pl-1
                      b-img(src="https://via.placeholder.com/150" height="200")
                    div.pl-1
                      b-img(src="https://via.placeholder.com/300x150" height="200")
                    div.pl-1
                      b-img(src="https://via.placeholder.com/500" height="200")
                    div.pl-1
                      b-img(src="https://via.placeholder.com/150" height="200")
                    div.pl-1
                      b-img(src="https://via.placeholder.com/150" height="200")
                    div.pl-1
                      b-img(src="https://via.placeholder.com/150" height="200")
                    div.pl-1
                      b-img(src="https://via.placeholder.com/300x150" height="200")
                    div.pl-1
                      b-img(src="https://via.placeholder.com/500" height="200")
                    div.pl-1
                      b-img(src="https://via.placeholder.com/150" height="200")
                    div.pl-1
                      b-img(src="https://via.placeholder.com/150" height="200")
                    //div
                      b-img(src="https://via.placeholder.com/150")
        b-row.pt-3.pb-3.bg-white
          b-col(cols=12 style="padding-left: 2rem !important").pr-5
            b-tabs.profileTab.homeTab(content-class="mt-3" fill active-nav-item-class="activeTabTitle" nav-class="tabTitle" active )
              b-tab.tabText(v-if="ecs_data.interactive" :title="$t('ecs_profile.comments')" style="border-color: var(--green-primary) var(--green-primary) #fff;" id="tab1" )
                comments-tab(:isAdmin="isOwner", :id_ecs="Number($route.params.id)")
              b-tab.tabText(:title="$t('ecs_profile.perceptions')" lazy style="border-color: var(--green-primary) var(--green-primary) #fff;"  id="tab2" )
                perceptionsTab(:id_ecs="Number($route.params.id)")
              b-tab.tabText(:title="$t('ecs_profile.assessment')" id="tab3"  lazy )
                sustUCES(:id_ecs="Number($route.params.id)" public)
              b-tab.tabText(v-if="ecs_data.interactive" :title="$t('ecs_profile.events')" id="tab3" disabled)
                template(#title) {{$t('ecs_profile.events')}}
                  b-icon-lock
                div(style="height:500px")
                  b-alert(show variant="warning" style="text-align:center;") This section is under construction.<br>When finished, it will show the upcoming events organized by the Edible City Solution.

</template>

<script>

import axios from "axios";
import imageCompression from "browser-image-compression"
import { mapGetters } from 'vuex'

import sustUCES from "@/components/assessment/sustUCESAlone.vue";
import commentsTab from "@/components/showProfile/comments/commentsTab";
import perceptionsTab from "@/components/showProfile/perceptions/perceptionsTab";

export default {
  name: "showECSProfile",
  components: {
    sustUCES,
    commentsTab,
    perceptionsTab
  },
  metaInfo() {
    return {
      title: this.ecs_data.name,
      meta: [
        { name: 'description', content: this.ecs_data.description},
        { property: 'og:title', content: this.ecs_data.name},
        { property: 'og:site_name', content: 'EdiCitNet toolbox'},
        { property: 'og:description', content: this.ecs_data.description},
        {property: 'og:type', content: 'profile'},
        {property: 'og:url', content: 'https://toolbox.edicitnet.com/showEcsProfile/' + this.$route.params.id}
      ]
    }
  },
  methods:{
    checkNetworkTooltip: function(ans,columns)
    {
	    if(ans[columns.contribution.id])
	    {
			let _this = this;
		    _this.ecs_data.network_tooltip.push(ans[columns.contribution.id].map(function (e){
			    if(e['text_'+_this.$store.getters.getLanguage.lang])
			    {
				    return e['text_'+_this.$store.getters.getLanguage.lang];
			    }
			    else if(e.text_1)
			    {
				    return e.text_1;
			    }
			    else if(e.text)
			    {
				    return e.text;
			    }
			    else
			    {
				    return "";
			    }
		    }).join(","));
	    }
    },
    mountComponent()
    {
      let _this = this;
      //console.log("User", this.$store.getters.getUser);
      axios.post('answers/getECSProfileExperiences',
          {"id_ecs": this.$route.params.id}
      )
          .then(response => {
            //console.log("Number of experiences: ", response.data.numberOfExperience)
            _this.ecs_data.numberOfExperience = response.data.numberOfExperience
          })
          .catch(err => {
            console.log("Number of experiences: ", err)
          }).finally(()=>{
        //console.log("done")
      })

      axios.post('answers/getECSProfileImage',
          {"id_ecs": this.$route.params.id}
      )
          .then(function (response){
            //console.log("image response", response);
            _this.ecs_data.logo = response.data;
          })
          .catch(function (error) {
            console.log("Error getting answers");
            console.log(error);
          });
      axios.post('questions/query/getquestions')
          .then(function (response) {
            //console.log('response getquestions');
            //console.log(response);
            if(!response.data){
            } else {

              response.data.forEach( question => {
                _this.questions[question.id] = JSON.parse(question.config);
              })
              //console.log("questions:", _this.questions)
              axios.post('answers/getECSProfileAnswers',
                  {"id_ecs": _this.$route.params.id}
              )
                  .then(function (response) {
                    if(!response.data){
                      _this.$router.push('/404');
                    } else {
                      response.data.forEach(function (answer) {
                        _this.ecs_data.name = answer.ecs_name;
                        //console.log(answer.creator_id, _this.$store.getters.getUser.id);
                        _this.isOwner = answer.creator_id === _this.$store.getters.getUser.id;
                        _this.ecs_data.creatorId = answer.creator_id;
                        let answers = JSON.parse(answer.answers).questions;
                        //console.log("All answers",answers);
                        for (const property in answers){
                          try {
                            _this.prepareData(answers[property]);

                          } catch (error) {
                            console.log(error);
                          }
                        }
						/*
						Hide no data
                        if(_this.ecs_data.description == "")
                        {
                          _this.ecs_data.description = _this.$t('profile.noData');
                        }
                        if(_this.ecs_data.iniciators.length == 0)
                        {
                          _this.ecs_data.iniciators.push(_this.$t('profile.noData'));
                        }
                        if(_this.ecs_data.landlordOwner.length == 0)
                        {
                          _this.ecs_data.landlordOwner.push(_this.$t('profile.noData'));
                        }
                        if(_this.ecs_data.ecsOwner.length == 0)
                        {
                          _this.ecs_data.ecsOwner.push(_this.$t('profile.noData'));
                        }
                        if(_this.ecs_data.decisionMaking.length == 0)
                        {
                          _this.ecs_data.decisionMaking.push(_this.$t('profile.noData'));
                        }
                        if(_this.ecs_data.type_founding.length == 0)
                        {
                          _this.ecs_data.type_founding.push(_this.$t('profile.noData'));
                        }
                        if(_this.ecs_data.type_marqueting.length == 0)
                        {
                          _this.ecs_data.type_marqueting.push(_this.$t('profile.noData'));
                        }
                        if(_this.ecs_data.stage == "")
                        {
                          _this.ecs_data.stage = _this.$t('profile.noData');
                        }
                        if(_this.ecs_data.access == "")
                        {
                          _this.ecs_data.access = _this.$t('profile.noData');
                        }
                        if(_this.ecs_data.products.length == 0)
                        {
                          _this.ecs_data.products.push({product:_this.$t('profile.noData')});
                        }
                        if(_this.ecs_data.productionMethods.length == 0)
                        {
                          _this.ecs_data.productionMethods.push(_this.$t('profile.noData'));
                        }
                        if(_this.ecs_data.activities.length == 0)
                        {
                          _this.ecs_data.activities.push({activity:_this.$t('profile.noData')});
                        }
                        // if(_this.ecs_data.network.length === 0)
                        // {
                        //   console.log("network length", _this.ecs_data.network, _this.ecs_data.network.length)
                        //   _this.ecs_data.network.push(_this.$t('profile.noData'));
                        // }
                        if(_this.ecs_data.benefits_text == "")
                        {
                          _this.ecs_data.benefits_text  = _this.$t('profile.noData');
                        }
                        if(_this.ecs_data.nonEdibleSolutions.length == 0)
                        {
                          _this.ecs_data.nonEdibleSolutions.push(_this.$t('profile.noData'));
                        }
                        if(_this.ecs_data.edibleNatureSolutions.length == 0)
                        {
                          _this.ecs_data.edibleNatureSolutions.push(_this.$t('profile.noData'));
                        }
						 */

                      });
                    }
                  })
                  .catch(function (error) {
                    console.log("Error getting answers");
                    console.log(error);
                  });
            }
          })
          .catch(function (error) {
            console.log("Error getting answers");
            console.log(error);
          });


      axios.post('images/getImages',
          {"id_ecs": this.$route.params.id}
      )
          .then(function (response){
            //console.log("image response", response);
            if(response.data !== "") _this.ecs_data.images = response.data;
          })
          .catch(function (error) {
            console.log("Error getting answers");
            console.log(error);
          });
    },
    prepareData(answer){
      let _this = this;
      if(answer.id_question) {
        if (answer.id_question === 29 ){
          this.ecs_data.location.address = answer.answer.address;
          this.ecs_data.location.coordinates.lat = answer.answer.location.lat;
          this.ecs_data.location.coordinates.lang = answer.answer.location.lng;
          this.ecs_data.location.mapURL = "http://maps.googleapis.com/maps/api/staticmap?" +
              "center="+answer.answer.location.lat+","+answer.answer.location.lng +
              "&zoom=18" +
              "&size=600x400"+
              "&markers=color:blue%7Cicon:https://jospueyo.github.io/root/marker_ecs_02.png%7C"+answer.answer.location.lat+","+answer.answer.location.lng +
              "&key=AIzaSyD6YD1VaM16bbuXFXOu2xd2Ik_SiBRTGDs"
        }         //Location
        else if(answer.id_question === 147)
        {
          _this.ecs_data.interactive = answer.answer.answer.text_1 === "Yes";
        }
        else if(answer.id_question === 30 ){
          _this.ecs_data.social = [];
          answer.answer.forEach(ans =>{
            //console.log(ans)
            let type = ""
            let url = ""
            let object = {
              type: "",
              url: ""
            }
            for (const property in ans){
              if(ans[property] && ans[property].id_custom === "25.Type"){
                type = ans[property].id_custom_answer
              }
              else if(ans[property] && ans[property].id_custom === "25.Url"){
                url = ans[property].text
              }
            }
            if(type === "25.1.1" && url !== "") {
              object.type = "facebook"
            }
            if(type === "25.1.2" && url !== "") {
              object.type = "instagram"
            }
            if(type === "25.1.3" && url !== "") {
              object.type = "twitter"
            }
            if(type === "25.2" && url !== "") {
              object.type = "web"
            }
            if(type === "25.3" && url !== ""){
              //this.ecs_data.social.email = url
              object.type = "email"
              this.ecs_data.location.email = url
            }
            object.url = url

            this.ecs_data.social.push(object);
          })

        }     //Social
        else if(answer.id_question === 124 ){
          this.ecs_data.description = answer.answer;
         // console.log('answer');
          //console.log(answer);

        }    //Description
        else if(answer.id_question === 54 ){
          this.ecs_data.year = answer.answer;
        }     //Year
        else if(answer.id_question === 66 ) {
          _this.ecs_data.type_founding = []
          console.log('answer 66',answer,_this.questions['66'].columns[0]);
          if (answer.answer.answer) {
            answer.answer.answer.forEach(ans => {
              if(typeof(ans['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
              {
                _this.ecs_data.type_founding.push(ans['text_'+_this.$store.getters.getLanguage.lang]);
              }
              else
              {
                _this.ecs_data.type_founding.push(ans.text_1);
              }
            });
          }
		  else if(answer.answer)
          {
            let timestamp = this.setTimeStamp(answer.answer, "66_1661160623300")
	          answer.answer.forEach(ans => {
              if(ans['66_updated'] && this.checkTimeStamp(ans, "66_1661160623300", timestamp))
              {
                ans['66_updated'].forEach(a_updated => {
                  let find = _this.questions['66'].columns[0].answers.find(element => element.custom_ids === a_updated.id_custom_answer);
                  let text = a_updated.text;
                  if(find && find['text_'+_this.$store.getters.getLanguage.lang])
                  {
                    _this.ecs_data.type_founding.push(find['text_'+_this.$store.getters.getLanguage.lang]);
                  }
                  else
                  {
                    _this.ecs_data.type_founding.push(find['text_1']);
                  }
                });
              }
	          });
          }
          if(answer.answer.isCustom)
          {
            _this.ecs_data.type_founding.push(answer.answer.answer_custom_text);
          }
        }    //Type of funding
        else if(answer.id_question === 69 ){
          _this.ecs_data.type_marqueting = [];
          // console.log("length answer", answer.answer.length)
          if(answer.answer.answer){
            // console.log("id_69", answer.answer.answer)

            answer.answer.answer.forEach(ans => {
              if(typeof(ans['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
              {
                _this.ecs_data.type_marqueting.push(ans['text_'+_this.$store.getters.getLanguage.lang]);
              }
              else
              {
                _this.ecs_data.type_marqueting.push(ans.text_1);
              }
            })
            if(answer.answer.isCustom)
            {
              _this.ecs_data.type_marqueting.push(answer.answer.answer_custom_text);
            }
          }
          else if (answer.answer.length > 0){
            let timestamp = this.setTimeStamp(answer.answer, "69_1642429255444")
            let _answer = []
            answer.answer.forEach(ans => {
              if (ans['69_1642428831860'] && this.checkTimeStamp(ans, '69_1642429255444', timestamp)) _answer = ans['69_1642428831860']
            })
            // console.log("marketing", _answer)
            let questions = _this.questions['69'].columns[0].answers
            _answer.forEach(ans => {
              if (ans.id === "other"){
                _this.ecs_data.type_marqueting.push(ans.text)
              }
              else if (questions.find(q => q.custom_ids == ans.id_custom_answer)){
                let lang = 'text_'+_this.$store.getters.getLanguage.lang
                if (lang == "text_en") lang = 'text_1'
                // console.log("lang", lang)
                _this.ecs_data.type_marqueting.push(questions.find(q => q.custom_ids == ans.id_custom_answer)[lang])
              }
            })
          }

        }     //Type of marketing
        else if(answer.id_question === 95 ){
          this.ecs_data.initial_budged = answer.answer
        }     //initial budget
        else if(answer.id_question === 67 ){
          this.ecs_data.runing_costs = 0
          if (answer.answer && Array.isArray(answer.answer) && answer.answer.length > 0) {
            let timestamp = this.setTimeStamp(answer.answer, '67_1641204333848')
            answer.answer.forEach(ans => {
              if (ans["67_1582632951616"] && this.checkTimeStamp(ans, '67_1641204333848', timestamp))
                _this.ecs_data.runing_costs += Number(ans["67_1582632951616"].text);
            });
          }
        }     //runing costs
        else if(answer.id_question === 145 ){
          if(answer.answer.isCustom)
          {
            this.ecs_data.access=answer.answer.answer_custom_text;
          }else
          {
            if(typeof(answer.answer.answer['text_'+this.$store.getters.getLanguage.lang]) !== "undefined")
            {
              this.ecs_data.access = answer.answer.answer['text_'+this.$store.getters.getLanguage.lang];
            }
            else
            {
              this.ecs_data.access = answer.answer.answer.text_1;
            }
          }
        }    //Access
        else if(answer.id_question === 62){
          let question = _this.questions[62];
          let columns = {
            "area": {id: "", position: ""},
            "landownership": {id: "", position: ""},
            "ecsownership": {id: "", position: ""},
            "decisionmaking": {id: "", position: ""}
          }
          question.columns.forEach((col,pos) => {
            if(col.custom_ids === "area") {
              columns.area.id = col.internal_id
              columns.area.position = pos
            }
            if(col.custom_ids === "landownership") {
              columns.landownership.id = col.internal_id
              columns.landownership.position = pos
            }
            if(col.custom_ids === "ecsownership") {
              columns.ecsownership.id = col.internal_id
              columns.ecsownership.position = pos
            }
            if(col.custom_ids === "decisionmaking") {
              columns.decisionmaking.id = col.internal_id
              columns.decisionmaking.position = pos
            }
          })
          _this.ecs_data.decisionMaking = [];
          _this.ecs_data.landlordOwner = [];
          _this.ecs_data.ecsOwner = [];
          if (answer.answer && answer.answer.length > 0) {
            // console.log(answer.answer)
            let timestamp = this.setTimeStamp(answer.answer, '62_1637672973559')
            _this.ecs_data.surface = 0
            answer.answer.forEach(ans => {
              // console.log(timestamp, ans['62_1637672973559'])
              if (this.checkTimeStamp(ans, '62_1637672973559', timestamp)){
                if(ans[columns.area.id] != null)
                {
                  _this.ecs_data.surface += Number(ans[columns.area.id].text)
                }
                ans[columns.landownership.id].forEach(col => {
                  if(col.id == "other" && !_this.ecs_data.landlordOwner.includes(col.text))
                  {
                    _this.ecs_data.landlordOwner.push(col.text)
                  }
                  else
                  {
                    question.columns[columns.landownership.position].answers.forEach(res =>{
                      //console.log(res, _this.ecs_data.landlordOwner);
                      if(res.internal_id === col.id){
                        if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined" && !_this.ecs_data.landlordOwner.includes(res['text_'+_this.$store.getters.getLanguage.lang]))
                        {
                          _this.ecs_data.landlordOwner.push(res['text_'+_this.$store.getters.getLanguage.lang]);
                        }
                        else if(!_this.ecs_data.landlordOwner.includes(res.text_1))
                        {
                          _this.ecs_data.landlordOwner.push(res.text_1)
                        }
                      }
                    });
                  }

                })
                ans[columns.ecsownership.id].forEach(col => {
                  if(col.id == "other" && !_this.ecs_data.ecsOwner.includes(col.text))
                  {
                    _this.ecs_data.ecsOwner.push(col.text)
                  }
                  else
                  {
                    question.columns[columns.ecsownership.position].answers.forEach(res =>{
                      if(res.internal_id === col.id){
                        if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined" && !_this.ecs_data.ecsOwner.includes(res['text_'+_this.$store.getters.getLanguage.lang]))
                        {
                          _this.ecs_data.ecsOwner.push(res['text_'+_this.$store.getters.getLanguage.lang]);
                        }
                        else if(!_this.ecs_data.ecsOwner.includes(res.text_1))
                        {
                          _this.ecs_data.ecsOwner.push(res.text_1)
                        }
                      }
                    });
                  }
                })
                ans[columns.decisionmaking.id].forEach(col => {
                  if(col.id == "other" && !_this.ecs_data.decisionMaking.includes(col.text))
                  {
                    _this.ecs_data.decisionMaking.push(col.text)
                  }
                  else {
                    question.columns[columns.decisionmaking.position].answers.forEach(res =>{
                      if(res.internal_id === col.id){
                        if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined" && !_this.ecs_data.decisionMaking.includes(res['text_'+_this.$store.getters.getLanguage.lang]))
                        {
                          _this.ecs_data.decisionMaking.push(res['text_'+_this.$store.getters.getLanguage.lang]);
                        }
                        else if(!_this.ecs_data.decisionMaking.includes(res.text_1))
                        {
                          _this.ecs_data.decisionMaking.push(res.text_1)
                        }
                      }
                    });
                  }
                })
              }
            })
          }

          if(answer.skip && answer.skip.custom)
          {
            //Custom answer is general
            //_this.ecs_data.landlordOwner.push(answer.skip.answer);
            //_this.ecs_data.decisionMaking.push(answer.skip.answer);
          }
        }      //Area
        else if(answer.id_question === 156) {

          if(answer.answer.isCustom)
          {
            _this.ecs_data.stage=answer.answer.answer_custom_text;
          }else
          {
            if(typeof(answer.answer.answer['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
            {
              _this.ecs_data.stage = answer.answer.answer['text_'+_this.$store.getters.getLanguage.lang];
            }
            else
            {
              _this.ecs_data.stage = answer.answer.answer.text_1;
            }
          }

        } // Stage
        else if(answer.id_question === 118) {
          _this.ecs_data.iniciators = [];
          answer.answer.answer.forEach(res => {
            if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
            {
              _this.ecs_data.iniciators.push(res['text_'+_this.$store.getters.getLanguage.lang]);
            }
            else if(res.text_1 !== "undefined")
            {
              _this.ecs_data.iniciators.push(res.text_1);
            }

          });
          if(answer.answer.isCustom)
          {
            _this.ecs_data.iniciators.push(answer.answer.answer_custom_text);
          }
        } // Iniciators
        else if(answer.id_question === 152){
          if(answer.answer.isCustom)
          {
            _this.ecs_data.typeOfECS =answer.answer.answer_custom_text;
          }
          else
          {
            if(typeof(answer.answer.answer['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
            {
              _this.ecs_data.typeOfECS = answer.answer.answer['text_'+_this.$store.getters.getLanguage.lang];
            }
            else if(answer.answer.answer != "")
            {
              _this.ecs_data.typeOfECS = answer.answer.answer.text_1;
            }
          }
        }     //Type of ECS
        else if(answer.id_question === 51){
          let question = _this.questions[51];
          let columns = {
            "products": {id: "", position: ""},
            "uses": {id: "", position: ""},
            "availability": {id: "", position: ""}
          }
          question.columns.forEach((col,pos) => {
            if(col.custom_ids === "products") {
              columns.products.id = col.internal_id
              columns.products.position = pos
            }
            if(col.custom_ids === "uses") {
              columns.uses.id = col.internal_id
              columns.uses.position = pos
            }
            if(col.custom_ids === "availability") {
              columns.availability.id = col.internal_id
              columns.availability.position = pos
            }
          });
          if (answer.answer && answer.answer.length > 0) {
            var products = [];
            // console.log("edible goods", answer.answer)
            let timestamp = this.setTimeStamp(answer.answer, '51_1637677038316')

            answer.answer.forEach(ans => {
              let product = {};
              if (this.checkTimeStamp(ans, '51_1637677038316', timestamp)){
                ans[columns.products.id].forEach(col => {
                  question.columns[columns.products.position].answers.forEach(res =>{
                    //console.log(res, _this.ecs_data.landlordOwner);
                    if (col){
                      if(col.code === "D Non edible goods") _this.ecs_data.icons.nonEdible = true
                      if(col.code === "A Raw products (plant based)") _this.ecs_data.icons.rawPlant = true
                      if(col.code === "B Raw products (animal based)") _this.ecs_data.icons.rawAnimal = true
                      if(col.code === "C Manufactured products") _this.ecs_data.icons.processed = true
                      if(res.includes(col.code2)){
                        //if(!_this.ecs_data.products.includes(res[0])) {
                        //_this.ecs_data.products.push(res[0])
                        switch(_this.$store.getters.getLanguage.lang)
                        {
                          case 'cat':
                            product["product"] = res[3];
                            break;
                          case 'fr':
                            product["product"] = res[4];
                            break;
                          case 'es':
                            product["product"] = res[5];
                            break;
                          case 'de':
                            product["product"] = res[6];
                            break;
                          case 'pr':
                            product["product"] = res[7];
                            break;
                          case 'sl':
                            product["product"] = res[8];
                            break;
                          case 'rm':
                            //TEMP
                            product["product"] = res[0];
                            break;
                          case 'en':
                          default:
                            product["product"] = res[0];
                            break;
                        }
                        //}
                      }
                      else if(col.id == "other")
                      {
                        product["product"] = col.text;
                      }
                    }

                  });
                  if(product['product'] != "" && !products.includes(product['product']))
                  {
                    products.push(product['product']);
                  }
                });

                product["months"] = []
                product['months_string'] = "";
                if(ans[columns.availability.id]) {
                  ans[columns.availability.id].forEach(col => {
                    let month = question.columns[columns.availability.position].answers.find(res => {
                      return res.internal_id === col.id
                    })
                    if (month)
                    {
                      if(typeof(month['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                      {
                        product["months"].push(month['text_'+_this.$store.getters.getLanguage.lang]);
                      }
                      else
                      {
                        product["months"].push(month.text_1);
                      }
                    }
                    //No hi hauria de passar però per si hi ha alguna estructura antiga
                    else if(col.id == "other" && !product["months"].includes(col.text))
                    {
                      product["months"].push(col.text);
                    }
                  })
                  product['months_string'] = product['months'].join('/');
                }
                if(ans[columns.uses.id]) {
                  ans[columns.uses.id].forEach(col => {
                    if (col.id_custom_answer === "7.4") _this.ecs_data.icons.self_consumption = true
                    if (col.id_custom_answer === "7.1.1") _this.ecs_data.icons.commercialitzation = true
                    if (col.id_custom_answer === "7.3") _this.ecs_data.icons.donation = true
                    if (col.id_custom_answer === "7.2") _this.ecs_data.icons.exchange = true
                  })
                }
                if(products.length>1)
                {
                  products.forEach(elem => {
                    if(!_this.ecs_data.products.some(res => {
                      return res.product == elem;
                    }))
                    {
                      var _product = {}
                      _product.product = elem;
                      _product.months = product.months;
                      _product.months_string = product.months_string;
                      _this.ecs_data.products.push(_product);
                    }
                  });

                }
                else
                {
                  if(!_this.ecs_data.products.some(res => {
                    return res.product == product.product;
                  }))
                  {
                    _this.ecs_data.products.push(product);
                  }
                }
              }

            });
          }
        }      //Products
        else if(answer.id_question === 137){
          let question = _this.questions[137]
          let columns = {
            "network": {id: "", position: ""},
            "contribution": {id: "", position: ""}
          }
          question.columns.forEach((col,pos) => {
            if(col.custom_ids === "network") {
              columns.network.id = col.internal_id
              columns.network.position = pos
            }
            if(col.custom_ids === "contribution") {
              columns.contribution.id = col.internal_id
              columns.contribution.position = pos
            }
          });
	        _this.ecs_data.network = [];
	        _this.ecs_data.network_tooltip = [];
          _this.ecs_data.contribution = [];
          let network_profiles_visited = [];
          if (answer.answer && answer.answer.length > 0) {
            // console.log("network", answer.answer)
            let timestamp = this.setTimeStamp(answer.answer, '137_1653660220783')
            answer.answer.forEach(ans => {
              if (this.checkTimeStamp(ans, '137_1653660220783', timestamp)){
                question.columns[columns.network.position].answers.forEach(res =>{
                  if(ans[columns.network.id] && typeof ans[columns.network.id] === "object" && typeof ans[columns.network.id].type !== "undefined" && ans[columns.network.id].type == 'profile' && !network_profiles_visited.includes(ans[columns.network.id].code))
                  {
	                  network_profiles_visited.push(ans[columns.network.id].code);
                    let network_profile = ans[columns.network.id];
                    network_profile.interactive = false;
                    // console.log(ans[columns.network.id]);
                    axios.post('answers/getECSProfileAnswers',
                        {"id_ecs": ans[columns.network.id].code}
                    ).then(function (response) {
                      let data =  JSON.parse(response.data[0].answers);
                      if(data.questions.id_147.answer.answer.internal_id === "147.1")
                      {
                        network_profile.interactive = true;
                      }
                      _this.ecs_data.network.push(network_profile);
					  if(ans[columns.contribution.id])
					  {
						  _this.checkNetworkTooltip(ans,columns);
					  }
                    })
                    .catch(function (error) {
                      _this.ecs_data.network.push(network_profile);
                      console.log("Error getting answers");
                      console.log(error);
                    });
                  }
                  else if(typeof ans[columns.network.id] === "string" && !_this.ecs_data.network.includes(ans[columns.network.id]))
                  {
	                  _this.ecs_data.network.push(ans[columns.network.id]);
	                  if(ans[columns.contribution.id])
	                  {
		                  _this.checkNetworkTooltip(ans,columns);
	                  }
                  }
                  else if(ans[columns.network.id] && res.internal_id === ans[columns.network.id].id)
				  {
					  if(typeof (res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined" && !_this.ecs_data.network.includes(res['text_'+_this.$store.getters.getLanguage.lang]))
                    {
                      _this.ecs_data.network.push(res['text_'+_this.$store.getters.getLanguage.lang]);
	                    if(ans[columns.contribution.id])
	                    {
		                    _this.checkNetworkTooltip(ans,columns);
	                    }
                    }
                    else if(!_this.ecs_data.network.includes(res.text_1))
                    {
                      _this.ecs_data.network.push(res.text_1)
	                    if(ans[columns.contribution.id])
	                    {
		                    _this.checkNetworkTooltip(ans,columns);
	                    }
                    }
                  }
                });
                question.columns[columns.contribution.position].answers.forEach(res =>{
                  if(Array.isArray(ans[columns.contribution.id])) {
                    ans[columns.contribution.id].forEach(ans => {
                      if(res.internal_id === ans.id){
                        if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined" && !_this.ecs_data.contribution.includes(res['text_'+_this.$store.getters.getLanguage.lang]))
                        {
                          _this.ecs_data.contribution.push(res['text_'+_this.$store.getters.getLanguage.lang]);
                        }
                        else if(!_this.ecs_data.contribution.includes(res.text_1))
                        {
                          _this.ecs_data.contribution.push(res.text_1)
                        }
                      }
                    });

                  }
                  else if(res.internal_id === ans[columns.contribution.id].id){
                    if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined" && !_this.ecs_data.contribution.includes(res['text_'+_this.$store.getters.getLanguage.lang]))
                    {
                      _this.ecs_data.contribution.push(res['text_'+_this.$store.getters.getLanguage.lang]);
                    }
                    else if(!_this.ecs_data.contribution.includes(res.text_1))
                    {
                      _this.ecs_data.contribution.push(res.text_1)
                    }
                  }
                });
              }
            });
            _this.ecs_data.contribution_tooltip = _this.ecs_data.contribution .join('/');
          }
          // console.log("network", this.ecs_data.network)

        }     //Network/partners
        else if(answer.id_question === 52){
          let question = _this.questions[52]
          let columns = {
            "activity": {id: "", position: ""},
            "months": {id: "", position: ""},
            "benefits": {id: "", position: ""}
          };

          question.columns.forEach((col,pos) => {
            if(col.custom_ids.includes("activity") || col.custom_ids.includes("activities")) {
              columns.activity.id = col.internal_id
              columns.activity.position = pos
            }
            else if(col.custom_ids.includes("months")) {
              columns.months.id = col.internal_id
              columns.months.position = pos
            }
            else if(col.custom_ids.includes("benefits")) {
              columns.benefits.id = col.internal_id
              columns.benefits.position = pos
            }
          });


          _this.ecs_data.activities = [];
          _this.ecs_data.benefits = [];

          if (answer.answer && answer.answer.length > 0) {
            console.log("Activities", answer.answer)
            let timestamp = this.setTimeStamp(answer.answer, '52_1653918575536')
            answer.answer.forEach(ans => {
              if (this.checkTimeStamp(ans, '52_1653918575536', timestamp)){
                let activity = {};
                var found = false;
                question.columns[columns.activity.position].answers.forEach(res =>{
                  if(ans['52_1580141191112'])
                  {
                    ans['189_1652422479138'] = ans['52_1580141191112'];
                  }
                  if(ans[columns.activity.id])
                  {
                    if(ans[columns.activity.id].id_custom_answer.startsWith("5.1")) _this.ecs_data.icons.educational = true
                    // if(ans[columns.activity.id].id_custom_answer === "5.1.2") _this.ecs_data.icons.educational = true
                    if(ans[columns.activity.id].id_custom_answer === "5.2.1") _this.ecs_data.icons.therapeutic = true
                    // an icon for recreation and physical is missing
                    if(ans[columns.activity.id].id_custom_answer.startsWith("5.8")) _this.ecs_data.icons.cooking = true
                    // if(ans[columns.activity.id].id_custom_answer === "5.8.2") _this.ecs_data.icons.cooking = true
                    if(ans[columns.activity.id].id_custom_answer === "5.3") _this.ecs_data.icons.research = true
                    if(ans[columns.activity.id].id_custom_answer === "5.4") _this.ecs_data.icons.markets_festivals = true
                    if(ans[columns.activity.id].id_custom_answer === "5.5") _this.ecs_data.icons.volunteer = true
                    if(ans[columns.activity.id].id_custom_answer === "5.6") _this.ecs_data.icons.discussions = true
                    if(ans[columns.activity.id].id_custom_answer === "5.9") _this.ecs_data.icons.planting = true
                    if(ans[columns.activity.id].id_custom_answer === "5.7") _this.ecs_data.icons.recovery = true
                    if(ans[columns.activity.id].id_custom_answer.startsWith("5.11")) _this.ecs_data.icons.recovery = true
                    if(ans[columns.activity.id].id_custom_answer.startsWith("5.10")) _this.ecs_data.icons.trading = true
                    if(res.custom_ids === ans[columns.activity.id].id_custom_answer){
                      found = true;
                      if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                      {
                        activity["activity"] = (res['text_'+_this.$store.getters.getLanguage.lang]);
                      }
                      else
                      {
                        activity["activity"] = (res['text_1']);
                      }
                    }
                  }
                });
                if(!found && ans[columns.activity.id] && typeof ans[columns.activity.id].text !== "undefined" && ans[columns.activity.id].text !=  "")
                {
                  activity["activity"] = ans[columns.activity.id].text;
                }
                else if(!found && ans[columns.activity.id] && typeof ans[columns.activity.id] === "string" && ans[columns.activity.id] !=  "")
                {
                  activity["activity"] = ans[columns.activity.id];
                }

                var found_benefit = false;
                question.columns[columns.benefits.position].answers.forEach(res =>{
                  if(ans[columns.benefits.id])
                  {
                    if(Array.isArray(answer.answer)) {
                      ans[columns.benefits.id].forEach(ans => {
                        if(res.internal_id === ans.id){
                          if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined" && !_this.ecs_data.benefits.includes(res['text_'+_this.$store.getters.getLanguage.lang]))
                          {
                            _this.ecs_data.benefits.push(res['text_'+_this.$store.getters.getLanguage.lang]);
                          }
                          else if(!_this.ecs_data.benefits.includes(res.text_1))
                          {
                            _this.ecs_data.benefits.push(res.text_1)
                          }
                        }
                      });
                    }
                    else if(res.internal_id === ans[columns.benefits.id].id){
                      found_benefit = true;
                      if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                      {
                        _this.ecs_data.benefits.push(res['text_'+_this.$store.getters.getLanguage.lang]);
                      }
                      else if(!_this.ecs_data.benefits.includes(res.text_1))
                      {
                        _this.ecs_data.benefits.push(res.text_1);
                      }
                    }
                  }
                });

                if(Array.isArray(ans[columns.benefits.id])) {
                  ans[columns.benefits.id].forEach(ans => {
                    if (ans.id == "other" && !_this.ecs_data.benefits.includes(ans.text)) {
                      _this.ecs_data.benefits.push(ans.text);
                    }
                  });
                }

                if(ans[columns.benefits.id] && typeof ans[columns.benefits.id].text !==  "undefined")
                {
                  _this.ecs_data.benefits.push(ans[columns.benefits.id].text);
                }
                else if(ans[columns.benefits.id] && typeof ans[columns.benefits.id].name !==  "undefined")
                {
                  _this.ecs_data.benefits.push(ans[columns.benefits.id].name);
                }
                _this.ecs_data.benefits_text = _this.ecs_data.benefits.join(', ');
                activity["months"] = []
                activity['months_string'] = "";
                if(ans['52_1580141192971'])
                {
                  ans['189_1652422532173'] = ans['52_1580141192971'];
                }
                if(ans[columns.months.id]) {
                  ans[columns.months.id].forEach(col => {
                    console.log('col',col);
                    let month = question.columns[columns.months.position].answers.find(res => {
                      return res.internal_id === col.id
                    })
                    if (typeof month !== "undefined" && typeof month['text_' + _this.$store.getters.getLanguage.lang] !== "undefined") {
                      if (month) activity["months"].push(month['text_' + _this.$store.getters.getLanguage.lang]);
                    } else {
                      if (month) {
                        activity["months"].push(month.text_1);
                      } else if (!month && col.text) {
                        activity["months"].push(col.text);
                      }
                    }
                    activity['months_string'] = activity['months'].join('/');
                  });
                }
                console.log('activity', activity);
                if(activity.activity != null && !_this.ecs_data.activities.includes(activity))
                {
                  _this.ecs_data.activities.push(activity)
                }
              }
            })
          }
        }      //Activities
        else if(answer.id_question === 75){
          _this.ecs_data.edibleNatureSolutions = [];
          _this.ecs_data.productionMethods = [];
          let question = _this.questions[75];
          let columns = {
            "ediblestructure": {id: "", position: ""},
            "product": {id: "", position: ""},
            "ediblearea": {id: "", position: ""}
          }

          question.columns.forEach((col,pos) => {
            if(col.custom_ids === "ediblestructure") {
              columns.ediblestructure.id = col.internal_id
              columns.ediblestructure.position = pos
            }
            if(col.custom_ids === "product") {
              columns.product.id = col.internal_id
              columns.product.position = pos
            }
            if(col.custom_ids === "ediblearea") {
              columns.ediblearea.id = col.internal_id
              columns.ediblearea.position = pos
            }
          });
          if (answer.answer && answer.answer.length > 0) {
            // console.log("Production", answer.answer)
            let timestamp = this.setTimeStamp(answer.answer, '75_1647951764939')
            answer.answer.forEach(ans => {
              if (this.checkTimeStamp(ans, '75_1647951764939', timestamp)){
                let ediblestructure = "";
                if(typeof ans[columns.ediblestructure.id].id !== "undefined")
                {
                  question.columns[columns.ediblestructure.position].answers.forEach(res =>{
                    if(res.internal_id === ans[columns.ediblestructure.id].id){
                      if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                      {
                        ediblestructure = (res['text_'+_this.$store.getters.getLanguage.lang]);
                      }
                      else
                      {
                        ediblestructure = res.text_1;
                      }
                      // console.log("ediblestructure", ediblestructure)
                      switch (res.custom_ids){
                        case "1.2.1":
                        case "1.2.2":
                        case "1.2.3":
                        case "1.2.4":
                        case "1.2.5":
                        case "1.2.6":
                        case "1.2.7":
                        case "1.2.8":
                        case "1.2.13":
                          if(!_this.ecs_data.edibleNatureSolutions.includes(ediblestructure))
                          {
                            _this.ecs_data.edibleNatureSolutions.push(ediblestructure);

                          }
                          break;
                        case "3.1":
                        case "3.2":
                        case "3.3":
                        case "3.4":
                        case "3.5":
                        case "3.6":
                        case "3.7":
                        case "3.8":
                        case "3.9":
                        case "3.10":
                        case "3.11":
                          if(!_this.ecs_data.productionMethods.includes(ediblestructure))
                          {
                            _this.ecs_data.productionMethods.push(ediblestructure);
                          }
                      }
                    }
                  })
                }
                else if(typeof ans[columns.ediblestructure.id] === "string" && ans[columns.ediblestructure.id] != null && !_this.ecs_data.productionMethods.includes(ans[columns.ediblestructure.id]))
                {
                  _this.ecs_data.productionMethods.push(ans[columns.ediblestructure.id]);
                }

                let product = {};
                var col = ans[columns.product.id];
                question.columns[columns.product.position].answers.forEach(res =>{
                  //console.log(res, _this.ecs_data.landlordOwner);
                  if (col){
                    if(col.code === "D Non edible goods") _this.ecs_data.icons.nonEdible = true
                    if(col.code === "A Raw products (plant based)") _this.ecs_data.icons.rawPlant = true
                    if(col.code === "B Raw products (animal based)") _this.ecs_data.icons.rawAnimal = true
                    if(col.code === "C Manufactured products") _this.ecs_data.icons.processed = true
                    if(res.includes(col.code2)){
                      //if(!_this.ecs_data.products.includes(res[0])) {
                      //_this.ecs_data.products.push(res[0])
                      switch(_this.$store.getters.getLanguage.lang)
                      {
                        case 'cat':
                          product["product"] = res[3];
                          break;
                        case 'fr':
                          product["product"] = res[4];
                          break;
                        case 'es':
                          product["product"] = res[5];
                          break;
                        case 'de':
                          product["product"] = res[6];
                          break;
                        case 'pr':
                          product["product"] = res[7];
                          break;
                        case 'sl':
                          product["product"] = res[8];
                          break;
                        case 'rm':
                          //TEMP
                          product["product"] = res[0];
                          break;
                        case 'en':
                        default:
                          product["product"] = res[0];
                          break;
                      }
                      //}
                    }
                    else if(col.id == "other")
                    {
                      product["product"] = col.text;
                    }
                  }

                });
                // console.log("column product", col)
                // console.log("product", product.product, "ecs_products", _this.ecs_data.products)
                product["months"] = []
                product['months_string'] = "";
                if(product.product != "" && product.product != null && !_this.ecs_data.products.some(res => {
                  return res.product == product.product;
                }))
                {
                  // console.log("products", _this.ecs_data.products)
                  _this.ecs_data.products.push(product);
                }
              }

            });

          }
        }      //Edible structure and Production
        else if(answer.id_question === 134){
          _this.ecs_data.nonEdibleSolutions = [];
          let question = _this.questions[134]
          let columns = {
            "greenstructure": {id: "", position: ""}
          }

          question.columns.forEach((col,pos) => {
            if(col.custom_ids === "greenstructure") {
              columns.greenstructure.id = col.internal_id
              columns.greenstructure.position = pos
            }
          })

          if (answer.answer && answer.answer.length > 0) {
            // console.log("green", answer.answer)
            let timestamp = this.setTimeStamp(answer.answer, '134_1641205460731')
            answer.answer.forEach(ans => {
              //console.log(ans,columns.network.id)
              if (this.checkTimeStamp(ans, '134_1641205460731', timestamp)){
                let greenstructure = "";
                if(typeof ans[columns.greenstructure.id].id !== "undefined")
                {
                  question.columns[columns.greenstructure.position].answers.forEach(res =>{
                    //console.log(res, _this.ecs_data.nonEdibleSolutions);
                    if(res.internal_id === ans[columns.greenstructure.id].id){


                      if(typeof(res['text_'+_this.$store.getters.getLanguage.lang]) !== "undefined")
                      {
                        greenstructure = (res['text_'+_this.$store.getters.getLanguage.lang]);
                      }
                      else
                      {
                        greenstructure = res.text_1;
                      }
                      switch (res.custom_ids){
                        case "1.3":
                        case "1.2.1":
                        case "1.2.2":
                        case "1.2.3":
                        case "1.2.4":
                        case "1.2.5":
                        case "1.2.6":
                        case "1.2.7":
                        case "1.2.8":
                        case "1.2.9":
                        case "1.2.10":
                        case "1.2.11":
                        case "1.2.12":
                        case "1.2.13":
                        case "1.2.14":

                          _this.ecs_data.nonEdibleSolutions.push(greenstructure);
                          break;
                      }
                    }
                  })
                }
                else if(ans[columns.greenstructure.id] != null)
                {
                  if(typeof ans[columns.greenstructure.id].text !== "undefined" && !_this.ecs_data.productionMethods.includes(ans[columns.greenstructure.id].text))
                  {
                    _this.ecs_data.nonEdibleSolutions.push(ans[columns.greenstructure.id].text);
                  }
                  else if(typeof ans[columns.greenstructure.id].name !== "undefined" && !_this.ecs_data.productionMethods.includes(ans[columns.greenstructure.id].name))
                  {
                    _this.ecs_data.nonEdibleSolutions.push(ans[columns.greenstructure.id].name);
                  }
                  else if(typeof ans[columns.greenstructure.id] === "string" && !_this.ecs_data.productionMethods.includes(ans[columns.greenstructure.id]))
                  {
                    _this.ecs_data.nonEdibleSolutions.push(ans[columns.greenstructure.id]);
                  }
                }
              }
            })
          }
        }     //Green structure
        // else if(answer.id_question === 154 ){
        //   this.ecs_data.reference = answer.answer
        // }
        // else if(answer.id_question === 155 ){
        //   this.ecs_data.moreInfo = answer.answer
        // }
        // else if(answer.id_question === 146)
        // {
        //   let question = _this.questions[146];
        //
        //   let columns = {
        //     "product": {id: "", position: ""},
        //   }
        //   question.columns.forEach((col,pos) => {
        //     if(col.custom_ids === "product") {
        //       columns.product.id = col.internal_id
        //       columns.product.position = pos
        //     }
        //   });
        //
        //   answer.answer.forEach(ans => {
        //     let product = {};
        //     var col = ans[columns.product.id];
        //     question.columns[columns.product.position].answers.forEach(res =>{
        //       //console.log(res, _this.ecs_data.landlordOwner);
        //       if(col.code === "D Non edible goods") _this.ecs_data.icons.nonEdible = true
        //       if(col.code === "A Raw products (plant based)") _this.ecs_data.icons.rawPlant = true
        //       if(col.code === "B Raw products (animal based)") _this.ecs_data.icons.rawAnimal = true
        //       if(col.code === "C Manufactured products") _this.ecs_data.icons.processed = true
        //       if(res.includes(col.code2)){
        //         //if(!_this.ecs_data.products.includes(res[0])) {
        //         //_this.ecs_data.products.push(res[0])
        //         switch(_this.$store.getters.getLanguage.lang)
        //         {
        //           case 'cat':
        //             product["product"] = res[3];
        //             break;
        //           case 'fr':
        //             product["product"] = res[4];
        //             break;
        //           case 'es':
        //             product["product"] = res[5];
        //             break;
        //           case 'de':
        //             product["product"] = res[6];
        //             break;
        //           case 'pr':
        //             product["product"] = res[7];
        //             break;
        //           case 'sl':
        //             product["product"] = res[8];
        //             break;
        //           case 'rm':
        //             //TEMP
        //             product["product"] = res[0];
        //             break;
        //           case 'en':
        //           default:
        //             product["product"] = res[0];
        //             break;
        //         }
        //         //}
        //       }
        //       else if(col.id == "other")
        //       {
        //         product["product"] = col.text;
        //       }
        //
        //     });
        //
        //     product["months"] = []
        //     product['months_string'] = "";
        //     if(product.product != "" && product.product != null && !_this.ecs_data.products.some(res => {
        //       return res.product == product.product;
        //     }))
        //     {
        //       _this.ecs_data.products.push(product);
        //     }
        //
        //   });
        //
        // }
        else if(answer.id_question === 65){
          //column "65_1582631987609"
          this.ecs_data.participants = 0
          //console.log(answer.answer)
          if (answer.answer && Array.isArray(answer.answer) && answer.answer.length > 0) {
            let timestamp = this.setTimeStamp(answer.answer, "65_1641204036629")
            // console.log(timestamp)

            answer.answer.forEach(ans => {
              if(ans["65_1614679824661"] !== null && this.checkTimeStamp(ans,"65_1641204036629", timestamp))
              {
                _this.ecs_data.participants += Number(ans["65_1614679824661"].text);
              }
            })
          }
        }     // Active members
      }
    },
    async deleteImage(image){
      // console.log(image)
      let _this = this;
      axios.post('images/deleteImage',
          {"id_image": image.id, filename: image.filename}
      )
          .then(function (response){
            // console.log("image response", response);
            _this.ecs_data.images = _this.ecs_data.images.filter(im => {
              return (im.id !== image.id);
            })
          })
          .catch(function (error) {
            console.log("Error getting answers");
            console.log(error);
          });

    },
    async addImage(event){
      let _this = this;
      this.file = event.target.files[0]
      if(this.file !== null){
        let data = new FormData();
        // console.log(this.file);

        this.file = await imageCompression(this.file,{
          maxSizeMB: 1
        })
        // console.log("File compressed, sending...")
        data.append('file', this.file, this.file.fileName);
        data.append('idEcs', this.$route.params.id);
        axios.post("images/uploadProfileECSImage", data, {
          headers: {
            'accept': 'application/json',
            'Accept-Language': 'es-ES,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
          }
        })
        .then((response) => {
          if(response.data){
            // console.log("Pushing image: ", response.data)
            _this.ecs_data.images.push({
              id: response.data.id,
              ecs_id: response.data.ecs_id,
              filename: response.data.filename
            })
          }
          console.log(response);
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    openFileSelect(){
      let fileInputElement = this.$refs.fileinput;
      fileInputElement.click();
    },
    setTimeStamp(answer, column_id){
      let timestamp = null
      if (answer[answer.length -1][column_id]) timestamp = '1900-01-01'
      answer.forEach(ans =>{
        // console.log("current timestamp", ans[column_id], column_id)
        if (ans[column_id] && ans[column_id].text > timestamp) timestamp = ans[column_id].text
      })
      return timestamp
    },
    checkTimeStamp(answer, column_id, timestamp){
      if (!timestamp && !answer[column_id]) return true
      if (answer[column_id] && answer[column_id].text === timestamp) return true
      return false
    }
  },
  mounted() {
    this.mountComponent();
    /*
    let _this = this;
    axios.get('answers/getAllECSProfileLocation')
      .then(function (response) {
        if (!response.data) {
          //answers.answers = {"questions": {}};
        } else {
          _this.allAnswers = response.data;
        }
        _this.mountComponent();
    }).catch(function (error) {
      _this.mountComponent();
      console.log("Error getting answers");
      console.log(error);
    });*/
  },
  watch: {
    '$store.getters.getLanguage': function() {
      //Object.assign(this.$data, this.$options.data.apply(this));
      // console.log('change language');
      this.mountComponent();
    }
  },
  data(){
    return {
      allAnswers: [],
      questions:{},
      file: null,
      ecs_data:{
        interactive: true,
        numberOfExperience: 0,
        participants: 0,
        images: [],
        creatorId: "",
        name: "",
        logo: "",
        social: [],
        description: "",
        reference: "",
        moreInfo: "",
        year: "",
        surface: 0,
        landlordOwner: [],
        ecsOwner: [],
        decisionMaking: [],
        type_founding: [],
        type_marqueting: [],
        initial_budged: "",
        runing_costs: 0,
        typeOfECS: "",
        access: "",
        products: [],
        network: [],
        network_tooltip:[],
        contribution: [],
        contribution_tooltip: "",
        activities: [],
        benefits: [],
        benefits_text: "",
        stage: "",
        iniciators: [],
        location: {
          address: "",
          email: "",
          site: "",
          coordinates: {
            lat: "",
            lang: ""
          },
          mapURL: ""
        },
        icons:{
          nonEdible: false,
          rawPlant: false,
          rawAnimal: false,
          processed: false,

          self_consumption: false,
          commercialitzation: false,
          donation: false,
          exchange: false,

          educational: false,
          therapeutic: false,
          cooking: false,
          research: false,
          markets_festivals: false,
          volunteer: false,
          discussions: false,
          recovery: false,
          planting: false,
          trading: false

        },
        edibleNatureSolutions:[],
        productionMethods:[],
        nonEdibleSolutions:[]
      },
      isOwner: false,

      socialFacebook: require("@/assets/socialIcons/facebook-icon.png"),
      socialInstagram: require("@/assets/socialIcons/instagram-icon.png"),
      socialPinteres: require("@/assets/socialIcons/pinterest-icon.png"),
      socialTwitter: require("@/assets/socialIcons/twitter-icon.png"),


      iconCanteen: require("@/assets/profileIcons/Cattering & canteen services.png"),
      iconCommercialitzation: require("@/assets/profileIcons/Commercialization.png"),
      iconSelfConsumption: require("@/assets/profileIcons/Self-consumption.svg"),
      iconDonation: require("@/assets/profileIcons/Donation.png"),
      iconExchange: require("@/assets/profileIcons/Exchange.png"),
      iconHarvesting: require("@/assets/profileIcons/Harvesting & planting events.png"),
      iconManEdibleGoods: require("@/assets/profileIcons/Manufacturing of edible goods.png"),
      iconMarkets: require("@/assets/profileIcons/Markets & festivals.png"),
      iconNonEdible: require("@/assets/profileIcons/Non-edible.png"),
      iconOpenDiscussions: require("@/assets/profileIcons/Open discussions.png"),
      iconProcessedEdibleGoods: require("@/assets/profileIcons/Processed edible goods.png"),
      iconRawAnimal: require("@/assets/profileIcons/Raw animal-based.png"),
      iconRawPlant: require("@/assets/profileIcons/Raw plant-based.png"),
      iconRecoveryProgram: require("@/assets/profileIcons/Recovery & reintegration programs.png"),
      iconResearch: require("@/assets/profileIcons/Research.png"),
      iconTherapeutic: require("@/assets/profileIcons/Therapeutic, recreational, physical activities.png"),
      iconVoluntering: require("@/assets/profileIcons/Voluntering programs.png"),
      iconWorkshop: require("@/assets/profileIcons/Workshops, trainings & seminars.svg"),
    }
  }
}
</script>

<style>
    .myTooltipClass .tooltip-inner{
        background: #A0CF84 !important;
    }
    .myTooltipClass .arrow::before{
        border-top-color: #A0CF84 !important;
        border-bottom-color: #A0CF84 !important;
    }

    .profileTab .nav-tabs .nav-link
    {
      border: 1px solid var(--light-gray-primary) !important;
    }

    .profileTab .nav-tabs .nav-link.active
    {
      border: 1px solid var(--green-primary) !important;
    }
</style>
<style scoped>
  .outlineButton{
    color: var(--green-primary);
    font-weight: var(--bold-text);
    font-size: small;
  }
  .textBlack{
    color: black !important;
  }
  .greenBack{
    background: #A0CF84;
    height: 115px;
    /*width: 95.9%;*/
    /*margin-left: -15px;*/
    display: block;
    position: absolute;
  }
  .whiteBack{
    background: white;
    height: 215px;
    /*width: 95.9%;*/
    top: 110px;
    z-index: 0;
    /*margin-left: -15px;*/
    display: block;
    position: absolute;
  }

  .greenBackground {
    /*background: linear-gradient(0deg, white 60%, #A0CF84 50%);*/
    /*background: linear-gradient(0deg, white 150px, #A0CF84 150px);*/
  }
  .greyBackground{
    background: #f4f4f4;
  }
  .boldTitle{
    color: #709e55;
    font-weight: bolder;
  }
  .boldText{
    font-weight: bold;
  }
  .littleText{
    font-size: small;
  }

  .textColorGrey{
    color: var(--gray-font-color);
  }
  .socialIcons{
    color: var(--gray-font-color);
    height: 16px;
    margin: 4px;
  }

  .network-icon
  {
    width: 15px;
    height: 20px;
    float:left;
    margin-right:10px;
  }

</style>
