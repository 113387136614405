<template lang="pug">
  .about_content.generalBackground
    b-container(fluid="true")
      b-tabs.homeTab(content-class="mt-3" fill active-nav-item-class="activeTabTitle" nav-class="tabTitle")
        //- b-tab.tabText(:title="$t('assessment.monitoring.title')" id="tab1")
          template(#title)
            span {{$t('assessment.monitoring.title')}}
              b-icon-lock.ml-2(v-b-tooltip.hover.right="$t('underConstruction.tooltip')")
          b-alert(show variant="warning" style="text-align:center;") {{$t('underConstruction.monitoring')}}
        b-tab.tabText(:title="$t('assessment.sust_uc_es.title')" active style="border-color: var(--green-primary) var(--green-primary) #fff;" id="tab2")
          b-container(fluid="true")
            sustUCES.component(:ecsUser="ecsUser")
        b-tab.tabText(:title="$t('assessment.indicators.title')" id="tab3")
          template(#title)
            span {{$t('assessment.indicators.title')}}
              b-icon-lock.ml-2(v-b-tooltip.hover.right="$t('underConstruction.tooltip')")
          b-alert(show variant="warning" style="text-align:center;") {{$t('underConstruction.monitoring')}}
          //-   span {{$t('assessment.indicators.title')}}
          //-     b-icon-lock.ml-2(v-b-tooltip.hover.right="$t('underConstruction.tooltip')")
          //- b-container(fluid="true")
          //-   b-alert(show variant="success" style="text-align:center;") {{$t('underConstruction.indicators')}}
          //-   b-row
          //-     b-col.m-3(cols="5")
          //-       b-form-group(:label="$t('assessment.selectECS')")
          //-         multiselect(
          //-           v-model="select_current_ecs"
          //-           :options="ecsAll"
          //-           :searchable="true"
          //-           :close-on-select="true"
          //-           :show-labels="true"
          //-           :placeholder="$t('placeholder.typeHere')"
          //-           label="name"
          //-           track-by="name"
          //-           v-on:keyup.enter="changeCurrent"
          //-         )
          //-         b-button.buttonGreen(pill @click="changeCurrent" :disabled="select_current_ecs === '' || select_current_ecs === null").mt-2 {{ $t('assessment.current_btn') }}
          //- div(class="nav justify-content-center")
          //-   h5 {{currentECSName}}
          //- indicators.component(:id_ecs="Number(currentECS)" v-if="currentECS != ''" :key="key")


</template>

<script>
  import sustUCES from "@/components/assessment/sustUCES.vue";
  import indicators from "@/components/assessment/indicators.vue"
  import axios from 'axios';
  import Multiselect from 'vue-multiselect';

  export default {
    name: "toolbox",
    components: {
      sustUCES,
      indicators,
      Multiselect
    },
    data: function (){
      return {
        ecsUser: [],
        key: 0
      }
    },
    mounted: function() {
      let _this = this
      axios.get('clients/ecs')
        .then(function (response) {
          console.log("ecsUser: ", response.data);
          if (_this.$route.params.id){
            _this.currentECS = _this.$route.params.id
            let ecs = response.data.find(element => element.id == _this.currentECS)
            _this.currentECSName = ecs.name
          } else {
            _this.currentECS = response.data[0].id
            _this.currentECSName = response.data[0].name
          }
          _this.ecsUser = response.data;
        })
        .catch(function (error) {
          console.log("Menu get client error:", error);
          // console.log(error);
        });
    },
    computed: {
    },
    methods: {
    }
  }
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  .border-legend{
    position: fixed;
    z-index: 10;
    bottom: 10em;
    left: 25em;
    border: 1px solid var(--gray-font-color);
    padding: 0.5em;
  }

  td{
    padding-left: 0.5em;
  }

  .legend{
    width: 2em;
  }

  /* .component{
    position: relative;
    top: -8em;
  } */

</style>
