<template lang="pug">
  b-container
    b-row
      b-col
        b-row.bg-success.p-2
          b-col.align-self-center
            h5.mb-0 {{ questionNumber+1 }}. Question:
          b-col(align-self="end")
            .text-right
              b-button(variant = "primary" @click="saveToProfile").mr-1 Guardar
              b-button(v-b-modal.modal_not_answer variant = "secondary") Not answer
      b-col(sm="12")
          label(v-html="stringToLabel(question.config['text_lang_1'])" class="col-form-label")
          multiselect(v-model="answered" :options="listValues" track-by="name" label="name" :multiple="question.config.multichoice" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pick a value")

</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import Multiselect from 'vue-multiselect';
  import axios from 'axios';

  export default {

    components: { Multiselect },
    name: "questionDropdownProfile",
    props: ['question', 'questionNumber','answer'],
    mixins: [utilMixin],
    data: function() {
      return{
        dataResult: null,
        answered: []
      }
    },
    mounted: function (){
      console.log("Loading dropdown question.")
      if(this.answer !== undefined){
        this.answered = this.answer.answer;
      }
    },
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else return "Not defined";
      },
      saveToProfile: function () {
        console.log("Save Question: ", this.question.id);
        let _this = this;
        if(this.answered === []){
          console.log("Please respond something");
        }
        else {
          this.$emit('sendResponse', {
            id_question: _this.question.id,
            id_category: _this.question.category_id,
            answer: this.answered});
          axios.post('answers/setClientProfileAnswer', {
            question_id: _this.question.id,
            category_id: _this.question.category_id,
            answer: _this.answered,
          })
            .then(function (response) {
              console.log(response);
              console.log("Done Modify question");
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },
    computed:{
      options: function () {
        let ret = [];
        this.question.answers.forEach(function (answer) {
          ret.push({
            text: answer.text_1,
            value: {
              id: answer.internal_id,
              text: answer.text_1
            }
          });
        });
        return ret;
      },
      listValues: function () {
        console.log("Data change");
        let ret = [];
        try {
          //TODO: add langs to read
          this.question.config.answers.forEach(function (answer) {
            ret.push({
              name: answer[0],
              code: answer[1],
              code2: answer[2]
            })
          });
        }
        catch (e) {
          console.error(e);
          return "";
        }

        return ret;
      }
    }
  }
</script>

<style scoped>

</style>