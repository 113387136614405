<template lang="pug">

  b-container(fluid="true")
    b-container(fluid="true")
      b-container.m-4(fluid="true")
        questionFooter(ref="footer" :questionNumber="questionNumber" :nQuestions="nQuestions" :dataAnswer="answered" @saveData="saveToProfileECS" :ecs_id="ecs_id")
        questionHeader(ref="header" :questionNumber="questionNumber" :nQuestions="nQuestions")
        b-row
          b-col(sm="12").mt-3.mb-5
            h6
              label.col-form-label(v-html="stringToLabel(question.config['text_lang_' + getLang])")
            input.mb-1.form-rounded(
              v-if="question.config.lines === 'oneline'"
              @change="changeEvent"
              :maxlength='question.config.maxchars'
              :type='question.config.text_type'
              v-model="answered"
              :class="'form-control'"
              :placeholder=" $t('placeholder.writeHere') ")
            textarea.mb-1.form-rounded(
              v-else-if="question.config.lines === 'multiline'"
              @change="changeEvent"
              :maxlength='question.config.maxchars'
              :type='question.config.text_type'
              v-model="answered"
              :class="'form-control'"
              placeholder="Write here")

            small.error(v-if="outOfRange") {{$t('ecsForm.typeRange')}}
            small.float-right(v-if="question.config.lines === 'multiline'") {{answered.length + " of " + question.config.maxchars + " characters"}}





</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import axios from 'axios';
  import questionHeader from "../questionHeader";
  import questionFooter from "../questionFooter";

  export default {
    name: "questionOpen",
    components: {questionHeader, questionFooter},
    props: ['question', 'questionNumber', 'answer','ecs_id', 'exp_id', 'nQuestions'],
    mixins: [utilMixin],
    data: function(){
      return{
        outOfRange: false,
        answered: "",
        skip: {
          skipped: "",
          custom: false,
          answer: ""
        },
        firstAnswered: false,
        firstSkipped: false,
        oldValue: "",
        langId: 1
      }
    },
    mounted: function(){
      if(this.answer){
        console.log("Loaded Simple question answer");
        this.answered = this.answer.answer;
        this.oldValue = this.answered;

        if(this.answer.skip) {
          this.$refs.footer.skip = this.answer.skip;
          this.skip = {
            skipped: this.$refs.footer.skip.skipped,
            custom: this.$refs.footer.customAnswer(),
            answer: this.$refs.footer.customText()
          }
        }
      }
      else{
        console.log("Loaded Simple not found")
      }
      this.firstAnsweredOrSkipped(this);
    },
    watch:{
      questionNumber: function(newVal,oldVal) {
        if(typeof(this.answer) === 'undefined'){
          this.resetAnswer();
          this.resetSkip();
          this.oldValue = "";

        }else{
          this.answered = this.answer.answer;
          this.oldValue = this.answered;

          if(this.hasBeenSkipped(this.answer)) {
            this.$refs.footer.skip = this.answer.skip;
            this.skip= {
              skipped: this.$refs.footer.skip.skipped,
              custom: this.$refs.footer.customAnswer(),
              answer: this.$refs.footer.customText()
            }
          }else{
            this.resetSkip();
          }
        }
        this.firstAnsweredOrSkipped(this)
      }
    },
    methods:{
      changeEvent: function(){
        this.$refs.footer.onChangeEventHandler();
      },
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          text = this.question.config['text_lang_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      saveToProfileECS: function (text) {
        if (this.question.config.text_type == 'number' && Number(this.answered) < 0){
          console.log("Out of range")
          this.outOfRange = true
          return
        }

        console.log("Save Question: ", this.question.id);
        if (this.$refs.footer.skipQuestion()) {
          this.skip.skipped = this.$refs.footer.skip.skipped;
          this.skip.custom = this.$refs.footer.customAnswer();
          this.skip.answer = this.$refs.footer.customText();
        }

        let _this = this;

        if(!_this.hasBeenAnsweredOrSkipped(this) && text==="next"){
          this.$emit('sendResponse', undefined, text);
        }else if(!_this.hasBeenAnsweredOrSkipped(this) && text==="previous"){
          this.$emit('sendResponse', undefined, text);
        } else {
          if(_this.firstAnswered && _this.hasBeenSkipped(_this)){
            _this.resetAnswer();
            _this.save(text)
          }else if(_this.firstSkipped && _this.hasBeenAnswered(_this)){
            _this.resetSkip()
            _this.save(text);
          }else if(_this.hasBeenAnswered(_this) && _this.hasBeenSkipped(_this)){
            this.$emit('sendResponse', undefined, text);
          }else{
            _this.save(text);
          }
        }
      },

      save(text){
        let _this = this;
        this.$emit('sendResponse', {
          id_question: _this.question.id,
          id_category: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip}, text);

          if (this.exp_id) {
            var ecs_exp_id = this.exp_id
            console.log("exp_id:",ecs_exp_id)
          } else {
            var ecs_exp_id = this.ecs_id
          }

        let oldQuestionId = _this.question.id;
        let auxAnswer = _this.answered;
        let auxOldValue = _this.oldValue
        axios.post(this.$parent.url_answer, {
          question_id: _this.question.id,
          ecs_id: ecs_exp_id,
          category_id: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip
        })
          .then(function (response) {
            console.log(response);
            console.log("Done Modify question");

            if(oldQuestionId === 28  && auxOldValue !== auxAnswer) {
              _this.$router.go(0);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        if(_this.questionNumber+1 === _this.nQuestions && text == 'next'){
          _this.$refs.footer.showSubmitModal();
        }
      },

      hasBeenAnswered: function (answer) {

        if(answer) return answer.answered !== "";
        return false;
      },

      hasBeenSkipped: function (answer) {
        if (answer && answer.skip) return answer.skip.skipped || answer.skip.custom;
        return false;
      },

      resetAnswer: function () {
        this.answered = "";
      },

      resetSkip: function () {
        this.$refs.footer.resetModal();
        this.skip = {
          skipped: "",
          custom: false,
          answer: ""
        }
      },

      hasBeenAnsweredOrSkipped: function (answer) {
        return this.hasBeenSkipped(answer) || this.hasBeenAnswered(answer);
      },

      firstAnsweredOrSkipped: function (answer) {
        this.firstAnswered = this.hasBeenAnswered(answer);
        this.firstSkipped = this.hasBeenSkipped(answer);
      }



    },
    computed: {
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      }
    }
  }
</script>

<style scoped>
  .questions{
    background-color: var(--light-gray-secondary);
  }

  .form-rounded {
    border-radius: 1rem;
  }

  .error {
    color: var(--dark-red);
  }
</style>
