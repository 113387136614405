<template lang="pug">
  .createECS
    b-container.p-5(fluid="true")
      b-row
        p Select a registered ECS.
      b-row
        b-col(cols="12")
          b-card
            label.typo__label Select ECS
            multiselect(
              v-model="selected_ecs"
              :options="options"
              :searchable="true"
              :close-on-select="true"
              :show-labels="true"
              placeholder="Choose the ECS"
              label="name"
              track-by="name"
            )
            b-button(@click="addExperience").mt-2 Start

</template>

<script>

import Multiselect from 'vue-multiselect';
import axios from 'axios';

export default {
  name: 'create_new_experience',
  components: { Multiselect },
  data: function(){
    return {
      options:[],
      selected_ecs: {}
    }
  },
  mounted: async function(){
    let _this = this;
    axios.get('clients/allecs')
      .then(function (response) {
        console.log("Asked all ECS: ",response.data);
        _this.options = response.data;
      })
      .catch(function (error) {
        console.log("Menu get client error:", error);
        // console.log(error);
      });
  },
  methods:{
    addExperience(){
      let _this = this;
      console.log("Add experience");
      if(!_this.selected_ecs) {
        console.log("empty ecs");
        return;
      }
      axios.post('clients/createExpirience',{
        "id_ecs": _this.selected_ecs.id
      })
        .then(response => {
          console.log(response)
          _this.$router.replace('/ecs-experience?id=' + response.data, () => _this.$router.go(0));
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  }
}
</script>

<style scoped>
  .createECS{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }
</style>
