<template lang="pug">
  b-container(fluid="true")
    b-container(fluid="true")
      b-container.m-4(fluid="true")
        questionFooter(ref="footer" :questionNumber="questionNumber" :nQuestions="nQuestions" :dataAnswer="answered" @saveData="saveToProfileECS" :ecs_id="ecs_id")
        questionHeader(ref="header" :questionNumber="questionNumber" :nQuestions="nQuestions")
        b-row
          b-col(sm="12")
            h6
              label.col-form-label() {{ stringToLabel(question.config['text_lang_' + getLang]) }}
            b-table(:items="answered" :key="key" :fields="fields" fixed).custom-border.mb-3
              template(#head()="data")
                span(v-on:click="setOrder(question.config.columns[data.field.index].internal_id)").pointer {{$t('column')}} {{ data.field.index+1 }}
                  div( :class="{'arrow-up ':order.column == question.config.columns[data.field.index].internal_id &&  order.type ==1,'arrow-down':order.column == question.config.columns[data.field.index].internal_id && order.type == -1,'arrow-right': order.column != question.config.columns[data.field.index].internal_id}")
                span(v-on:click="setOrder(question.config.columns[data.field.index].internal_id)").pointer  <br> {{ data.label }}
              template(#head(remove_button)="data" )
                span
              template(#table-colgroup="scope")
                col(v-for="field in scope.fields" :key="field.key" :style="{ width: field.key === 'remove_button' ? '100px' : '', 'padding-right': field.key === 'remove_button' ? '0px' : ''}")
              template(v-slot:cell(remove_button)="data")
                b-button(size="sm" pill block variant="outline-danger" @click="removeRow(data.item)" style="float: right;") {{ $t('buttons.remove') }}
                b-button(size="sm" pill block variant="outline-primary" v-if="!active || active.index != data.index" @click="editRow(data)" style="float: right;") {{ $t('buttons.edit') }}
                b-button(size="sm" pill block variant="outline-success" v-else-if="active && active.index == data.index" @click="editRow(data)" style="float: right;") {{ $t('buttons.add') }}
              template(v-slot:cell()="data")
                div(v-if="active && data.index == active.index")
                  questionOpenCol(v-if="question.config.columns[data.field.index].type === 'open_answer'" v-on:changeEvent="changeEvent" :ref="question.config.columns[data.field.index].internal_id" :question="question.config.columns[data.field.index]" :sendResult="getData(data.value)" :errorFound="errorFound")
                  questionLocationCol(v-else-if="question.config.columns[data.field.index].type === 'location_answer'" v-on:changeEvent="changeEvent" :ref="question.config.columns[data.field.index].internal_id" :question="question.config.columns[data.field.index]" :sendResult="data.value")
                  questionRadioCol(v-else-if="question.config.columns[data.field.index].type === 'single_answer'" v-on:changeEvent="changeEvent" :ref="question.config.columns[data.field.index].internal_id" :question="question.config.columns[data.field.index]" :sendResult="data.value")
                  questionCheckCol(v-else-if="question.config.columns[data.field.index].type === 'multi_answer'" v-on:changeEvent="changeEvent" :ref="question.config.columns[data.field.index].internal_id" :question="question.config.columns[data.field.index]" :sendResult="data.value")
                  questionRangeCol(v-else-if="question.config.columns[data.field.index].type === 'range_answer'" v-on:changeEvent="changeEvent" :ref="question.config.columns[data.field.index].internal_id" :question="question.config.columns[data.field.index]" :sendResult="getData(data.value)")
                  questionDropdownCol(v-else-if="question.config.columns[data.field.index].type === 'drowdown_answer'" v-on:changeEvent="changeEvent" :ref="question.config.columns[data.field.index].internal_id" :question="question.config.columns[data.field.index]" :sendResult="data.value")
                  questionCalendarCol(v-else-if="question.config.columns[data.field.index].type === 'calendar_answer'" v-on:changeEvent="changeEvent" :ref="question.config.columns[data.field.index].internal_id" :question="question.config.columns[data.field.index]" :sendResult="getData(data.value)")
                div(v-else) {{ getData(data.value) }}
            b-row.mr-1.mt-0
              template(v-if="!active" v-for="question in question.config.columns")
                b-col.mb-4
                  questionOpenCol(v-if="question.type === 'open_answer'" :ref="question.internal_id" :question="question" v-on:changeEvent="changeEvent" :sendResult="null" :errorFound="errorFound")
                  questionLocationCol(v-if="question.type === 'location_answer'" :ref="question.internal_id" v-on:changeEvent="changeEvent" :question="question" :sendResult="null")
                  questionRadioCol(v-if="question.type === 'single_answer'" v-on:changeEvent="changeEvent" :ref="question.internal_id" :question="question" :sendResult="null")
                  questionCheckCol(v-if="question.type === 'multi_answer'" v-on:changeEvent="changeEvent" :ref="question.internal_id" :question="question" :sendResult="[]")
                  questionRangeCol(v-if="question.type === 'range_answer'" v-on:changeEvent="changeEvent" :ref="question.internal_id" :question="question" :sendResult="null")
                  questionDropdownCol(v-if="question.type === 'drowdown_answer'" v-on:changeEvent="changeEvent" :ref="question.internal_id" :question="question" :sendResult="[]")
                  questionCalendarCol(v-if="question.type === 'calendar_answer'" v-on:changeEvent="changeEvent" :ref="question.internal_id" :question="question" :sendResult="null")
              b-col(v-if="!active"  cols="1" style="width: 100px").mt-3.pr-2.align-content-center
                b-button.addButton(size="sm" pill block @click="addLine") {{ $t('buttons.add') }}
              b-col.p-2(cols="12" v-if="errorFound.text")
                h3 Error: {{ this.errorFound.text }}


</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import questionOpenCol from '../tableCombinationQuestions/questionOpenCol';
  import questionLocationCol from '../tableCombinationQuestions/questionLocationCol';
  import questionRadioCol from '../tableCombinationQuestions/questionRadioCol';
  import questionRangeCol from '../tableCombinationQuestions/questionRangeCol';
  import questionDropdownCol from '../tableCombinationQuestions/questionDropdownCol';
  import questionCheckCol from '../tableCombinationQuestions/questionCheckCol';
  import questionCalendarCol from '../tableCombinationQuestions/questionCalendarCol';
  import questionHeader from "../questionHeader";
  import questionFooter from "../questionFooter";

  import axios from 'axios';

  export default {

    components: {
      questionOpenCol,
      questionRadioCol,
      questionRangeCol,
      questionDropdownCol,
      questionCheckCol,
      questionLocationCol,
      questionCalendarCol,
      questionHeader,
      questionFooter,
    },
    name: "questionTableCombination",
    props: ['question', 'questionNumber','ecs_id','exp_id', 'answer','nQuestions'],
    mixins: [utilMixin],
    mounted: function() {

      if (this.answer) {
        this.answered = this.answer.answer;
        let i = 0;
        this.checkArray();

        this.answered.forEach(function(e) {
          if (e['52_1580141191112']) {
            e['189_1652422479138'] = {
              text: e['52_1580141191112'].text,
              id_custom: "52_activities",
              id_custom_answer: e['52_1580141191112'].id_custom_answer
            };
          }
          if (e['52_1580141192971'] && !e['189_1652422532173']) {
            e['189_1652422532173'] = e['52_1580141192971'];
            e['189_1652422532173'].forEach(function(element) {
              element.id_custom = "52_months";
              element.id_custom_answer = "";
            });
          }
          if (e['52_1580141231711'] && !e['52_1653918097568']) {
            e['52_1580141231711'] = e['52_1653918097568'];
            //e['52_1580141231711'].id_custom = "52_participants";
          }
          e.order = i;
          i++;
        });
        if (this.answer.skip) {
          this.$refs.footer.skip = this.answer.skip;
          this.skip = {
            skipped: this.$refs.footer.skip.skipped,
            custom: this.$refs.footer.customAnswer(),
            answer: this.$refs.footer.customText()
          }
        }
      }
      else{
        console.log("Loaded Simple not found")
      }
        this.firstAnsweredOrSkipped(this);
    },
    watch:{
      questionNumber: function(newVal,oldVal) {
        this.editing = false;
        this.errorFound = {};
        if(typeof(this.answer) === 'undefined'){
          this.resetAnswer();
          this.resetSkip();

        }else{
          this.active = null;
          this.answered = this.answer.answer;
          let i = 0;
		  console.log('watch question number', this.answered, typeof this.answered);

		  this.checkArray();
          this.answered.forEach(function(e){
            e.order = i;
            i++;
          });
          if(this.hasBeenSkipped(this.answer)) {
            this.$refs.footer.skip = this.answer.skip;
            this.skip= {
              skipped: this.$refs.footer.skip.skipped,
              custom: this.$refs.footer.customAnswer(),
              answer: this.$refs.footer.customText()
            }
          }else{
            this.resetSkip();
          }
        }
        this.firstAnsweredOrSkipped(this)
      }
    },
    methods:{
	  checkArray: function(){
		  if(!Array.isArray(this.answered))
		  {
			  if(this.question.config.columns.length === 2 && this.question.config.columns[1].type === 'calendar_answer')
			  {
				  let new_answers =  [];
				  let answer = [];
				  let answered = {};
				  let new_answer = null;

				  switch(this.question.config.columns[0].type)
				  {
					  case "multi_answer":

						  //let old_answer = this.answered;
						  this.answered.answer.forEach(function(old_answer){
							  console.log('old answer',old_answer);
							  new_answer =
								  {
									  id: old_answer.internal_id,
									  text: old_answer.text_1,
									  id_custom_answer: old_answer.custom_ids
								  }

							  new_answers.push(new_answer);
						  });

						  if(this.answered.answer.isCustom || this.answered.answer.answer_custom_text)
						  {
							  let new_answer =
								  {
									  id: "other",
									  text: this.answered.answer.answer_custom_text,
								  }
							  new_answers.push(new_answer);
						  }

						  answered[this.question.id+'_updated'] = new_answers;
						  answer.push(answered);
						  this.answered = JSON.parse(JSON.stringify(answer));
						  break;
					  case "open_answer":

						  console.log('converting open_answer to table');
						  new_answer =
							  {
								  id: [this.question.id+'_updated'],
								  text: this.answered,
							  }
						  new_answers.push(new_answer);
						  answered[this.question.id+'_updated'] = new_answers;
						  answer.push(answered);
						  this.answered = JSON.parse(JSON.stringify(answer));
						  break;
					  case "single_answer":
						  if(this.answered.answer !== '')
						  {
							  new_answer =
								  {
									  id: this.answered.answer.internal_id,
									  text: this.answered.answer.text_1,
									  id_custom_answer: this.answered.answer.custom_ids
								  }
							  answered[this.question.id+'_updated'] = new_answer;
							  answer.push(answered);
							  this.answered = JSON.parse(JSON.stringify(answer));
						  }
						  else{
							  this.answered = [];
						  }


						  break;
				  }
			  }
			  else
			  {
				  this.answered = [];
			  }


		  }
	  },
      setOrder: function(order)
      {
        if(this.order.column == order)
        {
          this.order.type = this.order.type*-1;
        }
        else
        {
          this.order.column = order;
          this.order.type = 1;
        }

        if(this.active)
        {
          this.active = null;
        }
        this.answered.sort(this.dynamicSort());

      },
      dynamicSort: function () {
        let property = this.order.column;
        let sortOrder = this.order.type;
        return function (a,b) {
          let result;
          let _a = "z";
          let _b = "z";
          if(!a[property] || typeof a[property] === "undefined")
          {
            _a = "z";
          }
          else if(typeof  a[property] == "string")
          {
            if(a[property])
            {
              _a = a[property].toLowerCase();
            }

          }
          else if(typeof  a[property] == "array" || (typeof a[property].length !== "undefined" && a[property].length))
          {
            if(a[property] && a[property].length)
            {
              _a = a[property].map(function(element){
                if(typeof element.text !== "undefined")
                {
                  return element.text;
                }
                else if(typeof element.name !== "undefined")
                {
                  return element.name;
                }
              }).join(', ').toLowerCase();
            }
          }
          else if(typeof  a[property] == "object" && a[property])
          {
            if(typeof a[property].text !== "undefined")
            {
              _a = a[property].text;
            }
            else if(typeof a[property].name !== "undefined")
            {
              _a = a[property].name;
            }

          }
          else if(a[property]){
            _a = a[property];
          }

          if(!b[property] || typeof b[property] === "undefined")
          {
            _b = "z";
          }
          else if(typeof  b[property] == "string")
          {
            if(b[property])
            {
              _b = b[property].toLowerCase();
            }
          }
          else if(typeof  b[property] == "array" || (typeof b[property].length !== "undefined" && b[property].length))
          {
            if(b[property] && b[property].length)
            {
              _b = b[property].map(function(element){
                if(typeof element.text !== "undefined")
                {
                  return element.text;
                }
                else if(typeof element.name !== "undefined")
                {
                  return element.name;
                }
              }).join(', ').toLowerCase();
            }
          }
          else if(typeof  b[property] == "object" && b[property])
          {
            if(typeof b[property].text !== "undefined")
            {
              _b = b[property].text;
            }
            else if(typeof b[property].name !== "undefined")
            {
              _b = b[property].name;
            }
          }
          else if(b[property]){
            _b = b[property];
          }



          result = (_a < _b) ? -1 : (_a > _b) ? 1 : 0;

          return result * sortOrder;
        }
      },
      editRow: function(item)
      {
        let error = false;
        this.errorFound.text = "";
        if(this.active)
        {
          let _this = this;
          let object = {};

          let somethingFound = false;
          this.question.config.columns.forEach(function (col) {
            if(_this.$refs[col.internal_id].question.text_type == 'number' &&
                _this.$refs[col.internal_id].getDataResult() &&
                Number(_this.$refs[col.internal_id].getDataResult().text) < 0)
            {
              _this.errorFound.text = _this.$t('ecsForm.typeRange');
              error = true;
            }

            object[col.internal_id] = _this.$refs[col.internal_id].getDataResult();

            if(!error)
            {
              somethingFound = true
            }
            if(_this.$refs[col.internal_id].question.type === 'multi_answer')
            {
              _this.$refs[col.internal_id].dataResult = [];
            }
            else
            {
              _this.$refs[col.internal_id].dataResult = null;
            }

          });
          let order = this.answered[this.active.index].order;
          object.order = order;
          this.answered[this.active.index] = object;
          this.key++;
        }

        if(this.active && this.active.index == item.index && !error)
        {
          this.active = null;
          this.saveToProfileECS();
        }
        else {
          this.active = item;
        }
      },
      changeEvent: function(){
        this.$refs.footer.onChangeEventHandler();
      },
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
		else if(!this.question.config['text_lang_1'])
        {
	        return "";
        }
        else {
          text = this.question.config['text_lang_1'];
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      cancelLine: function () {
        this.editing = false;
      },
      removeRow: function (data) {
        this.active = null;
        this.answered = this.answered.filter(function (item) {
          return item !== data;
        });
        this.saveToProfileECS();
      },
      getData: function (data){
        let ret = "";
        if(Array.isArray(data)){
          data.forEach(function (item) {
            if(item.name) ret += item.name+", ";
            else if(item.text) ret += item.text+", ";
            else {
              ret += item;
            }
          });
          ret = ret.substring(0,ret.length-2);
          return ret;
        }
        else if(typeof data === 'object' && data !== null) {
          if(data.text) return data.text;
          else if(data.name) return data.name;
          else return "Not property found";
        }
        else
        {
			data = '';
			return data;
        }
      },
      addLine: function () {
        let _this = this;
        let _Vue = this.Vue;
        let error = false;
        let object = {};

        let somethingFound = false;
        this.question.config.columns.forEach(function (col) {
          if(_this.$refs[col.internal_id][0].question.text_type == 'number' &&
            _this.$refs[col.internal_id][0].getDataResult() &&
            Number(_this.$refs[col.internal_id][0].getDataResult().text) < 0){
              _this.errorFound.text = _this.$t('ecsForm.typeRange');
              error = true;
              console.log('error found');
          }

          object[col.internal_id] = _this.$refs[col.internal_id][0].getDataResult();
          if(!error) somethingFound = true
          if(_this.$refs[col.internal_id][0].question.type === 'multi_answer') {
            _this.$refs[col.internal_id][0].dataResult = [];
          }
          else _this.$refs[col.internal_id][0].dataResult = null;
          //answers.push(object);
        });

        //Save data
        if(error)
        {
          console.log(this.errorFound);
        }
        else
        {
          this.errorFound = {};
          this.answered.push(object);
        }
        this.auxData = {};
        this.saveToProfileECS();
        //this.editing = false;
      },
      onHidden() {
        // Return focus to the button once hidden
        this.$refs.button.focus()
      },
      saveToProfileECS: function (text) {
        if (this.$refs.footer.skipQuestion()) {
          this.skip.skipped = this.$refs.footer.skip.skipped;
          this.skip.custom = this.$refs.footer.customAnswer();
          this.skip.answer = this.$refs.footer.customText();
        }

        let _this = this;

        if(!_this.hasBeenAnsweredOrSkipped(this) && text==="next"){
          this.$emit('sendResponse', undefined, text);
        }else if(!_this.hasBeenAnsweredOrSkipped(this) && text==="previous"){
          this.$emit('sendResponse', undefined, text);
        } else {
          if(_this.firstAnswered && _this.hasBeenSkipped(_this)){
            _this.resetAnswer();
            _this.save(text)
          }else if(_this.firstSkipped && _this.hasBeenAnswered(_this)){
            _this.resetSkip()
            _this.save(text);
          }else if(_this.hasBeenAnswered(_this) && _this.hasBeenSkipped(_this)){
            this.$emit('sendResponse', undefined, text);
          }else{
            _this.save(text);
          }
        }
      },

      save: function(text){
        let _this = this;
        let res = [];

        function compareIndexFound(a, b) {
          if (a.order < b.order) { return -1; }
          if (a.order > b.order) { return 1; }
          return 0;
        }

        _this.answered.sort(compareIndexFound);
        _this.answered.forEach(function(item) {
          let tempItem = Object.assign({}, item);
          delete tempItem.order;
          res.push(tempItem);
        });
        this.answered.sort(this.dynamicSort());

        this.$emit('sendResponse', {
          id_question: _this.question.id,
          id_category: _this.question.category_id,
          answer: res,
          skip: _this.skip}, text);

        if (this.exp_id) {
          var ecs_exp_id = this.exp_id
        } else {
          var ecs_exp_id = this.ecs_id
        }

        axios.post(this.$parent.url_answer, {
          question_id: _this.question.id,
          ecs_id: ecs_exp_id,
          category_id: _this.question.category_id,
          answer: res,
          skip: _this.skip
        })
          .then(function (response) {
            //console.log(response);

          })
          .catch(function (error) {
            console.log(error);
          });
        if(_this.questionNumber+1 === _this.nQuestions && text == 'next'){
          _this.$refs.footer.showSubmitModal();
        }
      },

      hasBeenAnswered: function (answer) {

        if(answer) return answer.answered.length>0;
        return false;
      },

      hasBeenSkipped: function (answer) {
        if (answer && answer.skip) return answer.skip.skipped || answer.skip.custom;
        return false;
      },

      resetSkip: function () {
        this.$refs.footer.resetModal();
        this.skip = {
          skipped: "",
          custom: false,
          answer: ""
        }
      },

      hasBeenAnsweredOrSkipped: function (answer) {
        return this.hasBeenSkipped(answer) || this.hasBeenAnswered(answer);
      },

      firstAnsweredOrSkipped: function (answer) {
        this.firstAnswered = this.hasBeenAnswered(answer);
        this.firstSkipped = this.hasBeenSkipped(answer);
      },

      resetAnswer: function () {
        this.answered = [];
      },


    },
    data: function () {
      return{
        answered: [],
        editing: true,
        auxData: {},
        errorFound: {},
        busy: false,
        skip: {
          skipped: "",
          custom: false,
          answer: ""
        },
        firstAnswered: false,
        firstSkipped: false,
        langId: 1,
        active: null,
        key: 1,
        order:
        {
          column: null,
          type: 1
        }
      }
    },
    computed: {

      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      },
      fields: function () {
        let ret = [];
        let langId = this.getLang;
        let lang = '';
        if(!this.question.config) return [];

        this.question.config.columns.forEach( function (col,index) {
          if(!col['text_' + langId])
            lang = 'text_1';
          else
            lang = 'text_' + langId;

          let label = '';
		  if(col[lang])
		  {
			  if(col[lang].split('-')[1]) label = col[lang].split('-')[1]
			  else if(col[lang].split('–')[1]) label = col[lang].split('–')[1]
			  else label = col[lang]
		  }
          ret.push({
            key: col.internal_id,
            label: label,
            index: index
          });
        });
        ret.push({key: 'remove_button', label: '', index: this.question.config.columns.length});
        return ret;
      }
    }

  }
</script>

<style scoped>
  .custom-border{
    border-bottom: 1px solid rgb(128,128,128) !important;
  }
  .questions{
    background-color: var(--light-gray-secondary);
  }
  .addButton{
    color: #fff;
    background-color: var(--green-primary);
    border-color: var(--green-primary);
    font-weight: bold;
    width: 100px;
  }

  .pointer
  {
    cursor:pointer;
  }

  .arrow-up {
    width: 5px;
    height: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 2px;
  }

  .arrow-down {
    width: 5px;
    height: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black ;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 2px;
  }

  .arrow-right {
    width: 5px;
    height: 5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;

    border-left: 5px solid black;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 2px;
  }

</style>

<style>
  .table thead th{
    vertical-align: top !important;
  }
</style>
