<template lang="pug">
  b-container(fluid="true")
    b-container(fluid="true")
      b-container.m-4(fluid="true")
        questionFooter(ref="footer" :questionNumber="questionNumber" :nQuestions="nQuestions" :dataAnswer="answered" @saveData="saveToProfileECS" :ecs_id="ecs_id")
        questionHeader(ref="header" :questionNumber="questionNumber" :nQuestions="nQuestions")
        b-row.p-1
          b-col(sm="12").mb-1
            h6
              label.col-form-label(v-html="stringToLabel(question.config['text_lang_' + getLang])")
            template(v-for='answer in question.config.answers')
              b-row.mb-1
                b-col(sm="6")
                  span {{ getAnswers(answer) }}
                b-col(sm="6")
                  //b-form-input.align-content-end(v-model="answered[answer.internal_id]" min="0" max="100" type="number" step="1" :state="valid")
                  b-form-radio-group(style="text-align: right;" :id='answer.internal_id' @change="changeEvent" v-model="answered[answer.internal_id]" :options="[1,2,3,4,5,6,7]" :name="'radio_form_'+answer.internal_id")
                b-col(sm="12")
                  div.custom-separator
                  //template(v-for='optionValue in [1,2,3,4,5,6,7]')
                    //b-form-radio(@change="changeEvent" v-model="answered[answer.internal_id]" :name="'radio_form_'+question.id" :id='answer.internal_id' :value="optionValue") {{ optionValue }}


</template>

<script>
  import axios from 'axios';
  import utilMixin from "../../mixin/utilsMixin";
  import questionHeader from "../questionHeader";
  import questionFooter from "../questionFooter";


  export default {
    name: "questionScoreECS",
    components: {questionHeader, questionFooter},
    data: function(){
      return{
        selected: "{}",
        selected_custom: "",
        answered: {},
        valid: null,
        skip: {
          skipped: false,
          custom: false,
          answer: ""
        },
        firstAnswered: false,
        firstSkipped: false,
        langId: 1
      }
    },
    mounted: function(){

      if(this.answer){
        let _this = this;
        this.answered = {}
        this.question.config.answers.forEach( answer => {
          _this.$set(_this.answered, answer.internal_id, _this.answer.answer[answer.internal_id]);
        });

        if(this.answer.skip) {
          this.$refs.footer.skip = this.answer.skip;
          this.skip = {
            skipped: this.$refs.footer.skip.skipped,
            custom: this.$refs.footer.customAnswer(),
            answer: this.$refs.footer.customText()
          }
        }
      }else{
        this.resetAnswer();
      }

      this.firstAnsweredOrSkipped(this)

    },
    watch:{
      questionNumber: function(newVal,oldVal) {

        if(typeof(this.answer) === 'undefined'){

          this.resetAnswer();
          this.resetSkip();

        }else{

          let _this = this;
          this.answered = {};
          this.question.config.answers.forEach(answer => {
            _this.$set(_this.answered, answer.internal_id, _this.answer.answer[answer.internal_id]);
          });

          if(this.hasBeenSkipped(this.answer)) {
            this.$refs.footer.skip = this.answer.skip;
            this.skip= {
              skipped: this.$refs.footer.skip.skipped,
              custom: this.$refs.footer.customAnswer(),
              answer: this.$refs.footer.customText()
            }
          }else{
            this.resetSkip();
          }
        }
        this.firstAnsweredOrSkipped(this)
      }
    },
    computed: {
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      },
      getLangOptions: function () {
        let languageId = this.$store.getters.getLanguage.id;
        let language = "";
        switch (languageId) {
          case 2:
            language = "text_cat";
            break;
          case 3:
            language = "text_fr";
            break;
          case 4:
            language = "text_es";
            break;
          case 5:
            language = "text_de";
            break;
          case 6:
            language = "text_pt";
            break;
          default:
            language = "text_"+this.$store.getters.getLanguage.lang;
            break;
        }
        return language;
      }
    },
    props: ['question', 'questionNumber','answer','ecs_id', 'exp_id', 'nQuestions'],
    mixins: [utilMixin],
    methods:{
      saveToProfileECS: function (text) {

        console.log("Save Question: ", this.question.id);
        if (this.$refs.footer.skipQuestion()) {
          this.skip.skipped = this.$refs.footer.skip.skipped;
          this.skip.custom = this.$refs.footer.customAnswer();
          this.skip.answer = this.$refs.footer.customText();
        }

        let _this = this;

        /*
        if(!_this.hasBeenAnsweredOrSkipped(this) && text==="next"){
          this.$emit('sendResponse', undefined, text);
        }else if(!_this.hasBeenAnsweredOrSkipped(this) && text==="previous"){
          this.$emit('sendResponse', undefined, text);
        } else {
          if(_this.firstAnswered && _this.hasBeenSkipped(_this)){
            _this.resetAnswer();
            _this.save(text)
          }else if(_this.firstSkipped && _this.hasBeenAnswered(_this)){
            _this.resetSkip()
            _this.save(text);
          }else if(_this.hasBeenAnswered(_this) && _this.hasBeenSkipped(_this)){
            this.$emit('sendResponse', undefined, text);
          }else{
            _this.save(text);
          }
        }*/
        _this.save(text);
      },
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          text = this.question.config['text_lang_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },

      save(text){
        let _this = this;
        this.$emit('sendResponse', {
          id_question: _this.question.id,
          id_category: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip}, text);

        if (this.exp_id) {
          var ecs_exp_id = this.exp_id
          console.log("exp_id:",ecs_exp_id)
        } else {
          var ecs_exp_id = this.ecs_id
        }

        axios.post(this.$parent.url_answer, {
          question_id: _this.question.id,
          ecs_id: ecs_exp_id,
          category_id: _this.question.category_id,
          answer: _this.answered,
          skip: _this.skip
        })
          .then(function (response) {
            console.log(response);
            console.log("Done Modify question");

          })
          .catch(function (error) {
            console.log(error);
          });
        if(_this.questionNumber+1 === _this.nQuestions && text == 'next'){
          _this.$refs.footer.showSubmitModal();
        }
      },

      hasBeenAnswered: function (answer) {
        //Todo: says if answer.answered has been answered

      },

      hasBeenSkipped: function (answer) {
        if (answer && answer.skip) return answer.skip.skipped || answer.skip.custom;
        return false;
      },

      resetAnswer: function () {
        //Todo: makes this.answered empty
        this.answered = {}
        let _this = this;
        this.question.config.answers.forEach( answer => {
          _this.$set(_this.answered, answer.internal_id, null);
        });
      },

      resetSkip: function () {
        this.$refs.footer.resetModal();
        this.skip = {
          skipped: "",
          custom: false,
          answer: ""
        }
      },

      hasBeenAnsweredOrSkipped: function (answer) {
        return this.hasBeenSkipped(answer) || this.hasBeenAnswered(answer);
      },

      firstAnsweredOrSkipped: function (answer) {
        this.firstAnswered = this.hasBeenAnswered(answer);
        this.firstSkipped = this.hasBeenSkipped(answer);
      },

      changeEvent: function(){
        this.$refs.footer.onChangeEventHandler();
      },
      changeAnswer: function (question) {
        if(this.answered.answer !== `${question.id}_custom`){
          // this.answered.answer_custom_text = "";
          this.$set(this.answered, 'answer_custom_text', "")
        }
        //   console.log(value);
        //   let question_id = this.question.id;
        //   let category_id = this.question.category_id;
        //   let answer = value;
        //   let selected_custom = this.selected_custom;
        //
        //   axios.post('http://localhost:3030/api/answers/setAnswer',{
        //     question_id: question_id,
        //     category_id: category_id,
        //     answer: answer,
        //     selected_custom: selected_custom
        //   })
        //     .then(function (response) {
        //       console.log(response);
        //       console.log("Done Modify question");
        //     })
        //     .catch(function (error) {
        //       console.log(error);
        //     });
      },
      getAnswers: function (answer) {
        if(answer[this.getLangOptions])
          return answer[this.getLangOptions];
        else
          return answer['text_1'];
      }
    }
  }
</script>

<style scoped>
  .custom-separator{
    height: 2px;
    background-color: #666666;
  }
  .questions{
    background-color: var(--light-gray-secondary);
  }
</style>
