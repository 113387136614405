<template>
  <path vector-effect="non-scaling-stroke"
        d="M 41.90,21.10
           C 41.70,20.50 41.20,20.10 40.60,20.10
             40.60,20.10 30.30,19.40 30.30,19.40
             30.30,19.40 26.40,9.80 26.40,9.80
             26.10,9.00 25.20,8.70 24.50,9.00
             24.10,9.20 23.80,9.40 23.70,9.80
             23.70,9.80 19.80,19.40 19.80,19.40
             19.80,19.40 9.40,20.00 9.40,20.00
             8.60,20.10 8.00,20.80 8.00,21.60
             8.00,22.00 8.20,22.40 8.50,22.60
             8.50,22.60 16.40,29.20 16.40,29.20
             16.40,29.20 13.90,39.20 13.90,39.20
             13.70,40.00 14.20,40.80 15.00,41.00
             15.40,41.10 15.80,41.00 16.20,40.80
             16.20,40.80 25.00,35.30 25.00,35.30
             25.00,35.30 33.80,40.80 33.80,40.80
             34.50,41.20 35.40,41.00 35.90,40.30
             36.10,40.00 36.20,39.50 36.10,39.10
             36.10,39.10 33.60,29.10 33.60,29.10
             33.60,29.10 41.50,22.50 41.50,22.50
             41.90,22.30 42.10,21.60 41.90,21.10 Z
           M 24.70,10.40M 31.90,28.30
           C 31.60,28.50 31.50,28.90 31.60,29.20
             31.60,29.20 34.00,39.10 34.00,39.10
             34.00,39.10 25.40,33.70 25.40,33.70
             25.10,33.50 24.70,33.50 24.40,33.70
             24.40,33.70 15.80,39.10 15.80,39.10
             15.80,39.10 18.20,29.20 18.20,29.20
             18.30,28.90 18.20,28.50 17.90,28.30
             17.90,28.30 10.10,21.80 10.10,21.80
             10.10,21.80 20.20,21.10 20.20,21.10
             20.50,21.10 20.80,20.90 21.00,20.50
             21.00,20.50 24.80,11.10 24.80,11.10
             24.80,11.10 28.60,20.50 28.60,20.50
             28.70,20.80 29.00,21.00 29.40,21.10
             29.40,21.10 39.50,21.80 39.50,21.80
             39.50,21.80 31.90,28.30 31.90,28.30 Z" />
</template>

<script>
  export default {
    name: "EcsExpIcon"
  }
</script>

<style scoped>

</style>