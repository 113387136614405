import Vue from 'vue'
import App from './App.vue'
import Login from './views/Login'
import router from './router'
import store from './store'
import Axios from 'axios';

//import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-suggestion/dist/vue-suggestion.css';
import VueMeta from 'vue-meta'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import { i18n } from './i18n/i18n.js';

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueMeta);

import VueSuggestion from 'vue-suggestion';

Vue.use(VueSuggestion);
//Vue.use(VueAwesomeSwiper)



Vue.config.productionTip = false;

// set auth header
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;

// set API's baseURL
Axios.defaults.baseURL = 'https://edicitnet.icra.cat/api/';
// Axios.defaults.baseURL = 'http://localhost/api/';




new Vue({
  i18n,
  router,
  store,
  render: function (h) { return h(App) },
}).$mount('#app');
