<template lang="pug">
  .about_content.generalBackground
    b-container(fluid="true")
      b-tabs.homeTab(content-class="mt-3" fill active-nav-item-class="activeTabTitle" nav-class="tabTitle" )
        b-tab.tabText(:title="$t('abouts.functionalities_search.title')" active style="border-color: var(--green-primary) var(--green-primary) #fff;" id="tab1" )
          FunctionalitiesSearch
        b-tab.tabText(:title="$t('abouts.functionalities_assessment.title')" id="tab2")
          FunctionalitiesAssessment
        b-tab.tabText(:title="$t('abouts.functionalities_plan.title')" id="tab3")
          FunctionalitiesPlan
</template>

<script>

import FunctionalitiesSearch from "@/components/about/functionalities_search.vue";
import FunctionalitiesAssessment from "@/components/about/functionalities_assessment.vue";
import FunctionalitiesPlan from "@/components/about/functionalities_plan.vue";

export default {
  name: "functionalities",
  components: {
    FunctionalitiesSearch,
    FunctionalitiesAssessment,
    FunctionalitiesPlan
  }
}
</script>

<style>

h5 {
  color: var(--gren-primary);
}


</style>


<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

</style>
