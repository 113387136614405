<template lang="pug">
  .about_unlock.generalBackground
    b-container.p-4.m-4(fluid="true")
      b-row
        b-col
          h2 {{ $t('abouts.unlock.title') }}
          p.text-center
            b-img(:src="$t('abouts.unlock.image')" style="width: 50%")


</template>

<script>
export default {
name: "unlock"
}
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }
</style>