<template lang="pug">
  .edibleList
    b-jumbotron(header="")
      b-container.map.p-4(fluid="true")
        b-row
          b-col(cols=12)
            b-table(striped hover bordered :fields="fields" :items="filteredAnswers" :per-page="perPage" :current-page="currentPage" small).p-2
              template(#head(ecs_id)="data")
                span ID
              template(#head(ecs_name)="data")
                span {{$t('list.name')}}
              template(#head(ecs_type)="data")
                span {{ $t('edibleMap.advancedSearch.typeOfProfile') }}
              template(#head(description)="data")
                span {{ $t('ecs_profile.description.title') }}
              template(#cell(ecs_name)="data")
                a(:href="'/showEcsProfile/'+data.item.ecs_id")
                  span.pr-2 {{ data.item.ecs_name }}
              template(#cell(ecs_type)="data")
                span(v-if="data.item.interactive") {{ $t('list.interactive') }}
                span(v-else) {{ $t('list.non_interactive') }}
              template(#cell(description)="data")
                span {{JSON.parse(data.item.description)}}
            b-row
              b-col(cols=6)
                b-pagination(
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table")
              b-col
                b-row
                  b-col(cols="7")
                    b-row
                      b-col.align-self-center
                        span.float-right {{$t('buttons.downloadFormat')}}
                      b-col
                        b-form-select(v-model="downloadFormat" :options="options")
                  b-col
                    b-button.buttonGreen(pill @click="downloadData" v-if="loading==false" ) {{$t('buttons.downloadData')}}
                    img(src="/images/loading.gif" class="loading" v-if="loading==true")
            FilterZone(:custom_types_ecs="custom_types_ecs" :allAnswers="name_answers")
    Counter


    b-modal(id="downloadModal" :title="$t('edibleList.modal.title')" @ok="handleOk" ref="downloadModal")
      label {{$t('edibleList.modal.name')}}:
      b-form-input(v-model="modal.name" id="input")
      br
      label {{$t('edibleList.modal.number')}}:
      b-form-input(v-model="modal.number")
      br
      label {{$t('edibleList.modal.email')}}:
      b-form-input(v-model="modal.email")
      br
      label {{$t('edibleList.modal.area')}}:
      b-form-select.formulari(v-model="modal.area" :options="modalOptions")
      div(v-if="modal.area=='Other'")
        br
        label {{$t('edibleList.modal.options.specify')}}:
        b-form-input(v-model="modal.otherArea")
          label {{$t('edibleList.modal.area')}}:
          b-form-select.formulari(v-model="modal.area" :options="modalOptions")
      div(v-if="modal.area!='Citizen'")
        br
        label {{$t('edibleList.modal.institution')}}:
        b-form-input(v-model="modal.institution")
      p
      label {{$t('edibleList.modal.purpose')}}:
      b-form-textarea(v-model="modal.purpose" rows="5")
      br
      b-form-checkbox(v-model="modalCheck1") {{$t('edibleList.modal.check1')}}
      b-form-checkbox(v-model="modalCheck2") {{$t('edibleList.modal.check2')}}

</template>

<script>
import Counter from "@/components/home/Counter";
  import FilterZone from "@/components/home/FilterZone";
  import axios from "axios";
  import gmapsInit from "@/utils/gmaps";
  import XLSX from "xlsx";

  export default {
    name: "EdibleList",
    components: {Counter,FilterZone},
    async mounted() {

      let _this = this;
      await axios.get('answers/getAllECSProfileLocation')
          .then(function (response) {
            if(!response.data){
              //answers.answers = {"questions": {}};
              console.log("No data found");
            } else {
              //console.log("All ECS locations", response.data);
              _this.custom_types_ecs = [];
              response.data.forEach(async function (answer) {

                answer.ecs_type_2 = JSON.parse(answer.ecs_type_2);
                if(answer.ecs_type_2 != null && answer.ecs_type_2.answer.isCustom && !_this.custom_types_ecs.includes(answer.ecs_type_2.answer.answer_custom_text))
                {
                  //console.log(answer.ecs_type_2.answer.answer_custom_text);
                  _this.custom_types_ecs.push({value: answer.ecs_type_2.answer.answer_custom_text});
                }
                answer.ecs_products = JSON.parse(answer.ecs_products);
                _this.allAnswers.push(answer);
              });
              _this.filteredAnswers = _this.allAnswers.filter(answer => { return answer.ecs_name != '';});
            }
          })
          .catch(function (error) {
            console.log("Error getting answers");
            console.log(error);
          });

      this.$root.$on('endSetFilter',(map) => {
        let filtered = [...map.filteredAnswers].filter(answer => { return answer.ecs_name != '';});
        _this.filteredAnswers = filtered;
        _this.filter = map.filter;
      });

      this.$root.$on('filterResults',($event) => {
        _this.setFilter($event)
      });

      this.$root.$on('filterNames',($event) => {
        _this.setFilterNames($event)
      });
    },
    data(){
      return({
        custom_types_ecs: [],
        currentPage:1,
        perPage: 30,
        ecsList: [],
        fields: [
            { key: 'ecs_name', label: this.$t('list.name'), sortable: true },
            { key: 'ecs_type', label: null, sortable: true },
            { key: 'description', label: null, sortable: true }

        ],
        allAnswers: [],
        filteredAnswers: [],
        filter: {},
        filter_names: null,
        downloadFormat: 'xlsx',
        options: [
          //{value: 'csv', text: 'CSV'},
          {value: 'xlsx',text: this.$t('buttons.excelFile')},
          {value: 'json',text: 'JSON'}
        ],
        loading: false,
        array_ids: [],
        modal: {
          name: '',
          email: '',
          number: '',
          area: 'Research',
          otherArea: '',
          institution: '',
          purpose: ''
        },
        modalOptions: [
          {value: 'Research', text: this.$t('edibleList.modal.options.research')},
          {value: 'Public authority', text: this.$t('edibleList.modal.options.public')},
          {value: 'Private company', text: this.$t('edibleList.modal.options.private')},
          {value: 'NGO', text: this.$t('edibleList.modal.options.NGO')},
          {value: 'Citizen', text: this.$t('edibleList.modal.options.citizen')},
          {value: 'Other', text: this.$t('edibleList.modal.options.other')}
        ],
        modalCheck1: 'false',
        modalCheck2: 'false'
      })
    },
    computed: {
      name_answers: function()
      {
        let filter = this.filter_names;
        if(filter)
        {
          var answers = this.allAnswers.filter(answer => {
            if (!answer)
            {
              return false;
            }
            if ((!filter.showInteractive && !filter.showNonInteractive) || (filter.showInteractive && !filter.showNonInteractive && answer.interactive == "\"147.0\"") || (filter.showNonInteractive && !filter.showInteractive && answer.interactive !== "\"147.0\"")) {
              return false;
            }
            if (filter.name != "" && !answer.ecs_name.toLowerCase().match(filter.name.text.toLowerCase())) {
              return false;
            }

            if (filter.typeOfECS.select.length > 0 && !filter.typeOfECS.select.some(res => {
              return "\"" + res.text + "\"" === answer.ecs_type;
            })) {
              return false
            }
            if (filter.typeOfECSCustom.select.length > 0 && !filter.typeOfECSCustom.select.some(res => {
              return (typeof answer.ecs_type_2 !== "undefined" && answer.ecs_type_2 != null && typeof answer.ecs_type_2.answer !== "undefined" && answer.ecs_type_2.answer != null && answer.ecs_type_2.answer.isCustom && answer.ecs_type_2.answer.answer_custom_text == res.value)
            })) {
              return false;
            }
            return true;
          });
          return answers;
        }
        else
        {
          return this.allAnswers;
        }

      },
      rows() {
        return this.filteredAnswers.length
      }
    },
    methods:{
      searchLocation: async function (location, showLocation = true) {

        //this.filterData()

      },
      setFilterNames: function(filter)
      {
        this.filter_names = filter;
      },
      setFilter: function (filter){
        this.filter = filter;
        //this.filterData();
      },
      findInAnswer: function(answer,code)
      {
        if(!answer)
        {
          return false;
        }
        var is_array = false;
        if(Array.isArray(code))
        {
          is_array = true;
          var codes = [];
          var texts = [];
          Object.keys(code).forEach(key => {
            codes.push(code[key].code);
            texts.push(code[key].text);
          });
        }
        var found = false;
        var i = 0;
        if(!Array.isArray(answer) && typeof answer.answer !== "undefined")
        {
          answer = answer.answer;
        }

        while(!found && i<answer.length)
        {
          if(typeof answer[i].custom_ids !== "undefined")
          {
            if(is_array)
            {
              found = codes.includes(answer[i].custom_ids) || texts.includes(answer[i].custom_ids);
            }
            else
            {
              found = answer[i].custom_ids == code;
            }
          }
          else
          {
            Object.keys(answer[i]).forEach(key => {
              if(!found)
              {
                if(answer[i][key])
                {
                  if(Array.isArray(answer[i][key]))
                  {
                    var j = 0;
                    while(!found && j<answer[i][key].length)
                    {
                      if(is_array)
                      {
                        found = typeof answer[i][key][j].id_custom_answer !== "undefined" && (codes.includes(answer[i][key][j].id_custom_answer) || texts.includes(answer[i][key][j].id_custom_answer));
                      }
                      else
                      {
                        found = typeof answer[i][key][j].id_custom_answer !== "undefined" && answer[i][key][j].id_custom_answer == code;
                      }
                      j++;
                    }
                  }
                  else
                  {
                    if(is_array)
                    {
                      found = typeof answer[i][key].id_custom_answer !== "undefined" && (codes.includes(answer[i][key].id_custom_answer) || texts.includes(answer[i][key].id_custom_answer));
                    }
                    else
                    {
                      found = typeof answer[i][key].id_custom_answer !== "undefined" && answer[i][key].id_custom_answer == code;
                    }
                  }
                }
              }
            });
          }

          i++;
        }
        if(found)
        {
          //console.log(answer);
        }
        return found;
      },
      downloadData(){
        let array_ids = [];
        this.filteredAnswers.forEach(ecs => {
          array_ids.push(ecs.ecs_id)
        })
        this.array_ids = array_ids

        this.$refs['downloadModal'].show()
      },
      handleOk(bvModalEvt) {
        bvModalEvt.preventDefault()

        if (!this.checkFormValidity()) return
        let _this = this;
        axios.post('answers/downloadInfo',{
          info: _this.modal,
          ecs_ids: _this.array_ids
        })
          .then(function (response) {
            console.log(response);
            console.log("Information saved");
          })
          .catch(function (error) {
            console.log(error);
          });

        this.$nextTick(() => {
          this.$bvModal.hide('downloadModal')
        })
        this.modalClosed()

      },
      checkFormValidity(){
        if (
          this.modal.name == '' ||
          this.modal.number == '' ||
          this.modal.email == '' ||
          (this.modal.area == 'Other' && this.modal.otherArea == '') ||
          (this.modal.institution == '' && this.modal.area != 'Citizen') ||
          this.modal.purpose == '' ||
          this.modalCheck1 == false ||
          this.modalCheck2 == false
        ) {
          alert(this.$t('edibleList.modal.informationIncomplete'));
          return false;
        }
        return true;
      },
      async modalClosed(){
        this.loading = true;

        if (!this.$store.getters.getLanguage || this.$store.getters.getLanguage.lang == "en"){
          var lang = "text_1"
        } else  {
          var lang = "text_"+this.$store.getters.getLanguage.lang
        }

        let _this = this;

        await axios.post('answers/getECSParsedAnswers',
            {"ids_ecs": _this.array_ids, "lang": lang}
        )
            .then(function (response){
              console.log(response)
              if (_this.downloadFormat == 'csv')
                _this.downloadCsv(response.data);
              else if (_this.downloadFormat == 'xlsx')
                _this.downloadXlsx(response.data);
              else
                _this.downloadJson(response.data)
            })
            .catch(function (error){
              console.log('Error: '+error)
            })
            .finally(function(){
              _this.loading = false;
            })
      },
      downloadCsv(array){

        var fileTitle = 'ecs_data'

        var headers = '';
        for (let [key,value] of Object.entries(array[0])) {
          if (headers != '') headers += ';'
          headers += '"'+key+'"'
        }
        var csv = headers + '\r\n'

        var array = typeof array != 'object' ? JSON.parse(array) : array;

        for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
            if (line != '') line += ';'
            line += typeof array[i][index] == 'number' ? array[i][index] : '"'+array[i][index]+'"';
          }
          csv += line + '\r\n';
        }

        var exportedFilename = fileTitle + '.csv' || 'export.csv';
        var blob = new Blob([csv], { type: 'text/csv' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      downloadXlsx(data){
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'ecs_data');
        XLSX.writeFile(wb, 'ecs_data.xlsx')
      }, // end downloadXlsx
      downloadJson(data){
        var json = JSON.stringify(data)
        var exportedFilename = 'ecs_data.json';
        var blob = new Blob([json], { type: 'text/json' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }
    }
  }
</script>


  <style scoped>
  a {
    color:black;
  }

  p.barText {
    color: var(--green-primary);
    font-size: small;
  }

  img.loading{
    height: 30px;
  }

  .jumbotron {
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 0;
  }

  #map {
    height: 60vh;
    width: 100%;
  }

  .greenTitles {
    color: var(--green-primary);
    text-align: center;
    font-size: small;
  }

  .checkBox {
    text-align: left;
    color: var(--green-primary);
    font-size: small;
  }

  .form-control {
    border: var(--green-primary) 1px solid;
    border-radius: 25px;
    font-size: small;
  }

  .b-icon.bi.collapsed {
    transform: rotate(180deg);
  }

  .advancedSearchOptions {
    background-color: var(--light-gray-secondary);
    font-size: small;
    margin: 0;
  }

  .optionsBut {
    color: var(--light-gray-primary);
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 2rem;
    align-self: baseline;
    margin: 0.75rem;
    padding: 0 0.1em 0 0.1em;
  }

  .optionsBut:hover {
    color: #6c757d;
    background-color: #ffffff;
    border-color: var(--dark-red);
  }

  .optionsBut > div {
    margin: 0.55rem;
  }

  .optionsBut p {
    padding: 0rem;
    margin: 0rem;
  }

  .custom-control-inline {
    margin-right: 0;
  }

  .formulari {
    border-radius: 25px;
    border: var(--green-primary) 1px solid;
  }

  table
  {
    width:calc(95%);
  }


</style>
