<template lang="pug">
  .createECS
    b-container.p-0(fluid="true").banner
      b-img(src="/images/banner-myECSprofile.png" fluid alt="Fluid image;")
      div.bannerTitle
        p {{ $t('createNew.experience.create') }}
    b-container.pl-4.pr-4.pb-4.profile(fluid="true")
      b-row.pt-2.pb-2
        b-col(cols="auto" style="float: right")
          a(type="button" style="color: grey" v-b-toggle.advSearch) {{ $t('edibleMap.advancedSearch.advancedSearch') }}
        b-col(style="float: left")
          //b-icon(v-b-toggle.advSearch icon="chevron-up" style="color: var(--green-primary)")
          b-icon(v-b-toggle.advSearch icon="chevron-down" style="color: grey")
      b-collapse(:visible="false" id="advSearch")
        b-row
          b-col(cols="4")
            //- filter by country
            b-form-group(:label="$t('assessment.filterCountry')+':'")
              multiselect(
                v-model="filterCountry"
                :options="countriesFiltered"
                :searchable="true"
                :show-labels="true"
                :placeholder="$t('placeholder.allValues')"
                label="long_name"
                track-by="short_name"
                :multiple="true"
                )
          //- filter by city
          b-col(cols="4")
            b-form-group(:label="$t('assessment.filterCity')+':'")
              multiselect(
                v-model="filterCity"
                :options="cities"
                :searchable="true"
                :show-labels="true"
                :placeholder="$t('placeholder.allValues')"
                label="long_name"
                track-by="short_name"
                :multiple="true"
                )
      hr.mb-5
      b-row
        b-col(cols="12")
          b-form-group(:label="$t('createNew.experience.text1')")
            multiselect(
            v-model="selected_ecs"
            :options="optionsFiltered"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            placeholder="Select one"
            label="name"
            track-by="name"
            )
            b-button.buttonGreen(pill @click="addExperience" :disabled="selected_ecs === '' || selected_ecs === null").mt-2 {{ $t('createNew.experience.create') }}

</template>

<script>

import gmapsInit from "../utils/gmaps.js"
import Multiselect from 'vue-multiselect';
import axios from 'axios';

export default {
  name: 'create_ecs',
  components: { Multiselect },
  data: function(){
    return {
      options:[],
      listExpirience:[],
      selected_ecs: {},
      location: "",
      value: '',
      map: null,
      gmap: null,
      ecs_id: 0,
      cities: [],
      filterCountry: [],
      filterCity: []
    }
  },
  mounted: async function(){
    let _this = this;

    // _this.gmap = await gmapsInit();
    // _this.searchLocation();
    axios.get('clients/allecs')
      .then(function (response) {
        console.log("Asked all ECS: ",response.data);
        _this.options = response.data;

        _this.options.forEach(async function(ecs){
          ecs.address = JSON.parse(ecs.address);
          
          if (ecs.address){
            ecs.address.forEach(function(comp){
              if (comp.types.includes("locality") || comp.types.includes("postal_town")){
                ecs.city = comp
              } else if (comp.types.includes("country")){
                ecs.country = comp
              }
            })
          }
          // console.log(ecs.address, ecs.city, ecs.country)
        });

        if(_this.$route.params){
          // console.log("experience with param:",_this.$route.params.id)
          // console.log("Options: ",_this.options)
          _this.selected_ecs = _this.options.find(opt => {
            // console.log("experience with param:",_this.$route.params.id)
            // console.log("Options: ",opt.id)
            let id = Number(_this.$route.params.id);
            return opt.id === id
          })
        }
      })
      .catch(function (error) {
        console.log("Menu get client error:", error);
        // console.log(error);
      });
    // axios.get('clients/expirience')
    //     .then(function (response) {
    //       console.log("Asked response expirience: ", response);
    //       _this.listExpirience = response.data;
    //     })
    //     .catch(function (error) {
    //       console.log("Menu get client error:", error);
    //       // console.log(error);
    //     });
  },
  methods:{
    addExperience(){
      let _this = this;
      console.log("Add experience");
      if(!_this.selected_ecs) {
        console.log("empty ecs");
        return;
      }
      axios.post('clients/createExpirience',{
        "id_ecs": _this.selected_ecs.id
      })
        .then(response => {
          console.log('/ecs-experience?id=' + response.data +'&id_ecs='+_this.selected_ecs.id + '&name_ecs=' + _this.selected_ecs.name)
          _this.$router.replace('/ecs-experience?id=' + response.data +'&id_ecs='+_this.selected_ecs.id + '&name_ecs=' + _this.selected_ecs.name, () => _this.$router.go(0));
        })
        .catch(function (error) {
          console.log(error);
        });

    },
    comparePlaces: function(a,b){
      if ( a['long_name'] < b['long_name'] ){
        return -1;
      } else if ( a['long_name'] > b['long_name'] ){
        return 1;
      }
        return 0;
    }
  },
  computed:{
    optionsFiltered: function(){
      let optionsF = []
      // filter by country
      if (this.filterCountry.length > 0){
        let _this = this
        this.options.forEach(function(ecs){
          if(ecs.country && _this.filterCountry.filter(e => e.short_name == ecs.country.short_name).length > 0)
            optionsF.push(ecs)
        });
        console.log("options filtered by country", optionsF)
      } else {
        optionsF = this.options
      }
      // filter by city
      let optionsFF = []
      if (this.filterCity.length > 0){
        let _this = this
        console.log("filterCity", this.filterCity)
        optionsF.forEach(function(ecs){
          if(ecs.city && _this.filterCity.filter(e => e.short_name == ecs.city.short_name).length > 0)
            optionsFF.push(ecs)
        });
        console.log("options filtered by city", optionsFF)
      } else {
        optionsFF = optionsF
      }
      return optionsFF
    },
    countriesFiltered: function(){
      let countries = []
      let countriesShortNames = []
      this.options.forEach(function(ecs){
        if (ecs.country && !countriesShortNames.includes(ecs.country.short_name)){
          countriesShortNames.push(ecs.country.short_name)
          countries.push(ecs.country)
        }
      })
      countries.sort(this.comparePlaces)

      this.cities = []
      this.filterCity = []
      let shortNames = []
      let _this = this

      if (this.filterCountry.length > 0){
        this.options.forEach(function(ecs){
          if (ecs.city
            && !shortNames.includes(ecs.city.short_name)
            && _this.filterCountry.find(c => c.short_name == ecs.country.short_name)){
            shortNames.push(ecs.city.short_name)
            _this.cities.push(ecs.city)
          }
        })
      } else {
        this.options.forEach(function(ecs){
          if (ecs.city && !shortNames.includes(ecs.city.short_name)){
            shortNames.push(ecs.city.short_name)
            _this.cities.push(ecs.city)
          }
        });
      }

      this.cities.sort(this.comparePlaces)
      return countries
    },
    citiesFiltered: function(){
      let cities = []
      let shortNames = []
      let _this = this

      if (this.filterCountry.length > 0){
        this.options.forEach(function(ecs){
          if (ecs.city
            && !shortNames.includes(ecs.city.short_name)
            && _this.filterCountry.find(c => c.short_name == ecs.country.short_name)){
            shortNames.push(ecs.city.short_name)
            cities.push(ecs.city)
          }
        })
      } else {
        this.options.forEach(function(ecs){
          if (ecs.city && !shortNames.includes(ecs.city.short_name)){
            shortNames.push(ecs.city.short_name)
            cities.push(ecs.city)
          }
        });
      }

      cities.sort(this.comparePlaces)
      return cities
    }
    // optionsFiltered(){
    //   return this.options.filter(ecs => {
    //     console.log(ecs.id,this.listExpirience.findIndex(i => i.id_ecs === ecs.id));
    //     return this.listExpirience.findIndex(i => i.id_ecs === ecs.id) === -1
    //   })
    // }
  }
}
</script>

<style scoped>
  #map{
    height: 14rem;
    width: 100%;
    margin-top: 10px;
  }

  .createECS{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  h2 {
    color: var(--green-primary);
  }

</style>
