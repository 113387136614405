<template lang="pug">
    b-row.mt-2.mb-2
        b-col(sm="12")
            h6
              //label.col-form-label(v-html="stringToLabel(question['text_' + getLang])")
            b-form-datepicker.mb-1(@change="changeEvent" v-model="dataResult" :type='question.text_type' :class="'form-control'")
            //small.red(v-if="errorFound.text") {{errorFound.text}}

</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";

  export default {

    name: "questionCalendarCol",
    props: ['question', 'sendResult', 'errorFound'],
    mixins: [utilMixin],
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          let text = this.question['text_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      changeEvent: function(){
        this.$emit("changeEvent", this);
      },
      getDataResult: function () {
        if (this.dataResult != null && this.dataResult !== '') {
            // console.log("getDataResult", this.dataResult)
            return {
              id: this.question.internal_id,
              text: this.dataResult,
              id_custom: this.question.custom_ids
            }
        }
        else return null;
      }
    },
    computed: {
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      }
    },
    data: function () {
      return {
        dataResult: this.sendResult,
        langId: 1
      }
    },
    mounted: function()
    {
    }
  }
</script>

<style scoped>
  .form-rounded {
    border-radius: 1rem;
  }

  .red{
    color: var(--dark-red)
  }
</style>
