<script>
import { HorizontalBar, mixins } from 'vue-chartjs'

export default {
  name: "perceptionBars",
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}

</script>

<style scoped>

</style>