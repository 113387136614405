<template lang="pug">
  b-row.mt-4.offset-lg-4(align-h="end")
            b-col.p-2(sm="12" lg="4")
              //b-button.buttonGreen(v-if="questionNumber > 0" pill block @click="previousPressed") < {{ $t('buttons.previous') }}
              //b-button(v-if="questionNumber > 0" :variant = "dataModified" @click="previousPressed") < PREVIOUS QUESTION
            //b-col.p-2(sm="12" lg="4")
              b-button.buttonGreen(v-b-modal = "questionId(questionNumber)" pill block) {{ $t('buttons.skip') }}
                b-modal(title="Choose one please:" :id="questionId(questionNumber)" @ok="nextPressed")
                  b-form-group( label-size="lg")
                    b-form-radio( name="radio_form_not_answer" id="not_understand" value="not_understand" v-model="skip.skipped") {{ $t('ecsForm.skip.notUnderstood') }}
                    b-form-radio( name="radio_form_not_answer" id="not_info" value="not_info" v-model="skip.skipped") {{ $t('ecsForm.skip.notInfo') }}
                    b-form-radio( name="radio_form_not_answer" id="not_confusing" value="not_confusing" v-model="skip.skipped") {{ $t('ecsForm.skip.confusing') }}
                    b-form-radio( name="radio_form_not_answer" id="not_relevant" value="not_relevant" v-model="skip.skipped") {{ $t('ecsForm.skip.notRelevant') }}
                    b-form-radio( name="radio_form_not_answer" id="not_personal" value="not_personal" v-model="skip.skipped") {{ $t('ecsForm.skip.personal') }}
                    b-form-radio( name="radio_form_not_answer" id="not_others" v-model="skip.custom" :value="true") {{ $t('ecsForm.skip.other') }}
                    b-form-input( type="text" :disabled="customEnabled" v-model="skip.answer").my-1
            b-col.p-2.mb-4(sm="12" lg="4")
              //b-button.buttonGreen(v-if="questionNumber+1  < nQuestions" pill block @click="nextPressed") {{ $t('buttons.next') }} >
              //b-button.buttonGreen(v-else pill block @click="nextPressed" ) {{ $t('buttons.end') }}
              //b-button(v-if="questionNumber+1  < nQuestions" :variant = "dataModified" @click="nextPressed").mr-1 Next
              //b-button(v-else @click="nextPressed" :variant = "dataModified").mr-1 End profile
            b-modal(title="Thank you" ref="endProfileModal" id="endProfileModal" ok-only size="lg")
              p.text-center {{ $t('ecsForm.modalEnd.text1') }}
                br
                | {{ $t('ecsForm.modalEnd.text2') }}

</template>

<script>
  export default {
    name: "questionHeader",
    props: ['questionNumber','dataAnswer', 'nQuestions', 'ecs_id'],
    data(){
      return{
        dataModified: "primary",
        skip: {
          skipped: "",
          custom: false,
          answer: ""
        }
      }
    },
    mounted: function() {
      window.addEventListener("keydown", this.keyPressed)
    },
    methods: {
      // keyPressed(e){
      //   if (e.key=="ArrowLeft" && this.questionNumber > 0) this.previousPressed();
      //   else if (e.key=="ArrowRight" && this.questionNumber+1  < this.nQuestions) this.nextPressed();
      // },
      nextPressed() {
        this.dataModified = "primary";
        console.log("test");
        this.$emit('saveData', "next");
      },
      previousPressed() {
        this.dataModified = "primary";
        console.log("test");
        this.$emit('saveData', "previous");
      },
      onChangeEventHandler() {
        this.dataModified = "danger";
        this.$emit('saveData');
        console.log("Change");
      },
      questionId(questionNumber) {
        return "skipQuestion" + questionNumber.toString();
      },
      skipQuestion() {
        return this.skip.skipped || this.skip.custom;
      },
      customAnswer() {
        return this.skip.custom;
      },
      customText() {
        return this.skip.answer;
      },
      resetModal() {
        this.skip.skipped = "";
        this.skip.custom = false;
        this.skip.answer = "";
      },
      showSubmitModal(){
        let route = "showEcsProfile/"+this.ecs_id;
        //this.$router.push(route)
        //this.$refs.endProfileModal.show();
      }

    },
    watch:{
    },
    computed: {
      customEnabled: function () {
        return !this.skip.custom;
      }
    }
  }
</script>

<style scoped>


</style>
