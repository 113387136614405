<template lang="pug">
  .bar(style="width: 100%")
    b-row.progress_bar(v-if="steps > 1")
      b-col.p-1( v-for="(n) in steps-1" v-bind:key="n" :id="getID(n,'bar')" :class="getClass(n)" @click="setStep(n)")
        span
        template()
          b-row
            b-col
              //p(style="font-size:1.2em;transform:translate(-1em, 0.3em);text-align:center;")  {{questions[n-1].id}}
              p(v-if="questions[n-1]" :class="getClass2(questions[n-1].id)").rotateText {{ questions[n-1].config["text_navbar_lang_"+$store.getters.getLanguage.id] }}
      b-col.p-1.final(v-bind:key=steps :id="getID(steps,'bar')" :class="getClass(steps)" @click="setStep(steps)")
        b-row
          b-col
            //p(style="font-size:1.2em;transform:translate(-0.7em, 0.8em);text-align:center;")  {{questions[steps-1].id}}
            p(v-if="questions[steps-1]").rotateText {{ questions[steps-1].config["text_navbar_lang_"+$store.getters.getLanguage.id] }}
    p.mt-3 * {{$t('progressBar.footnote')}}

</template>

<script>

  export default {
    name: "ProgressBarNavigator",
    props: {
      answers: Object,
      max: {type: Number, default: 0},
      value: Number,
      questions: {
        type: Array,
        default:[],
        required: false
      },
      stepsLabels: {
        type: Array,
        default: [],
        required: false,
      },
      keyPoints: {
        type: Array,
        default: [],
        required: false,
      },
      step: Number,
      progressStep: Number
    },
    data: function () {
      return {
        progressValue: this.value,
        steps: 0,
        labels: [],
        currentLanguage: {}
      }
    },
    computed: {
      hasLabels: function () {
        return (this.labels.length !== 0);
      },
    },
    mounted() {
      this.currentLanguage = this.$store.getters.getLanguage
      this.steps = this.max
    },
    methods: {
      getID: function(index, type) {
        if(type === 'bar'){
          return ("step_" + index);
        }
        else{
          return ("text_" + index);
        }
      },
      getLabel: function(index){
        return (this.labels[index-1]);
      },
      getClass: function (index) {
        var className ="";
        if(this.answers['id_'+this.questions[index-1].id]){
          className += " active";
            // if(this.progressValue+1 > index)
               className += " completed";
        }
        if(this.keyPoints.includes(index)){
          className += " key-point";
        }
        if(this.progressStep === index){
          className += " current"
        }
        return className;
      },
      getClass2: function (index) {
        var className ="";
        if(this.keyPoints.includes(index))
          className += " monitoring";

        return className;
      },
      fontWeight: function (index) {
        var fontWeight = "";
        if(this.progressValue+1 >= index){
          fontWeight = "font-weight: var(--bold-text)";
        }
        return fontWeight;
      },
      setStep: function(step){
        console.log("step: "+step)
        this.$emit("setStep", step)
      }
    }
  }

</script>

<style scoped>
  .rotateText{
    -webkit-transform: rotate(
        -67deg
    );
    -moz-transform: rotate(-67deg);
    -o-transform: rotate(-67deg);
    -webkit-writing-mode: lr-tb;
    transform-origin: left;
    -ms-writing-mode: lr-tb;
    writing-mode: lr-tb;
    white-space: nowrap;
    position: absolute;
    left: 10px;
    top: -35px;
    text-align: start;
  }

  p{
    color: var(--green-primary);
    font-size: smaller;
  }

  .key-point:before{
    width: 1.5em !important;
    height: 1.5em !important;
    left: -0.75em !important;
    top: -0.35em !important;
  }

  .progress_bar > div.current:before{
    background: var(--dark-red) !important;
    border-color: var(--dark-red) !important;
  }

  .progress_bar > div.current:after{
    background: var(--dark-red) !important;
  }

  .key-point.active:after{
    left: -0.5em;
    width: 1em;
    height: 1em;
    top: -0.1em;
    border-radius: 2em;
    cursor: pointer;
  }

  .progress_bar > div span{
    width:auto;
    display:block;
    overflow:hidden;
    top: 0.17em;
    left: 0;
    right: 0;
    margin: auto;
    background: var(--green-secondary);
    height: 0.5em;
    z-index: 1;
  }

  .progress_bar > div.active:before{
    background: #fff;
    border: 0.1em solid var(--green-primary);
    box-sizing: border-box;
    cursor: pointer;
  }

  .progress_bar > div:before{
    content: '';
    display: block;
    position: absolute;
    left: -0.5em;
    width: 1em;
    height: 1em;
    top: -0.07em;
    border-radius: 2em;
    z-index: 10;
    background: #FFFFFF;
    border: 0.1em solid var(--green-primary);
    box-sizing: border-box;
    cursor:pointer;
  }
  .progress_bar > div:after{
    content: '';
    display: block;
    position: absolute;
    left: -0.25em;
    width: 0.5em;
    height: 0.5em;
    top: 0.18em;
    border-radius: 2em;
    z-index: 10;
    background: #fff;
    box-sizing: border-box;
    -webkit-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -0-transition: all 0.1s linear;
    transition: all 0.1s linear;
    cursor:pointer;
  }
  .progress_bar > div.active:after{
    background: var(--green-primary);
    cursor: pointer;
  }
  .progress_bar > div span:before{
    content: '';
    width:0px;
    position:absolute;
    display:block;
    top: 0.17em;
    margin: auto;
    background: var(--green-primary);
    border-radius:5px;
    height: .5em;
    z-index: 2;
    -webkit-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -0-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
  .progress_bar > div.active span:before{
    width: 50%;
  }

  .progress_bar > div.active.completed span:before{
    width: 0%;
  }

  .final {
    max-width: fit-content;
  }

  .monitoring {
    /*font-weight: var(--bold-text);*/
    color: var(--dark-red);
  }

</style>
