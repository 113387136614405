<template lang="pug">
  b-container(fluid="true")
    b-container(fluid="true")
      b-container.m-4(fluid="true")
        questionFooter(ref="footer" :questionNumber="questionNumber" :nQuestions="nQuestions" :dataAnswer="answered" @saveData="saveToProfileECS" :ecs_id="ecs_id")
        questionHeader(ref="header" :questionNumber="questionNumber" :nQuestions="nQuestions")
        b-row
          b-col(sm="12")
            h6
              label(v-html="stringToLabel(question.config['text_lang_' + getLang])" class="col-form-label")
          multiselect(v-model="answered" :options="listValues" track-by="name" label="name" :multiple="question.config.multichoice" :searchable="true" :close-on-select="true" :show-labels="false" :placeholder="$t('placeholder.dropdown')")

</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import Multiselect from 'vue-multiselect';
  import axios from 'axios';
  import questionHeader from "../questionHeader";
  import questionFooter from "../questionFooter";

  export default {

    components: {
      Multiselect,
      questionFooter,
      questionHeader
    },
    name: "questionDropdownProfile",
    props: ['question', 'questionNumber','answer','nQuestions'],
    mixins: [utilMixin],
    data: function() {
      return{
        dataResult: null,
        answered: [],
        skip: {
          skipped: "",
          custom: false,
          answer: ""
        },
        langId: 1
      }
    },
    mounted: function (){
      console.log("Loading dropdown question.")
      if(this.answer !== undefined){
        this.answered = this.answer.answer
        if(this.answer.skip) {
          this.$refs.header.skip = this.answer.skip;
        }
      }
    },
    watch:{
      answer: function(newVal,oldVal) {
        this.answered = this.answer.answer;
        if(this.answer.skip) this.$refs.header.skip = this.answer.skip;
      }
    },
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          text = this.question.config['text_lang_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      saveToProfileECS: function (text) {
        console.log("Save Question: ", this.question.id);
        if (this.$refs.header.skipQuestion()) {
          this.skip.skipped = this.$refs.header.skip.skipped;
          this.skip.custom = this.$refs.header.customAnswer();
          this.skip.answer = this.$refs.header.customText();
        }

        let _this = this;
        if(this.answered === "" && this.valid && !this.skip.skipped && text === "next"){
          console.log("Please respond, something");
        }
        else {

          this.$emit('sendResponse', {
            id_question: _this.question.id,
            id_category: _this.question.category_id,
            answer: this.answered}, text);
          axios.post(this.$parent.url_answer, {
            question_id: _this.question.id,
            ecs_id: _this.ecs_id,
            category_id: _this.question.category_id,
            answer: _this.answered,
            skip: _this.skip
          })
            .then(function (response) {
              console.log(response);
              console.log("Done Modify question");
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },
    computed:{
      options: function () {
        let ret = [];
        this.question.answers.forEach(function (answer) {
          ret.push({
            text: answer.text_1,
            value: {
              id: answer.internal_id,
              text: answer.text_1
            }
          });
        });
        return ret;
      },
      listValues: function () {
        console.log("Data change");
        let ret = [];
        try {
          //TODO: add langs to read
          this.question.config.answers.forEach(function (answer) {
            ret.push({
              name: answer[0],
              code: answer[1],
              code2: answer[2]
            })
          });
        }
        catch (e) {
          console.error(e);
          return "";
        }

        return ret;
      },
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      }
    }
  }
</script>

<style scoped>
  .form-rounded {
    border-radius: 1rem;
  }

</style>