<template lang="pug">
  .about_functionalities_plan.generalBackground
    b-container.p-4.m-4(fluid="true")
      b-row
        b-col
          p(style="white-space: pre-line") {{ $t('abouts.functionalities_plan.text') }}
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.designECS icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.designECS) {{ $t('abouts.functionalities_plan.subtitle1') }}
          b-collapse.collapsed(id="designECS" )
            p(style="white-space: pre-line") {{ $t('abouts.functionalities_plan.text1') }}
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.designCity icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.designCity) {{ $t('abouts.functionalities_plan.subtitle2') }}
          b-collapse.collapsed(id="designCity" )
            p(style="white-space: pre-line") {{ $t('abouts.functionalities_plan.text2') }}

</template>

<script>
export default {
name: "functionalities_plan"
}
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  a, a[type=button] {
    color: var(--green-primary);
    align-self: center;
    font-weight: var(--bold-text);
  }
  a:hover, a:not([href]):hover, a[type=button]:hover {
    color: var(--dark-red);
    text-decoration: none;
  }
  a:active, a[type=button]:active{
    color: var(--dark-red);
  }

  .coll-button{
    color: var(--green-primary);
  }

  .collapsed > a {
    font-weight: var(--text-weight);
  }

  .b-icon.bi.collapsed {
    transform: rotate(180deg);
  }


</style>
