<template lang="pug">
    b-row.mt-2.mb-2
      b-col(sm="12")
        b-form-group()
          b-form-radio(v-model="dataResult" :value="'select_profile'" v-if="question.custom_ids == 'network'") Another ECS profile
          multiselect(v-if="question.custom_ids == 'network'" :disabled="!profile_enabled" @change="changeEvent" v-model="customDataResultDropdown" :options="listValues" track-by="name" label="name" :taggable="false" :multiple="false" :close-on-select="true" :show-labels="false" :placeholder="$t('placeholder.dropdown')").form-rounded
          b-form-radio-group(stacked @change="changeEvent" :id="question.internal_id" v-model="dataResult" :options="options" :name="question.internal_id" )
          b-form-radio(@change="changeEvent" v-model="dataResult" :value="false" :name="question.internal_id" v-if="question.customAnswer === 'true'") {{  $t('ecsForm.skip.other') }}
          b-form-input(@change="changeEvent" :disabled="customEnabled" v-model="customDataResult" type="text" v-if="question.customAnswer === 'true'").my-1

</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import Multiselect from 'vue-multiselect';
  import axios from "axios";

  export default {
    components: { Multiselect },
    name: "questionRadioCol",
    props: ['question', 'answered', 'sendResult'],
    mixins: [utilMixin],
    data: function() {
      return{
        dataResult: this.sendResult,
        //isCustom: false,
        customDataResult: "",
        customDataResultDropdown: "",
        others: false,
        langId: 1,
        //langOptions: ""
        listValues: [
        ]
      }
    },
    watch: {
		question: function(){
			console.log('question watch');
		},
      dataResult: function(val)
      {
      }
    },
    mounted() {
      let _this = this;
      if(this.question.custom_ids == 'network')
      {
        axios.get('answers/getAllECSProfileLocation').then(response => {
          response.data.forEach(function(e){
            var object = {};
            if (e.ecs_name != "")
            {
              object.name = e.ecs_name;
              object.code = e.ecs_id;
              object.type = 'profile';
              _this.listValues.push(object);
            }
            _this.listValues.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          });
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
        });
      }
      if(this.sendResult)
      {

        if(typeof this.sendResult === 'string')
        {
          this.dataResult = false;
          this.customDataResult = this.sendResult;
        }
        else if(typeof this.sendResult === 'object' && typeof this.sendResult.name !== 'undefined' )
        {
          this.dataResult = 'select_profile';
          this.customDataResultDropdown = this.sendResult;
        }
		else
        {
	        let found = this.options.find(element => element.value.id_custom_answer == this.dataResult.id_custom_answer)
			if(found)
			{
				this.dataResult = found.value;
			}
        }
      }

    },
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          let text = this.question['text_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      changeEvent: function(){
		  console.log('change event');
        this.$emit("changeEvent", this);
      },
      getDataResult: function () {
		  console.log('get data result');
        if (this.dataResult === false && this.customDataResult != null) {
          return this.customDataResult;
        }
        else if (this.dataResult == 'select_profile' && this.customDataResultDropdown != null) {
          return this.customDataResultDropdown;
        }
        else if (this.dataResult != null && this.dataResult !== '' && this.dataResult !== false) {
          return this.dataResult;
        }
        else return null;
      },
    },
    computed:{

      profile_enabled: function(){
        return this.dataResult == 'select_profile';
      },
      options: function () {
        let ret = [];
        let _this = this;
        try{
          if(!this.question.answers) return ret;
          let lang = this.getLangOptions;
          this.question.answers.forEach(function (answer) {
            if(answer[lang] === '' || !answer[lang])
              lang = 'text_1';
            ret.push({
              text: answer[lang],
              value: {
                id: answer.internal_id,
                text: answer[lang],
                id_custom: _this.question.custom_ids,
                id_custom_answer: answer.custom_ids
              }
            })
          });
        }
        catch (e) {
          console.error("Error getting options: ", e);
          return "";
        }
        return ret;
      },
      customEnabled: function () {
        return this.dataResult !== false;
      },
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      },
      getLangOptions: function () {
        let languageId = this.$store.getters.getLanguage.id;
        let language = "";
        switch (languageId) {
          case 2:
            language = "text_cat";
            break;
          case 3:
            language = "text_fr";
            break;
          case 4:
            language = "text_es";
            break;
          case 5:
            language = "text_de";
            break;
          case 6:
            language = "text_pt";
            break;
          case 7:
            language = "text_sl";
            break;
          default:
            language = "text_"+this.$store.getters.getLanguage.lang;
            break;
        }
        return language;
      }
    }
  }
</script>

<style scoped>

</style>