<template lang="pug">
  .about_create_profile.generalBackground
    b-container.p-4.m-4(fluid="true")
      b-row
        b-col
          h3 {{ $t('menu.info.share_knowledge') }}
          br
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.createProfile icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.createProfile) {{ $t('abouts.share_knowledge_create_profile.title') }}
          b-collapse.collapsed(id="createProfile" )
            p(style="white-space: pre-line") {{ $t('abouts.share_knowledge_create_profile.text') }}
            ol
              li {{ $t('abouts.share_knowledge_create_profile.list.line1') }}<br>

              li {{ $t('abouts.share_knowledge_create_profile.list.line2') }}<br>
                //b-img(:src="$t('abouts.share_knowledge_create_profile.image1')" style="width: 20%")
              li {{ $t('abouts.share_knowledge_create_profile.list.line3') }}<br>
                //b-img(:src="$t('abouts.share_knowledge_create_profile.image2')" style="width: 50%")
              li {{ $t('abouts.share_knowledge_create_profile.list.line4') }}<br>
                //b-img(:src="$t('abouts.share_knowledge_create_profile.image3')" style="width: 50%")
              li {{ $t('abouts.share_knowledge_create_profile.list.line5') }}<br>
                //b-img(:src="$t('abouts.share_knowledge_create_profile.image4')" style="width: 50%")
      b-row
        b-col(cols="0")
          h5(class="coll-button")
            b-icon(v-b-toggle.createExperience icon="chevron-up" size="sm")
        b-col()
          h5(class="coll-button")
            a(type="button" v-b-toggle.createExperience) {{ $t('abouts.share_knowledge_create_expirence.title') }}
          b-collapse.collapsed(id="createExperience" )
            p(style="white-space: pre-line") {{ $t('abouts.share_knowledge_create_expirence.text') }}
            ol
              li {{ $t('abouts.share_knowledge_create_expirence.list.line1') }}<br>

              li {{ $t('abouts.share_knowledge_create_expirence.list.line2') }}<br>
                //b-img(:src="$t('abouts.share_knowledge_create_expirence.image1')" style="width: 20%")
              li {{ $t('abouts.share_knowledge_create_expirence.list.line3') }}<br>
                //b-img(:src="$t('abouts.share_knowledge_create_expirence.image2')" style="width: 70%")
              li {{ $t('abouts.share_knowledge_create_expirence.list.line4') }}<br>
                //b-img(:src="$t('abouts.share_knowledge_create_expirence.image3')" style="width: 50%")
              li {{ $t('abouts.share_knowledge_create_expirence.list.line5') }}<br>
                //b-img(:src="$t('abouts.share_knowledge_create_expirence.image4')" style="width: 50%")
</template>

<script>
export default {
name: "share_knowledge_create_profile"
}
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

    a, a[type=button] {
      color: var(--green-primary);
      align-self: center;
      font-weight: var(--bold-text);
    }
    a:hover, a:not([href]):hover, a[type=button]:hover {
      color: var(--dark-red);
      text-decoration: none;
    }
    a:active, a[type=button]:active{
      color: var(--dark-red);
    }

    .coll-button{
      color: var(--green-primary);
    }

    .collapsed > a {
      font-weight: var(--text-weight);
    }

    .b-icon.bi.collapsed {
      transform: rotate(180deg);
    }


</style>
