<template lang="pug">
    .categories.my-4
        category-tree
    
</template>

<script>
  import CategoryTree from "./vc-categories/category-tree";

  export default {
    name: "Categories",
    components: {CategoryTree},
    mounted:function () {
      this.$store.commit('SET_TITLE', 'Categories')
    }
  }
</script>

<style scoped>

</style>