<template lang="pug">
.about_content.generalBackground
  b-container(fluid="true" v-if="scores.length>1")
    b-row
      //- in case no ECS matches the filters
      b-col(cols="9" v-if="!singleECS[0]")
        b-alert(show style="text-align:center;") {{$t('assessment.noMatch')}}
      //- in case ecs user has not score
      b-col(cols="9" v-if="option1=='single' && singleECS[0] && !singleECS[0].id" )
        h4 {{singleECS[0].name}}
        div {{ECSnotList}}
        p {{answerNotList.problem}}
        ul(v-if="unanswered.length > 0")
          li(v-for="question in unanswered")
            a(:href="'/ecs-profile?id='+answerNotList.id+'&step='+question.id") {{question.config[getNavLang]}}
      // star plot
      b-col(cols="9" v-if="option1=='single' && singleECS[0] && singleECS[0].id")
        div
          div.legend
          a(:href="'/showEcsProfile/'+singleECS[0].ecs_id")
            h4.link {{singleECS[0].name}}
        div(v-if="singleECS[1] && singleECS[1].name")
          div.legend.legend2
          a.compare(:href="'/showEcsProfile/'+singleECS[1].ecs_id")
            h4.compare.link {{singleECS[1].name}}
        div(v-if="singleECS[2] && singleECS[2].name")
          div.legend.legend3
          a.compare2(:href="'/showEcsProfile/'+singleECS[2].ecs_id")
            h4.compare2.link {{singleECS[2].name}}
        div.plot(v-if="chartSust && singleECS[0].ecs_id")
          //h5 {{$t('assessment.sust_uc_es.sust.title')}}
          Radar.m-3(
            :chartData= "chartSust"
            :options="plotOptions"
            :key="plotKey"
            :height= "250"
          )
        br
        small {{$t('assessment.sust_uc_es.for_dummies')}}
      // top 10
      b-col(cols="9" v-if="option1=='compare'")
        h5(v-if="scoresCompare.name") Top10 - {{scoresCompare.name}}
        b-row
          b-col
            div.plotCompare(v-if="scoresCompare && rankingData.length && barData" id="barContainer")
              HorizontalBar.m-3(
                :chartData= "barData"
                :options="barOptions"
                :key="plotKey"
                class="barPlot"
              )
              small {{$t('assessment.sust_uc_es.goProfile')}}
        p(v-if="scoresCompare && rankingData.length==0" style="text-align:center;") {{$t('assessment.sust_uc_es.noScoring')}}
      //right menu
      b-col.rightMenu(cols="3")
        // select what you want to do
        b-form-group(label = "Select what you want to do")
          b-form-radio(v-model="option1", value = "single") {{$t('assessment.sust_uc_es.exploreECS')}}
          b-form-radio(v-model="option1", value = "compare" @change="getRanking(scoresCompare.value)") {{$t('assessment.sust_uc_es.compareScores')}}
        hr.mt-5
        // advanced search button
        b-row.pt-2.pb-2
          b-col(cols="auto" style="float: right")
            a(type="button" style="color: grey" v-b-toggle.advSearch) {{ $t('edibleMap.advancedSearch.advancedSearch') }}
          b-col(style="float: left")
            //b-icon(v-b-toggle.advSearch icon="chevron-up" style="color: var(--green-primary)")
            b-icon(v-b-toggle.advSearch icon="chevron-down" style="color: grey")
        // advanced search menu
        b-collapse(:visible="false" id="advSearch")
          //- filter by type
          b-form-group(:label="$t('edibleMap.advancedSearch.edibleCitySolutions.edibleCitySolutions')+':'")
            multiselect(
              v-model="filterType"
              :options="typesFiltered"
              :searchable="true"
              :show-labels="true"
              :placeholder="$t('placeholder.allValues')"
              :label="getLanguage"
              :multiple="true"
              track-by="custom_ids"
              @input="randomECS();getRanking(scoresCompare.value)"
              )
          //- filter by country
          b-form-group(:label="$t('assessment.filterCountry')+':'")
            multiselect(
              v-model="filterCountry"
              :options="countriesFiltered"
              :searchable="true"
              :show-labels="true"
              :placeholder="$t('placeholder.allValues')"
              label="long_name"
              track-by="short_name"
              :multiple="true"
              @input="randomECS();getRanking(scoresCompare.value)"
              )
          //- filter by city
          b-form-group(:label="$t('assessment.filterCity')+':'")
            multiselect(
              v-model="filterCity"
              :options="cities"
              :searchable="true"
              :show-labels="true"
              :placeholder="$t('placeholder.allValues')"
              label="long_name"
              track-by="short_name"
              :multiple="true"
              @input="randomECS();getRanking(scoresCompare.value)"
              )
        hr.mb-5
        div(v-if="option1 == 'single' && singleECS")
          //- select ECS
          b-form-group(:label="$t('assessment.selectECS')")
            multiselect(
              v-model="singleECS"
              :options="scoresFiltered"
              :searchable="true"
              :show-labels="true"
              :placeholder="$t('placeholder.typeHere')"
              label="name"
              track-by="id"
              :multiple="true"
              :max="3"
              @input="changeCurrent"
            )
          //- select ECS to compare
          p {{$t('assessment.sust_uc_es.selectPlots')}}:
          b-form-checkbox(v-model="check.sust" @change = "updatePlot()") {{$t('assessment.sust_uc_es.sust.title')}}
          b-form-checkbox(v-model="check.uC" @change = "updatePlot()") {{$t('assessment.sust_uc_es.uc.title')}}
          b-form-checkbox(v-model="check.catES" @change = "updatePlot()") {{$t('assessment.sust_uc_es.es.title')}}
          br
        div(v-if="option1 == 'compare'")
          //- Preselect facet
          b-form-group(:label="$t('assessment.preSelectFacet')")
            b-form-radio(v-model="preFacet" value="sust" @change="changePreFacet()") {{$t('assessment.sust_uc_es.sust.title')}}
            b-form-radio(v-model="preFacet" value="uc" @change="changePreFacet()") {{$t('assessment.sust_uc_es.uc.title')}}
            b-form-radio(v-model="preFacet" value="es" @change="changePreFacet()") {{$t('assessment.sust_uc_es.es.title')}}
          //- Select facet
          b-form-group(:label="$t('assessment.selectFacet')")
            multiselect(
              v-model="scoresCompare"
              :options="getFacetOptions()"
              :searchable="true"
              group-values="scores",
              group-label="group",
              :group-select="false",
              :show-labels="true"
              :placeholder="$t('placeholder.typeHere')"
              track-by="value"
              label = "name"
              @input="getRanking($event.value)"
            )
        hr
        downloadData(
          :json="scores"
          style="margin-top:2em;margin-bottom:2em;")
        b-button.btn-secondary(
          pill
          v-b-modal.methodology) {{$t('assessment.sust_uc_es.methodology')}}


    //- modal for ECS not in list
    //b-modal(
    //  id="ECSnotList"
    //  :title="answerNotList.name"
    //  ok-only
    //  )
    //  p {{answerNotList.problem}}:
    //  ul(v-if="answerNotList.questions")
    //    li(v-for="question in unanswered")
    //      a(:href="'/ecs-profile?id='+answerNotList.id+'&step='+question.position") {{question.config[getNavLang]}}

    //- modal for methodology
    b-modal(
      id="methodology"
      :title="$t('assessment.sust_uc_es.methodology')"
      ok-only)
      h6 {{$t('assessment.sust_uc_es.methodText.title1')}}:
      p {{$t('assessment.sust_uc_es.methodText.text1')}}
      h6 {{$t('assessment.sust_uc_es.methodText.title2')}}:
      p {{$t('assessment.sust_uc_es.methodText.text2')}}
        a(href="https://doi.org/10.1016/j.scitotenv.2021.146237" target="_blank")  Castellar et al., 2021)
        | {{$t('assessment.sust_uc_es.methodText.text3')}}

</template>

<script>
  import axios from 'axios';
  import Multiselect from 'vue-multiselect';
  import Radar from "./vue-charts/radar.vue";
  import HorizontalBar from "./vue-charts/horizontalBar.vue";
  import DownloadData from "../downloadData.vue"

  export default {
    name: "Sust-UC-ES",
    components: {
      Radar,
      HorizontalBar,
      Multiselect,
      DownloadData
    },
    props: {
      ecsUser:Array
    },
    data: function () {
      return {
        scores: [],
        option1: "single",
        singleECS: [],
        plotKey: 0,
        answerNotList: [],
        anyNotinList: false,
        check: {sust: true, uC: true, catES: true},
        scoresCompare: {value:'avg_sust', name: this.$t('assessment.sust_uc_es.average')+' '+this.$t('assessment.sust_uc_es.sust.title')},
        question152: [],
        filterType: [],
        filterCountry: [],
        filterCity: [],
        cities: [],
        preFacet: "sust",
        rankingData: {},
        unanswered: [],
        barData: {},
        updatedPlot: true,
        plotOptions: {
          legend: false,
          responsive: true,
          maintainAspectRatio:true,
          scale: {
            ticks: {
              display: false,
              min: 0,
              max: 1,
              stepSize: 0.1
            },
            pointLabels: {
              fontSize: 13
            }
          }
        },
        barOptions: {
          'onClick': (evt, item)=>{
              this.clickPlot(item)
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              //barThickness: 20,
              ticks: {
                beginAtZero: true,
                max: 1
              }
            }],
            yAxes: [{
              categoryPercentage: 1,
              barPercentage: 0.7,
              //barThickness: 20,
              //maxBarThickness: 10,
              gridLines: {
                display: false
              },
              ticks: {
                fontSize: 15,
              },
              afterFit: function (scaleInstance) {
                scaleInstance.width = 380; // sets the width to 100px
              }
            }],
          },
          responsive: true,
          maintainAspectRatio: false
        },
        scoresSust: [
          {
            group: this.$t('assessment.sust_uc_es.sust.title'),
            scores: [
              {value: "avg_sust", name: this.$t('assessment.sust_uc_es.average')+' '+this.$t('assessment.sust_uc_es.sust.title')},
              {value: "seeds", name: this.$t('assessment.sust_uc_es.sust.seeds')},
              {value: "weed", name: this.$t('assessment.sust_uc_es.sust.weed')},
              {value: "waste", name: this.$t('assessment.sust_uc_es.sust.waste')},
              {value: "food", name: this.$t('assessment.sust_uc_es.sust.food')},
              {value: "water", name: this.$t('assessment.sust_uc_es.sust.water')},
              {value: "packaging", name: this.$t('assessment.sust_uc_es.sust.packaging1')+' '+this.$t('assessment.sust_uc_es.sust.packaging2')},
              {value: "energy", name: this.$t('assessment.sust_uc_es.sust.energy')},
              {value: "fuel", name: this.$t('assessment.sust_uc_es.sust.fuel')},
              {value: "fertilizers", name: this.$t('assessment.sust_uc_es.sust.fertilizers')}
            ]
          }
        ],
        scoresUC: [
          {
            group: this.$t('assessment.sust_uc_es.uc.title'),
            scores: [
              {value: "avg_uc", name: this.$t('assessment.sust_uc_es.average')+' '+this.$t('assessment.sust_uc_es.uc.title')},
              {value: "UC_climate", name: this.$t('assessment.sust_uc_es.uc.climate1')+' '+this.$t('assessment.sust_uc_es.uc.climate2')},
              {value: "UC_water", name: this.$t('assessment.sust_uc_es.uc.water1')+' '+this.$t('assessment.sust_uc_es.uc.water2')},
              {value: "UC_coastal", name: this.$t('assessment.sust_uc_es.uc.coastal1')+' '+this.$t('assessment.sust_uc_es.uc.coastal2')},
              {value: "UC_green", name: this.$t('assessment.sust_uc_es.uc.green1')+' '+this.$t('assessment.sust_uc_es.uc.green2')},
              {value: "UC_air", name: this.$t('assessment.sust_uc_es.uc.airQuality')},
              {value: "UC_urban", name: this.$t('assessment.sust_uc_es.uc.urban1')+' '+this.$t('assessment.sust_uc_es.uc.urban2')},
              {value: "UC_participatory", name: this.$t('assessment.sust_uc_es.uc.participatory1')+' '+this.$t('assessment.sust_uc_es.uc.participatory2')},
              {value: "UC_social", name: this.$t('assessment.sust_uc_es.uc.socialJustice1')+' '+this.$t('assessment.sust_uc_es.uc.socialJustice2')},
              {value: "UC_health", name: this.$t('assessment.sust_uc_es.uc.publicHealth1')+' '+this.$t('assessment.sust_uc_es.uc.publicHealth2')},
              {value: "UC_economic", name: this.$t('assessment.sust_uc_es.uc.economic1')+' '+this.$t('assessment.sust_uc_es.uc.economic2')}
            ]
          }
        ],
        scoresES: [
          {
            group: this.$t('assessment.sust_uc_es.es.title'),
            scores: [
              {value: "avg_es", name: this.$t('assessment.sust_uc_es.average')+' '+this.$t('assessment.sust_uc_es.es.title')},
              {value: "ES_regulation", name: this.$t('assessment.sust_uc_es.es.regulation')},
              {value: "ES_cultural", name: this.$t('assessment.sust_uc_es.es.cultural')},
              {value: "ES_supporting", name: this.$t('assessment.sust_uc_es.es.supporting')},
              {value: "ES_provision", name: this.$t('assessment.sust_uc_es.es.provisioning')}
            ]
          },
          {
            group: this.$t('assessment.sust_uc_es.es.regulation'),
            scores: [
              {value: "climate_regulation", name: this.$t('assessment.sust_uc_es.es.climate_regulation1')+' '+this.$t('assessment.sust_uc_es.es.climate_regulation2')},
              {value: "water_regulation", name: this.$t('assessment.sust_uc_es.es.water_regulation1')+' '+this.$t('assessment.sust_uc_es.es.water_regulation2')},
              {value: "water_purification", name: this.$t('assessment.sust_uc_es.es.water_purification1')+' '+this.$t('assessment.sust_uc_es.es.water_purification2')},
              {value: "air_regulation", name: this.$t('assessment.sust_uc_es.es.air_quality1')+' '+this.$t('assessment.sust_uc_es.es.air_quality2')},
              {value: "pollination", name: this.$t('assessment.sust_uc_es.es.pollination')},
              {value: "pest_regulation", name: this.$t('assessment.sust_uc_es.es.disease1')+' '+this.$t('assessment.sust_uc_es.es.disease2')},
              {value: "erosion_control", name: this.$t('assessment.sust_uc_es.es.erosion1')+' '+this.$t('assessment.sust_uc_es.es.erosion2')}
            ]
          },
          {
            group: this.$t('assessment.sust_uc_es.es.cultural'),
            scores: [
              {value: "aesthetic", name: this.$t('assessment.sust_uc_es.es.aesthetic1')+' '+this.$t('assessment.sust_uc_es.es.aesthetic2')},
              {value: "recreational", name: this.$t('assessment.sust_uc_es.es.recreation1')+' '+this.$t('assessment.sust_uc_es.es.recreation2')},
              {value: "spiritual_values", name: this.$t('assessment.sust_uc_es.es.spiritual1')+' '+this.$t('assessment.sust_uc_es.es.spiritual2')},
              {value: "social_relations", name: this.$t('assessment.sust_uc_es.es.social_relations1')+' '+this.$t('assessment.sust_uc_es.es.social_relations2')}
            ]
          },
          {
            group: this.$t('assessment.sust_uc_es.es.supporting'),
            scores: [
              {value: "habitat", name: this.$t('assessment.sust_uc_es.es.habitat1')+' '+this.$t('assessment.sust_uc_es.es.habitat2')},
              {value: "soil_formation", name: this.$t('assessment.sust_uc_es.es.soil_formation1')+' '+this.$t('assessment.sust_uc_es.es.soil_formation2')},
              {value: "nutrient_cycling", name: this.$t('assessment.sust_uc_es.es.nutrient_cycling')},
              {value: "primary_production", name: this.$t('assessment.sust_uc_es.es.primary_production1')+' '+this.$t('assessment.sust_uc_es.es.primary_production2')}
            ]
          },
          {
            group: this.$t('assessment.sust_uc_es.es.provisioning'),
            scores: [
              {value: "food_fiber", name: this.$t('assessment.sust_uc_es.es.food')},
              {value: "fuel_es", name: this.$t('assessment.sust_uc_es.es.fuel')},
              {value: "biochemicals", name: this.$t('assessment.sust_uc_es.es.biochemical1')+' '+this.$t('assessment.sust_uc_es.es.biochemical2')},
              {value: "fresh_water", name: this.$t('assessment.sust_uc_es.es.fresh_water')}
            ]
          }
        ]
      }
    },
    mounted: async function(){
      let _this = this
      // get scores
      await axios.post('answers/getAssessment')
      .then(function (response){
        _this.scores = response.data
        _this.scores.forEach(async function(ecs){
          ecs.address = JSON.parse(ecs.address)
          if (ecs.address){
            ecs.address.forEach(function(comp){
              if (comp.types.includes("locality") || comp.types.includes("postal_town")){
                ecs.city = comp
              } else if (comp.types.includes("country")){
                ecs.country = comp
              }
            })
          }
          // console.log(ecs.address, ecs.city, ecs.country)
        });
        console.log("scores", _this.scores)
      })
      .catch(function (error){
        console.log("getAssessment error", error)
      })
      // get question type
      await axios.post('questions/query/getQuestionbyId', {id_question: 152})
      .then(function (response){
        _this.question152 = JSON.parse(response.data[0].config).answers;
        console.log("question 152", _this.question152)
      })
      .catch(function (error){
        console.log("get question error", error)
      });

      // add user ecs in case they are not in scores
      if (_this.ecsUser.length > 0){
        _this.ecsUser.forEach(function(ecs){
          // if user ecs not in scores, add at the end, if so set as singleECS
          if (!_this.scores.find(e => e.ecs_id == ecs.id)){
            _this.scores.push({
              ecs_id: ecs.id,
              name: ecs.name
            })
          }
        })
      };

      // check if id is in route and set single ECS consequently
      if (_this.$route.params.id && _this.scores.find(e => e.ecs_id ==_this.$route.params.id)){
        console.log("condition on params")
        _this.singleECS = [_this.scores.find(e => e.ecs_id ==_this.$route.params.id)]
      } else if (_this.ecsUser.length > 0){
        console.log("condition in user", _this.ecsUser)
        _this.ecsUser.forEach(function(ecs){
          if (_this.scores.find(e => e.ecs_id == ecs.id) && !_this.singleECS[0]){
            _this.singleECS = [_this.scores.find(e => e.ecs_id == ecs.id)]
          }
        })
      } else {
        _this.randomECS()
      }
    },
    computed: {
      scoresFiltered: function(){
        console.log("filterType", this.filterType)
        let scoresF = []
        // filter by type
        if (this.filterType.length > 0){
          let _this = this
          this.scores.forEach(function(ecs){
            if(_this.filterType.filter(e => e.custom_ids == ecs.type_id).length > 0)
              scoresF.push(ecs)
          });

        } else {
          scoresF = this.scores
          console.log("scores filtered by type", scoresF)
        }
        // filter by country
        let scoresFF = []
        if (this.filterCountry.length > 0){
          let _this = this
          scoresF.forEach(function(ecs){
            if(ecs.country && _this.filterCountry.filter(e => e.short_name == ecs.country.short_name).length > 0)
              scoresFF.push(ecs)
          });
          console.log("scores filtered by country", scoresFF)
        } else {
          scoresFF = scoresF
        }
        // filter by city
        let scoresFFF = []
        if (this.filterCity.length > 0){
          let _this = this
          console.log("filterCity", this.filterCity)
          scoresFF.forEach(function(ecs){
            if(ecs.city && _this.filterCity.filter(e => e.short_name == ecs.city.short_name).length > 0)
              scoresFFF.push(ecs)
          });
          console.log("scores filtered by city", scoresFFF)
        } else {
          scoresFFF = scoresFF
        }
        return scoresFFF
      },
      chartSust: function() {

        return this.getSustData();
      },
      ECSnotList: function(){
        let ecs = this.singleECS[0]
        this.unanswered = []
        let _this = this
        axios.post('answers/setECSassessment', {"id_ecs": ecs.ecs_id})
            .then(function (response) {
              console.log("ECSnotList", response.data)
              if (response.data.errorArea) {
                _this.checkAnswers({id_62: false, id_75: false, id_134: false})
                _this.answerNotList = {
                  id: ecs.id,
                  name: ecs.name,
                  problem: _this.$t('assessment.sust_uc_es.errorAreas')
                }
              } else if (response.data.noScores) {
                _this.checkAnswers(response.data.answers)
                _this.answerNotList = {
                  id: ecs.ecs_id,
                  name: ecs.name,
                  problem: _this.$t('assessment.sust_uc_es.noScores'),
                  questions: true
                }
              }
            })
            .catch(function (error) {
              console.log("error", error)
            });
      },
      getLanguage: function(){
        if (!this.$store.getters.getLanguage || this.$store.getters.getLanguage.lang == "en"){
          return "text_1"
        } else  {
          return "text_"+this.$store.getters.getLanguage.lang
        }
      },
      getNavLang: function(){
        if (!this.$store.getters.getLanguage){
          return "text_navbar_lang1"
        } else {
        return "text_navbar_lang_"+this.$store.getters.getLanguage.id
        }
      },
      typesFiltered: function(){
        let types = []
        let _this = this
        this.question152.forEach(function(type){
            if(_this.scores.filter(e => e.type_id == type.custom_ids).length > 0)
              types.push(type)
          });

        types.sort(this.compareTypes)
        return types
      },
      countriesFiltered: function(){
        let countries = []
        let countriesShortNames = []
        this.scores.forEach(function(ecs){
          if (ecs.country && !countriesShortNames.includes(ecs.country.short_name)){
            countriesShortNames.push(ecs.country.short_name)
            countries.push(ecs.country)
          }
        })
        countries.sort(this.comparePlaces)

        this.cities = []
        this.filterCity = []
        let shortNames = []
        let _this = this

        if (this.filterCountry.length > 0){
          this.scores.forEach(function(ecs){
            if (ecs.city
              && !shortNames.includes(ecs.city.short_name)
              && _this.filterCountry.find(c => c.short_name == ecs.country.short_name)){
              shortNames.push(ecs.city.short_name)
              _this.cities.push(ecs.city)
            }
          })
        } else {
          this.scores.forEach(function(ecs){
            if (ecs.city && !shortNames.includes(ecs.city.short_name)){
              shortNames.push(ecs.city.short_name)
              _this.cities.push(ecs.city)
            }
          });
        }

        this.cities.sort(this.comparePlaces)
        return countries
      },
      citiesFiltered: function(){
        let cities = []
        let shortNames = []
        let _this = this

        if (this.filterCountry.length > 0){
          this.scores.forEach(function(ecs){
            if (ecs.city
              && !shortNames.includes(ecs.city.short_name)
              && _this.filterCountry.find(c => c.short_name == ecs.country.short_name)){
              shortNames.push(ecs.city.short_name)
              cities.push(ecs.city)
            }
          })
        } else {
          this.scores.forEach(function(ecs){
            if (ecs.city && !shortNames.includes(ecs.city.short_name)){
              shortNames.push(ecs.city.short_name)
              cities.push(ecs.city)
            }
          });
        }

        cities.sort(this.comparePlaces)
        return cities
      }
    },
    methods:{
      comparePlaces: function(a,b){
        if ( a['long_name'] < b['long_name'] ){
          return -1;
        } else if ( a['long_name'] > b['long_name'] ){
          return 1;
        }
          return 0;
      },
      compareTypes: function(a,b){
        if ( a[this.getLanguage] < b[this.getLanguage] ){
          return -1;
        } else if ( a[this.getLanguage] > b[this.getLanguage] ){
          return 1;
        }
          return 0;
      },
      randomECS: async function(){
        let scores = await this.scoresFiltered
        this.singleECS = [scores[Math.floor(Math.random() * (scores.length-1))]]
        console.log("randomECS", this.singleECS)
        this.changeCurrent()
      },
      changeCurrent: function (){
        this.plotKey += 1;
        console.log("keyPlot", this.plotKey)
      },
      getSustData: function() {

        let sustLabels = [];
        let sustScores0 = [];
        let sustScores1 = [];
        let sustScores2 = [];

        let backgroundcolors = ['rgba(93, 143, 73, 0.5)', 'rgba(197, 30, 35, 0.5)','rgba(36, 46, 227, 0.5)']
        let datasets = []

        // sustainability scores
        if (this.check.sust) {
          let sustLabelsObj = {
            seeds: this.$t('assessment.sust_uc_es.sust.seeds'),
            weed: this.$t('assessment.sust_uc_es.sust.weed'),
            waste: this.$t('assessment.sust_uc_es.sust.waste'),
            food: this.$t('assessment.sust_uc_es.sust.food'),
            water: this.$t('assessment.sust_uc_es.sust.water'),
            packaging: [this.$t('assessment.sust_uc_es.sust.packaging1'), this.$t('assessment.sust_uc_es.sust.packaging2')],
            energy: this.$t('assessment.sust_uc_es.sust.energy'),
            fuel: this.$t('assessment.sust_uc_es.sust.fuel'),
            fertilizers: this.$t('assessment.sust_uc_es.sust.fertilizers')
          };
          if (this.singleECS.length === 3 && this.singleECS[2].ecs_id) {
            for (const [key, value] of Object.entries(this.singleECS[0])){
              if (sustLabelsObj.hasOwnProperty(key) &&
                  value !== null &&
                  this.singleECS[1][key] !== null &&
                  this.singleECS[2][key] !== null){
                sustLabels.push(sustLabelsObj[key])
                sustScores0.push(this.singleECS[0][key])
                sustScores1.push(this.singleECS[1][key])
                sustScores2.push(this.singleECS[2][key])
              }
            }
          } else if (this.singleECS.length === 2 && this.singleECS[1].ecs_id) {
            for (const [key, value] of Object.entries(this.singleECS[0])) {
              if (sustLabelsObj.hasOwnProperty(key) && value !== null && this.singleECS[1][key] !== null) {
                sustLabels.push(sustLabelsObj[key])
                sustScores0.push(this.singleECS[0][key])
                sustScores1.push(this.singleECS[1][key])
              }
            }
          } else {
            for (const [key, value] of Object.entries(this.singleECS[0])) {
              if (sustLabelsObj.hasOwnProperty(key) && value !== null) {
                sustLabels.push(sustLabelsObj[key])
                sustScores0.push(this.singleECS[0][key])
              }
            }
          }
        }

        // urban challenges scores
      if (this.check.uC) {
          let labelsUC = {
            UC_climate: [this.$t('assessment.sust_uc_es.uc.climate1'),this.$t('assessment.sust_uc_es.uc.climate2')],
            UC_water: [this.$t('assessment.sust_uc_es.uc.water1'),this.$t('assessment.sust_uc_es.uc.water2')],
            UC_coastal: [this.$t('assessment.sust_uc_es.uc.coastal1'),this.$t('assessment.sust_uc_es.uc.coastal2')],
            UC_green: [this.$t('assessment.sust_uc_es.uc.green1'),this.$t('assessment.sust_uc_es.uc.green2')],
            UC_air: this.$t('assessment.sust_uc_es.uc.airQuality'),
            UC_urban: [this.$t('assessment.sust_uc_es.uc.urban1'),this.$t('assessment.sust_uc_es.uc.urban2')],
            UC_participatory: [this.$t('assessment.sust_uc_es.uc.participatory1'),this.$t('assessment.sust_uc_es.uc.participatory2')],
            UC_social: [this.$t('assessment.sust_uc_es.uc.socialJustice1'),this.$t('assessment.sust_uc_es.uc.socialJustice2')],
            UC_health: [this.$t('assessment.sust_uc_es.uc.publicHealth1'),this.$t('assessment.sust_uc_es.uc.publicHealth2')],
            UC_economic: [this.$t('assessment.sust_uc_es.uc.economic1'),this.$t('assessment.sust_uc_es.uc.economic2')]
          }

          for (const [key, value] of Object.entries(this.singleECS[0])) {
            if (labelsUC.hasOwnProperty(key)) {
              sustLabels.push(labelsUC[key])
              if (value === null) sustScores0.push(0)
              else sustScores0.push(value)
            }
          }
          if (this.singleECS[1] && this.singleECS[1].ecs_id) {
            for (const [key, value] of Object.entries(this.singleECS[1])) {
              if (labelsUC.hasOwnProperty(key)) {
                if (value === null) sustScores1.push(0)
                else sustScores1.push(value)
              }
            }
          }
          if (this.singleECS[2] && this.singleECS[2].ecs_id) {
            for (const [key, value] of Object.entries(this.singleECS[1])) {
              if (labelsUC.hasOwnProperty(key)) {
                if (value === null) sustScores2.push(0)
                else sustScores2.push(value)
              }
            }
          }
        }

        // categories of ecosystem services
        if (this.check.catES) {
          let labelsES = {
            ES_regulation: this.$t('assessment.sust_uc_es.es.regulation'),
            ES_cultural: this.$t('assessment.sust_uc_es.es.cultural'),
            ES_supporting: this.$t('assessment.sust_uc_es.es.supporting'),
            ES_provision: this.$t('assessment.sust_uc_es.es.provisioning')
          }
          
          console.log("catES is true")
          for (const [key, value] of Object.entries(this.singleECS[0])) {
            if (labelsES.hasOwnProperty(key)) {
              sustLabels.push(labelsES[key])
              if (value === null) sustScores0.push(0)
              else sustScores0.push(value)
            }
          }
          if (this.singleECS[1] && this.singleECS[1].ecs_id) {
            for (const [key, value] of Object.entries(this.singleECS[1])) {
              if (labelsES.hasOwnProperty(key)) {
                if (value === null) sustScores1.push(0)
                else sustScores1.push(value)
              }
            }
          }
          if (this.singleECS[2] && this.singleECS[2].ecs_id) {
            for (const [key, value] of Object.entries(this.singleECS[2])) {
              if (labelsES.hasOwnProperty(key)) {
                if (value === null) sustScores2.push(0)
                else sustScores2.push(value)
              }
            }
          }
        }


        // if there less than 3 labels, create empty ones
        while (sustScores0.length < 3) {
          sustLabels.push('')
          sustScores0.push(1)
          sustScores1.push(1)
          sustScores2.push(1)
        };

        var sustData = {
          labels: sustLabels,
          datasets: [{
            label: this.singleECS[0].name,
            data: sustScores0,
            backgroundColor: backgroundcolors[0],
            pointRadius: 0
          }]
        }

        if (this.singleECS[1] && this.singleECS[1].ecs_id) {
          sustData.datasets.push({
            label: this.singleECS[1].name,
            data: sustScores1,
            backgroundColor: backgroundcolors[1],
            pointRadius: 0
          });
        }
        if (this.singleECS[2] && this.singleECS[2].ecs_id) {
          sustData.datasets.push({
            label: this.singleECS[2].name,
            data: sustScores2,
            backgroundColor: backgroundcolors[2],
            pointRadius: 0
          });
        }

        console.log("sustData", sustData)
        return sustData;
      },
      updatePlot: function(){
        if (!this.check.sust && !this.check.uC && !this.check.catES) {
          this.check.sust = true;
          this.check.uC = true;
          this.check.catES = true;
        }
        this.plotKey +=1
      },
      checkAnswers: async function(answers){
        let _this = this;
        for (const [key, value] of Object.entries(answers)) {
          if (value === false){
            // get question type
            await axios.post('questions/query/getQuestionbyId', {id_question: Number(key.slice(3))})
            .then(function (response){
              _this.unanswered.push({
                id: Number(key.slice(3)),
                config: JSON.parse(response.data[0].config)
                });
            })
            .catch(function (error){
              console.log("get question error", error)
            });

          }
        }
        console.log("unanswered", _this.unanswered);

      },
      checkNotList: function(ecs_id){
        if(this.scores.find(e => e.ecs_id == ecs_id)){
          return false
        } else {
          this.anyNotinList = true
          return true
        }
      },
      changePreFacet: function(){
        console.log("changePreFacet", this.preFacet)
        this.scoresCompare = {
          value: 'avg_'+this.preFacet,
          name: this.$t('assessment.sust_uc_es.average')+' '+this.$t('assessment.sust_uc_es.'+this.preFacet+'.title')
        }
        this.getRanking('avg_'+this.preFacet)
      },
      getFacetOptions: function(){
        if (this.preFacet == 'sust')
          return this.scoresSust
        else if (this.preFacet == 'uc')
          return this.scoresUC
        else
          return this.scoresES
      },
      getRanking(facet){
        console.log("facet",facet)

        let i = -1

        function compare(a, b) {
          if ( a[facet] > b[facet] ){
            return -1;
          } else if ( a[facet] < b[facet] ){
            return 1;
          }
            return 0;
        }

        this.rankingData = JSON.parse(JSON.stringify(this.scoresFiltered));
        this.rankingData = this.rankingData.sort(compare);
        let rankingNotNull = [];
        this.rankingData.forEach(function(ecs){
          if (ecs[facet] > 0){
            rankingNotNull.push(ecs)
          };
        });
        this.rankingData = rankingNotNull;
        let labels = [];
        let scores = [];
        let _this = this
        this.rankingData.slice(0,10).forEach(function(ecs){
          labels.push(ecs.name);
          scores.push(ecs[facet])
        });
        console.log("scores", scores)

        // change the colors of user's ecs
        let myColors = [];
        labels.forEach(function(label){
          if (_this.ecsUser.find(e => e.name == label))
            myColors.push('rgba(197, 30, 35, 0.5)')
          else
            myColors.push('rgba(93, 143, 73, 0.5)')
        });

        // add the user's ecs in case it's not in top10 and fill with grey
        i = 0;
        _this.ecsUser.forEach(function(ecs){
          if (!labels.includes(ecs.name) && !_this.checkNotList(ecs.id)){
            let newECS = _this.scoresFiltered.find(e => e.name == ecs.name);
            if (newECS) {
              if (i == 0){
                labels.push('');
                scores.push(0);
                myColors.push('rgba(180, 180, 180, 0.5)')
              }
              // add empy column before ecs
              labels.push(ecs.name);
              if (newECS[facet]) scores.push(newECS[facet])
              else scores.push(0);
            }
            myColors.push('rgba(180, 180, 180, 0.5)')

            i ++;
          };
        });

        this.barData = {
          labels: labels,
          datasets: [{
              label: this.scoresCompare.name,
              data: scores,
              backgroundColor: myColors
            }]
        }
        this.changeCurrent();
      },
      clickPlot: function (item) {
        if (item.length > 0){
          let ecsName = item[0]['_model'].label
          let id = this.scores.find(e => e.name == ecsName).ecs_id
          this.$router.push('/showEcsProfile/'+id)
        }
      },
      goToEditProfile: function(ecs_id){
        this.$router.push('/ecs-profile?id='+ecs_id)
      }
    }
  }

</script>

<style scoped>

  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  .rightMenu{
    background-color: var(--light-gray-primary);
    padding: 2em;
  }

  .plot {
    margin: auto;
    padding: 1em;
    text-align: center;
  }

  .plotCompare {
      padding: 1em;
      text-align: center;
      margin-left: auto;
  }

  h4, h4:hover {
    color: var(--green-primary);
    display: inline-block;
  }

  .compare, .compare:hover {
    color: var(--dark-red);
    text-decoration-color: var(--dark-red);
  }

  .compare2, .compare2:hover {
    color: rgb(36, 46, 227);
    text-decoration-color: rgb(36, 46, 227);
  }


  a:hover {
    text-decoration-color: var(--green-primary);
  }

  h5 {
    text-align: center;
    color: var(--green-primary)
  }

  table.ranking {
    width:100%;
    margin-top:2em;
  }

  td {
    padding: 0.5em;
  }

  .barPlot {
    cursor: pointer;
  }

  .notList {
    list-style-type: none;
    line-height: 50%;
  }

  .notList:hover {
    text-decoration: underline;
  }

  .linkButton {
    padding-left: 0px;
  }


  .buttonIn {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: right;
  }

  .legend{
    background-color: var(--green-primary);
    height: 1em;
    width: 1em;
    display: inline-block;
    margin-right: 0.5em;
  }

  .legend2{
    background-color: var(--dark-red);
  }

  .legend3{
    background-color: rgb(36, 46, 227);
  }

  .link:hover {
    text-decoration: underline;
  }

</style>
