<!-- PROPS:
        ecs_ids: Array of ids, then the component makes an axios call to get the object with data
        json: If json is provided, the component use this json to generate the excel file, the structure
                of json is an array of objects (only 1 level of attributes is accepted):
                        [{id: 1, attr1: xxxxx, attr2: xxxxx}],
                        [{id: 2, attr1: xxxxx, attr2: xxxxx}],
                        ...
-->

<template lang="pug">
  div
    b-row(align-v="center")
      b-col()
        span {{$t('buttons.downloadFormat')}}
        b-form-select(v-model="downloadFormat" :options="options")
      b-col()
        b-button.buttonGreen(pill @click="downloadData" v-if="!loading") {{$t('buttons.downloadData')}}
        img(:src="'../images/loading.gif'" style="height:30px" v-if="loading")

</template>

<script>

  import axios from 'axios';
  import XLSX from 'xlsx'

  export default {
    name: 'downloadData',
    props: {
      ecs_ids: Array,
      exp_ids: Array,
      monitoring_id: String,
      ecs_name: String,
      json: Array
    },
    data(){
      return {
        downloadFormat: 'xlsx',
        options: [
          //{value: 'csv', text: 'CSV'},
          {value: 'xlsx',text: this.$t('buttons.excelFile')},
          {value: 'json',text: 'JSON'}
        ],
        loading: false
      }
    },
    methods: {
      async downloadData(){
        console.log("downloadData function active", this.monitoring_id)
        if (this.ecs_ids || this.exp_ids || this.monitoring_id){
          this.loading = true;
          let _this = this;
          if (!this.$store.getters.getLanguage || this.$store.getters.getLanguage.lang == "en"){
            var lang = "text_1"
          } else  {
            var lang = "text_"+this.$store.getters.getLanguage.lang
          }

          if (this.ecs_ids){
            var url = 'answers/getECSParsedAnswers'
            var axiosKey = 'ids_ecs'
            var axiosValue = _this.ecs_ids
          } else if (this.exp_ids) {
            var url = 'answers/getExperiencesParsedAnswers'
            var axiosKey = 'ids_experience'
            var axiosValue = _this.exp_ids
          } else if (this.monitoring_id){
            var url = 'answers/getMonitoringanswers'
            var axiosKey = 'ecs_id'
            var axiosValue = _this.monitoring_id

          } else {
            _this.loading = false;
            return
          };

          await axios.post(url,
            {[axiosKey]: axiosValue, "lang": lang}
          )
            .then(function (response){
              // console.log(response)
              if (_this.downloadFormat == 'xlsx')
                _this.downloadXlsx(response.data);
              else
                _this.downloadJson(response.data)
          })
            .catch(function (error){
              console.log('Error: '+error)
            })
            .finally(function(){
              _this.loading = false;
            })
        }
        else if (this.json){
          if (this.downloadFormat == 'xlsx')
            this.downloadXlsx(this.json);
          else
            this.downloadJson(this.json)
        }
      },
      downloadXlsx(data){
        const wb = XLSX.WorkBook = XLSX.utils.book_new();
        if (this.monitoring_id){
          // console.log('json', data)
          Object.keys(data).forEach(ans => {
            const ws = XLSX.utils.json_to_sheet(data[ans])
            let sheet_name = ans.replace(/[\\\/\?\*\[\]]/, "")
            console.log(sheet_name)
            XLSX.utils.book_append_sheet(wb, ws, sheet_name)
          });
          var excelName = this.ecs_name
        } else {
          const ws = XLSX.utils.json_to_sheet(data)
          XLSX.utils.book_append_sheet(wb, ws, 'ecs_data');
          var excelName = 'ecs_data'
        }
        XLSX.writeFile(wb, excelName+'.xlsx')
      }, // end downloadXlsx
      downloadJson(data){
        var json = JSON.stringify(data)
        var exportedFilename = this.ecs_name+'.json';
        var blob = new Blob([json], { type: 'text/json' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
      }
    }
  }

</script>

<style scoped>
.buttonGreen {
  margin-bottom: -2em !important;
}
</style>
