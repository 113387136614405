<template>
  <path vector-effect="non-scaling-stroke"
        d="M 29.70,24.50
           C 29.70,24.50 36.00,24.50 36.00,24.50
             36.00,24.50 25.00,12.50 25.00,12.50
             25.00,12.50 14.10,24.50 14.10,24.50
             14.10,24.50 20.40,24.50 20.40,24.50
             20.90,24.50 21.40,24.90 21.40,25.50
             21.40,25.50 21.40,25.50 21.40,25.50
             21.40,25.50 21.40,38.90 21.40,38.90
             21.40,39.40 21.00,39.90 20.40,39.90
             19.90,39.90 19.40,39.50 19.40,38.90
             19.40,38.90 19.40,38.90 19.40,38.90
             19.40,38.90 19.40,26.40 19.40,26.40
             19.40,26.40 11.90,26.40 11.90,26.40
             11.40,26.40 10.90,26.00 10.90,25.40
             10.90,25.20 11.00,24.90 11.10,24.80
             11.10,24.80 24.20,10.40 24.20,10.40
             24.60,10.00 25.20,10.00 25.60,10.40
             25.60,10.40 25.60,10.40 25.60,10.40
             25.60,10.40 38.70,24.80 38.70,24.80
             39.10,25.20 39.00,25.80 38.60,26.20
             38.40,26.40 38.20,26.40 38.00,26.40
             38.00,26.40 30.50,26.40 30.50,26.40
             30.50,26.40 30.50,38.80 30.50,38.80
             30.50,39.30 30.10,39.80 29.50,39.80
             29.00,39.80 28.50,39.40 28.50,38.80
             28.50,38.80 28.50,38.80 28.50,38.80
             28.50,38.80 28.50,25.50 28.50,25.50
             28.70,25.00 29.10,24.50 29.70,24.50
             29.70,24.50 29.70,24.50 29.70,24.50 Z" />
</template>

<script>
  export default {
    name: "EdibleMatchIcon"
  }
</script>

<style scoped>

</style>