<template lang="pug">
  b-container
    b-row
      b-col
        b-row.bg-success.p-2
          b-col.align-self-center
            h5.mb-0 {{ questionNumber+1 }}. Question:
          b-col(align-self="end")
            .text-right
              b-button(variant = "primary" @click="saveToProfile").mr-1 Guardar
              b-button(v-b-modal.modal_not_answer variant = "secondary") Not answer
      b-col(sm="12")
          label.col-form-label(v-html="stringToLabel(question.config['text_lang_1'])")
          input.mb-1(:class="'form-control'" v-model="answered")
          b-button(@click="searchLocation") Search
          div#map
          //script(src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD6YD1VaM16bbuXFXOu2xd2Ik_SiBRTGDs&libraries=places&callback=initMap" async defer)


</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import gmapsInit from "../../../utils/gmaps.js"
  import axios from 'axios';

  export default {
    name: "questionLocation",
    props: ['question', 'questionNumber','answer'],
    mixins: [utilMixin],
    data: function(){
      return{
         address: "",
         map: null,
         infowindow: null,
         valid: false,
         answered: ""
      }
    },
    methods:{
      searchLocation: async function () {
        if(this.answered.length < 0) return;
        const google = await gmapsInit();
        const _this = this;

        var sydney = new google.maps.LatLng(-33.867, 151.195);

        var request = {
          query: this.answered,
          fields: ['name', 'geometry'],
        };

        if(_this.map == null){
            _this.map = new google.maps.Map(this.$el.querySelector('#map'), {
              center: sydney,
              zoom: 15,
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false
            });
        }
        const service = new google.maps.places.PlacesService(_this.map);

        service.findPlaceFromQuery(request, async function(results, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            for (var i = 0; i < results.length; i++) {
              await _this.createMarker(results[i]);
              console.log(results[i]);
            }
            console.log(results[0].geometry.location);
            _this.map.setCenter(results[0].geometry.location);
          }
        });
      },
      createMarker: async function (place) {

        const _this = this;
        const google = await gmapsInit();
        _this.valid = true;
        var marker = new google.maps.Marker({
          map: _this.map,
          position: place.geometry.location
        });
      },
      saveToProfile: function () {
        console.log("Save Question: ", this.question.id);
        let _this = this;
        if(this.answered === "" && this.valid){
          console.log("Please respond, valid address");
        }
        else {
          this.$emit('sendResponse', {
            id_question: _this.question.id,
            id_category: _this.question.category_id,
            answer: this.answered});
          axios.post('answers/setClientProfileAnswer', {
            question_id: _this.question.id,
            category_id: _this.question.category_id,
            answer: _this.answered,
          })
            .then(function (response) {
              console.log(response);
              console.log("Done Modify question");
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },
    mounted: async function () {
      try {
        console.log("Loading location question.")
        if(this.answer !== undefined){
          this.answered = this.answer.answer;
        }
        //console.log("Ref Object",this.$el.querySelector('#map'));
        // const _this = this;
        // const google = await gmapsInit();
        // const sydney = new google.maps.LatLng(-33.867, 151.195);
        // const geocoder = new google.maps.Geocoder();
        //
        // _this.map = new google.maps.Map(this.$el.querySelector('#map'), {
        //   center: sydney,
        //   zoom: 15,
        //   zoomControl: true,
        //   mapTypeControl: false,
        //   scaleControl: false,
        //   streetViewControl: false,
        //   rotateControl: false,
        //   fullscreenControl: false
        // });
        //
        // _this.map.setCenter(sydney);
        this.searchLocation();
        //_this.map = new google.maps.Map(this.$el.querySelector('#map'));
      } catch (error) {
        console.error(error);
      }
    }
  }
</script>

<style scoped>
    #map{
        height: 10rem;
        width: 100%;
        margin-top: 10px;
    }
</style>