<template lang="pug">
  .listECS
    b-container.p-0(fluid="true").banner
      b-img(src="/images/banner-myECSprofile.png" fluid alt="Fluid image;")
      div.bannerTitle
        p {{ $t('menu.experiences.myEcsExperiences') }}
    b-container.pl-4.pr-4.pb-4.profile(fluid="true")
      b-row(align-v="end")
        b-col
        b-col(cols="2")
          b-button.buttonGreen(pill @click="$router.push('/new-ecs')" ) {{ $t('menu.experiences.addNewExperience')}}
        b-col(cols="4" v-if="exp_ids.length > 0")
          DownloadData(:exp_ids="exp_ids")
      b-col(sm="12")
        br
        b-table(:items="items" :fields="fields" hover)
          template(v-slot:cell(buttons)="data")
            //- b-dropdown(id="dropdown-1" size="lg"  variant="btn-outline-secondary" toggle-class="text-decoration-none" text="···")
            //-   b-dropdown-item(:href="'ecs-experience?id='+data.item.experience_id+'&id_ecs='+data.item.id_ecs+'&name_ecs='+data.item.name") {{ $t('buttons.editExperience') }}
            //-   b-dropdown-item(@click="removeRow(data.item)") {{ $t('buttons.remove') }}
            b-button.greenLink.mr-3(variant="link" :href="'ecs-experience?id='+data.item.experience_id+'&id_ecs='+data.item.id_ecs+'&name_ecs='+data.item.name") {{ $t('buttons.editExperience') }}
            b-button.redLink.mr-3(variant="link" @click="removeRow(data.item)") {{ $t('buttons.remove') }}
          template(v-slot:cell()="data")
            div {{ data.value }}
</template>

<script>
  import axios from 'axios';
  import XLSX from 'xlsx';
  import DownloadData from './downloadData.vue'

  export default {
    name: "ExperienceList",
    components: {
      DownloadData
    },
    mounted() {
      let _this = this;


      axios.get('clients/expirience')
        .then(function (response) {
          console.log("Asked response: ",response.data);
          response.data.forEach( item => {
            _this.items.push({
              "experience_id" : item.id,
              "name": item.name,
              "id_ecs": item.id_ecs
             })
            _this.exp_ids.push(item.id)
          });
        })
        .catch(function (error) {
          console.log("Menu get client error:", error);
          // console.log(error);
        });
    },
    data(){
      return{
        fields:[
          { key: 'experience_id', label: this.$t('list.id'), sortable: true },
          { key: 'name', label: this.$t('list.name'), sortable: true },
          {key: 'buttons', label: ''}

        ],
        items: [],
        exp_ids: []
      }
    },
    methods:{
      async removeRow(item){
        if (!confirm("Are you sure to delete "+item.name+"?")) return

        console.log(item);
        let _this = this;
        await axios.post('clients/removeExperience',
          {"id_experience": item.experience_id}
        )
          .then(function (response) {
            console.log(response);
            console.log("Done Delete");
            _this.$router.go(0);
          })
          .catch(function (error) {
            console.log("Error getting answers");
            console.log(error);
          });
      }
    }
  }
</script>

<style scoped>
  .listECS{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  .greenLink, .greenLink:hover{
    color: var(--green-primary)
  }

  .redLink, .redLink:hover{
    color: var(--dark-red)
  }
</style>
