<template lang="pug">
    .design-planing
      b-container(fluid)
       b-row
        b-col(style="")
          iframe(v-if="!id_ecs" style="background-color: white; width: 100%; height: 2000px; border: none;"
            src="https://edicitnet.fgg.uni-lj.si/web-app/dss/set-scenario-area?v1")
          iframe(v-else-if="id_ecs" style="background-color: white; width: 100%; height: 2000px; border: none;"
            :src="'https://edicitnet.fgg.uni-lj.si/web-app/dss/set-scenario-area?v1&id_ecs='+id_ecs")

</template>

<script>
export default {
  name: 'DesignPlaning',
  props: {
    msg: String
  },
  data: function(){
    return {id_ecs: this.$route.params.id}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .design-planing{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 2rem 2rem;
    min-height: var(--height-child-comp);
  }
</style>
