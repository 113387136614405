<template lang="pug">
  .welcome
    b-jumbotron(header="")
      p Put your ECS in the most comprehensive Edible network map
      b-button(variant="primary" href="/new-ecs") Create an ECS PROFILE
      p Are you participating in a ECS? If yes share with us your ECS Experience
      b-button(variant="primary" href="/new-ecs") Create an ECS EXPERIENCE
</template>

<script>
  export default {
    name: "Welcome-Profile"
  }
</script>

<style scoped>

</style>