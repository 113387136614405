<template lang="pug">
  b-row
    b-col
      //- b-row.p-2
      //-   b-col.align-self-center
      //-     h5.mb-0.mt-2.questionNumberColor {{ questionNumber+1 }}/{{ nQuestions }}
      //-   b-col(align-self="end")
      //-     .text-right

      b-row
        b-col(sm="12")
          .lineSeparator
</template>

<script>
  export default {
    name: "questionHeader",
    props: ['questionNumber', 'nQuestions']
  }
</script>

<style scoped>

  .lineSeparator{
    height: 1px;
    background-color: var(--questions-font-color);
    width: 100%;
  }
  .questionNumberColor{
    color: black;
  }
</style>
