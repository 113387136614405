<template lang="pug">
  b-container(fluid).p-0
    b-row(no-gutters style="background-color: var(--light-gray-primary)")
      b-col(cols="4" md="3" xl="2"  style="min-height: 100vh; position:sticky; top:0; background-color: white")
        b-navbar(toggleable='lg' style="font-size: 0.9rem;")
          .sidebar-header
            b-container(fluid type="button" @click="home")#menuHeader
              h3(style="text-align: center;")
                span.title
                  span E
                  span di
                  span C
                  span it
                  span N
                  span et
                  span  Toolbox
              p  {{  $t('subtitle') }}
            b-container(fluid)#menuBlock1
              ul.list-unstyled.components()
                template(v-if="isLogIn")
                  li
                    b-row(fluid)
                      b-col(cols="2")
                        icon-base(icon-name="login")
                          login-icon
                      b-col(cols="8")
                        a(type="button" @click="logout") {{  $t('menu.register.logOut') }}
                template(v-else)
                  li
                    b-row(fluid)
                      b-col(cols="2")
                        icon-base(icon-name="login")
                          login-icon
                      b-col(cols="8")
                        a(type="button" @click="login") {{  $t('menu.register.logIn') }}
                  li
                    b-row(style="min-height: 31px")
                      b-col(cols="2")
                      b-col(cols="8")
                        a(type="button" @click="register") {{  $t('menu.register.registerNewAccount') }}
                li(v-if="languagesFetched" v-bind:key="this.currentLanguage.id")
                  b-row(style="min-height: 31px")
                    b-col(cols="2")
                    b-col(cols="8" style="margin-bottom: 0.75rem;")
                      //a LANGUAGES
                      a(type="button" v-b-toggle.ecsLgs style="color: var(--dark-red); font-weight: var(--bold-text); text-transform: uppercase;") {{ this.currentLanguage['lang_text'] }}
                      b-collapse.collapsed(id="ecsLgs" v-for="lang,index in listLangs" v-bind:key="lang.id" )
                        //a(type="button" :href="'/ecs-experience?id='+list.id+'&id_ecs='+list.id_ecs+'&name_ecs='+list.name") {{ index }} - {{ list.name }}
                        a(v-if="lang !== activeLang" type="button" @click="changeLang(lang)") {{ lang['lang_text'] }}
                        //:style="activeLang(lang)" @click="changeLang(lang)"
                    b-col(cols="2")
                      b-icon(v-b-toggle.ecsLgs icon="chevron-up" size="sm")
                li(v-if="isLogIn")
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="myecs")
                        my-ecs-icon
                    b-col(cols="8")
                      a(type="button" href="/ecs-lists") {{  $t('menu.profiles.myEcsProfiles') }}
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base()
                        my-ecs-icon(v-if="!isLogIn")
                    b-col(cols="8")
                      a(type="button" href="/new-ecs2" style="margin-bottom: 0.75rem") {{  $t('menu.profiles.createANewEcs') }}
                li(v-if="isLogIn")
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="ecsExp")
                        ecs-exp-icon
                    b-col(cols="8")
                      a(type="button" href="/ecs-experience-list") {{  $t('menu.experiences.myEcsExperiences') }}
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base()
                        ecs-exp-icon(v-if="!isLogIn")
                    b-col(cols="8")
                      a(type="button" href="/new-ecs" style="margin-bottom: 0.75rem") {{  $t('menu.experiences.addNewExperience') }}

                    template(v-if="isLogIn")
                      b-col(cols="2")
                        b-icon(v-b-toggle.ecsExp icon="chevron-up" size="sm")
                li(hidden)
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="savedItems")
                        items-icon
                    b-col(cols="8")
                      a.comingSoon(type="button") {{  $t('menu.mySavedItems') }}
                      //b-collapse(id="ecsItems" v-for="list,index in listExpirience" v-bind:key="list.id")
                      //    a(type="button" style="color: black" :href="'/ecs-experience?id='+list.id") {{ index }} - {{ list.name }}
                    template(v-if="isLogIn")
                      b-col(cols="2")
                        b-icon(v-b-toggle.ecsItems icon="chevron-up" size="sm")
                li(hidden)
                  b-row(fluid)
                    b-col(cols="2")
                      //icon-base(icon-name="savedItems")
                        items-icon
                    b-col(cols="8")
                      a.comingSoon(type="button") {{  $t('menu.myEventsNews') }}
                      //b-collapse(id="ecsItems" v-for="list,index in listExpirience" v-bind:key="list.id")
                      //    a(type="button" style="color: black" :href="'/ecs-experience?id='+list.id") {{ index }} - {{ list.name }}
                    template(v-if="isLogIn")
                      b-col(cols="2")
                        b-icon(v-b-toggle.ecsItems icon="chevron-up" size="sm")
                b-dd-divider
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="partOfUs")
                        part-of-us-icon
                    b-col(cols="8")
                      a(type="button" href="/biz" ) {{  $t('menu.marketplace') }}
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="designEcs")
                        design-ecs-icon
                    b-col(cols="8")
                      a(type="button" href="/design-planing" ) {{  $t('menu.designingAndPlanningEcs') }}
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="ecsPerf")
                        ecs-perf-icon
                    b-col(cols="8")
                      a(type="button" href="/assessment/") {{  $t('menu.ecsPerformance') }}
                li(hidden)
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="edibleMatch")
                        edible-match-icon
                    b-col(cols="8")
                      a.comingSoon(type="button" href="/none") {{  $t('menu.edibleMatch') }}
                //li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="toolsViews")
                        tools-views-icon
                    b-col(cols="8")
                      a(type="button" href="") HOW TO UNLOCK TOOLS AND VIEWS
                b-dd-divider
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="whatEcs")
                        login-icon
                    b-col(cols="8")
                      a(type="button" target="_blank" href="https://www.edicitnet.com/about/") {{  $t('menu.info.about_edicitnet') }}
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="whatEcs")
                        ecs-perf-icon
                    b-col(cols="8")
                      a(type="button" href="/about_toolbox") {{  $t('menu.info.about_toolbox') }}
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="whatEcs")
                        what-ecs-icon
                    b-col(cols="8")
                      //a LANGUAGES
                      a(type="button" href="/about_content") {{  $t('menu.info.about_content')  }}
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="ecs exp")
                        ecs-exp-icon
                    b-col(cols="8" style="margin-bottom: 0.75rem;")
                      //a LANGUAGES
                      a(type="button" href="/share_knowledge") {{  $t('menu.info.share_knowledge')  }}
                      b-collapse.collapsed(id="ecsknowledgeAbout" )
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base()
                        design-ecs-icon
                    b-col(cols="8" style="margin-bottom: 0.75rem;")
                      //a LANGUAGES
                      a(type="button" href="/about_functionalities") {{  $t('menu.info.about_functionalities')  }}
                li(hidden)
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="whatEcs")
                        what-ecs-icon
                    b-col(cols="8")
                      a(type="button" href="/unlock_tools") {{  $t('menu.info.unlock_tools') }}
                li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="whatEcs")
                        what-ecs-icon
                    b-col(cols="8")
                      a(type="button" href="/about_classification") {{  $t('menu.info.classification') }}
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="ecs exp")
                        what-ecs-icon
                    b-col(cols="8")
                      a(type="button" href="/report_bugs") Report bugs
                //li
                  b-row(fluid)
                    b-col(cols="2")
                      icon-base(icon-name="partOfUs")
                        part-of-us-icon
                    b-col(cols="8")
                      a.comingSoon(type="button" href="/none") {{  $t('menu.bePartOfEdibleNetworkmap') }}
            b-container(fluid)#menuFooter
              a(href="https://www.edicitnet.com/" target="_blank" title="Go to EdiCitNet website")
                b-img(src="/images/logo.png" fluid alt="Fluid image" style="margin-bottom: 15px;")
              b-button.buttonRed(pill block href="mailto:jcastellar@icra.cat?Cc=ariu@icra.cat&Subject=Edicity:%20comment" target="_blank") {{  $t('buttons.leaveComment') }}
      b-col(cols="8" md="9" xl="10" )
        router-view

</template>

<script>
  import AuthService from '@/services/AuthService.js';
  import axios from 'axios';
  import IconBase from '@/components/IconBase.vue';
  import MyEcsIcon from '@/components/icons/MyEcsIcon.vue';
  import LoginIcon from '@/components/icons/LoginIcon.vue';
  import EcsExpIcon from '@/components/icons/EcsExpIcon.vue';
  import ItemsIcon from '@/components/icons/ItemsIcon.vue';
  import DesignEcsIcon from '@/components/icons/DesignEcsIcon.vue';
  import EcsPerfIcon from '@/components/icons/EcsPerfIcon.vue';
  import EdibleMatchIcon from '@/components/icons/EdibleMatchIcon.vue';
  import ToolsViewsIcon from '@/components/icons/ToolsViewsIcon.vue';
  import WhatEcsIcon from '@/components/icons/WhatEcsIcon.vue';
  import PartOfUsIcon from '@/components/icons/PartOfUsIcon.vue';
  import {i18n} from "../i18n/i18n";

  export default {
    name: "Menus",
    data() {
      return {
        secretMessage: '',
        username: '',
        categories: [],
        listECS: [],
        listExpirience: [],
        listLangs: [],
        currentLanguage: null
      };
    },
    components:{ //Icons
      IconBase,
      MyEcsIcon,
      LoginIcon,
      EcsExpIcon,
      ItemsIcon,
      DesignEcsIcon,
      EcsPerfIcon,
      EdibleMatchIcon,
      ToolsViewsIcon,
      WhatEcsIcon,
      PartOfUsIcon
    },
    mounted: function () {
      let _this = this;

      if (!this.$store.getters.isLoggedIn) {
        //console.log("Not logged in", this.$router.currentRoute);
      } else {
        this.username = this.$store.getters.getUser.email;
        //console.log("Logged in: ", this.$store.getters.getUser);
      }

      if(_this.$store.getters.isLoggedIn) {
        axios.get('clients/ecs')
            .then(function (response) {
              //console.log("Asked response: ", response.data);
              _this.listECS = response.data;
            })
            .catch(function (error) {
              //console.log("Menu get client error:", error);
              if (error.response.status === 401) {
                console.log("Auth error loggin out");
                _this.logout()
              }
              //console.log(error.response.status);
            });

        axios.get('clients/expirience')
            .then(function (response) {
              //console.log("Asked response expirience: ", response);
              _this.listExpirience = response.data;
            })
            .catch(function (error) {
              //console.log("Menu get client error:", error);
              // console.log(error);
            });
      }
      if (!this.$store.getters.getLanguage) {
        //console.log("Default language undefined");
      } else {
        this.currentLanguage = this.$store.getters.getLanguage;
        //console.log("Current language: ", this.$store.getters.getLanguage['lang_text']);
      }

      if(_this.listLangs.length === 0 || _this.currentLanguage === null) {
        axios.get('langs/all')
          .then(function (response) {
            //console.log(response);
            if(_this.listLangs.length === 0) {
              _this.listLangs = response.data;
            }
            if(_this.currentLanguage === null){
              let language = _this.listLangs[0];
              _this.$store.dispatch('changeLanguage', {language});
              //console.log("Default language saved : ", _this.listLangs[0]);
              _this.currentLanguage = language;
            }
          })
          .catch(function (error) {
            //console.log("Menu get languages error:", error, error.response);
          });

      }
      i18n.locale = _this.currentLanguage.lang;

    },
    computed: {
      isLogIn: function () {
        return this.$store.getters.isLoggedIn
      },
      title() {
        return this.$store.state.title
      },
      categoriesMap() {
        let map = {};
        this.categories.forEach(function (item) {
          map[item.id] = item;
        });
        return map;
      },
      languagesFetched: function(){
        return this.listLangs !== 0 && this.currentLanguage;
      },
      activeLang: function () {
        return this.currentLanguage;
      }
    },
    async created() {
      let _this = this;
      /*
      if (!this.$store.getters.isLoggedIn) {
        console.log("Not logged in");
        if(this.$route.path !== '/sign-up')
          await this.$router.push('/login');
      }*/
      //console.log("Is logged in? ",this.$store.getters.isLoggedIn);
      this.username = this.$store.getters.getUser.username;
      //this.secretMessage = await AuthService.getSecretContent();
      //console.log("Loged in username: ", this.username);
      this.$store.commit('SET_TITLE', 'Profile');
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout');
        let language = this.currentLanguage;
        this.$store.dispatch('changeLanguage', {language});
        this.username = '';
        this.listECS = [];
        this.listExpirience = [];
        console.log("Logged out");
        this.home();

      },
      login: function () {
        this.$router.push('/login');
      },
      register: function() {
        this.$router.push('/sign-up');
      },
      home: function () {
        const path = '/';
        if(this.$route.path !== path)
          this.$router.push('/');
      },
      changeLang: function (lang) {
        var language = lang;
        this.$store.dispatch('changeLanguage', {language});
        this.currentLanguage = language;
        if(this.$store.getters.isLoggedIn){
          this.updateClientLang();
        }
        i18n.locale = this.currentLanguage.lang;
        console.log("Default language changed to: ", this.$store.getters.getLanguage['lang_text']);

      },
      styleLang: function (lang, index) {
        var style = "";
        if(this.currentLanguage.id === lang.id){
          style = "font-weight: var(--bold-text); color: var(--dark-red)";
        }
        if((index === 5 && this.currentLanguage.id !== 6) || (index === 4 && this.currentLanguage.id === 6)){
          style += "margin-bottom: 0.75rem;";
        }
        return style;
      },
      async updateClientLang(){

        let _this = this;
        await axios.post('clients/updateLang',
          {"lang": _this.currentLanguage}
        )
          .then(function (response) {
            //console.log(response);
            //console.log("Done updating client's lang on API");
            //_this.$router.go(0);
          })
          .catch(function (error) {
            //console.log("Error getting answers while updating client's lang");
            //console.log(error);
          });
      }
    }
  }
</script>

<style scoped>
  a, a[type=button] {
    color: var(--green-primary);
    align-self: center;
    font-weight: var(--bold-text);
  }
  a:hover, a:not([href]):hover, a[type=button]:hover {
    color: var(--dark-red);
    text-decoration: none;
  }
  a:active, a[type=button]:active{
    color: var(--dark-red);
  }

  .collapsed > a {
    font-weight: var(--text-weight);
  }

  a.comingSoon, a.comingSoon:hover{
    color: gray;
    font-weight: var(--text-weight);
    cursor: default;
  }

  .sidebar-header > a {
    color: var(--green-primary);
  }

  #menuHeader {
    color: var(--green-primary);
    margin-top: 20px;
    margin-bottom: 30px;
  }

  #menuHeader > p {
    font-size: x-small;
    text-align: center;
    font-weight: var(--bold-text);
  }

  #menuBlock1 {
    margin-top: 30px;
    margin-bottom: 30px;
    color: var(--green-primary);
  }

  #menuFooter {
    margin-bottom: 30px;
  }

  .title span {
    color: var(--green-primary);
    font-weight: var(--bold-text);
    text-align: center;
  }
  .title span:nth-child(2n+1) {
    color: var(--dark-red);
    font-weight: var(--bold-text);
    text-align: center;
  }

  .dropdown-divider {
    height: 0;
    margin: 1.5rem 0;
    overflow: hidden;
    border-top: 1px solid var(--gray-font-color);
  }

  .b-icon.bi.collapsed {
    transform: rotate(180deg);
  }


</style>
