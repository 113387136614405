
import en from '../../public/languages/en.json'
import es from '../../public/languages/es.json'
import cat from '../../public/languages/ca.json'
import fr from '../../public/languages/fr.json'
import de from '../../public/languages/de.json'
import pt from '../../public/languages/pt.json'
import sl from '../../public/languages/sl.json'
import ro from '../../public/languages/ro.json'


export const defaultLocale = 'en'

export const languages = {
  en: en,
  es: es,
  cat: cat,
  fr: fr,
  de: de,
  pt: pt,
  sl: sl,
  ro: ro
}