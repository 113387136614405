<template lang="pug">
  .createECS
    b-container.p-0(fluid="true").banner
      b-img(src="/images/banner-myECSprofile.png" fluid alt="Fluid image;")
      div.bannerTitle
        p {{ $t('createNew.profile.create') }}
    b-container.pl-4.pr-4.pb-4.profile(fluid="true")
      b-row
        b-col
          b-form-group(:label="$t('createNew.profile.text1')" valid-feedback="Thank you!" :invalid-feedback="invalidFeedback" :state="state")
            b-form-input(id="input-1" v-model="newEcsName" :state="state" trim)
          b-button.buttonGreen(pill :disabled="!state"  @click="createECS()") {{ $t('createNew.profile.create') }}

</template>

<script>

import gmapsInit from "../utils/gmaps.js"
import Multiselect from 'vue-multiselect';
import axios from 'axios';

export default {
  name: 'create_ecs',
  components: { Multiselect },
  data: function(){
    return {
      options:[],
      newEcsName: "",

    }
  },
  mounted: async function(){
    let _this = this;
    // _this.gmap = await gmapsInit();
    // _this.searchLocation();
    axios.get('answers/getAllECSProfileName')
      .then(function (response) {
        console.log("Asked all ECS: ",response.data);
        _this.options = response.data;
      })
      .catch(function (error) {
        console.log("Menu get client error:", error);
        // console.log(error);
      });

  },
  methods:{
    createECS() {
      let _this = this;
      console.log("Creating ECS...")
      axios.post('questions/query/createECSName', {
        "ecsname": this.newEcsName
      })
          .then(response => {
            _this.ecs_id = response.data;
            axios.post('questions/query/setUserECS', {
              "ecsid": _this.ecs_id
            }).then(response => {
              console.log(response);
              _this.$router.replace('/ecs-profile?id='+_this.ecs_id, () => _this.$router.go(0));
            })
                .catch(error => console.log(error));
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  },
  computed: {
    filteredOptions() {
      return this.options.filter(item=>{return item.ecs_name === this.newEcsName});
    },
    state() {
      if(this.newEcsName.length >= 4){
        return this.filteredOptions.length <= 0
      }
      else return false;

    },
    invalidFeedback() {
      if (this.newEcsName.length > 0) {
        if(this.filteredOptions.length > 0) return 'This ECS is already registered.';
        return 'Enter at least 4 characters.'
      }
      return ''
    }
  }
}
</script>

<style scoped>
  #map{
    height: 14rem;
    width: 100%;
    margin-top: 10px;
  }

  .createECS{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  h2 {
    color: var(--green-primary);
  }

</style>
