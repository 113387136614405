<template lang="pug">
    b-row.mt-2.mb-2.dropdownCol
        b-col(sm="12")
            multiselect(
              ref="multiselect"
              :disabled="disabled"
              @change="changeEvent"
              v-model="dataResult"
              :options="listValues"
              track-by="name"
              label="name"
              :taggable="true"
              @tag="addTag"
              :multiple="question.multichoice"
              :close-on-select="true"
              :custom-label="customLabel",
              :show-labels="false"
              :placeholder="$t('placeholder.dropdown')").form-rounded
                template(slot="option", slot-scope="props")
                  span.option__desc
                    span.option__title(v-if="!showTooltip") {{ props.option.name }}
                    span.option__title(v-else style="width:100%;" ) {{ props.option.name }}
                      div(v-b-tooltip.hover.left="{ customClass: 'dropwdown-tooltip'}" :title="findTooltip(props.option.code2)").info i
            //b-form-checkbox(@change="changeEvent" v-model="isCustom" :value="true" :unchecked-value="false") Other (Please specify)


</template>

<script>
  import utilMixin from "../../mixin/utilsMixin";
  import Multiselect from 'vue-multiselect';

  export default {

    components: { Multiselect },
    name: "questionDropdownCol",
    props: ['question', 'answered','sendResult'],
    mixins: [utilMixin],
    data: function() {
      return{
        dataResult: this.sendResult,
        selectAnswer: [],
        isCustom: false,
        customDataResult: "",
        langId: 1,
        disabled: false,
        relateds: {
          "187_1": ["187_1","187_2"],
          "187_2":["187_3","187_7","187_8"],
          "187_3":["187_12","187_13"],
          "187_4":["187_9","187_10","187_11"],
          "187_5":["187_4","187_5","187_6"],
          "187_6":["187_14","187_15","187_16","187_26"],
          "187_7":["187_17","187_18","187_19"],
          "187_8":["187_20","187_21","187_22","187_25"],
          "187_9":["187_23","187_24"],
          "187_10":["187_27","187_28","187_29","187_30","187_31","187_32","187_33","187_34"],
          "189_1": ["189_1"],
          "189_2": ["189_2","189_3","189_4"],
          "189_3": ["189_5"],
          "189_4": ["189_6"],
          "189_5": ["189_7","189_8","189_9","189_10","189_11"],
          "189_6": ["189_12","189_13"],
          "189_7": ["189_14","189_15","189_16","189_17","189_18","189_19","189_20"],
          "189_8": ["189_21","189_22","189_23"]
        },
        showTooltip: false,
        related: null
      }
    },
    mounted: function()
    {
      this.initListeners(this.question);
    },
    watch: {
      dataResult: function(val)
      {
        console.log('change data result',val);
        if(val)
        {
          if(this.question.custom_ids=="category")
          {
            this.$root.$emit("category_choose", val);
          }
          else if(this.question.custom_ids=="189_categories")
          {
            this.$root.$emit("category_choose_189", val);
          }
        }

      },
      question: function(val)
      {
        console.log('change question',val);
        this.initListeners(val);
      }
    },
    methods:{
      findTooltip: function(code2){
        let resp = Object.keys(this.relateds).find(element => this.relateds[element].indexOf(code2) >=0);
        let tooltip = "";
        if(this.question.custom_ids === "expertise")
        {
          return `${this.$t('tooltip_187.187_0')} ${this.$t('tooltip_187.'+code2)}`;
        }
        else if(this.question.custom_ids === "189_categories")
        {
          return this.$t('tooltip_189.'+code2);
        }
        return "";
      },
      initListeners: function(question)
      {
        this.disabled = false;
        let _this = this;
        this.related = null;
        this.showTooltip = false;

        if(question.custom_ids=="expertise")
        {
          this.disabled = true;
          this.$root.$on('category_choose', (data) => {
            _this.related = data.code2;
            _this.disabled = false;
            _this.dataResult = null;
          });
        }
        else if(question.custom_ids=="189_type")
        {
          this.disabled = true;
          this.$root.$on('category_choose_189', (data) => {
            _this.related = data.code2;
            _this.disabled = false;
            _this.dataResult = null;
          });
        }

        if(question.custom_ids=="category")
        {
          this.$root.$emit("category_choose", this.dataResult);
        }

        if(question.custom_ids=="189_categories")
        {
          this.$root.$emit("category_choose_189", this.dataResult);
        }


        if(question.custom_ids === "expertise" || question.custom_ids === "189_categories")
        {
          this.showTooltip = true;
        }
      },
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else {
          let text = this.question['text_1']
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
      },
      changeEvent: function(){
        this.$emit("changeEvent", this);

      },
      getDataResult: function () {
        if (this.isCustom && this.customDataResult != null) {
          return this.customDataResult;
        }
        else if (this.dataResult != null && this.dataResult !== '') {
          return this.dataResult;
        }
        else return null;
      },

      addTag(newTag) {
        console.log("Tag", newTag);
        let id = newTag.substring(0, 2) + Math.floor((Math.random() * 10000000));
        const tag = {
          name: newTag,
          code: id,
          code2: id
        };

        this.question.answers.push([newTag,id,id]);
        //console.log(this.dataResult);
        if(this.question.multichoice) {
          if (this.dataResult) this.dataResult.push(tag);
          else {
            this.dataResult = [];
            this.dataResult.push(tag);
          }
        }
        else this.dataResult = tag;
      },
      customLabel ({ name, desc }) {
        return `${name}`
      }

    },
    computed:{
      // options: function () {
      //   let ret = [];
      //   this.question.answers.forEach(function (answer) {
      //     ret.push({
      //       text: answer.text_1,
      //       value: {
      //         id: answer.internal_id,
      //         text: answer.text_1
      //       }
      //     });
      //   });
      //   return ret;
      // },
      customEnabled: function () {
        return !this.isCustom;
      },
      listValues: function () {
        let ret = [];
        let _this = this;
        try {
          let lang = this.getLangValue;
          //console.log("langID dropdown: ", lang);
          //console.log(this.question.answers);
          this.question.answers.forEach(function (answer) {
            if(answer[lang] === '' || !answer[lang]){
              lang = 0;
              //console.log('answer',(answer[lang]))
            }
            if(_this.question.custom_ids=="expertise" || _this.question.custom_ids=="189_type")
            {
              if(_this.related && _this.relateds[_this.related].find(element => element == answer[2]))
              {
                ret.push({
                  name: answer[lang],
                  code: answer[1],
                  code2: answer[2]
                });
              }

            }
            else
            {
              ret.push({
                name: answer[lang],
                code: answer[1],
                code2: answer[2]
              });
            }
          });
        }
        catch (e) {
          console.error(e);
          return "";
        }

        return ret;
      },
      getLang: function () {
        let language = this.$store.getters.getLanguage.id;
        this.langId = language;
        //console.log("Question langID: ", language);
        return language;
      },
      getLangValue: function () {
        let language = this.$store.getters.getLanguage.id;
        if(language === 1)
          language -= 1;
        else if(language > 1)
          language += 1;
        //console.log("Values langID: ", language);
        return language;
      }
    }
  }
</script>

<style scoped>

.info {
  display:inline-block;
  padding:0px 0px 0px 0px;
  color:var(--green-primary);
  font-size:8px;
  border-radius:50%;
  border:solid 3px var(--green-primary);
  width:20px;
  height:20px;
  font-weight:bold;
  text-align:center;
  position:absolute;
  top:10px;
  right: 10px;
  cursor:pointer;
}

.multiselect__option--highlight .info
{
  color:white;
  border:solid 3px white;

}


</style>

<style>
span span
{
  background:transparent !important;
}

.dropwdown-tooltip .arrow::before {
  border-left-color: #A0CF84 !important;
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
}

.dropdown-tooltip .tooltip-inner,.tooltip-inner{
  background: #A0CF84 !important;
}
</style>