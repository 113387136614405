<template lang="pug">
  .about_content_plan.generalBackground
    b-container.p-4.m-4(fluid="true")
      b-row
        b-col
          //h2 {{ $t('menu.info.about_content') }}
          br
          h5 {{ $t('abouts.content_plan.text.subtitle1') }}
          p(style="white-space: pre-line") {{ $t('abouts.content_plan.text.text1') }}
          h5 {{ $t('abouts.content_plan.text.subtitle2') }}
          p(style="white-space: pre-line") {{ $t('abouts.content_plan.text.text2') }}
          h5 {{ $t('abouts.content_plan.text.subtitle3') }}
          p(style="white-space: pre-line") {{ $t('abouts.content_plan.text.text3') }}


</template>

<script>
export default {
name: "toolbox"
}
</script>

<style scoped>
  .generalBackground{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  h5 {
    color: var(--green-primary);
  }
</style>
