<template lang="pug">
  div
    //.container.login-container
      .row
        .col-md-6.login-form-1
    .login
      b-container.p-0(fluid="true").banner
        b-img(src="/images/banner-login.png" fluid alt="Fluid image;")
        div.bannerTitle
          p {{ $t('login.recoverPassword') }}
      b-container.px-4.pb-4(fluid="true")
        b-row
          b-col
            b-container(fluid="true").mx-4.mb-4
              h5.display-5 {{ $t('login.recoverPassword') }}
              //p.pt-3 {{ $t('signUp.subtitle') }}
        b-row
          b-col
            b-container.signIn.m-4(fluid="true")
              b-row(align-h="center")
                b-col.login-form-1(sm="12" md="8" xl="4" )
                  form().p-4
                    h5.my-3 {{ $t('login.recoverPassword') }}
                    b-form-input.input(v-if="!password_token" type='text' :placeholder="$t('placeholder.email')" v-model='username' style="border-color: var(--light-gray-secondary); border-radius: 25px; font-size: small;" )
                    b-form-input.input(v-if="password_token" type='password' :placeholder="$t('placeholder.password')" required v-model='password' style="border-color: var(--light-gray-secondary); border-radius: 25px; font-size: small;" )
                    b-form-input.input(v-if="password_token" type='password' :placeholder="$t('placeholder.repeatPassword')" required v-model='password_repeat' style="border-color: var(--light-gray-secondary); border-radius: 25px; font-size: small;" )
                    br
                    .form-group.text-center.m-0
                      input.btnSubmit(v-if="!password_token" type='button' :value="$t('login.recoverPassword')" @click='sendRecoverPassEmail')
                      input.btnSubmit(v-else type='button' :value="$t('resetPassword')" @click='resetPassword')
                    template(v-if="msg")
                      b-form-group.text-center
                        span.errorLogin.mx-2 {{ msg }}
                    .form-group.text-center
                      a.ForgetPwd(href='../login'  value='Login') {{ $t('login.signIn') }}
          //.col-md-6
            b-img(src="/images/logo.png" fluid-grow alt="Responsive image")

</template>

<style>
.terms {
  font-size: small;
  font-weight: 600;
}
</style>

<script>
import AuthService from '@/services/AuthService.js';
import axios from "axios";

export default {
  data() {
    return {
      username: '',
      msg: '',
      password_token: this.$route.params.token,
      password: '',
      password_repeat: ''
    };
  },
  mounted() {
    console.log(this.password_token);
  },
  methods: {
    resetPassword(){
      let data = {
        password: this.password,
        password_repeat: this.password_repeat,
        token: this.password_token
      };
      this.msg = '';
      axios.post('reset-password',
          {data}
      )
          .then(response => {
            //console.log("Number of experiences: ", response.data.numberOfExperience)
            console.log(response);
            this.msg = response.data.msg;
          })
          .catch(err => {
            this.msg = err.response.data.msg;
            console.log("Error: ", err)
          }).finally(()=>{
      });
    },
    sendRecoverPassEmail(){
      try {
        const credentials = {
          username: this.username,
        };

        axios.post('recoverPasswordMail',
            credentials
        )
            .then(response => {
              //console.log("Number of experiences: ", response.data.numberOfExperience)
              this.msg = response.data.msg;
            })
            .catch(err => {
              this.msg = err.response.data.msg;
              console.log("Error: ", err)
            }).finally(()=>{
        });

        console.log('send recover pass email');
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    }

  },
  computed: {
    getLang: function () {
      let lang = this.$store.getters.getLanguage;
      return lang;
    }
  }
};
</script>