<template lang="pug">
  #app
    router-view

</template>

<script>


  export default {

    name: "App",

    data: function(){
      return {
        freePaths:[
            "/",
            "/sign-up",
            "/recovery",
            "/recover-password",
            "/about_toolbox",
            "/about_content",
            "/about_content_ecs",
            "/about_content_master_plans",
            "/about_content_food_projects",
            "/about_content_best_practices",
            "/share_knowledge",
            "/share_knowledge_create_profile",
            "/share_knowledge_create_expirence",
            "/share_knowledge_add_plan",
            "/share_knowledge_add_project",
            "/share_knowledge_add_practice",
            "/about_functionalities",
            "/about_functionalities_search",
            "/about_functionalities_assessment",
            "/about_functionalities_design",
            "/unlock_tools",
            "/about_classification",
            "/assessment/",
            "/design-planing",
            "/biz",
            "/report_bugs"
        ]
      }
    },
    metaInfo() {
      return {
        title: "EdiCitNet toolbox",
        meta: [
          { name: 'description', content:  this.$t('meta.description')},
          { property: 'og:title', content: "EdiCitNet toolbox"},
          { property: 'og:site_name', content: 'EdiCitNet toolbox'},
          {property: 'og:type', content: 'website'},
          {name: 'robots', content: 'index,follow'}
        ]
      }
    },
    mounted: function () {

      if (!this.$store.getters.isLoggedIn) {
        console.log();
        if (!this.freePaths.includes(this.$router.currentRoute.fullPath)
        && !this.$router.currentRoute.fullPath.includes("showEcsProfile")
        && !this.$router.currentRoute.fullPath.includes("assessment")
        && !this.$router.currentRoute.fullPath.includes("design-planing")
        && !this.$router.currentRoute.fullPath.includes("recover-password")
      ) {

          // if(((this.$router.currentRoute.fullPath.toLowerCase()).includes("showEcsProfile").toLowerCase()))
          //   console.log("Show ECS Profile")
          // else if(((this.$router.currentRoute.fullPath.toLowerCase()).includes("assessment").toLowerCase())){
          //   console.log("Show ECS Assessment")
            this.$router.push('/login').catch(()=>{});;
          // }
        }
      }
    }
  };
</script>

<style>
  @import "https://fonts.googleapis.com/css?family=Fira+Sans:400,300,300italic,400italic,500,500italic,700,700italic";

  :root {
    --font-family-primary: "Fira Sans",Helvetica,Arial,Lucida,sans-serif;
    --green-primary: #5d8f49;
    --green-oldDesign: #3fcb9a;
    --green-secondary: #c2dcb8;
    --green-tertiary: #f0f9f1;
    --gray-font-color: #706f6f;
    --questions-font-color: #343a40;
    --dark-red: #c51e23;
    --light-gray-secondary: #f8f9fa;
    --light-gray-primary: #e9ecef;
    --bold-text: 600;
    --text-weight: 400;
    --height-child-comp: calc(100vh - 4rem);
  }

  div {
    font-family: var(--font-family-primary);
    color: var(--gray-font-color);
    font-weight: var(--text-weight);
  }

  /*---- BANNERS ----*/
  .banner {
    position: relative;
  }
  .bannerTitle {
    color: white;
    position: absolute;
    bottom: 52%;
    top: 48%;
    left: 20%;
  }

  .bannerTitle > p {
    font-size: 2vw;
    font-weight: var(--bold-text);
  }


  /*---- QUESTION STATEMENT FONT COLOR ----*/
  .col-form-label {
    color: var(--questions-font-color);
  }

  /*---- BUTTONS ----*/
  .buttonRed.btn-secondary {
    color: #fff;
    background-color: var(--dark-red);
    border-color: var(--dark-red);
    font-weight: var(--bold-text);
    font-size: small;
    overflow: hidden;
    min-width: fit-content;
  }

  .buttonRed.btn-secondary:hover {
    color: #fff;
  }



  .buttonGreen.btn-secondary  {
    color: #fff;
    background-color: var(--green-primary);
    border-color: var(--green-primary);
    font-weight: var(--bold-text);
    font-size: small;
    overflow: hidden;
    min-width: fit-content;
  }

  .buttonGreenSmall.btn-secondary {
    width: 50%;
    min-width: fit-content;
    border-radius: 1rem;
    color: #fff;
    background-color: var(--green-primary);
    border-color: var(--green-primary);
    font-weight: bold;
    font-size: x-small;
    overflow: hidden
  }


</style>
