<template lang="pug">
  b-container(fluid).comment-div.border-bottom.pt-3.mb-3.pb-3
    b-row
      b-col.user
        span
          b {{ commentContent.client_name }} | {{ commentContent.creationDate.toLocaleString() }}
        b-button.ml-2(size="sm" variant="outline-danger" v-if="myComment" @click="deleteComent") {{$t('comments.delete')}}
    b-row
      b-col.comment-text
        p(style="white-space: pre-line;") {{ commentContent.comment_text }}
    b-row.pl-4
      b-col.comment-ansewer(v-if="commentContent.comment_answer")
        span
          b.pr-1 {{$t('comments.answer')}}:
        p(style="white-space: pre-line;") {{ commentContent.comment_answer }}
      b-col.comment_answer(v-else-if="isAdmin")
        span {{$t('comments.answer')}}
        b-textarea(v-model="currentAsnwer")
        .sendButton
          b-button.float-right.mt-3(@click="setAnswer") {{$t('comments.sendAnswer')}}

</template>

<script>
import axios from "axios";

export default {
  name: "comment",
  props: ["commentContent", "isAdmin"],
  computed:{
    myComment() {
      return this.commentContent.client_uid === this.$store.getters.getUser.id
    }
  },
  data(){
    return {currentAsnwer: ""}
  },
  methods:{
    async deleteComent(){
      try{
        let comment = await axios.post("comments/deleteComment",{id_comment: this.commentContent.id})
        console.log(comment)
        if(comment) this.$emit("deleteComment",this.commentContent.id)
      }
      catch (err){
        console.log(err)
      }
    },
    async setAnswer(){
      try{
        let comment = await axios.post("comments/updateAnswerComment",{id_ecs: this.commentContent.ecs_id, id_comment: this.commentContent.id, comment_text: this.currentAsnwer})
        console.log(comment)
        this.commentContent.comment_answer = this.currentAsnwer;
      }
      catch (err){
        console.log(err)
      }
    }
  }
}
</script>

<style scoped>

</style>