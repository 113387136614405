<template>
  <path vector-effect="non-scaling-stroke"
        d="M 39.40,25.00
           C 39.40,25.00 35.90,25.00 35.90,25.00
             35.90,25.00 35.90,21.50 35.90,21.50
             35.90,21.10 35.60,20.70 35.10,20.70
             34.60,20.70 34.30,21.00 34.30,21.50
             34.30,21.50 34.30,25.00 34.30,25.00
             34.30,25.00 31.00,25.00 31.00,25.00
             30.60,25.00 30.20,25.30 30.20,25.80
             30.20,26.30 30.50,26.60 31.00,26.60
             31.00,26.60 34.50,26.60 34.50,26.60
             34.50,26.60 34.50,30.00 34.50,30.00
             34.50,30.40 34.80,30.80 35.30,30.80
             35.80,30.80 36.10,30.50 36.10,30.00
             36.10,30.00 36.10,26.50 36.10,26.50
             36.10,26.50 39.60,26.50 39.60,26.50
             40.00,26.50 40.40,26.20 40.40,25.70
             40.40,25.20 39.80,25.00 39.40,25.00 Z
           M 44.90,25.80
           C 44.90,20.40 40.50,16.10 35.20,16.10
             32.30,16.10 29.60,17.40 27.90,19.50
             25.70,16.90 23.20,14.60 20.60,13.80
             18.80,13.20 17.10,13.20 15.50,14.00
             15.50,14.00 15.30,11.00 15.30,11.00
             15.30,9.70 14.20,8.80 13.00,8.80
             11.80,8.90 10.90,9.80 10.80,10.90
             9.90,10.20 8.50,10.30 7.80,11.20
             7.00,12.10 7.00,13.30 7.80,14.20
             6.70,14.40 5.90,15.30 6.00,16.50
             6.00,17.10 6.30,17.60 6.70,18.10
             7.10,18.50 7.60,18.70 8.20,18.70
             8.20,18.70 8.20,18.70 8.30,18.70
             8.30,18.70 11.00,18.60 11.00,18.60
             10.40,20.10 10.40,21.60 11.00,23.30
             12.60,28.10 18.90,32.60 23.80,35.60
             29.20,38.80 34.70,41.10 35.80,41.30
             36.00,41.30 36.10,41.30 36.30,41.30
             37.00,41.30 37.60,41.10 38.00,40.70
             38.50,40.20 38.70,39.40 38.60,38.50
             38.50,38.10 38.10,36.90 37.40,35.30
             41.60,34.30 44.90,30.40 44.90,25.80 Z
           M 13.00,10.20
           C 13.50,10.20 13.90,10.60 13.90,11.00
             13.90,11.00 14.00,13.80 14.00,13.80
             14.00,13.80 12.20,12.20 12.20,12.20
             12.20,12.20 12.20,11.10 12.20,11.10
             12.20,10.60 12.50,10.20 13.00,10.20 Z
           M 8.80,12.10
           C 9.10,11.70 9.70,11.70 10.00,12.10
             10.00,12.10 13.30,15.10 13.30,15.10
             12.90,15.50 12.50,15.90 12.10,16.40
             12.10,16.40 8.80,13.40 8.80,13.40
             8.50,13.00 8.50,12.40 8.80,12.10 Z
           M 8.10,17.20
           C 7.90,17.20 7.60,17.20 7.50,17.00
             7.30,16.80 7.30,16.60 7.30,16.40
             7.30,15.90 7.60,15.50 8.10,15.50
             8.10,15.50 9.20,15.50 9.20,15.50
             9.20,15.50 11.00,17.10 11.00,17.10
             11.00,17.10 8.10,17.20 8.10,17.20 Z
           M 37.10,38.70
           C 37.20,39.30 37.00,39.60 36.90,39.70
             36.80,39.80 36.50,39.90 35.80,39.90
             35.10,39.80 29.90,37.80 24.30,34.50
             24.10,34.40 24.00,34.30 23.80,34.20
             23.80,34.20 24.80,33.20 24.80,33.20
             24.80,33.20 23.80,32.20 23.80,32.20
             23.80,32.20 22.60,33.40 22.60,33.40
             21.40,32.60 20.20,31.80 19.00,30.90
             19.00,30.90 22.20,27.70 22.20,27.70
             22.20,27.70 21.20,26.70 21.20,26.70
             21.20,26.70 17.90,30.00 17.90,30.00
             15.30,27.80 13.00,25.40 12.20,22.90
             11.40,20.60 12.00,18.60 13.90,16.70
             15.30,15.30 16.60,14.70 18.20,14.70
             18.80,14.70 19.50,14.80 20.20,15.00
             21.60,15.50 23.10,16.50 24.50,17.70
             24.50,17.70 20.40,21.80 20.40,21.80
             20.40,21.80 21.40,22.80 21.40,22.80
             21.40,22.80 25.50,18.70 25.50,18.70
             26.10,19.30 26.60,19.90 27.10,20.50
             26.10,22.00 25.50,23.80 25.50,25.80
             25.50,31.20 29.90,35.50 35.20,35.50
             35.40,35.50 35.70,35.50 35.90,35.50
             36.60,37.10 37.00,38.30 37.10,38.70 Z
           M 35.20,34.00
           C 30.70,34.00 27.00,30.30 27.00,25.80
             27.00,21.30 30.70,17.60 35.20,17.60
             39.70,17.60 43.40,21.30 43.40,25.80
             43.40,30.30 39.70,34.00 35.20,34.00 Z" />
</template>

<script>
  export default {
    name: "DesignEcsIcon"
  }
</script>

<style scoped>

</style>