<template lang="pug">
      b-container.pl-4.pr-4.profile(fluid="true")
        b-row.align-self-center.position-sticky.sticky-top.bg-white(style="z-index: -100")
          b-col(sm="4").align-self-center
            h2.m-4 User registration
          b-col(sm="8").align-self-center
            b-progress(height="2rem" :value="progressValue" :variant="progressColor" :max="progressMaxValue" show-progress :animated="progressAnimated")
        b-row
          b-col
            b-container.questions
              template(v-for="question, index in questions")
                  questionOpen(v-if="question.config.type === 'open_answer'" :questionNumber="index" :question="question"   :answered="answers.answers.questions['id_'+question.id]").mb-4
                  questionSingle(v-else-if="question.config.type === 'single_answer'"  @sendResponse="recieveResponse"      :answer="answers.answers.questions['id_'+question.id]" :questionNumber="index" :question="question" :answered="answers.answers.questions['id_'+question.id]").mb-4
                  questionDropdown(v-else-if="question.config.type === 'dropdown-answer'"  @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="index" :question="question" :answered="answers.answers.questions['id_'+question.id]").mb-4
                  questionLocation(v-else-if="question.config.type === 'location_answer'"  @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="index" :question="question" :answered="answers.answers.questions['id_'+question.id]").mb-4
                  questionRange(v-else-if="question.config.type === 'range-answer'"     @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="index" :question="question" :answered="answers.answers.questions['id_'+question.id]").mb-4
                  questionCombination(v-else-if="question.config.type === 'table-combination-answer'"     @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="index" :question="question" :answered="answers.answers.questions['id_'+question.id]").mb-4
                  div(v-else) ToDo: {{ question.config.type }}
</template>

<script>
  import axios from 'axios';

  import questionOpen from './questionTypes/profile/questionOpenProfile';
  import questionSingle from './questionTypes/profile/questionSingleProfile';
  import questionDropdown from './questionTypes/profile/questionDropdownProfile';
  import questionLocation from './questionTypes/profile/questionLocationProfile';
  import questionRange from './questionTypes/profile/questionRangeProfile';
  import questionCombination from './questionTypes/profile/questionTableCombinationProfile';

  export default {
    name: "Profile",
    components: {
      questionOpen,
      questionSingle,
      questionDropdown,
      questionLocation,
      questionRange,
      questionCombination
    },
    data: function () {
      return {
        questions: [],
        answers: {"answers": {}},
        progressValue: 0,
        progressMaxValue: 0,
        progressColor: 'danger',
        progressAnimated: true
      }
    },
    mounted: function(){
      this.$store.commit('SET_TITLE', 'Profile');
      let questions = this.questions;
      let _this = this;
      console.log("Start petition");
      axios.post('questions/getProfileQuestions')
        .then(function (response) {
          console.log(response);
          console.log("Done");
          response.data.forEach(function (question) {
            _this.progressMaxValue++;
            question.config = JSON.parse(question.config);
            questions.push(question);
          });
          _this.refreshProgressBar();
        })
        .catch(function (error) {
          console.log(error);
          if(error.response.status === 401){
            _this.$router.push('/login');
          }
        });
      let answers = this.answers;
      console.log("Loading Answers Client");
      axios.post('answers/getClientProfileAnswers')
        .then(function (response) {
          console.log(response);
          console.log("Done Answers");
          response.data.forEach(function (answer) {
            console.log(answer.answers);
            answers.answers = JSON.parse(answer.answers);
          });
        })
        .catch(function (error) {
          console.log("Error");
          console.log(error);
        });
    },
    methods:{
      stringToLabel: function (text) {
        if (text) {
          let res = text.replace(/\n/g, '<br>');
          return res;
        }
        else return "Not defined";
      },
      recieveResponse: function (answer) {
        console.log("Value sended", answer);
        this.answers.answers.questions["id_"+answer.id_question] = answer;
        this.refreshProgressBar();
      },
      refreshProgressBar: function () {
        let numberAnsweredQuestions = Object.keys(this.answers.answers.questions).length;
        this.progressValue = numberAnsweredQuestions;
        if(this.progressMaxValue === this.progressValue){
          this.progressColor = 'success';
          this.progressAnimated = false;
        }
        else if(this.progressValue/this.progressMaxValue > 0.5 ){
          this.progressColor = 'warning';
        }
      }
    }
  }
</script>

<style scoped>

</style>