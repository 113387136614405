<template lang="pug">
  .profileNew
    b-container.p-0(fluid="true").banner
      b-img(src="/images/banner-myECSprofile.png" fluid alt="Fluid image;")
      div.bannerTitle
        //p {{ $t('ecsForm.title') }}

    b-container.pl-4.pr-4.profile(fluid="true")
      b-row.align-self-center.position-sticky.sticky-top.bg-white
        b-col(sm="1").align-self-center
          b-img(v-show="imageShown" :src="`${imageURL}`" fluid)
          //b-img(v-show="!imageShown" src="/images/addImage.png" fluid)
        b-col(sm="6").align-self-center
          h2.m-4(style="color: var(--questions-font-color);") {{ ecs_name }}
            b-button.ml-5.buttonGreen(pill :href="'/showEcsProfile/'+ecs_id" ) {{ $t('buttons.checkprofile') }}
            b-button.ml-3.buttonGreen(pill href="#" disabled) {{ $t('buttons.monitor') }}

      //- b-row
      //-   b-col
      //-     b-container.m-3
      //-       h5.ECSdescription {{ $t('ecsForm.title') }}
      //-       p.pt-3 {{ $t('ecsForm.subtitle') }}

      b-row
        b-col(style="margin-top: 230px")
          b-container.ml-5.mr-5.mt-2(fluid="true")
            ProgressBarNavigator(:questions="questions" :answers="answers.answers.questions" :steps-labels="createLabels" :max="progressMaxValue" :value="progressMaxValue"
              v-bind:key="progressValue" :keyPoints="keyPoints", :progressStep="step+1" v-on:setStep="clickAnswer")
      b-row
        b-col
          b-container.questions.m-4(fluid="true")
            div(v-if="question")
              questionOpen(v-if="question.config.type === 'open_answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question", :nQuestions="questions.length").mb-4
              questionFileUpload(v-else-if="question.config.type === 'file_upload'" :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question", :nQuestions="questions.length" ).mb-4
              questionSingle(v-else-if="question.config.type === 'single_answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"      :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
              questionMulti(v-else-if="question.config.type === 'multi_answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"      :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
              //questionDropdown(v-else-if="question.config.type === 'dropdown-answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]").mb-4
              questionLocation(v-else-if="question.config.type === 'location_answer'" :ecs_id="ecs_id" @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
              questionRange(v-else-if="question.config.type === 'range-answer'" :ecs_id="ecs_id"   @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
              questionTableCombination(v-else-if="question.config.type === 'table-combination-answer'"  :ecs_id="ecs_id"   @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
              questionScore(v-else-if="question.config.type === 'score_answer'"  :ecs_id="ecs_id"   @sendResponse="recieveResponse"  :answer="answers.answers.questions['id_'+question.id]" :questionNumber="step" :question="question" :answered="answers.answers.questions['id_'+question.id]", :nQuestions="questions.length").mb-4
              div(v-else) Question format error
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import axios from 'axios';

  import questionOpen from './questionTypes/profileECS/questionOpenProfileECS';
  import questionRange from './questionTypes/profileECS/questionRangeProfileECS';
  import questionLocation from './questionTypes/profileECS/questionLocationProfileECS';
  import questionFileUpload from './questionTypes/profileECS/questionFileUploadProfileECS';
  import questionSingle from './questionTypes/profileECS/questionSingleProfileECS';
  import questionScore from './questionTypes/profileECS/questionScoreProfileECS';
  import questionMulti from './questionTypes/profileECS/questionMultiProfileECS';
  import questionDropdown from './questionTypes/profileECS/questionDropdownProfileECS';
  import questionTableCombination from './questionTypes/profileECS/questionTableCombinationProfileECS';
  import ProgressBarNavigator from "@/components/ProgressBarNavigator";

  export default {
    name: "EcsProfile",
    components: {
      ProgressBarNavigator,
      Multiselect,
      questionOpen,
      questionRange,
      questionLocation,
      questionMulti,
      questionDropdown,
      questionSingle,
      questionScore,
      questionFileUpload,
      questionTableCombination
    },
    data: function () {
      return {
        questions: [],
        answers: {"answers": {
            "questions": {}
          }
        },
        progressValue: 0,
        progressMaxValue: 0,
        ecs_id: "",
        ecs_name: "",
        url_answer: '/answers/setECSProfileAnswer',
        question: null,
        step: 0,
        imageShown: true,
        imageURL: "",
        // ids of monitoring questions
        keyPoints: [62,75,182,51,146,52,78,86,65,67,134,137,69]
      }
    },
    computed: {
      createLabels() {
        let labels = [];
        for (let i=0;i<=38;i++){
          if (i == this.keyPoints[0]) labels.push(this.$t('progressBar.basicProfile'));
          else if (i == this.keyPoints[1]) labels.push(this.$t('progressBar.productsActivities'));
          else if (i == this.keyPoints[2]) labels.push(this.$t('progressBar.participationNetwork'));
          else if (i == this.keyPoints[3]) labels.push(this.$t('progressBar.financialInformation'));
          else if (i == this.keyPoints[4]) labels.push(this.$t('progressBar.sustainabilityAssessment'));
          else labels.push('')
        }



        return labels
      }
    },
    mounted: async function () {
      console.log(this.$route.query.id);
      this.ecs_id = this.$route.query.id;
      let _this = this;

      let questions = this.questions;
      console.log("Start petition");
      await axios.post('answers/getECSProfileImage', {"id_ecs": _this.ecs_id})
        .then(function (response) {
          if(response.data !== "") _this.imageURL = 'https://edicitnet.icra.cat/images/'+response.data;
          else _this.imageURL = 'https://toolbox.edicitnet.com/images/addImage.png';
        })
        .catch(function (error) {
          console.log("Error getting answers");
          console.log(error);
        });

      let returnIsValid = await axios.post('questions/getECSProfileQuestions')
        .then(function (response) {
          console.log(response);
          if(response.status === 401){
            console.log("Unauthorized");
            alert("Unauthorized please re-login");
            return false;
          }
          else {
            console.log("Done getting questions.");
            response.data.forEach(function (question) {
              //console.log("showQuestion")
              //console.log(question, question.config)
              question.config = JSON.parse(question.config);
              _this.progressMaxValue++;
              questions.push(question);

            });

            return true;
          }
        })
        .catch(function (error) {
          return false;
          console.log("Error getting questions: ", error);
        });

      console.log("Valid Call Qurestions:", returnIsValid);

      let answers = this.answers;
      console.log("Loading Answers Client");
      await axios.post('answers/getECSProfileAnswers',
        {"id_ecs": _this.ecs_id, "client_id": _this.$store.getters.getUser.id}
      )
        .then(function (response) {
          console.log(response);
          console.log("Done Answers ECS");
          if(!response.data){
            //answers.answers = {"questions": {}};
            console.log("No data found");
            _this.step = 0;

          } else {
            response.data.forEach(function (answer) {
              console.log(answer);
              _this.ecs_name = answer.ecs_name;

              _this.answers.answers = JSON.parse(answer.answers);
              _this.step = Object.keys(_this.answers.answers.questions).length;
              if(_this.step >= _this.questions.length){
                _this.step = _this.questions.length -1;
              }
              // console.log(_this.answers.answers.questions["id_94"]);
              // if (_this.answers.answers.questions["id_94"]===undefined){
              //   _this.imageShown = false;
              // } else {
              //   _this.imageShown = _this.answers.answers.questions["id_94"].answer;
              // }

            });
          }

          if (_this.$route.query.step){
            console.log("Step is set",_this.$route.query.step)
            _this.step = Number(_this.$route.query.step)
          }

          _this.refreshProgressBar();


          _this.question=questions[_this.step];


        })
        .catch(function (error) {
          console.log("Error getting answers");
          console.log(error);
        });

    },
    methods: {
      recieveResponse: function (answer, text) {

        if(answer !== undefined){
          console.log("Value sended", answer);
          console.log(answer.id_question);
          this.answers.answers.questions["id_"+answer.id_question] = answer;
          console.log("id_"+answer.id_question);
          console.log(answer);

          // if (answer.id_question === 94){
          //   this.imageShown = answer.answer;
          //   //this.$router.go(0);
          // }

        }

        if(text === "next" && this.step<this.questions.length){
          this.step++;
        }else if(text === "previous"){
          this.step--;
        }
        this.refreshProgressBar();


        console.log(this.step, this.questions[this.step])
        this.question=this.questions[this.step];
        console.log(this.question)
        console.log(this.answers.answers.questions['id_'+this.question.id])
      },
      refreshProgressBar: function () {
        let numberAnsweredQuestions = Object.keys(this.answers.answers.questions).length;
        this.progressValue = numberAnsweredQuestions;
      },
      clickAnswer (text) {
        //if (Number(text)-1 <= this.progressValue){
          this.step = Number(text)-1
          this.recieveResponse();
        //}
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
  .profileNew{
    background-color: white;
    border-radius: 0rem;
    padding: 0rem;
    margin: 2rem 2rem 0;
    min-height: var(--height-child-comp);
  }

  .ECSdescription{
    color: var(--green-primary);
    font-weight: bold;
  }



</style>
